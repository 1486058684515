import { connect } from 'react-redux';

import KeywordSearchField from './keyword-search-field';

import * as searchBarActions from '../search-bar-actions';
import { ShellState, ShellUseDispatch } from '../../../reducers/common';

const mapState = ({ searchBar, experiments, baseConfig: { device } }: ShellState) => ({
    suggestions: searchBar.keyword.suggestions,
    useRecentHistory: searchBar.keyword.useRecentHistory,
    query: searchBar.keyword.value,
    experiments,
    device,
});

const mapDispatch = (dispatch: ShellUseDispatch) => ({
    setKeywordAndUpdateSuggestions: (value: string) => {
        dispatch(searchBarActions.setKeywordAndUpdateSuggestions(value));
    },
    loadRecentKeywordHistory: () => {
        dispatch(searchBarActions.loadRecentKeywordHistory());
    },
    selectSuggestion: (
        suggestion: {
            name: string;
            highlight?: string;
            category?: string;
            categoryDisplayName?: string;
        },
        useRecentHistory: boolean
    ) => {
        dispatch(searchBarActions.selectKeywordSuggestion(suggestion, useRecentHistory));
    },
    removeSingleFromHistory: (suggestion: string) => {
        dispatch(searchBarActions.removeSingleSearchFromHistory(suggestion));
    },
    clearAllFromHistory: () => {
        dispatch(searchBarActions.clearAllSearchesFromHistory());
    },
});

export default connect(mapState, mapDispatch)(KeywordSearchField);
