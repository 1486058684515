import React, { useEffect, useState } from 'react';

import TypeAheadOptions from '@gumtree/ui-library/src/form/type-ahead/type-ahead-options';
import Input from '@gumtree/ui-library/src/form/input';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@gumtree/ui-library';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';

import * as searchBarActions from '../search-bar-actions';
import {
    StyledLocationContainer,
    StyledTitle,
    StyledTopSection,
    StyledIcon,
    StyledField,
    StyledDialog,
    ErrorWindow,
} from './modal.style';
import {
    locationFieldAriaLabel,
    locationFieldPlaceholderText,
    searchFieldAriaLabel,
    searchFieldPlaceholderText,
} from '../constants';
import { ShellState, ShellUseDispatch } from '../../../reducers/common';
import { ActiveField } from './types';
import useTypeAheadOptionsParams from './type-ahead-options-hook';

export const getState = (state: ShellState) => {
    return {
        keywordSuggestions: state.searchBar?.keyword?.suggestions || [],
        locationSuggestions: state.searchBar?.location?.suggestions || [],
        searchQuery: state.searchBar?.keyword?.value || '',
        locationQuery: state.searchBar?.location?.value || '',
        keywordUseRecentHistory: state.searchBar?.keyword?.useRecentHistory,
        locationUseRecentHistory: state.searchBar?.location?.useRecentHistory,
    };
};

export const SearchModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    const [currentActiveField, setCurrentActiveField] = useState<ActiveField>(ActiveField.KEYWORD);
    const [keywordInputRef, setKeywordInputRef] = useState<HTMLInputElement | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (keywordInputRef) {
            keywordInputRef.focus();
        }
    }, [keywordInputRef]);

    const dispatch = useDispatch() as ShellUseDispatch;
    const { searchQuery, locationQuery } = useSelector(getState);

    const typeAheadOptionsParams = useTypeAheadOptionsParams({
        currentActiveField,
        searchQuery,
        locationQuery,
        isOpen,
    });

    return (
        <StyledDialog isOpen={isOpen} onClose={onClose}>
            <StyledTopSection>
                <StyledTitle>Search</StyledTitle>
                <StyledField>
                    <StyledIcon type="magnifying-glass" />
                    <Input
                        {...qaAttribute('search-modal-keyword-input')}
                        aria-label={searchFieldAriaLabel}
                        placeholder={searchFieldPlaceholderText}
                        autoComplete="off"
                        className="input"
                        id="search-modal-keyword-input"
                        name="search-modal-keyword-input"
                        inputRef={(element: HTMLInputElement): void => {
                            setKeywordInputRef(element);
                        }}
                        onFocus={() => {
                            setCurrentActiveField(ActiveField.KEYWORD);
                        }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const {
                                target: { value },
                            } = event;
                            dispatch(searchBarActions.setKeywordAndUpdateSuggestions(value));
                        }}
                        onClear={() => {
                            dispatch(searchBarActions.setKeywordAndUpdateSuggestions(''));
                        }}
                        value={searchQuery}
                    />
                </StyledField>
                <StyledLocationContainer>
                    <StyledField>
                        <StyledIcon type="beacon" />
                        <Input
                            {...qaAttribute('search-modal-location-input')}
                            aria-label={locationFieldAriaLabel}
                            placeholder={locationFieldPlaceholderText}
                            autoComplete="off"
                            className="input"
                            id="search-modal-location-input"
                            name="search-modal-location-input"
                            onFocus={() => {
                                setCurrentActiveField(ActiveField.LOCATION);
                            }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const {
                                    target: { value },
                                } = event;
                                dispatch(searchBarActions.setLocationAndUpdateSuggestions(value));
                            }}
                            onClear={() => {
                                dispatch(searchBarActions.setLocationAndUpdateSuggestions(''));
                            }}
                            value={locationQuery}
                        />
                    </StyledField>
                </StyledLocationContainer>
                {/* A visually-hidden Button is needed so that Enter key in the input will trigger a submit */}
                <button style={{ display: 'none' }} type="submit" />
            </StyledTopSection>
            <TypeAheadOptions
                icon={<Icon type={typeAheadOptionsParams.iconType} size="medium" />}
                {...typeAheadOptionsParams}
            />
            {errorMessage && (
                <ErrorWindow
                    message={errorMessage}
                    onCloseClick={(e) => {
                        e.preventDefault(); // prevent button triggering submit
                        setErrorMessage(undefined);
                    }}
                />
            )}
        </StyledDialog>
    );
};
