import React from 'react';
import classnames from 'classnames';

import './container.scss';

const getClassName = (className, disable, isSection) => {
    const disabledSizesClassName = disable.map((size) => `container--no-${size}`).join(' ');

    return classnames({
        container: true,
        [disabledSizesClassName]: true,
        [className]: !!className,
        section: isSection,
    });
};

interface Props {
    /** We require a container to have children. */
    children: React.ReactNode;
    className?: string;
    disable?: ('l' | 'xl' | 'xxl')[];
    isSection?: boolean;
}

const Container: React.FC<Props> = ({
    children,
    className,
    disable,
    isSection,
    ...props
}) => (
    <div className={getClassName(className, disable, isSection)} {...props}>
        {children}
    </div>
);

Container.defaultProps = {
    className: '',
    disable: [],
    isSection: false,
};

export default Container;
