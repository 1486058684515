import React from 'react';
import PropTypes from 'prop-types';

const styles = {
    fontWeight: 'bold',
    fontSize: '13px',
    color: '#007fb0',
    textDecoration: 'none',
    cursor: 'pointer',
};

const DisplayUrl = ({ url }) => <span style={styles}>{url}</span>;

DisplayUrl.propTypes = {
    url: PropTypes.string.isRequired,
};

export default DisplayUrl;
