import { trackV2 } from "@gumtree/shared/src/util/track-ga-event";

const reportCarouselScrollClick = (eventLabel, pageType, window) => {
    if (!window || !window.dataLayer) {
        return;
    }

    trackV2('BingAdsCarouselClicked', eventLabel, {
        eventCategory: pageType,
        eventAction: 'BingAdsCarouselClicked',
    });
};

export const reportCarouselScrollLeftClick = (pageType, window) => {
    reportCarouselScrollClick('LeftChevron', pageType, window);
};

export const reportCarouselScrollRightClick = (pageType, window) => {
    reportCarouselScrollClick('RightChevron', pageType, window);
};
