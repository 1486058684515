import React from 'react';
import PropTypes from 'prop-types';

import './sub-menu-item.scss';

const SubMenuItem = ({ children }) => (
    <li className="sub-menu-item">
        {children}
    </li>
);

SubMenuItem.propTypes = {
    children: PropTypes.node.isRequired
};

export default SubMenuItem;
