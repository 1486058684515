import { trackGA4Event } from "./ga4-shared";

export const GTM_TRACKER_EXCEPTIONS: { [key: string]: { id: string; message: string } } = {
    NO_EVENT_NAME: {
        id: 'NO_EVENT_NAME',
        message: '[GtmTracker]: No event name or event name is not a string',
    },
};

export const objectToMatrixUri = (obj: { [key: string]: any }): string => {
    return Object.keys(obj)
        .reduce((acc, key) => `${acc}${key}=${obj[key]};`, '')
        .slice(0, -1);
};

export const generateEventPayload = ({
    action,
    label,
    others = {},
    eventCallback,
}: {
    action: string;
    label?: string | object;
    others?: object;
    eventCallback?: (value: any) => void;
}) => {
    if (!action || typeof action !== 'string') {
        throw GTM_TRACKER_EXCEPTIONS.NO_EVENT_NAME;
    }
    let transformedLabel = '';
    if (label) {
        if (typeof label === 'object') {
            transformedLabel = objectToMatrixUri(label);
        } else {
            transformedLabel = label;
        }
    }

    return {
        event: action,
        eventLabel: transformedLabel,
        ...others,
        eventCallback,
    };
};

export const trackGtmEvent = ({
    action,
    label,
    others = {},
}: {
    action: string;
    label?: string | object;
    others?: object;
}) => {
    const eventPayload = generateEventPayload({
        action,
        label,
        others,
    });

    trackGA4Event<GA4.GA3LegacyEvent>({
        event: 'ga3_legacy_event',
        legacyEvent: {
            name: eventPayload.event,
            label: eventPayload.eventLabel,
            extra: undefined,
        },
    });
};
