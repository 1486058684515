import { SET_UNREAD_MESSAGES_COUNT } from '@gumtree/shared/src/constants/message-centre';
import axios from 'axios';
import logger from '../../logger';
import type { ShellState, ShellUseDispatch } from '.';

type MessagesUnreadResponse = {
    totalUnreadCount: number;
};

export const setUnreadMessagesCount = (unreadMessagesCount = 0) => ({
    type: SET_UNREAD_MESSAGES_COUNT,
    payload: unreadMessagesCount,
});

export const fetchUnreadMessagesCount = () => async (dispatch: ShellUseDispatch, getState: () => ShellState) => {
    const {
        baseConfig: { bffUrl },
    } = getState();

    try {
        const { data: { totalUnreadCount } } = await axios.get<MessagesUnreadResponse>(`${bffUrl}/bff-api/message-centre/messages/unread`);
        dispatch(setUnreadMessagesCount(totalUnreadCount));
    } catch (e) {
        if (e.response && e.response.status >= 300) {
            return false;
        } else {
            logger.error('client: message-centre/messages/unread', e);
        }
    }
    return true;
};

export type State = number;

const initialState: State = 0;

export default (state: State = initialState, { type, payload }): State => {
    switch (type) {
        case SET_UNREAD_MESSAGES_COUNT: {
            const count = payload && payload > 0 ? payload : 0;
            return count;
        }

        default:
            return state;
    }
};
