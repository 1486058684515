import React from 'react';
import PropTypes from 'prop-types';

const Description = ({ children: text }) => {
    const styles = {
        fontSize: '13px',
        maxWidth: '600px',
        lineHeight: '20px',
        color: '#3c3241',
        margin: '0',
        textAlign: 'justify',
        ...(text.length > 100
            ? {
                  maxHeight: '41px',
                  overflow: 'hidden',
                  position: 'relative',
                  display: 'block',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
              }
            : {}),
        ...(window?.innerWidth >= 768 ? { marginRight: '20%' } : {}),
    };

    return <p style={styles}>{text}</p>;
};

Description.propTypes = {
    children: PropTypes.string.isRequired,
};

export default Description;
