import { ExperimentNames } from '@gumtree/shared/src/model/experiment';
import { ShellState } from '../reducers/common';

export { trackV2 } from '@gumtree/shared/src/util/track-ga-event';

export const navigate = (url: string) => ({
    type: 'NAVIGATE_TO_URL',
    url,
});

/** Redirect to `baseConfig.loginUrl` and then return to current url */
export const loginThenComeBack = () => {
    return (dispatch, getState: () => ShellState) => {
        const {
            baseConfig: { loginUrl, buyerUrl },
            experiments
        } = getState();
        const inExperimentGTC1815 = experiments?.[ExperimentNames.REGISTER_LOGIN_BFF] === 'B';

        dispatch(navigate(`${inExperimentGTC1815 ? `${buyerUrl}/login` : loginUrl}?cb=${encodeURIComponent(window.location.href)}`));
    };
};

export const launchBanner = (target: string) => ({
    type: 'OPEN_BANNER_TAKEOVER',
    target,
});

export const trackAndNavigate = (_eventName: string, url: string, _eventLabel?: string) => {
    return (dispatch) => {
        dispatch(navigate(url));
    };
};
