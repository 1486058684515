import { Nullable } from 'typescript-nullable';
import Pubmatic from './partners/pubmatic';
import Rubicon from './partners/rubicon';
import SlotName from './common/slot-name';
import IndexExchange from './partners/index-exchange';
import AppNexus from './partners/appnexus';
import MediaGrid from './partners/media-grid';
import Ozone from './partners/ozone';
import { getSizesBySlotName } from './media-types/banner';
import ScreenSize from './common/screen-size';
import Criteo from './partners/criteo';
import Adyoulike from './partners/adyoulike';
import DeviceType from './common/device-type';
import PageType from './common/page-type';

const slotConfig = (
    pageType: PageType,
    deviceType: DeviceType,
    screenSize: ScreenSize,
    slotName: SlotName,
    categoryL1: Nullable<string>,
    categoryL2: Nullable<string>,
    categoryL3: Nullable<string>,
    keywords: Nullable<string>,
    experiments: Array<string>,
    vipAdId: Nullable<number>
) => {
    if (Nullable.isNone(slotName)) {
        return;
    }

    const sizes = getSizesBySlotName(pageType, deviceType, slotName, screenSize);

    const bids = [
        new Rubicon(
            pageType,
            deviceType,
            slotName,
            screenSize,
            keywords,
            categoryL1,
            categoryL2,
            categoryL3,
            experiments
        ),
        new AppNexus(
            pageType,
            deviceType,
            slotName,
            screenSize,
            keywords,
            categoryL1,
            categoryL2,
            categoryL3,
            experiments,
            vipAdId
        ),
        new Criteo(
            pageType,
            deviceType,
            slotName,
            screenSize,
            keywords,
            categoryL1,
            categoryL2,
            categoryL3,
            experiments
        ),
        new IndexExchange(
            pageType,
            deviceType,
            slotName,
            screenSize,
            keywords,
            categoryL1,
            categoryL2,
            categoryL3,
            experiments
        ),
        new Pubmatic(
            pageType,
            deviceType,
            slotName,
            screenSize,
            keywords,
            categoryL1,
            categoryL2,
            categoryL3,
            experiments
        ),
        new MediaGrid(
            pageType,
            deviceType,
            slotName,
            screenSize,
            keywords,
            categoryL1,
            categoryL2,
            categoryL3,
            experiments
        ),
        new Adyoulike(
            pageType,
            deviceType,
            slotName,
            screenSize,
            keywords,
            categoryL1,
            categoryL2,
            categoryL3,
            experiments
        ),
        new Ozone(
            pageType,
            deviceType,
            slotName,
            screenSize,
            keywords,
            categoryL1,
            categoryL2,
            categoryL3,
            experiments
        ),
    ]
        .map((partner) => partner.toObject())
        .filter(Nullable.isSome);

    return {
        code: slotName.toString(),
        bids,
        mediaTypes: {
            banner: {
                sizes,
            },
        },
        sizes,
    };
};

export default slotConfig;
