import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ text }) => {
    const styles = {
        color: '#3c3241',
        fontWeight: 'bold',
        fontSize: '15px',
        height: 'auto',
        marginRight: '35px',
        margin: '0',
        overflow: 'hidden',
        display: 'block',
        position: 'relative',
        ...(window.innerWidth >= 768 ? { textOverflow: 'ellipsis', whiteSpace: 'nowrap' } : {}),
    };

    return <h2 style={styles}>{text}</h2>;
};

Title.propTypes = {
    text: PropTypes.string.isRequired,
};

export default Title;
