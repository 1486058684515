import { Nullable } from 'typescript-nullable';
import Partner from './partner';
import SlotName from '../common/slot-name';
import ScreenSize from '../common/screen-size';
import DeviceType from '../common/device-type';
import PageType from '../common/page-type';

export default class Addyoulike extends Partner {
    readonly bidder = 'adyoulike';
    mapping = {
        [PageType.ResultsPage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.integratedMpu]: '81ff2200127f7d1e3a442f7960f4c467',
                    [SlotName.rSkyT]: '9516b5b381f99aa4442eb2505843bad7',
                    [SlotName.rSkyT2]: 'e7e2be76d5998b594318817c94ee52a8',
                    [SlotName.tBanner]: '0b8f0d11d21ccb6d051fc8e9180669e9',
                    [SlotName.rSkyB]: 'b1c817c27ba81a759bb706cf6c9d10b1',
                    [SlotName.rSkyB2]: '75eaec13d451b428126882b21706a262',
                    [SlotName.integratedListing]: 'a2ad6cf03dc8262891c3a7bcc24b322b',
                },
                [ScreenSize.XL]: {
                    [SlotName.integratedMpu]: '81ff2200127f7d1e3a442f7960f4c467',
                    [SlotName.rSkyT]: '9516b5b381f99aa4442eb2505843bad7',
                    [SlotName.rSkyT2]: 'e7e2be76d5998b594318817c94ee52a8',
                    [SlotName.tBanner]: '0b8f0d11d21ccb6d051fc8e9180669e9',
                    [SlotName.rSkyB]: 'b1c817c27ba81a759bb706cf6c9d10b1',
                    [SlotName.rSkyB2]: '75eaec13d451b428126882b21706a262',
                    [SlotName.integratedListing]: 'a2ad6cf03dc8262891c3a7bcc24b322b',
                },
                [ScreenSize.L]: {
                    [SlotName.integratedMpu]: '81ff2200127f7d1e3a442f7960f4c467',
                    [SlotName.tBanner]: '0b8f0d11d21ccb6d051fc8e9180669e9',
                    [SlotName.integratedListing]: 'a2ad6cf03dc8262891c3a7bcc24b322b',
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.integratedMpu]: '81ff2200127f7d1e3a442f7960f4c467',
                    [SlotName.tBanner]: '0b8f0d11d21ccb6d051fc8e9180669e9',
                    [SlotName.integratedListing]: 'a2ad6cf03dc8262891c3a7bcc24b322b',
                    [SlotName.baseMpu]: '8b2f89c0f554db7cab70a3fc995260ff',
                },
                [ScreenSize.M]: {
                    [SlotName.integratedMpu]: '81ff2200127f7d1e3a442f7960f4c467',
                    [SlotName.tBanner]: '0b8f0d11d21ccb6d051fc8e9180669e9',
                    [SlotName.integratedListing]: 'a2ad6cf03dc8262891c3a7bcc24b322b',
                    [SlotName.baseMpu]: '8b2f89c0f554db7cab70a3fc995260ff',
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.integratedMpu]: '81ff2200127f7d1e3a442f7960f4c467',
                    [SlotName.integratedListing]: '80c3cabc6a378cc8cd0309b8a27b45b2',
                    [SlotName.floatingFooter]: '273a554585f2d559e1b8f32e2dbcc338',
                },
                [ScreenSize.XS]: {
                    [SlotName.integratedMpu]: '81ff2200127f7d1e3a442f7960f4c467',
                    [SlotName.integratedListing]: '80c3cabc6a378cc8cd0309b8a27b45b2',
                    [SlotName.floatingFooter]: '273a554585f2d559e1b8f32e2dbcc338',
                },
            },
        },
        [PageType.VIP]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.vipBanner]: '14cd84a79a3a5a43462b5f8dc750abea',
                    [SlotName.mpu]: '0800b04f648c9830b1beda37404db8f0',
                    [SlotName.vipMiddleDesktop]: '86c91214661be5867258188d965da4d7',
                },
                [ScreenSize.XL]: {
                    [SlotName.vipBanner]: '14cd84a79a3a5a43462b5f8dc750abea',
                    [SlotName.mpu]: '0800b04f648c9830b1beda37404db8f0',
                    [SlotName.vipMiddleDesktop]: '86c91214661be5867258188d965da4d7',
                },
                [ScreenSize.L]: {
                    [SlotName.vipBanner]: '14cd84a79a3a5a43462b5f8dc750abea',
                    [SlotName.mpu]: '0800b04f648c9830b1beda37404db8f0',
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.vipBanner]: '14cd84a79a3a5a43462b5f8dc750abea',
                    [SlotName.mpu]: '0800b04f648c9830b1beda37404db8f0',
                    [SlotName.fBanner]: '3f18b7004ac2af40fe38a85ac58f630b',
                },
                [ScreenSize.M]: {
                    [SlotName.vipBanner]: '14cd84a79a3a5a43462b5f8dc750abea',
                    [SlotName.mpu]: '0800b04f648c9830b1beda37404db8f0',
                    [SlotName.fBanner]: '3f18b7004ac2af40fe38a85ac58f630b',
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.floatingFooter]: '273a554585f2d559e1b8f32e2dbcc338',
                    [SlotName.vipMiddleMobile]: 'f63c4e6ee4c95cbf3dfb35d9147c0905',
                },
                [ScreenSize.XS]: {
                    [SlotName.floatingFooter]: '273a554585f2d559e1b8f32e2dbcc338',
                    [SlotName.vipMiddleMobile]: 'f63c4e6ee4c95cbf3dfb35d9147c0905',
                },
            },
        },
        [PageType.Homepage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.homeSideAd]: '33493e802b12420dda6b0b827dcfbe9e',
                },
                [ScreenSize.XL]: {
                    [SlotName.homeSideAd]: '33493e802b12420dda6b0b827dcfbe9e',
                },
                [ScreenSize.L]: {
                    [SlotName.homeSideAd]: '33493e802b12420dda6b0b827dcfbe9e',
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.homeSideAd]: '33493e802b12420dda6b0b827dcfbe9e',
                },
                [ScreenSize.M]: {
                    [SlotName.homeSideAd]: '33493e802b12420dda6b0b827dcfbe9e',
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.homeBanner]: '273a554585f2d559e1b8f32e2dbcc338',
                },
                [ScreenSize.XS]: {
                    [SlotName.homeBanner]: '273a554585f2d559e1b8f32e2dbcc338',
                },
            },
        },
    };

    toObject() {
        if (Nullable.isNone(this.getPlacement())) {
            return undefined;
        }

        return {
            bidder: this.bidder,
            params: {
                placement: this.getPlacement(),
            },
        };
    }
}
