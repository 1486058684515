import uniqBy from 'lodash.uniqby';
import { RenderedAd } from './renderer';
import post from './util/post';

const replaceBingWithGumtreeAPI = (impressionFeedbackUrl: string) =>
    impressionFeedbackUrl.replace(
        'https://www.bingapis.com/api/V7/ads/adsimpressionfeedback',
        '/partner/bingimpression'
    );

const sendImpressionFeedback = ({
    renderedAds,
    impressionFeedbackUrl,
}: {
    renderedAds: RenderedAd[];
    impressionFeedbackUrl: string | undefined;
}) => {
    if (!impressionFeedbackUrl) {
        return;
    }

    const shownAds = uniqBy(renderedAds, 'impressionToken');

    const gumtreeImpressionFeedbackUrl = replaceBingWithGumtreeAPI(impressionFeedbackUrl);

    post(gumtreeImpressionFeedbackUrl, { shownAds });
};

export default sendImpressionFeedback;
