import React from 'react';
import { useTheme } from '@emotion/react';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import { ExperimentNames } from '@gumtree/shared/src/model/experiment';
import { DeviceTypes } from '@gumtree/shared/src/types/client-data';
import SideMenuSection from './side-menu-section';
import sideMenuData from './side-menu-data';
import SideNav from '../side-nav/side-nav';
import { sideMenuCss } from './side-menu.style';

interface SideMenuProps {
    baseConfig: {
        buyerUrl: string;
        sellerUrl: string;
        device: {
            type?: DeviceTypes;
            os?: string;
        };
    };
    experiments: { [key: string]: string };
    isLoggedIn?: boolean;
    openDialog?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
}

const SideMenu: React.FC<SideMenuProps> = ({
    baseConfig,
    experiments,
    isLoggedIn = false,
    openDialog,
}) => {
    const theme = useTheme();
    const inExperimentGTC1815 = experiments?.[ExperimentNames.REGISTER_LOGIN_BFF] === 'B';
    const inExperimentGTC2229 = experiments?.[ExperimentNames.LOGIN_MODAL] === 'B';
    const inExperimentGTP2827 = experiments?.[ExperimentNames.MANAGE_ADS_BFF] === 'B';

    return (
        <div className="side-menu-container" css={sideMenuCss(theme)}>
            <SideNav dark right inExperimentGTC2229={inExperimentGTC2229}>
                {({ close }: { close: () => void }) => (
                    <nav className="side-menu" {...qaAttribute('side-menu')}>
                        {sideMenuData(
                            baseConfig,
                            isLoggedIn,
                            inExperimentGTC1815,
                            inExperimentGTC2229,
                            inExperimentGTP2827
                        ).map((section) => (
                            <SideMenuSection
                                key={section.id}
                                isLoggedIn={isLoggedIn}
                                title={section.title}
                                openDialog={openDialog}
                                inExperimentGTC2229={inExperimentGTC2229}
                                onItemClick={close}
                                items={section.items}
                            />
                        ))}
                    </nav>
                )}
            </SideNav>
        </div>
    );
};

SideMenu.defaultProps = {
    isLoggedIn: false,
    openDialog: () => null,
};

export default SideMenu;
