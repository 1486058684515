import { connect } from 'react-redux';

import LocationField from './location-field';

import * as searchBarActions from '../search-bar-actions';
import { ShellUseDispatch } from '../../../reducers/common';

const mapState = ({ searchBar, experiments, baseConfig: { device } }) => ({
    suggestions: searchBar.location.suggestions,
    useRecentHistory: searchBar.location.useRecentHistory,
    query: searchBar.location.value,
    experiments,
    device,
});

const mapDispatch = (dispatch: ShellUseDispatch) => ({
    setLocationAndUpdateSuggestions: (value: string) => {
        dispatch(searchBarActions.setLocationAndUpdateSuggestions(value));
    },
    loadRecentLocationHistory: () => {
        dispatch(searchBarActions.loadRecentLocationHistory());
    },
    selectSuggestion: (suggestion: { name: string }, useRecentHistory: boolean) => {
        dispatch(searchBarActions.selectLocationSuggestion(suggestion, useRecentHistory));
    },
    setLocationSuggestions: (suggestions: { name: string }[]) => {
        dispatch(searchBarActions.setLocationSuggestions(suggestions));
    },
    removeSingleFromHistory: (suggestion: string) => {
        dispatch(searchBarActions.removeSingleLocationFromHistory(suggestion));
    },
    clearAllFromHistory: () => {
        dispatch(searchBarActions.clearAllLocationsFromHistory());
    },
});

export default connect(mapState, mapDispatch)(LocationField);
