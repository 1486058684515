import styled from '@emotion/styled';

import { Container } from '@gumtree/ui-library';
import { fontSizes, colors, mediaQuery, breakpoints, gutterSizes, fontWeights } from '@gumtree/ui-library/src/base/theme';

export const BrowseLinksContainer = styled(Container)`
    clear: both;
    h2 {
        font-size: ${fontSizes.base};
    }

    .tabs {
        background: ${colors.white};

        .tabs-title {
            background: ${colors.white};

            ${mediaQuery.until(breakpoints.small)} {
                padding-bottom: ${gutterSizes.xlarge}
            }

            &:before {
                background: ${colors.white};
            }
        }

        #top-locations-tabpanel {
            text-align: center;
        }

        .tabs-content {
            background: ${colors.white};

            h2 {
                margin: 0 0 ${gutterSizes.medium} ${gutterSizes.base};
            }

            h4 {
                margin: ${gutterSizes.medium} 0;
            }

            .top-searches-content {
                ${mediaQuery.from(breakpoints.medium)} {
                    grid-template-columns: repeat(7, minmax(0, 1fr));
                }

                .section {
                    padding: 0 ${gutterSizes.base};
                }
            }
        }
    }

    .link-list {
        width: 83.3%;
        margin: 0 auto;

        .item {
            margin: 0 5px 5px 0;

            :after {
                padding-left: 10px;
                font-weight: ${fontWeights.bolder};
                color: ${colors.bark20}
            }

            .link {
                padding: 0;
                font-size: ${fontSizes.base};
                font-weight: ${fontWeights.lightBold};
            }
        }
    }
`;
