import { Device, L1Category, L2Category, PageType } from '@gumtree/shared/src/types/client-data';
import { ScreenSize } from '@gumtree/shared/src/util/breakpoints-service';

type AbTests = string[];

export type SlotConfigParams = {
    page: PageType;
    resultSize: number;
    device: Device;
    l1Category: L1Category;
    l2Category: L2Category;
    abTests?: AbTests;
    isClientDataExist?: boolean;
};

export type Mapping = {
    screenSize: string[];
    screenHeight?: number;
    adSize: (number[] | 'fluid')[];
};

export type AdUnit = {
    [key in ScreenSize]: string;
};

export type SlotName =
    | 'premiumNative1'
    | 'premiumNative2'
    | 'textLink'
    | 'vipBanner'
    | 'overlayVideo'
    | 'top3'
    | 'floatingFooter'
    | 'textLinkBase'
    | 'integratedMpu'
    | 'tBanner'
    | 'fBanner'
    | 'headerBanner'
    | 'leaderboard'
    | 'carsHomeStickyBanner'
    | 'carsHomeBanner'
    | 'carsHomeLeaderBoard'
    | 'carsHomeMpu'
    | 'vipMiddleDesktop'
    | 'homeBanner'
    | 'top_takeover'
    | 'homeSideAd'
    | 'browseFooter'
    | 'integratedListing'
    | 'mpu'
    | 'mpu2'
    | 'pixel'
    | 'baseMpu'
    | 'galleryBanner'
    | 'vipMiddleMobile'
    | 'rSkyT'
    | 'rSkyT2'
    | 'rSkyB'
    | 'rSkyB2'
    | 'lSkyB'
    | 'lSkyT'
    | 'partnershipWidget'
    | 'partnershipWidget1'
    | 'partnershipBottom';

export type SlotConfig =
    | {
          pos: string;
          unitPos: AdUnit;
          divId: SlotName;
          mapping: Mapping[];
          startWithCollapsedSlot?: {
              pageType: PageType;
              screenSizes: ScreenSize[];
          }[];
          shouldRenderEvenNotOnDom?: boolean;
      }
    | undefined;

export type SlotOptions = {
    slotName: SlotName;
    abTests?;
    l1Category?: L1Category;
    pageType?: PageType;
};

export const resultsPages: PageType[] = ['ResultsSearch', 'ResultsBrowse'];
export const vipPages: PageType[] = ['VIP', 'eVIP', 'pVIP'];

export const getSlotByName = (options: SlotOptions): SlotConfig => {
    const { slotName, abTests, l1Category, pageType } = options;
    const showLeaderBoardOnly =
        l1Category === 'cars-vans-motorbikes' &&
        (abTests?.includes('GTALL-25626-B') || abTests?.includes('GTALL-25626-C'));

    if (slotName === 'premiumNative1') {
        return {
            divId: 'premiumNative1',
            mapping: [
                {
                    adSize: ['fluid'],
                    screenSize: ['xs', 's', 'm', 'l', 'xl', 'xxl'],
                },
            ],
            pos: 'premiumNative1',
            unitPos: {
                l: 'premiumNative1',
                m: 'premiumNative1',
                s: 'premiumNative1',
                xl: 'premiumNative1',
                xs: 'premiumNative1',
                xxl: 'premiumNative1',
            },
            startWithCollapsedSlot: [
                { pageType: 'ResultsBrowse', screenSizes: ['xs', 's', 'm', 'l', 'xl', 'xxl'] },
                { pageType: 'ResultsSearch', screenSizes: ['xs', 's', 'm', 'l', 'xl', 'xxl'] },
            ],
        };
    }
    if (slotName === 'premiumNative2') {
        return {
            divId: 'premiumNative2',
            mapping: [
                {
                    adSize: ['fluid'],
                    screenSize: ['xs', 's', 'm', 'l', 'xl', 'xxl'],
                },
            ],
            pos: 'premiumNative2',
            unitPos: {
                l: 'premiumNative2',
                m: 'premiumNative2',
                s: 'premiumNative2',
                xl: 'premiumNative2',
                xs: 'premiumNative2',
                xxl: 'premiumNative2',
            },
            startWithCollapsedSlot: [
                { pageType: 'ResultsBrowse', screenSizes: ['xs', 's', 'm', 'l', 'xl', 'xxl'] },
                { pageType: 'ResultsSearch', screenSizes: ['xs', 's', 'm', 'l', 'xl', 'xxl'] },
            ],
        };
    }
    if (slotName === 'textLink') {
        if (!pageType) {
            return;
        }
        return {
            divId: 'textLink',
            mapping: [
                {
                    adSize: [[330, 75], 'fluid', ...(vipPages.includes(pageType) ? [[1, 1]] : [])],
                    screenSize: ['xs', 's', ...(vipPages.includes(pageType) ? 'm' : '')],
                },
                {
                    adSize: ['fluid', ...(vipPages.includes(pageType) ? [[1, 1]] : [])],
                    screenSize: [...(resultsPages.includes(pageType) ? 'm' : ''), 'l', 'xl', 'xxl'],
                },
            ],
            pos: 'top',
            unitPos: {
                l: resultsPages.includes(pageType) ? 'topTextlink' : 'bottomTextLink',
                m: resultsPages.includes(pageType) ? 'topTextlink' : 'bottomTextLink',
                s: resultsPages.includes(pageType) ? 'topTextlink' : 'bottomTextLink',
                xl: resultsPages.includes(pageType) ? 'topTextlink' : 'bottomTextLink',
                xs: resultsPages.includes(pageType) ? 'topTextlink' : 'bottomTextLink',
                xxl: resultsPages.includes(pageType) ? 'topTextlink' : 'bottomTextLink',
            },
        };
    }

    if (slotName === 'vipBanner') {
        return {
            divId: 'vipBanner',
            mapping: [
                {
                    adSize: [
                        [728, 90],
                        [1, 1],
                    ],
                    screenSize: ['m'],
                },
                {
                    adSize: showLeaderBoardOnly
                        ? [
                              [970, 90],
                              [1, 1],
                          ]
                        : [
                              [728, 90],
                              [970, 90],
                              [970, 250],
                              [1, 1],
                          ],
                    screenSize: ['l', 'xl', 'xxl'],
                },
            ],
            pos: 'top',
            unitPos: {
                l: 'top',
                m: 'top',
                s: 'top',
                xl: 'top',
                xs: 'top',
                xxl: 'top',
            },
        };
    }

    if (slotName === 'overlayVideo') {
        return {
            divId: 'overlayVideo',
            mapping: [
                {
                    adSize: ['fluid'],
                    screenSize: ['xs', 's'],
                },
            ],
            pos: 'cover2',
            unitPos: {
                l: 'cover2',
                m: 'cover2',
                s: 'cover2',
                xl: 'cover2',
                xs: 'cover2',
                xxl: 'cover2',
            },
        };
    }

    if (slotName === 'top3') {
        return {
            divId: 'top3',
            mapping: [
                {
                    adSize: ['fluid'],
                    screenSize: ['xs', 's'],
                },
            ],
            pos: 'top3',
            unitPos: {
                l: 'top3',
                m: 'top3',
                s: 'top3',
                xl: 'top3',
                xs: 'top3',
                xxl: 'top3',
            },
        };
    }

    throw new Error(`Slot cannot be found ${slotName}`);
};

const buildSlotConfig = (params: SlotConfigParams): SlotConfig[] => {
    const { resultSize, l1Category, device, page, abTests, isClientDataExist } = params;
    const hasZeroResults = resultSize === 0;
    const hasLargeResults = resultSize > 14;
    const hasSmallResults = resultSize > 0 && resultSize <= 14;

    const showNewGalleryBanner =
        l1Category === 'cars-vans-motorbikes' &&
        (abTests?.includes('GTALL-25626-B') ||
            abTests?.includes('GTALL-25626-C') ||
            abTests?.includes('GTALL-25626-D') ||
            abTests?.includes('GTALL-25626-E')) &&
        isClientDataExist;

    const desktopAdHoldOutVariantB = Boolean(
        device === 'desktop' && abTests?.includes('GTALL-24643-B')
    );
    const desktopAdHoldOutVariantC = Boolean(
        device === 'desktop' && abTests?.includes('GTALL-24643-C')
    );

    const mobileAdHoldOutVariantB = Boolean(
        device === 'mobile' && abTests?.includes('GTALL-24688-B')
    );

    const isBff = Boolean(abTests?.includes('GTF-1067-B'));
    const isGridView =
        isBff && Boolean(abTests?.includes('GTF-1068-B') || abTests?.includes('GTF-1827-B'));

    const floatingFooter: SlotConfig = {
        pos: 'floating',
        unitPos: {
            xxl: 'floating',
            xl: 'floating',
            l: 'floating',
            m: 'floating',
            s: 'floating',
            xs: 'floating',
        },
        divId: 'floatingFooter',
        mapping: [
            {
                screenSize: ['xs', 's'],
                adSize: vipPages.includes(page)
                    ? [
                          [320, 50],
                          [1, 1],
                      ]
                    : [[320, 50]],
            },
        ],
    };
    const textLinkBase: SlotConfig = {
        pos: 'bottom',
        unitPos: {
            xxl: resultsPages.includes(page) ? 'bottomTextlink' : 'bottom',
            xl: resultsPages.includes(page) ? 'bottomTextlink' : 'bottom',
            l: resultsPages.includes(page) ? 'bottomTextlink' : 'bottom',
            m: resultsPages.includes(page) ? 'bottomTextlink' : 'bottom',
            s: resultsPages.includes(page) ? 'middle2' : 'bottom',
            xs: resultsPages.includes(page) ? 'middle2' : 'bottom',
        },
        divId: 'textLinkBase',
        mapping: [
            {
                screenSize: ['xs', 's'],
                adSize: [
                    [330, 75],
                    ...(resultsPages.includes(page) ? [[300, 250]] : []),
                    ...(vipPages.includes(page) ? [[1, 1]] : []),
                    'fluid',
                ],
            },
            {
                screenSize: ['m', 'l', 'xl', 'xxl'],
                adSize: [...(vipPages.includes(page) ? [[1, 1]] : []), 'fluid'],
            },
        ],
    };

    const integratedMpu: SlotConfig = {
        pos: 'middle1',
        unitPos: {
            xxl: 'middle1',
            xl: 'middle1',
            l: 'middle1',
            m: 'middle1',
            s: 'middle1',
            xs: 'middle1',
        },
        divId: 'integratedMpu',
        mapping: [
            {
                screenSize: ['xs', 's'],
                adSize: [[1, 1], [320, 200], [300, 250], [320, 50], 'fluid'],
            },
            {
                screenSize: ['m', 'l', 'xl', 'xxl'],
                adSize: [[300, 250], [728, 90], 'fluid'],
            },
        ],
    };
    const tBanner: SlotConfig = {
        pos: 'top',
        unitPos: {
            xxl: 'top',
            xl: 'top',
            l: 'top',
            m: 'top',
            s: 'top',
            xs: 'top',
        },
        divId: 'tBanner',
        mapping: [
            {
                screenSize: ['xs', 's'],
                adSize: [
                    [2000, 255],
                    ...(resultsPages.includes(page)
                        ? [[414, 155], [414, 160], 'fluid' as 'fluid']
                        : []),
                ],
            },
            {
                screenSize: ['m'],
                adSize: [
                    [728, 90],
                    [2000, 255],
                ],
            },
            {
                screenSize: ['l', 'xl', 'xxl'],
                adSize: [
                    [728, 90],
                    [970, 90],
                    [970, 250],
                    [2000, 255],
                ],
            },
        ],
        startWithCollapsedSlot: [
            { pageType: 'ResultsBrowse', screenSizes: ['xs', 's'] },
            { pageType: 'ResultsSearch', screenSizes: ['xs', 's'] },
        ],
    };
    const pixel: SlotConfig = {
        pos: 'pixel',
        unitPos: {
            xxl: 'pixel',
            xl: 'pixel',
            l: 'pixel',
            m: 'pixel',
            s: 'pixel',
            xs: 'pixel',
        },
        divId: 'pixel',
        mapping: [
            {
                screenSize: ['xs', 's', 'm', 'l', 'xl', 'xxl'],
                adSize: [[1, 1]],
            },
        ],
    };

    const rSkyT: SlotConfig = {
        divId: 'rSkyT',
        mapping: [
            {
                adSize: [
                    [160, 600],
                    [1, 1],
                ],
                screenSize: ['xl'],
            },
            {
                adSize: [
                    [300, 250],
                    [300, 600],
                    [300, 1050],
                    [1, 1],
                ],
                screenSize: ['xxl'],
            },
        ],
        pos: 'top',
        unitPos: {
            l: 'topRight',
            m: 'topRight',
            s: 'topRight',
            xl: 'topRight',
            xs: 'topRight',
            xxl: 'topRight',
        },
    };
    const rSkyT2: SlotConfig = {
        divId: 'rSkyT2',
        mapping: [
            {
                adSize: [
                    [300, 250],
                    [300, 600],
                    [1, 1],
                ],
                screenSize: ['xxl'],
            },
        ],
        pos: 'top',
        unitPos: {
            l: 'topRight2',
            m: 'topRight2',
            s: 'topRight2',
            xl: 'topRight2',
            xs: 'topRight2',
            xxl: 'topRight2',
        },
    };
    const rSkyB: SlotConfig = {
        divId: 'rSkyB',
        mapping: [
            {
                adSize: [
                    [160, 600],
                    [1, 1],
                ],
                screenSize: ['xl'],
            },
            {
                adSize: [
                    [300, 250],
                    [300, 600],
                    [300, 1050],
                    [1, 1],
                ],
                screenSize: ['xxl'],
            },
        ],
        pos: 'floating-bottom',
        unitPos: {
            l: 'bottomRight',
            m: 'bottomRight',
            s: 'bottomRight',
            xl: 'bottomRight',
            xs: 'bottomRight',
            xxl: 'bottomRight',
        },
    };
    const rSkyB2: SlotConfig = {
        divId: 'rSkyB2',
        mapping: [
            {
                adSize: [
                    [300, 250],
                    [300, 600],
                    [1, 1],
                ],
                screenSize: ['xxl'],
            },
        ],
        pos: 'floating-bottom',
        unitPos: {
            l: 'bottomRight2',
            m: 'bottomRight2',
            s: 'bottomRight2',
            xl: 'bottomRight2',
            xs: 'bottomRight2',
            xxl: 'bottomRight2',
        },
    };

    const partnershipWidget: SlotConfig = {
        divId: 'partnershipWidget',
        mapping: [
            {
                adSize: ['fluid'],
                screenSize: ['xs', 's', 'm', 'l', 'xl', 'xxl'],
            },
        ],
        pos: 'partnershipWidget',
        unitPos: {
            l: 'partnershipWidget',
            m: 'partnershipWidget',
            s: 'partnershipWidget',
            xl: 'partnershipWidget',
            xs: 'partnershipWidget',
            xxl: 'partnershipWidget',
        },
    };

    const partnershipWidget1: SlotConfig = {
        divId: 'partnershipWidget1',
        mapping: [
            {
                adSize: ['fluid'],
                screenSize: ['xs', 's', 'm', 'l', 'xl', 'xxl'],
            },
        ],
        pos: 'partnershipWidget1',
        unitPos: {
            l: 'partnershipWidget1',
            m: 'partnershipWidget1',
            s: 'partnershipWidget1',
            xl: 'partnershipWidget1',
            xs: 'partnershipWidget1',
            xxl: 'partnershipWidget1',
        },
    };

    const partnershipBottom: SlotConfig = {
        divId: 'partnershipBottom',
        mapping: [
            {
                adSize: ['fluid'],
                screenSize: ['xs', 's', 'm', 'l', 'xl', 'xxl'],
            },
        ],
        pos: 'top',
        unitPos: {
            l: 'partnershipBottom',
            m: 'partnershipBottom',
            s: 'partnershipBottom',
            xl: 'partnershipBottom',
            xs: 'partnershipBottom',
            xxl: 'partnershipBottom',
        },
    };

    const integratedListing: SlotConfig = {
        divId: 'integratedListing',
        mapping: [
            {
                adSize: [
                    [320, 50],
                    [300, 250],
                    [320, 100],
                ],
                screenSize: ['xs', 's'],
            },
            {
                adSize: [[728, 200], [300, 250], [728, 90], 'fluid'],
                screenSize: ['m', 'l', 'xl', 'xxl'],
            },
        ],
        pos: 'middle2',
        unitPos: {
            l: 'middle2',
            m: 'middle2',
            s: 'middle2',
            xl: 'middle2',
            xs: 'middle2',
            xxl: 'middle2',
        },
    };

    const galleryBanner: SlotConfig = {
        divId: 'galleryBanner',
        mapping: showNewGalleryBanner
            ? [
                  {
                      adSize: [
                          [320, 50],
                          [1, 1],
                      ],
                      screenSize: ['xs', 's', 'm'],
                  },
                  {
                      adSize: [
                          [160, 600],
                          [1, 1],
                      ],
                      screenSize: ['l'],
                  },
                  {
                      adSize: [
                          [300, 600],
                          [160, 600],
                          [1, 1],
                      ],
                      screenSize: device === 'desktop' ? ['l', 'xl', 'xxl'] : ['xl', 'xxl'],
                  },
              ]
            : [
                  {
                      adSize: [
                          [320, 50],
                          [1, 1],
                      ],
                      screenSize: ['xs', 's'],
                  },
                  {
                      adSize: [
                          [728, 90],
                          [1, 1],
                      ],
                      screenSize: ['m', 'l', 'xl', 'xxl'],
                  },
              ],
        pos: 'gallery',
        unitPos: {
            l: 'gallery',
            m: 'gallery',
            s: 'gallery',
            xl: 'gallery',
            xs: 'gallery',
            xxl: 'gallery',
        },
        shouldRenderEvenNotOnDom: true,
    };
    const vipMiddleMobile: SlotConfig = {
        divId: 'vipMiddleMobile',
        mapping: [
            {
                adSize: [
                    [300, 250],
                    [1, 1],
                ],
                screenSize: ['xs', 's'],
            },
        ],
        pos: 'vip-middle-mobile',
        unitPos: {
            l: 'middle1',
            m: 'middle1',
            s: 'middle1',
            xl: 'middle1',
            xs: 'middle1',
            xxl: 'middle1',
        },
    };

    let mpu: SlotConfig;
    if (vipPages.includes(page)) {
        mpu = {
            divId: 'mpu',
            mapping: [
                {
                    adSize: [
                        [300, 250],
                        [1, 1],
                    ],
                    screenSize: ['xs', 's'],
                },
                {
                    adSize: [
                        [300, 250],
                        [300, 600],
                        [1, 1],
                    ],
                    screenSize: ['m', 'l', 'xl', 'xxl'],
                },
            ],
            pos: 'top',
            unitPos: {
                l: 'topRight',
                m: 'topRight',
                s: 'topRight',
                xl: 'topRight',
                xs: 'topRight',
                xxl: 'topRight',
            },
        };
    } else if (page === 'R2SEmailSuccess') {
        mpu = {
            divId: 'mpu',
            mapping: [
                {
                    adSize: [
                        [320, 50],
                        [300, 250],
                    ],
                    screenSize: ['xs', 's', 'm'],
                },
                {
                    adSize: [
                        [300, 250],
                        [640, 480],
                    ],
                    screenSize: ['l', 'xl', 'xxl'],
                },
            ],
            pos: 'bottom',
            unitPos: {
                l: 'middle1',
                m: 'middle1',
                s: 'middle1',
                xl: 'middle1',
                xs: 'middle1',
                xxl: 'middle1',
            },
        };
    } else {
        mpu = {
            divId: 'mpu',
            mapping: [
                {
                    adSize: [[300, 250]],
                    screenSize: ['xs', 's', 'm', 'l', 'xl', 'xxl'],
                },
            ],
            pos: 'top',
            unitPos: {
                l: 'topRight',
                m: 'bottom',
                s: 'bottom',
                xl: 'topRight',
                xs: 'bottom',
                xxl: 'topRight',
            },
        };
    }

    const fBanner: SlotConfig = {
        divId: 'fBanner',
        mapping: [
            {
                adSize: [[320, 50], [300, 250], ...(vipPages.includes(page) ? [[1, 1]] : [])],
                screenSize: ['xs', 's'],
            },
            {
                adSize: [[728, 90], ...(vipPages.includes(page) ? [[1, 1]] : [])],
                screenSize: ['m'],
            },
            {
                adSize: [
                    [728, 90],
                    [970, 90],
                    [970, 250],
                    ...(vipPages.includes(page) ? [[1, 1]] : []),
                ],
                screenSize: ['l', 'xl', 'xxl'],
            },
        ],
        pos: 'bottom',
        unitPos: {
            l: 'bottom',
            m: 'bottom',
            s: 'bottom',
            xl: 'bottom',
            xs: 'bottom',
            xxl: 'bottom',
        },
    };

    const vipMiddleDesktop: SlotConfig = {
        divId: 'vipMiddleDesktop',
        mapping: [
            l1Category === 'for-sale' &&
            device === 'desktop' &&
            (abTests?.includes('GTALL-22219-B') || abTests?.includes('GTALL-22219-C'))
                ? {
                      adSize: [
                          [300, 600],
                          [300, 250],
                          [1, 1],
                      ],
                      screenSize: ['xl', 'xxl'],
                  }
                : {
                      adSize: [
                          [300, 250],
                          [1, 1],
                      ],
                      screenSize: ['xl', 'xxl'],
                  },
        ],
        pos: 'vip-middle-desktop',
        unitPos: {
            l: 'bottomRight',
            m: 'bottomRight',
            s: 'bottomRight',
            xl: 'bottomRight',
            xs: 'bottomRight',
            xxl: 'bottomRight',
        },
    };

    const topTakeover: SlotConfig = {
        divId: 'top_takeover',
        mapping: [
            {
                adSize: [[2000, 255]],
                screenSize: ['xs', 's'],
            },
            {
                adSize: [[728, 90], ...(abTests?.includes('GTALL-27550-B') ? [] : [[2000, 255]])],
                screenSize: ['m'],
            },
            {
                adSize: [[970, 250], ...(abTests?.includes('GTALL-27550-B') ? [] : [[2000, 255]])],
                screenSize: ['l', 'xl', 'xxl'],
            },
        ],
        pos: 'top',
        unitPos: {
            l: 'top',
            m: 'top',
            s: 'top',
            xl: 'top',
            xs: 'top',
            xxl: 'top',
        },
    };
    const homeSideAd: SlotConfig = {
        divId: 'homeSideAd',
        mapping: [
            {
                adSize: [[160, 600]],
                screenSize: ['m'],
            },
            {
                adSize: [
                    [300, 250],
                    [300, 600],
                    [300, 1050],
                ],
                screenSize: ['l', 'xl', 'xxl'],
            },
        ],
        pos: 'top',
        unitPos: {
            l: 'topRight',
            m: 'topRight',
            s: 'topRight',
            xl: 'topRight',
            xs: 'topRight',
            xxl: 'topRight',
        },
    };
    const homeBanner: SlotConfig = {
        divId: 'homeBanner',
        mapping: [
            {
                adSize: [[320, 50]],
                screenSize: ['xs', 's'],
            },
        ],
        pos: 'top',
        unitPos: {
            l: 'middle1',
            m: 'middle1',
            s: 'middle1',
            xl: 'middle1',
            xs: 'middle1',
            xxl: 'middle1',
        },
    };

    const browseFooter: SlotConfig = {
        divId: 'browseFooter',
        mapping: [
            {
                adSize: [
                    [320, 100],
                    [320, 50],
                ],
                screenSize: ['xs', 's'],
            },
        ],
        pos: 'top',
        unitPos: {
            l: 'bottom',
            m: 'bottom',
            s: 'bottom',
            xl: 'bottom',
            xs: 'bottom',
            xxl: 'bottom',
        },
    };

    const headerBanner: SlotConfig = {
        divId: 'headerBanner',
        mapping: [
            {
                adSize: [[728, 90]],
                screenSize: ['m'],
            },
            {
                adSize: [
                    [728, 90],
                    [970, 90],
                    [970, 250],
                ],
                screenSize: ['l', 'xl', 'xxl'],
            },
        ],
        pos: 'top',
        unitPos: {
            l: 'top',
            m: 'top',
            s: 'top',
            xl: 'top',
            xs: 'top',
            xxl: 'top',
        },
    };

    const carsHomeBanner: SlotConfig = {
        divId: 'carsHomeBanner',
        mapping: [
            {
                adSize: [[320, 50]],
                screenSize: ['xs', 's'],
            },
            {
                adSize: [[728, 90]],
                screenSize: ['m', 'l', 'xl', 'xxl'],
            },
        ],
        pos: 'middle1',
        unitPos: {
            l: 'middle1',
            m: 'middle1',
            s: 'middle1',
            xl: 'middle1',
            xs: 'middle1',
            xxl: 'middle1',
        },
    };
    const carsHomeLeaderBoard: SlotConfig = {
        divId: 'carsHomeLeaderBoard',
        mapping: [
            {
                adSize: [[728, 90]],
                screenSize: ['l', 'xl', 'xxl'],
            },
        ],
        pos: 'top',
        unitPos: {
            l: 'top',
            m: 'middle1',
            s: 'middle1',
            xl: 'top',
            xs: 'middle1',
            xxl: 'top',
        },
    };
    const carsHomeMpu: SlotConfig = {
        divId: 'carsHomeMpu',
        mapping: [
            {
                adSize: [[300, 250]],
                screenSize: ['xs', 's', 'm'],
            },
        ],
        pos: 'middle2',
        unitPos: {
            l: 'middle1',
            m: 'middle2',
            s: 'middle2',
            xl: 'middle1',
            xs: 'middle2',
            xxl: 'middle1',
        },
    };
    const carsHomeStickyBanner: SlotConfig = {
        divId: 'carsHomeStickyBanner',
        mapping: [
            {
                adSize: [[320, 50]],
                screenSize: ['xs', 's'],
            },
        ],
        pos: 'floating',
        unitPos: {
            l: 'floating',
            m: 'floating',
            s: 'floating',
            xl: 'floating',
            xs: 'floating',
            xxl: 'floating',
        },
    };
    const manageAdsLeaderboard: SlotConfig = {
        divId: 'leaderboard',
        mapping: [
            {
                adSize: [[320, 50]],
                screenSize: ['xs', 's'],
            },
            {
                adSize: [[728, 90]],
                screenSize: ['m', 'l', 'xl', 'xxl'],
            },
        ],
        pos: 'top',
        unitPos: {
            l: 'top',
            m: 'top',
            s: 'top',
            xl: 'top',
            xs: 'top',
            xxl: 'top',
        },
    };

    const mpu2: SlotConfig = {
        divId: 'mpu2',
        mapping: [
            {
                screenSize: ['xl', 'xxl'],
                adSize: [[300, 250]],
            },
        ],
        pos: 'top',
        unitPos: {
            l: 'topRight',
            m: 'topRight',
            s: 'topRight',
            xl: 'topRight',
            xs: 'topRight',
            xxl: 'topRight',
        },
    };

    const hidePremiumNativeSlots = Boolean(desktopAdHoldOutVariantC);

    const showPremiumNative1 =
        !hidePremiumNativeSlots &&
        (
            [
                'cars-vans-motorbikes',
                'business-services',
                'flats-houses',
                'community',
                'jobs',
                'pets',
            ] as L1Category[]
        ).includes(l1Category);

    const showPremiumNative2 =
        !hidePremiumNativeSlots && (['jobs'] as L1Category[]).includes(l1Category);

    const textLinkSlot: SlotConfig[] = !(
        resultsPages.includes(page) &&
        (showPremiumNative1 || showPremiumNative2)
    )
        ? [getSlotByName({ slotName: 'textLink', pageType: page })]
        : [];

    const topTakeoverSlot: SlotConfig[] =
        desktopAdHoldOutVariantB || desktopAdHoldOutVariantC ? [] : [topTakeover];

    const tBannerSlot: SlotConfig[] =
        resultsPages.includes(page) && (desktopAdHoldOutVariantB || desktopAdHoldOutVariantC)
            ? []
            : [tBanner];

    const integratedMpuSlot: SlotConfig[] =
        desktopAdHoldOutVariantB || desktopAdHoldOutVariantC || mobileAdHoldOutVariantB
            ? []
            : [integratedMpu];

    const integratedListingSlot: SlotConfig[] =
        desktopAdHoldOutVariantB || desktopAdHoldOutVariantC ? [] : [integratedListing];

    const mpuSlot: SlotConfig[] =
        vipPages.includes(page) && (desktopAdHoldOutVariantB || desktopAdHoldOutVariantC)
            ? []
            : [mpu];

    const galleryBannerSlot: SlotConfig[] = desktopAdHoldOutVariantC ? [] : [galleryBanner];

    const vipMiddleDesktopSlot: SlotConfig[] = desktopAdHoldOutVariantC ? [] : [vipMiddleDesktop];

    const homeBannerSlot: SlotConfig[] = mobileAdHoldOutVariantB ? [] : [homeBanner];

    const vipMiddleMobileSlot: SlotConfig[] = mobileAdHoldOutVariantB ? [] : [vipMiddleMobile];

    if (resultsPages.includes(page)) {
        const premiumNativeSlots: SlotConfig[] = [
            ...(showPremiumNative1
                ? [
                      getSlotByName({
                          slotName: 'premiumNative1',
                      }),
                  ]
                : []),
            ...(showPremiumNative2 ? [getSlotByName({ slotName: 'premiumNative2' })] : []),
        ];

        if (device === 'mobile') {
            if (hasZeroResults) {
                return [floatingFooter, pixel];
            } else if (hasSmallResults) {
                return [
                    floatingFooter,
                    ...tBannerSlot,
                    ...integratedMpuSlot,
                    pixel,
                    ...premiumNativeSlots,
                ];
            } else if (hasLargeResults) {
                return [
                    floatingFooter,
                    ...tBannerSlot,
                    ...integratedMpuSlot,
                    ...integratedListingSlot,
                    pixel,
                    ...premiumNativeSlots,
                ];
            }
        }
        if (device === 'tablet') {
            if (hasZeroResults) {
                return [...tBannerSlot, pixel, ...premiumNativeSlots];
            } else if (hasSmallResults) {
                return [
                    ...tBannerSlot,
                    ...integratedMpuSlot,
                    ...integratedListingSlot,
                    pixel,
                    rSkyT,
                    rSkyB,
                    ...premiumNativeSlots,
                ];
            } else if (hasLargeResults) {
                return [
                    ...tBannerSlot,
                    ...integratedMpuSlot,
                    ...integratedListingSlot,
                    pixel,
                    rSkyT,
                    rSkyB,
                    ...premiumNativeSlots,
                ];
            }
        }
        if (device === 'desktop') {
            if (hasZeroResults) {
                return [pixel, ...tBannerSlot, rSkyT, ...premiumNativeSlots];
            } else if (hasSmallResults) {
                return [
                    ...integratedMpuSlot,
                    ...integratedListingSlot,
                    pixel,
                    ...tBannerSlot,
                    rSkyT,
                    rSkyT2,
                    rSkyB,
                    ...(isGridView ? [] : [rSkyB2]),
                    ...premiumNativeSlots,
                ];
            } else if (hasLargeResults) {
                return [
                    ...integratedMpuSlot,
                    ...integratedListingSlot,
                    pixel,
                    ...tBannerSlot,
                    rSkyT,
                    rSkyT2,
                    rSkyB,
                    rSkyB2,
                    ...premiumNativeSlots,
                ];
            }
        }
    }

    if (vipPages.includes(page)) {
        const vipBannerSlot: SlotConfig[] =
            desktopAdHoldOutVariantB ||
            desktopAdHoldOutVariantC ||
            (l1Category === 'for-sale' &&
                device === 'desktop' &&
                abTests?.includes('GTALL-22219-B'))
                ? []
                : [
                      getSlotByName({
                          slotName: 'vipBanner',
                          abTests,
                      }),
                  ];

        if (device === 'mobile') {
            return [
                partnershipWidget,
                partnershipWidget1,
                partnershipBottom,
                floatingFooter,
                textLinkBase,
                ...galleryBannerSlot,
                ...vipMiddleMobileSlot,
                pixel,
            ];
        }
        if (device === 'tablet') {
            return [
                partnershipWidget,
                partnershipWidget1,
                partnershipBottom,
                ...vipBannerSlot,
                ...textLinkSlot,
                ...mpuSlot,
                fBanner,
                ...galleryBannerSlot,
                ...vipMiddleDesktopSlot,
                pixel,
            ];
        }
        if (device === 'desktop') {
            return [
                partnershipWidget,
                partnershipWidget1,
                partnershipBottom,
                ...vipBannerSlot,
                ...textLinkSlot,
                ...mpuSlot,
                ...galleryBannerSlot,
                ...vipMiddleDesktopSlot,
                pixel,
            ];
        }
    }

    if (page === 'Homepage') {
        return [...topTakeoverSlot, homeSideAd, ...homeBannerSlot, pixel];
    }

    if (page === 'VipGallery') {
        return [...galleryBannerSlot];
    }

    if (page === 'Article') {
        return [...tBannerSlot, ...mpuSlot, fBanner];
    }

    if (page === 'ResultsSeller' || page === 'UserStatic') {
        return [...mpuSlot];
    }

    if (page === 'LandingPageCategory') {
        return [browseFooter];
    }

    if (['Error_404', 'Error_500'].includes(page)) {
        return [...mpuSlot];
    }

    if (page === 'R2SEmailSuccess') {
        if (device === 'mobile') {
            return [];
        }
        return [headerBanner];
    }

    if (page === 'ManageAds' || page === 'ManageAdsPro') {
        return [manageAdsLeaderboard];
    }

    if (page === 'PaymentSuccess') {
        return [manageAdsLeaderboard, mpu2];
    }

    if (page === 'LandingPageCars') {
        return [carsHomeBanner, carsHomeLeaderBoard, carsHomeMpu, carsHomeStickyBanner];
    }

    return [];
};

export default buildSlotConfig;
