// TODO clean
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import PropTypes from 'prop-types';

import { Fade } from '../transitions';

import './overlay.scss';

const Overlay = ({ isOpen, onClick, onKeyUp, children, action }) => (
    <Fade in={isOpen}>
        <div
            className="overlay"
            onClick={onClick}
            onKeyUp={onKeyUp}
            tabIndex={0}
            role="button"
            data-testid="overlay"
            action={action}
        >
            {children}
        </div>
    </Fade>
);

Overlay.defaultProps = {
    action: '',
    children: null, // eslint-disable-line react/default-props-match-prop-types
    isOpen: false,
    onKeyUp: () => {},
    onClick: () => {},
};

Overlay.propTypes = {
    action: PropTypes.string,
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    onClick: PropTypes.func,
    onKeyUp: PropTypes.func,
};

export default Overlay;
