import React from 'react';
import classnames from 'classnames';
import { bool, node, number, oneOf, oneOfType, string, objectOf } from 'prop-types';
import './grid.scss';

const Grid = ({
    children,
    className,
    col,
    colL,
    colM,
    colS,
    colXl,
    colXxl,
    container,
    gutter,
    item,
    justifyContent,
    offset,
    offsetL,
    offsetM,
    offsetS,
    offsetXl,
    offsetXxl,
    hidden,
    hiddenUntilL,
    hiddenUntilM,
    hiddenUntilS,
    hiddenUntilXl,
    hiddenUntilXxl,
    style,
}) => {
    let styles = {};
    const classes = classnames(className, 'grid', {
        'grid--container': container,
        'grid--item': item,
        [`grid--col-${col}`]: col,
        [`grid--col-${colS}-s`]: colS,
        [`grid--col-${colM}-m`]: colM,
        [`grid--col-${colL}-l`]: colL,
        [`grid--col-${colXl}-xl`]: colXl,
        [`grid--col-${colXxl}-xxl`]: colXxl,
        [`grid--gutter-${gutter}`]: gutter,
        [`grid--col-offset-${offset}`]: Number.isInteger(offset),
        [`grid--col-offset-${offsetS}-s`]: Number.isInteger(offsetS),
        [`grid--col-offset-${offsetM}-m`]: Number.isInteger(offsetM),
        [`grid--col-offset-${offsetL}-l`]: Number.isInteger(offsetL),
        [`grid--col-offset-${offsetXl}-xl`]: Number.isInteger(offsetXl),
        [`grid--col-offset-${offsetXxl}-xxl`]: Number.isInteger(offsetXxl),
        [`grid--hidden`]: hidden,
        [`grid--hidden-until-l`]: hiddenUntilL,
        [`grid--hidden-until-m`]: hiddenUntilM,
        [`grid--hidden-until-s`]: hiddenUntilS,
        [`grid--hidden-until-xl`]: hiddenUntilXl,
        [`grid--hidden-until-xxl`]: hiddenUntilXxl,
    });

    if (justifyContent) {
        styles = {
            ...styles,
            display: 'flex',
            justifyContent,
            ...style,
        };
    }

    return (
        <div className={classes} style={styles}>
            {children}
        </div>
    );
};

Grid.propTypes = {
    children: node.isRequired,
    className: string,
    col: oneOfType([number, oneOf(['auto'])]),
    colL: oneOfType([number, oneOf(['auto'])]),
    colM: oneOfType([number, oneOf(['auto'])]),
    colS: oneOfType([number, oneOf(['auto'])]),
    colXl: oneOfType([number, oneOf(['auto'])]),
    colXxl: oneOfType([number, oneOf(['auto'])]),
    container: bool,
    gutter: oneOf(['none', 'small', 'medium', 'large']),
    hidden: bool,
    hiddenUntilL: bool,
    hiddenUntilM: bool,
    hiddenUntilS: bool,
    hiddenUntilXl: bool,
    hiddenUntilXxl: bool,
    item: bool,
    justifyContent: string,
    offset: number,
    offsetL: number,
    offsetM: number,
    offsetS: number,
    offsetXl: number,
    offsetXxl: number,
    style: objectOf(string),
};
// TODO clean
Grid.defaultProps = {
    className: '',
    col: null,
    colL: null,
    colM: null,
    colS: null,
    colXl: null,
    colXs: null, // eslint-disable-line react/default-props-match-prop-types
    colXxl: null,
    container: false,
    gutter: null,
    item: false,
    justifyContent: null,
    offset: null,
    offsetS: null,
    offsetM: null,
    offsetL: null,
    offsetXl: null,
    offsetXxl: null,
    hidden: null,
    hiddenUntilL: null,
    hiddenUntilM: null,
    hiddenUntilS: null,
    hiddenUntilXl: null,
    hiddenUntilXxl: null,
    span: null, // eslint-disable-line react/default-props-match-prop-types
    style: {},
};

export default Grid;
