import { Nullable } from 'typescript-nullable';
import PageType from './page-type';
import ScreenSize from './screen-size';
import DeviceType from './device-type';

enum SlotName {
    lSkyB = 'lSkyB',
    integratedMpu = 'integratedMpu',
    lSkyT = 'lSkyT',
    rSkyT = 'rSkyT',
    rSkyT2 = 'rSkyT2',
    tBanner = 'tBanner',
    rSkyB = 'rSkyB',
    rSkyB2 = 'rSkyB2',
    integratedListing = 'integratedListing',
    baseMpu = 'baseMpu',
    textLinkBase = 'textLinkBase',
    floatingFooter = 'floatingFooter',
    vipBanner = 'vipBanner',
    mpu = 'mpu',
    vipMiddleDesktop = 'vipMiddleDesktop',
    fBanner = 'fBanner',
    vipMiddleMobile = 'vipMiddleMobile',
    homeSideAd = 'homeSideAd',
    homeBanner = 'homeBanner',
}

export function getSlotNamesBy(
    pageType: PageType,
    deviceType: DeviceType,
    screenSize: ScreenSize
): Nullable<SlotName[]> {
    const mapping: { [index: string]: { [index: string]: { [index: string]: SlotName[] } } } = {
        [PageType.ResultsPage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: [
                    SlotName.integratedMpu,
                    SlotName.rSkyT,
                    SlotName.tBanner,
                    SlotName.rSkyB,
                    SlotName.integratedListing,
                    SlotName.rSkyT2,
                    SlotName.rSkyB2,
                ],
                [ScreenSize.XL]: [
                    SlotName.lSkyB,
                    SlotName.lSkyT,
                    SlotName.integratedMpu,
                    SlotName.rSkyT,
                    SlotName.tBanner,
                    SlotName.rSkyB,
                    SlotName.integratedListing,
                ],
                [ScreenSize.L]: [
                    SlotName.lSkyB,
                    SlotName.lSkyT,
                    SlotName.integratedMpu,
                    SlotName.tBanner,
                    SlotName.baseMpu,
                    SlotName.integratedListing,
                ],
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: [
                    SlotName.lSkyB,
                    SlotName.lSkyT,
                    SlotName.integratedMpu,
                    SlotName.tBanner,
                    SlotName.baseMpu,
                    SlotName.integratedListing,
                ],
                [ScreenSize.M]: [
                    SlotName.integratedMpu,
                    SlotName.tBanner,
                    SlotName.baseMpu,
                    SlotName.integratedListing,
                ],
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: [
                    SlotName.integratedListing,
                    SlotName.floatingFooter,
                    SlotName.integratedMpu,
                ],
                [ScreenSize.XS]: [
                    SlotName.integratedListing,
                    SlotName.floatingFooter,
                    SlotName.integratedMpu,
                ],
            },
        },
        [PageType.VIP]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: [SlotName.vipBanner, SlotName.mpu, SlotName.vipMiddleDesktop],
                [ScreenSize.XL]: [SlotName.vipBanner, SlotName.mpu, SlotName.vipMiddleDesktop],
                [ScreenSize.L]: [SlotName.vipBanner, SlotName.mpu],
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: [SlotName.vipBanner, SlotName.mpu, SlotName.fBanner],
                [ScreenSize.M]: [SlotName.vipBanner, SlotName.mpu, SlotName.fBanner],
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: [
                    SlotName.integratedListing,
                    SlotName.floatingFooter,
                    SlotName.vipMiddleMobile,
                ],
                [ScreenSize.XS]: [
                    SlotName.integratedListing,
                    SlotName.floatingFooter,
                    SlotName.vipMiddleMobile,
                ],
            },
        },
        [PageType.Homepage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: [SlotName.homeSideAd],
                [ScreenSize.XL]: [SlotName.homeSideAd],
                [ScreenSize.L]: [SlotName.homeSideAd],
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: [SlotName.homeSideAd],
                [ScreenSize.M]: [SlotName.homeSideAd],
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: [SlotName.homeBanner],
                [ScreenSize.XS]: [SlotName.homeBanner],
            },
        },
    };

    if (mapping[pageType] && mapping[pageType][deviceType]) {
        return mapping[pageType][deviceType][screenSize];
    }

    return undefined;
}

export default SlotName;
