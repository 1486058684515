/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import Button from './button';
import Icon from '../icon/icon';
import { qaAttribute } from '../utils/qa-service';
import { postAdButtonCss } from './post-ad-button.style';

const PostAdButton = (props) => {
    const { iconName, experiments, ...rest } = props;
    const iconType = iconName;

    return (
        <Button
            {...rest}
            {...qaAttribute('post-ad')}
            icon={<Icon type={iconType} />}
            css={postAdButtonCss}
        />
    );
};

PostAdButton.propTypes = {
    bottomLabel: PropTypes.bool,
    dark: PropTypes.bool,
    experiments: PropTypes.object,
    iconName: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

PostAdButton.defaultProps = {
    bottomLabel: true,
    dark: true,
    experiments: {},
    iconName: 'circ-plus',
    label: '',
};

export default PostAdButton;
