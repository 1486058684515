import styled from "@emotion/styled";
import { breakpoints, mediaQuery } from "@gumtree/ui-library/src/base/theme";

const screenSizeThreshold = breakpoints.large;

export const OnlyShowOnSmallScreen = styled.div`
    ${mediaQuery.from(screenSizeThreshold)} {
        display: none;
    }
`;

export const OnlyShowOnLargeScreen = styled.div`
    ${mediaQuery.until(screenSizeThreshold)} {
        display: none;
    }
`;
