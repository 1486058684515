export const getValue = (key: string): object | undefined => {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : undefined;
};

export const getArray = (key: string): any[] => {
    const value = getValue(key);
    return Array.isArray(value) ? value : [];
};

export const setValue = (key: string, value: object) => {
    window.localStorage?.setItem(key, JSON.stringify(value));
};

export const removeByKey = (key: string) => {
    window.localStorage?.removeItem(key);
};

export const removeMultipleItems = (regex) => {
    for (let i = 0; i < window.localStorage.length; i++) {
        const key = window.localStorage.key(i);
        if (regex.test(key)) {
            window.localStorage.removeItem(key!);
        }
    }
};

export const removeItemsViaRegex = (regex) => {
    removeMultipleItems(regex);
};
