import React, { useEffect, useRef, useState } from 'react';
import { isChildVisible } from './util';

const WithVisibilityObserver = ({
    children,
    onVisible,
    threshold,
    ...props
}: React.PropsWithChildren<any> & {
    onVisible: (isVisibleOnLoad: boolean) => void;
    threshold: number;
    style?: object;
}) => {
    const componentRef = useRef(null);
    const [hasFired, setHasFired] = useState(false); // State to track if the event has already fired

    useEffect(() => {
        if (
            hasFired ||
            !onVisible ||
            !componentRef.current ||
            !(componentRef.current as HTMLElement).parentNode
        ) {
            return;
        }

        const isVisible = isChildVisible(
            (componentRef.current as HTMLElement).parentNode as HTMLElement,
            componentRef.current
        );

        if (isVisible) {
            onVisible(true);
            setHasFired(true);
        } else {
            // Check if IntersectionObserver is supported
            if ('IntersectionObserver' in window) {
                const observer = new IntersectionObserver(
                    (entries) => {
                        const entry = entries[0];
                        if (entry.isIntersecting && !hasFired) {
                            // Ensure the callback only fires once
                            onVisible(false);
                            setHasFired(true);
                            observer.disconnect(); // Optionally disconnect observer after firing
                        }
                    },
                    {
                        root: null, // using the viewport as the root
                        rootMargin: '0px',
                        threshold, // 0.1 means trigger when at least 10% of the observed element is visible
                    }
                );

                if (componentRef.current) {
                    observer.observe(componentRef.current);
                }
            }
        }
    }, []); // Depend on hasFired to correctly handle its updates

    // Wrap children in a div and apply the ref to the wrapper
    return (
        <div ref={componentRef} {...props}>
            {children}
        </div>
    );
};

export default WithVisibilityObserver;

// Usage example
/*
function App() {
  return (
    <WithVisibilityObserver onVisible={() => console.log('Child is now visible!')}>
      <div>Child Component</div>
    </WithVisibilityObserver>
  );
}
*/

// Explanation
/*
Why `hasFired` and `observer.disconnect()` Are Used Together:

- React's state updates (e.g., `setHasFired(true)`) are asynchronous. This means changes do not apply immediately, and React batches these updates for performance.
- The Intersection Observer's callback might fire multiple times in quick succession (e.g., when an element becomes visible then quickly not visible), before React processes state updates or re-renders.
- Using `observer.disconnect()` alone after the first invocation does not guarantee a one-off event because React may not have processed the `hasFired` state update in time, leading to potential multiple invocations.
- `hasFired` state ensures a one-off event by providing an immediate condition check within the callback, preventing multiple executions.
- This approach combines an immediate guard against repeated invocations (`hasFired`) with a method to stop future observations (`observer.disconnect()`), ensuring robust one-off visibility detection even amid rapid visibility changes and asynchronous state updates.
*/
