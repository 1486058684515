import { LOGIN_APP_DISPLAY_NAME, LOGIN_PLATFORM } from './constants';

export type LoginDisplayAppName =
    typeof LOGIN_APP_DISPLAY_NAME[keyof typeof LOGIN_APP_DISPLAY_NAME];

export enum LoginErrorType {
    ShareEmailError = 'share_email_error',
    NoEmailRegistered = 'no_email_registered',
}

export type SocialLoginPlatformType = typeof LOGIN_PLATFORM[keyof typeof LOGIN_PLATFORM];

export interface SocialLoginPayload {
    username: string;
    password: string;
    loginPlatform: SocialLoginPlatformType;
}

export interface SubmitSocialLoginFunction {
    (loginPayload: SocialLoginPayload): void;
}
