import { connect } from 'react-redux';

import BrowseLinks from './browse-links';

const mapState = ({ browseLinks, baseConfig: { pageType } }) => ({
    browseLinks,
    pageType,
});

export default connect(mapState)(BrowseLinks);
