import React from 'react';
import PropTypes from 'prop-types';

const Side = ({ children, style }) => <div style={style}>{children}</div>;

Side.propTypes = {
    children: PropTypes.any.isRequired,
    style: PropTypes.object,
};

Side.defaultProps = {
    style: {},
};

export default Side;
