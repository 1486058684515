import { getClientData, getLegacyGumData } from './data-service';
import { ExperimentName, Experiments, ExperimentVariants } from '../types/client-data';

const getExperiments = (name: ExperimentName): ExperimentVariants | undefined => {
    if ((window as any).clientData) {
        return getClientData(`experiments.${name}`);
    } else {
        return getLegacyGumData(`domain.ab.${name}`);
    }
};

export const convertExperimentsToStringArray = (
    experiments: Experiments
): `${'GTALL' | 'GTP' | 'GTF'}-${string}-${ExperimentVariants}`[] =>
    Object.entries(experiments).map(
        ([key, value]) =>
            `${key}-${value}` as `${'GTALL' | 'GTP' | 'GTF'}-${string}-${ExperimentVariants}`
    );

export { getExperiments };
