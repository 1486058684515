import { Nullable } from 'typescript-nullable';
import ScreenSize from '../common/screen-size';
import SlotName from '../common/slot-name';
import DeviceType from '../common/device-type';
import PageType from '../common/page-type';

export default abstract class Partner {
    protected slotName: SlotName;
    protected pageType: PageType;
    protected deviceType: DeviceType;
    protected screenSize: ScreenSize;
    protected keywords: Nullable<string>;
    protected categoryL1: Nullable<string>;
    protected categoryL2: Nullable<string>;
    protected categoryL3: Nullable<string>;
    protected experiments: Array<string>;
    protected vipAdId: Nullable<number>;
    protected abstract readonly bidder: string;
    protected abstract mapping: {
        [key: string]: {
            [key: string]: { [key: string]: { [key: string]: number | string } };
        };
    };

    constructor(
        pageType: PageType,
        deviceType: DeviceType,
        slotName: SlotName,
        screenSize: ScreenSize,
        keywords: Nullable<string>,
        categoryL1: Nullable<string>,
        categoryL2: Nullable<string>,
        categoryL3: Nullable<string>,
        experiments: Array<string>,
        vipAdId?: Nullable<number>
    ) {
        this.pageType = pageType;
        this.slotName = slotName;
        this.deviceType = deviceType;
        this.screenSize = screenSize;
        this.keywords = keywords;
        this.categoryL1 = categoryL1;
        this.categoryL2 = categoryL2;
        this.categoryL3 = categoryL3;
        this.experiments = experiments;
        this.vipAdId = vipAdId;
    }

    protected getPlacement(): Nullable<number | string> {
        if (this.mapping[this.pageType]) {
            if (this.mapping[this.pageType][this.deviceType]) {
                if (this.mapping[this.pageType][this.deviceType][this.screenSize]) {
                    return this.mapping[this.pageType][this.deviceType][this.screenSize][
                        this.slotName
                    ];
                }
            }
        }

        return undefined;
    }
}
