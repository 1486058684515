 
import { ThunkDispatch } from 'redux-thunk';

export function deepClone<T>(serializable: T): T {
    return JSON.parse(JSON.stringify(serializable));
}

/**
 * Typed `Object.keys`, usually as finitely many string literals.
 */
export function keys<T extends string>(record: Record<T, any> | Partial<Record<T, any>>) {
    return Object.keys(record) as T[];
}

/** Usage e.g. `default: throw testNever(switchValue, 'some context')` */
export function testNever(input: never, prefix = '') {
    return `${prefix ? `${prefix} :` : ''}testNever: case ${input} not implemented`;
}

export interface NameValuePair {
    name: string;
    value: string;
}

/**
 * Given type `typeof { foo: () => 'bar', baz: (_) => /\s+/, qux: () => 0 as 0  }`,
 * returns type `{ foo: string, baz: RegExp, qux: 0 }`.
 */
export type KeyedReturnTypes<Type extends Record<string, (...args: any[]) => any>> = {
    [Key in keyof Type]: ReturnType<Type[Key]>;
};

/**
 * Exclude keys K from T.
 */
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

/**
 * List of types of arguments of function.
 */
export type ArgumentTypes<F extends (...args: any[]) => any> = F extends (...args: infer A) => any
    ? A
    : never;

export type FirstArg<F extends (...args: any[]) => any> = ArgumentTypes<F>[0];
export type SecondArg<F extends (...args: any[]) => any> = ArgumentTypes<F>[1];

/** Action dispatcher */
export type AD<ActionType, ActionPayload> = (payload: ActionPayload) => {
    type: ActionType;
    payload: ActionPayload;
};

/** Thunk dispatcher */
export interface ThunkDispatcher<State, Payload, ReturnValue = any> {
    (payload: Payload): (
        dispatch: ThunkDispatch<State, {}, any>,
        getState: () => State
    ) => Promise<ReturnValue> | ReturnValue;
}

export type Unpacked<T> = T extends (infer U)[]
    ? U
    : T extends (...args: any[]) => infer U
    ? U
    : T extends Promise<infer U>
    ? U
    : T;

export type RecursivePartial<T> = {
    [P in keyof T]?: T[P] extends (infer U)[]
        ? RecursivePartial<U>[]
        : T[P] extends object
        ? RecursivePartial<T[P]>
        : T[P];
};

export type Pretty<T> = T extends unknown ? { [K in keyof T]: T[K] } : never;
