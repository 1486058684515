import React from 'react';
import PropTypes from 'prop-types';
import RatingStars from './rating-stars';
import RatingCount from './rating-count';

const Rating = ({ domain, rating, numberOfRatings }) => (
    <div className="listing-rating">
        <span>{domain} is rated</span>
        <RatingStars rating={rating} />
        <RatingCount value={numberOfRatings} />
    </div>
);

Rating.propTypes = {
    domain: PropTypes.string.isRequired,
    numberOfRatings: PropTypes.number.isRequired,
    rating: PropTypes.number.isRequired,
};

export default Rating;
