export const cookieKey = 'gt_adb';
export const cookieValueForDuckDuckGo = 'ddg';

export const isDuckDuckGoExtensionActiveOnWindow = (window) =>
    window.document.querySelectorAll('[hidden] [data-display-ad]').length > 0;

export const isDuckDuckGoExtensionActiveInCookie = (cookieValue) =>
    cookieValue === cookieValueForDuckDuckGo;

export const setAdBlockCookie = (window, value) => {
    window.document.cookie = `${cookieKey}=${value}`;
};

export const markDuckDuckGoCookie = (window: Window) => {
    // necessary because without it on Firefox the extension seems to have not finished running
    window.setTimeout(() => {
        if (isDuckDuckGoExtensionActiveOnWindow(window)) {
            setAdBlockCookie(window, cookieValueForDuckDuckGo);
        }
    });
};
