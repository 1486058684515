import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './link-button.scss';

const getClassName = (isPadded) => (
    classnames({
        'link-button': true,
        padded: isPadded
    })
);

const LinkButton = ({ children, isPadded, url, ...props }) => (
    <a
        className={getClassName(isPadded)}
        href={url}
        {...props}
    >
        {children}
    </a>
);

LinkButton.propTypes = {
    children: PropTypes.node.isRequired,
    isPadded: PropTypes.bool,
    url: PropTypes.string.isRequired
};

LinkButton.defaultProps = {
    isPadded: false
};

export default LinkButton;
