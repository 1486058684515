const empty = {
    offsetTop: 0,
    offsetHeight: 0,
    offsetLeft: 0,
    offsetWidth: 0,
};

export const getOutsidePosition = (elem = empty, place, window) => {
    switch (place) {
        case 'bottom':
            return window.innerHeight - elem.offsetTop;
        case 'top':
            return elem.offsetTop + elem.offsetHeight;
        case 'right':
            return window.innerWidth - elem.offsetLeft;
        case 'left':
            return elem.offsetLeft + elem.offsetWidth;
    }
};
