import { L1Category, PageType } from '@gumtree/shared/src/types/client-data';

export const isBingEnabled = (
    pageType: PageType,
    isGTF2602On: boolean,
    l1Category: L1Category | undefined
) => {
    return (
        (pageType === 'ResultsBrowse' || pageType === 'ResultsSearch') &&
        (l1Category === 'for-sale' || l1Category === 'toplevel') &&
        !isGTF2602On
    );
};
