import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
    redirectToUrl
} from '@gumtree/ui-library/src/utils/browser-service';

import './favicon.ico';

class Content extends Component {
    componentDidUpdate() {
        const { url } = this.props;

        if (url) {
            redirectToUrl(url);
        }
    }

    render() {
        const { children } = this.props;

        return (
            <Fragment>
                {children}
            </Fragment>
        );
    }
}

Content.propTypes = {
    children: PropTypes.node.isRequired,
    url: PropTypes.string
};

Content.defaultProps = {
    url: null
};

export default Content;
