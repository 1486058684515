import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import { Icon } from '@gumtree/ui-library';
import { gutterSizes, colors } from '@gumtree/ui-library/src/base/theme';
import { removeItemsViaRegex } from '@gumtree/ui-library/src/utils/local-storage-service';
import { trackGA4PreNav } from '@gumtree/shared/src/util/ga4-shared';
import type { ShellState } from '../reducers/common';
import type { UsersData } from '../reducers/common/user-data';

const regex = /^review-[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

export default function LogoutForm(props: { mobile?: boolean }) {
    const state = useSelector(
        ({
            baseConfig: { sellerUrl },
            userData: { csrfToken },
        }: ShellState & { userData: UsersData }) => ({
            sellerUrl,
            csrfToken,
        }),
        () => true
    );

    const handleLogout = async () => {
        trackGA4PreNav<GA4.UserLogout>({ event: 'user_logout' });
        removeItemsViaRegex(regex);
    };

    return (
        <form method="post" action={`${state.sellerUrl}/logout`}>
            <input type="hidden" name="token" value={state.csrfToken} />
            <LogoutSubmitButton type="submit" mobile={props.mobile} onClick={handleLogout}>
                <span>Logout</span>
                <Icon type="chevron-r" />
            </LogoutSubmitButton>
        </form>
    );
}

const LogoutSubmitButton = styled.button<{ mobile?: boolean }>`
    all: unset;

    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: ${gutterSizes.medium} 0;
    color: ${colors.white};
    cursor: pointer;

    span {
        margin-left: ${(props) => (props.mobile ? gutterSizes.xlarge : gutterSizes.medium)};
        &.icon {
            margin-right: ${(props) => (props.mobile ? gutterSizes.xlarge : gutterSizes.medium)};
        }
    }
`;
