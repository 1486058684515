import { trackV2 } from '../../util/track-ga-event';
import { validateOrigin } from '../../util/message-helpers';
import { trackGA4Event } from '../../util/ga4-shared';
import { getDomainFromUrl } from '../../util/url-service';

type IframeGaMessageData = {
    command: string;
    tracking: {
        eventAction: string;
        eventLabel?: string;
        uaNonInt?: boolean;
    };
};

const iframeGaEventListener = () => {
    window.addEventListener('message', (event) => {
        if (!validateOrigin(window, event.origin)) {
            return;
        }
        if (
            typeof event.data === 'string' &&
            event.data.includes('SEND_GA_EVENT_FROM_PARENT_DOCUMENT')
        ) {
            const parsedData: IframeGaMessageData = JSON.parse(event.data);
            trackV2(parsedData.tracking.eventAction, parsedData.tracking.eventLabel, {
                eventAction: parsedData.tracking.eventAction,
                uaNonInt: parsedData.tracking.uaNonInt,
            });
            return;
        }
        if (typeof event.data !== 'string') return;
        try {
            const { command, payload } = JSON.parse(event.data);

            if (command === 'TRACK_PARTNERSHIP_CLICK') {
                trackV2('PartnershipAdClick', undefined, payload);

                return;
            }

            if (command === 'GA4_TRACK_PARTNERSHIP_CLICK') {
                const event = {
                    ...payload,
                    event: 'click_on_partner_ad',
                    linkDomain: payload.linkURL ? getDomainFromUrl(payload.linkURL) : undefined,
                };

                trackGA4Event(event);

                return;
            }
        } catch {
            // do nothing
        }
    });
};

export default iframeGaEventListener;
