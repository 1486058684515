import React from 'react';
import PropTypes from 'prop-types';
import PhoneNumber from '../../../common/tracked-phone-number';

const ClickToCallNumbers = ({ numbers }) => (
    <div>
        {numbers.map((ext) => (
            <div key={ext.id}>
                <PhoneNumber number={ext.number} trackingUrl={ext.tracking}>
                    {ext.number}
                </PhoneNumber>
            </div>
        ))}
    </div>
);

ClickToCallNumbers.propTypes = {
    numbers: PropTypes.array.isRequired,
};

export default ClickToCallNumbers;
