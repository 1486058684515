import { Device, L1Category, PageType } from '@gumtree/shared/src/types/client-data';
import { AdsType, getAdsTypeToShow } from './ad-type-selector';
import { buildTracingTag } from './url-builder/build-tracing-tag';

export const APP_ID_BACKFILL = '8ED44B28311A63CF32CCBBDA850C072B30E41182';
export const APP_ID_DEFAULT = 'CFEAB5E0AC88686BA597A26FB4B545552FCA87AE';

export const buildAppId = (adsType: AdsType) => {
    return adsType === AdsType.ProductWithBackfill ? APP_ID_BACKFILL : APP_ID_DEFAULT;
};

const buildAdUnitId = (
    adsType: AdsType,
    device: Device,
    l1Category: L1Category,
    isGooglePpcVisit: boolean,
    pageType: PageType
) => {
    if (['ResultsSearch', 'ResultsBrowse'].includes(pageType)) {
        if (device === 'mobile') {
            if (isGooglePpcVisit || l1Category === 'jobs') {
                return '11649760';
            } else if (adsType === AdsType.ProductWithBackfill) {
                return '11707002';
            } else {
                return '11649760';
            }
        } else if (device === 'tablet') {
            if (adsType === AdsType.ProductWithBackfill) {
                return '11707001';
            } else {
                return '11649762';
            }
        } else if (device === 'desktop') {
            return '11700036';
        }
    } else if (pageType === 'VIP') {
        if (device === 'desktop') {
            return '11720127';
        }
        if (device === 'tablet') {
            return '11720128';
        }
        if (device === 'mobile') {
            return '11720126';
        }
    }

    return undefined;
};

export const buildFilterType = (adsType: AdsType): string => {
    switch (adsType) {
        case AdsType.ProductWithBackfill:
            return 'TextAds%2CProductAds';
        default:
            return 'TextAds';
    }
};

export const buildAdTypesPreference = (adsType: AdsType) => {
    switch (adsType) {
        case AdsType.ProductWithBackfill:
            return 'ProductAds';
        default:
            return '';
    }
};

export const encodeKeywords = (unencodedKeywords) =>
    unencodedKeywords
        ?.replace(/ /g, '+')
        .replace(/&/g, '%26')
        .replace(/([^a-zA-Z0-9+%]*)/g, '');

export default (params: {
    device: Device;
    l1Category: L1Category;
    keywords: string;
    pageType: PageType;
    clientIp: string | undefined;
    isGooglePpcVisit: boolean;
    mainlineCount: number;
    abTests: string[];
    resultSize: number | undefined;
}): string => {
    const {
        device,
        l1Category,
        keywords,
        pageType,
        clientIp,
        isGooglePpcVisit,
        mainlineCount,
        abTests,
        resultSize,
    } = params;

    const adsType: AdsType = getAdsTypeToShow(l1Category);
    const appId = buildAppId(adsType);
    const adUnitId = buildAdUnitId(adsType, device, l1Category, isGooglePpcVisit, pageType);
    const supportedAdExtensions =
        adsType === AdsType.Text || adsType === AdsType.ProductWithBackfill
            ? encodeURIComponent('SiteLinks,LongAdTitle')
            : '';
    const tracingTag = buildTracingTag({ device, l1Category, pageType, resultSize, abTests });
    const filterType: string = buildFilterType(adsType);
    const adTypesPreference = buildAdTypesPreference(adsType);

    const lowerCasedQuery = keywords.toLowerCase(); // lowercase generates more PLA results see GTF-2247

    const queryParameters = [
        { key: 'appid', value: appId },
        { key: 'propertyId', value: 10278676 },
        { key: 'q', value: encodeKeywords(lowerCasedQuery) },
        { key: 'adUnitId', value: adUnitId },
        { key: 'supportedAdExtensions', value: supportedAdExtensions },
        { key: 'clientIP', value: clientIp },
        { key: 'mainlineCount', value: mainlineCount.toString() },
        { key: 'sidebarCount', value: '0' },
        { key: 'safeSearch', value: 'strict' },
        { key: 'mkt', value: 'en-GB' },
        { key: 'tracingTag', value: tracingTag },
        { key: 'adTypesFilter', value: filterType },
        { key: 'AdTypesPreference', value: adTypesPreference },
    ];

    const url = `/partner/bing?${queryParameters
        .filter(({ value }) => Boolean(value))
        .map(({ key, value }) => `${key}=${value}`)
        .join('&')}`;

    return url;
};
