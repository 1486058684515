import { getCurrentBreakpoint } from '@gumtree/shared/src/util/breakpoints-service';

export default (googletag) => {
    const tshirtSize = getCurrentBreakpoint(window)?.tshirt;

    if (!tshirtSize) {
        return;
    }

    googletag.cmd.push(() => {
        googletag.pubads().setTargeting('SR', tshirtSize);
    });
};
