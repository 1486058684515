import { validateOrigin } from '@gumtree/shared/src/util/message-helpers';

const addEventListenerForHouseRefresh = (window) => {
    window.addEventListener('message', (event) => {
        if (!validateOrigin(window, event.origin)) {
            return;
        }
        if (
            typeof event.data === 'string' &&
            event.data.includes('DISPLAY_ADS_HOUSE_REFRESH_TURN_OFF')
        ) {
            window.advertising = {
                display: {
                    isHouseRefreshOff: true,
                },
            };
        }
    });
};

export default addEventListenerForHouseRefresh;
