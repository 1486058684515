import styled from '@emotion/styled';

import { mediaQuery, gutterSizes, breakpoints } from '@gumtree/ui-library/src/base/theme';

export const TopSearchesContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 12px;

    .section {
        margin-bottom: ${gutterSizes.base};
    }

    ${mediaQuery.from(breakpoints.medium)} {
        grid-template-columns: repeat(7, 1fr);
        grid-column-gap: 8px;
    }
`;
