import styled from '@emotion/styled';
import {
    gutterSizes,
    fontSizes,
    colors,
    mediaQuery,
    breakpoints,
} from '@gumtree/ui-library/src/base/theme';

export const FooterSocialContainer = styled.div`
    color: ${colors.white};

    ${mediaQuery.until(breakpoints.medium)} {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: ${gutterSizes.large};
    }
`;

export const H4 = styled.h4`
    font-size: ${fontSizes.header};
    color: ${colors.bark40};
    margin-bottom: ${gutterSizes.base};
`;

export const IconLink = styled.a`
    display: inline-flex;
    padding: ${gutterSizes.base};
    margin-top: -${gutterSizes.base};

    ${mediaQuery.from(breakpoints.medium)} {
        &:first-of-type {
            margin-left: -${gutterSizes.base};
        }
    }
`;
