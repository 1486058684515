import React from 'react';
import { connect } from 'react-redux';

import { deepClone } from '@gumtree/shared/src/model/generic.model';
import { Container, Grid } from '@gumtree/ui-library';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';

import { copyright, globalSites, sections as footerSections, apps } from './footer-data.json';
import FooterCopyright from './footer-copyright';
import FooterLinks from './footer-links';
import FooterAppLinks from './footer-app-links';
import FooterSocial from './footer-social';
import FooterGlobalSites from './global-sites';
import { trackAndNavigate } from '../common/common-actions';
import ErrorBoundary from '../error-boundary';
import type { ShellState } from '../reducers/common';

import { FooterBlock, FooterDividerStyle } from './style';

const Divider = () => (
    <Grid container>
        <Grid item col={12} colXl={10} offsetXl={1}>
            <div css={FooterDividerStyle} />
        </Grid>
    </Grid>
);

export const Footer: React.FC<Props> = ({ device, trackAndNavigate }) => {
    const sections = deepClone(footerSections);

    if (device.type === 'mobile') {
        sections.pop();
    }

    return (
        <ErrorBoundary>
            <FooterBlock {...qaAttribute('footer-container')}>
                <Container isSection>
                    <nav>
                        {device.type === 'mobile' && (
                            <>
                                <FooterAppLinks
                                    sections={apps}
                                    device={device}
                                    trackAndNavigate={trackAndNavigate}
                                />
                            </>
                        )}
                        <FooterLinks
                            sections={sections}
                            device={device}
                            trackAndNavigate={trackAndNavigate}
                        />
                        <Divider />
                        <Grid container>
                            <Grid item col={12} colL={6} colXl={5} offsetXl={1}>
                                <FooterSocial />
                            </Grid>
                            <Grid item col={12} colL={6} colXl={5}>
                                <FooterGlobalSites
                                    title={globalSites.title}
                                    items={globalSites.items}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                        <FooterCopyright links={copyright.links} />
                    </nav>
                </Container>
            </FooterBlock>
        </ErrorBoundary>
    );
};

const mapState = ({ baseConfig: { device }, experiments }: ShellState) => ({
    device,
    experiments,
});

const mapDispatch = (dispatch) => ({
    trackAndNavigate: (event: string, url: string, eventLabel?: string) => {
        dispatch(trackAndNavigate(event, url, eventLabel));
    },
});

export type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export default connect(mapState, mapDispatch)(Footer);
