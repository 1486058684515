export const getContainerStyle = (height, width) => {
    const style = {};

    if (height) {
        style.height = `${height}px`;
    }

    if (width) {
        style.width = `${width}px`;
    }

    return style;
};

export const getImageStyle = (
    { offsetWidth: containerWidth, offsetHeight: containerHeight } = {},
    { offsetWidth: imageWidth, offsetHeight: imageHeight } = {}
) => {
    const style = {};

    if (containerWidth && imageWidth) {
        const containerRatio = containerWidth / containerHeight;
        const imageRatio = imageWidth / imageHeight;

        if (containerRatio > imageRatio) {
            if (imageWidth > containerWidth) {
                style.maxWidth = '100%';
            } else {
                style.minWidth = '100%';
            }
        } else {
            if (imageHeight > containerHeight) {
                style.maxHeight = '100%';
            } else {
                style.minHeight = '100%';
            }
        }
    }

    return style;
};
