import React from "react";
import { css } from "@emotion/react";
import { breakpoints, colors, gutterSizes, mediaQuery, zIndexes } from "@gumtree/ui-library/src/base/theme";

export const PanelContainer = ({children, isActive, ...props}: React.PropsWithChildren<{
    isActive?: boolean
}>) => {
    const PanelContainerStyle = css`
        display: ${isActive ? 'block' : 'none'};
        padding-top: 80px;
        position: fixed;
        top: 0;
        height: 100%;
        overflow: auto;
        background-color: ${colors.white};
        width: 100%;
        box-sizing: border-box;
        z-index: ${zIndexes.high + 2};

        ${mediaQuery.from(breakpoints.small)} {
            padding-top: ${gutterSizes.xlarge};
            padding-bottom: ${gutterSizes.xlarge};

            position: absolute;
            top: initial;
            height: initial;
            overflow: initial;
            z-index: ${zIndexes.high};
        }
    `;

    return (<div css={PanelContainerStyle} {...props}>
        {children}
    </div>);
}
