import injectScript from '@gumtree/ui-library/src/utils/inject-script';

import { ConsentStatus, getConsentStatus, WindowWithCmp } from '../gdpr/util';

export const addBlockthrough = async (window: WindowWithCmp) => {
    getConsentStatus(window).then(({ consentStatus }) => {
        if (
            consentStatus === ConsentStatus.CONSENTED ||
            consentStatus === ConsentStatus.IMPLICITLY_CONSENTED
        ) {
            injectScript('//gumtree-com-uk.videoplayerhub.com/galleryplayer.js', { async: true });
        }
    });
};
