import { Nullable } from 'typescript-nullable';
import Partner from './partner';
import SlotName from '../common/slot-name';
import ScreenSize from '../common/screen-size';
import DeviceType from '../common/device-type';
import PageType from '../common/page-type';

export default class Appnexus extends Partner {
    readonly bidder = 'appnexus';
    mapping = {
        [PageType.ResultsPage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.integratedMpu]: 10881385,
                    [SlotName.rSkyT]: 10881384,
                    [SlotName.rSkyT2]: 20049125,
                    [SlotName.tBanner]: 10881383,
                    [SlotName.rSkyB]: 10881388,
                    [SlotName.rSkyB2]: 20049126,
                    [SlotName.integratedListing]: 10881386,
                },
                [ScreenSize.XL]: {
                    [SlotName.integratedMpu]: 10881385,
                    [SlotName.rSkyT]: 10881384,
                    [SlotName.rSkyT2]: 20049125,
                    [SlotName.tBanner]: 10881383,
                    [SlotName.rSkyB]: 10881388,
                    [SlotName.rSkyB2]: 20049126,
                    [SlotName.integratedListing]: 10881386,
                },
                [ScreenSize.L]: {
                    [SlotName.integratedMpu]: 10881385,
                    [SlotName.tBanner]: 10881383,
                    [SlotName.integratedListing]: 10881386,
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.integratedMpu]: 10881399,
                    [SlotName.tBanner]: 10881397,
                    [SlotName.integratedListing]: 10881401,
                },
                [ScreenSize.M]: {
                    [SlotName.integratedMpu]: 10881399,
                    [SlotName.tBanner]: 10881397,
                    [SlotName.integratedListing]: 10881401,
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.integratedMpu]: 10881417,
                    [SlotName.integratedListing]: 15969573,
                    [SlotName.floatingFooter]: 10881416,
                },
                [ScreenSize.XS]: {
                    [SlotName.integratedMpu]: 10881417,
                    [SlotName.integratedListing]: 15969573,
                    [SlotName.floatingFooter]: 10881416,
                },
            },
        },
        [PageType.VIP]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.vipBanner]: 10881393,
                    [SlotName.mpu]: 10881395,
                    [SlotName.vipMiddleDesktop]: 13483951,
                },
                [ScreenSize.XL]: {
                    [SlotName.vipBanner]: 10881393,
                    [SlotName.mpu]: 10881395,
                    [SlotName.vipMiddleDesktop]: 13483951,
                },
                [ScreenSize.L]: {
                    [SlotName.vipBanner]: 10881393,
                    [SlotName.mpu]: 10881395,
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.vipBanner]: 10881406,
                    [SlotName.mpu]: 10881408,
                    [SlotName.fBanner]: 10881409,
                },
                [ScreenSize.M]: {
                    [SlotName.vipBanner]: 10881406,
                    [SlotName.mpu]: 10881408,
                    [SlotName.fBanner]: 10881409,
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.floatingFooter]: 10881419,
                    [SlotName.vipMiddleMobile]: 13492293,
                },
                [ScreenSize.XS]: {
                    [SlotName.floatingFooter]: 10881419,
                    [SlotName.vipMiddleMobile]: 13492293,
                },
            },
        },
        [PageType.Homepage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.homeSideAd]: 10881382,
                },
                [ScreenSize.XL]: {
                    [SlotName.homeSideAd]: 10881382,
                },
                [ScreenSize.L]: {
                    [SlotName.homeSideAd]: 10881382,
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.homeSideAd]: 10881396,
                },
                [ScreenSize.M]: {
                    [SlotName.homeSideAd]: 10881396,
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.homeBanner]: 10881411,
                },
                [ScreenSize.XS]: {
                    [SlotName.homeBanner]: 10881411,
                },
            },
        },
    };

    toObject() {
        if (Nullable.isNone(this.getPlacement())) {
            return undefined;
        }

        return {
            bidder: this.bidder,
            params: {
                placementId: this.getPlacement(),
                keywords: {
                    ...(Nullable.isSome(this.categoryL1) && { l1Category: this.categoryL1 }),
                    ...(Nullable.isSome(this.categoryL2) && { l2Category: this.categoryL2 }),
                    ...(Nullable.isSome(this.categoryL3) && { l3Category: this.categoryL3 }),
                    ...(Nullable.isSome(this.screenSize) && { SR: ScreenSize[this.screenSize] }),
                    ...(window.innerWidth >= 1440 ? { inskin: 1 } : {}),
                    ...(Nullable.isSome(this.vipAdId) && { vipAdId: this.vipAdId }),
                },
            },
        };
    }
}
