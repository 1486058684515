import React from 'react';
import PropTypes from 'prop-types';

import slug from '@gumtree/ui-library/src/utils/slug';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import TopSearchesSection from './top-searches-section';

import { TopSearchesContent } from './top-search-style';

const TopSearchesTabSection = ({ sections, title }) => (
    <>
        <h2 {...qaAttribute(`top-searches-toggler-${slug(title)}`)}>{title}</h2>
        <TopSearchesContent>
            {sections.map((section, index) => (
                <TopSearchesSection key={index} {...section} />
            ))}
        </TopSearchesContent>
    </>
);

TopSearchesTabSection.propTypes = {
    sections: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
};

export default TopSearchesTabSection;
