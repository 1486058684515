import React from 'react';
import PropTypes from 'prop-types';
import Star from './star';

const RatingStars = ({ rating }) => {
    const stars = Array(5)
        .fill(0)
        .map((v, i) => ({
            id: `star-${i}`,
            noFill: i > rating / 2 - 1,
        }));
    return (
        <span className="listing-rating-stars">
            {stars.map((star) => (
                <Star key={star.id} noFill={star.noFill} />
            ))}
        </span>
    );
};

RatingStars.propTypes = {
    rating: PropTypes.number.isRequired,
};

export default RatingStars;
