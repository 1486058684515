import { L1Category, PageType } from '@gumtree/shared/src/types/client-data';

export const isAdSenseEnabled = (
    pageType: PageType,
    isGTF2602On: boolean,
    l1Category: L1Category | undefined
) => {
    return (
        (pageType === 'ResultsBrowse' || pageType === 'ResultsSearch') &&
        (l1Category === 'for-sale' || l1Category === 'toplevel') &&
        isGTF2602On
    );
};

const libraryTag = () =>
    `<script async="async" src="https://www.google.com/adsense/search/ads.js"></script>`;

export const initialisationTag = (
    pageType: PageType,
    isGTF2602On: boolean,
    l1Category: L1Category | undefined
) =>
    isAdSenseEnabled(pageType, isGTF2602On, l1Category)
        ? `
      ${libraryTag()}
      <script type="text/javascript" charset="utf-8">
      (function(g,o){g[o]=g[o]||function(){(g[o]['q']=g[o]['q']||[]).push(
      arguments)},g[o]['t']=1*new Date})(window,'_googCsa');
      </script>
      `
        : '';
