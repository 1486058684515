export const OPEN_BANNER_TAKEOVER = 'OPEN_BANNER_TAKEOVER';
export const CLOSE_BANNER_TAKEOVER = 'CLOSE_BANNER_TAKEOVER';

export const closeBanner = () => ({
    type: 'CLOSE_BANNER_TAKEOVER',
});

export default (
    state = {
        isOpenBanner: false,
        target: '',
    },
    action
) => {
    switch (action.type) {
        case 'OPEN_BANNER_TAKEOVER':
            return {
                ...state,
                isOpenBanner: true,
                target: action.target,
            };
        case 'CLOSE_BANNER_TAKEOVER':
            return {
                ...state,
                isOpenBanner: false,
            };
        default:
            return state;
    }
};
