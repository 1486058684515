import { Nullable } from 'typescript-nullable';
import Partner from './partner';
import SlotName from '../common/slot-name';
import ScreenSize from '../common/screen-size';
import DeviceType from '../common/device-type';
import PageType from '../common/page-type';

export default class Ozone extends Partner {
    readonly bidder = 'ozone';
    mapping = {
        [PageType.ResultsPage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.integratedMpu]: 1500000149,
                    [SlotName.rSkyT]: 1500000150,
                    [SlotName.rSkyT2]: 1500000155,
                    [SlotName.tBanner]: 1500000151,
                    [SlotName.rSkyB]: 1500000153,
                    [SlotName.rSkyB2]: 1500000156,
                    [SlotName.integratedListing]: 1500000154,
                },
                [ScreenSize.XL]: {
                    [SlotName.integratedMpu]: 1500000149,
                    [SlotName.rSkyT]: 1500000150,
                    [SlotName.rSkyT2]: 1500000155,
                    [SlotName.tBanner]: 1500000151,
                    [SlotName.rSkyB]: 1500000153,
                    [SlotName.rSkyB2]: 1500000156,
                    [SlotName.integratedListing]: 1500000154,
                },
                [ScreenSize.L]: {
                    [SlotName.integratedMpu]: 1500000149,
                    [SlotName.tBanner]: 1500000151,
                    [SlotName.integratedListing]: 1500000154,
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.integratedMpu]: 1500000159,
                    [SlotName.tBanner]: 1500000160,
                    [SlotName.integratedListing]: 1500000159,
                },
                [ScreenSize.M]: {
                    [SlotName.integratedMpu]: 1500000159,
                    [SlotName.tBanner]: 1500000160,
                    [SlotName.integratedListing]: 1500000159,
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.integratedMpu]: 1500000163,
                    [SlotName.integratedListing]: 1500000164,
                    [SlotName.floatingFooter]: 1500000165,
                },
                [ScreenSize.XS]: {
                    [SlotName.integratedMpu]: 1500000163,
                    [SlotName.integratedListing]: 1500000164,
                    [SlotName.floatingFooter]: 1500000165,
                },
            },
        },
        [PageType.VIP]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.vipBanner]: 1500000167,
                    [SlotName.mpu]: 1500000166,
                    [SlotName.vipMiddleDesktop]: 1500000168,
                },
                [ScreenSize.XL]: {
                    [SlotName.vipBanner]: 1500000167,
                    [SlotName.mpu]: 1500000166,
                    [SlotName.vipMiddleDesktop]: 1500000168,
                },
                [ScreenSize.L]: {
                    [SlotName.vipBanner]: 1500000167,
                    [SlotName.mpu]: 1500000166,
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.vipBanner]: 1500000171,
                    [SlotName.mpu]: 1500000170,
                    [SlotName.fBanner]: 1500000169,
                },
                [ScreenSize.M]: {
                    [SlotName.vipBanner]: 1500000171,
                    [SlotName.mpu]: 1500000170,
                    [SlotName.fBanner]: 1500000169,
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.floatingFooter]: 1500000172,
                    [SlotName.vipMiddleMobile]: 1500000173,
                },
                [ScreenSize.XS]: {
                    [SlotName.floatingFooter]: 1500000172,
                    [SlotName.vipMiddleMobile]: 1500000173,
                },
            },
        },
        [PageType.Homepage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.homeSideAd]: 1500000144,
                },
                [ScreenSize.XL]: {
                    [SlotName.homeSideAd]: 1500000144,
                },
                [ScreenSize.L]: {
                    [SlotName.homeSideAd]: 1500000144,
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.homeSideAd]: 1500000145,
                },
                [ScreenSize.M]: {
                    [SlotName.homeSideAd]: 1500000145,
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.homeBanner]: 1500000146,
                },
                [ScreenSize.XS]: {
                    [SlotName.homeBanner]: 1500000146,
                },
            },
        },
    };

    toObject() {
        if (Nullable.isNone(this.getPlacement())) {
            return undefined;
        }

        return {
            bidder: this.bidder,
            params: {
                publisherId: 'OZONEGT00001',
                placementId: this.getPlacement(),
                siteId: '1500000159',
                customData: [{ settings: {}, targeting: { page_type: this.pageType } }],
            },
        };
    }
}
