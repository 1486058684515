import {
    getCurrentBreakpoint,
    getBreakpointMinByTshirtSize,
} from '@gumtree/shared/src/util/breakpoints-service';

import { getWhitelistedSlotIds } from './refresh-whitelist';

class DisplayAdSlot {
    constructor(pageType, config, googletag, w) {
        this.mapping = [];
        this.adSlot = undefined;
        this.isGoogleDisplayCalled = false;
        this.mediaQueries = [];
        this.spec = config;
        this.googletag = googletag;

        this.setupMapping();
        this.define(pageType, w);
    }

    /**
     * Mapping function
     */
    setupMapping() {
        if (Array.isArray(this.spec.mapping)) {
            const mediaQueries = new Set();

            this.spec.mapping.forEach(({ screenSize }) => {
                screenSize.forEach((size) => mediaQueries.add(size));
            });

            this.mediaQueries = [...mediaQueries];

            this.googletag.cmd.push(() => {
                let mapping = this.googletag.sizeMapping();

                this.spec.mapping
                    .map(({ screenSize, screenHeight, adSize }) => {
                        const minWidth = getBreakpointMinByTshirtSize(screenSize[0]);
                        const minHeight = screenHeight;
                        return { screenSize: [minWidth, minHeight || 0], adSize };
                    })
                    .forEach(({ screenSize, adSize }) => {
                        mapping = mapping.addSize(screenSize, adSize);
                    });

                this.mapping = mapping.build();
            });
        }
    }

    /**
     * Checks is ad should run at this MQ
     */
    checkMQ(w) {
        return this.mediaQueries.includes(getCurrentBreakpoint(w).tshirt);
    }

    checkDom(w) {
        return w.document.getElementById(this.spec.slotId);
    }

    isRefreshable(device, l1Category, pageType, w) {
        const currentBreakpoint = getCurrentBreakpoint(w);
        if (!currentBreakpoint) {
            throw new Error('Cannot determine screen size. Error id: 11e3');
        }
        const currentScreenSize = currentBreakpoint.tshirt;
        const whitelistedSlotIds = getWhitelistedSlotIds(
            device,
            l1Category,
            pageType,
            currentScreenSize
        );
        return whitelistedSlotIds.includes(this.spec.slotId);
    }

    /**
     * Display function
     */
    callGoogleDisplay(w) {
        if (this.checkMQ(w) && !this.isGoogleDisplayCalled) {
            this.googletag.cmd.push(() => {
                this.googletag.display(this.spec.slotId);
            });

            this.isGoogleDisplayCalled = true;
        }
    }

    startWithCollapsedSlot(adSlot) {
        return adSlot.setCollapseEmptyDiv(true, true);
    }

    collapseSlotIfEmpty(adSlot) {
        return adSlot.setCollapseEmptyDiv(true);
    }

    /**
     * Define function
     */
    define(pageType, w) {
        if (this.adSlot) {
            return;
        }

        this.googletag.cmd.push(() => {
            const screenTShirtSize = getCurrentBreakpoint(w).tshirt;

            const { adSize } =
                this.spec.mapping.find(({ screenSize }) => screenSize.includes(screenTShirtSize)) ||
                {};

            if (adSize) {
                const adUnit = this.spec.adUnit[screenTShirtSize];
                const slot = this.googletag
                    .defineSlot(adUnit, adSize, this.spec.slotId)
                    .defineSizeMapping(this.mapping)
                    .setTargeting('ad_h', new Date().getUTCHours().toString())
                    .addService(this.googletag.pubads());

                if (
                    this.spec.startWithCollapsedSlot &&
                    this.spec.startWithCollapsedSlot.find(
                        (_) => _.pageType === pageType && _.screenSizes.includes(screenTShirtSize)
                    )
                ) {
                    this.startWithCollapsedSlot(slot);
                } else {
                    this.collapseSlotIfEmpty(slot); // default
                }

                if (this.spec.targeting) {
                    Object.entries(this.spec.targeting).forEach(([key, value]) =>
                        slot.setTargeting(key, value)
                    );
                }

                this.adSlot = slot;
            }
        });
    }
}

export default DisplayAdSlot;
