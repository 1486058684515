import transformItem from './transform-offered-item';
import transformCurrency from './transform-currency';
import { Offer } from '../../transform-data-into-ad-models';

const transformOffer = (offer: Offer) => {
    return {
        url: offer.url,
        description: offer.itemOffered?.description,
        seller: offer.seller.name,
        price: {
            sale: offer.lowPrice,
            original: offer.price,
            currency: transformCurrency(offer.priceCurrency),
        },
        item: transformItem(offer.itemOffered),
    };
};

export default transformOffer;
