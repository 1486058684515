import { Nullable } from 'typescript-nullable';
import Partner from './partner';
import SlotName from '../common/slot-name';
import ScreenSize from '../common/screen-size';
import DeviceType from '../common/device-type';
import PageType from '../common/page-type';

export default class IndexExchange extends Partner {
    readonly bidder = 'ix';
    mapping = {
        [PageType.ResultsPage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.integratedMpu]: '208970',
                    [SlotName.rSkyT]: '208969',
                    [SlotName.rSkyT2]: '564445',
                    [SlotName.tBanner]: '208968',
                    [SlotName.rSkyB]: '208973',
                    [SlotName.rSkyB2]: '564446',
                    [SlotName.integratedListing]: '208971',
                },
                [ScreenSize.XL]: {
                    [SlotName.integratedMpu]: '208970',
                    [SlotName.rSkyT]: '208969',
                    [SlotName.rSkyT2]: '564445',
                    [SlotName.tBanner]: '208968',
                    [SlotName.rSkyB]: '208973',
                    [SlotName.rSkyB2]: '564446',
                    [SlotName.integratedListing]: '208971',
                },
                [ScreenSize.L]: {
                    [SlotName.integratedMpu]: '208970',
                    [SlotName.tBanner]: '208968',
                    [SlotName.integratedListing]: '208971',
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.integratedMpu]: '387743',
                    [SlotName.tBanner]: '208990',
                    [SlotName.integratedListing]: '387744',
                },
                [ScreenSize.M]: {
                    [SlotName.integratedMpu]: '387743',
                    [SlotName.tBanner]: '208990',
                    [SlotName.integratedListing]: '387744',
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.integratedMpu]: '387745',
                    [SlotName.integratedListing]: '387746',
                    [SlotName.floatingFooter]: '208986',
                },
                [ScreenSize.XS]: {
                    [SlotName.integratedMpu]: '387745',
                    [SlotName.integratedListing]: '387746',
                    [SlotName.floatingFooter]: '208986',
                },
            },
        },
        [PageType.VIP]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.vipBanner]: '208976',
                    [SlotName.mpu]: '208974',
                    [SlotName.vipMiddleDesktop]: '278314',
                },
                [ScreenSize.XL]: {
                    [SlotName.vipBanner]: '208976',
                    [SlotName.mpu]: '208974',
                    [SlotName.vipMiddleDesktop]: '278314',
                },
                [ScreenSize.L]: {
                    [SlotName.vipBanner]: '208976',
                    [SlotName.mpu]: '208974',
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.vipBanner]: '208982',
                    [SlotName.mpu]: '208983',
                    [SlotName.fBanner]: '208984',
                },
                [ScreenSize.M]: {
                    [SlotName.mpu]: '208983',
                    [SlotName.fBanner]: '208984',
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.floatingFooter]: '208988',
                    [SlotName.vipMiddleMobile]: '278315',
                },
                [ScreenSize.XS]: {
                    [SlotName.floatingFooter]: '208988',
                    [SlotName.vipMiddleMobile]: '278315',
                },
            },
        },
        [PageType.Homepage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.homeSideAd]: '208890',
                },
                [ScreenSize.XL]: {
                    [SlotName.homeSideAd]: '208890',
                },
                [ScreenSize.L]: {
                    [SlotName.homeSideAd]: '208890',
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.M]: {
                    [SlotName.homeSideAd]: '208989',
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.homeBanner]: '208985',
                },
                [ScreenSize.XS]: {
                    [SlotName.homeBanner]: '208985',
                },
            },
        },
    };

    findSize(): Nullable<[number, number]> {
        const sizeMapping: {
            [index: string]: {
                [index: string]: { [index: string]: { [index: string]: [number, number] } };
            };
        } = {
            [PageType.ResultsPage]: {
                [DeviceType.desktop]: {
                    [ScreenSize.XXL]: {
                        [SlotName.integratedMpu]: [300, 250],
                        [SlotName.rSkyT]: [300, 600],
                        [SlotName.rSkyT2]: [300, 600],
                        [SlotName.tBanner]: [970, 250],
                        [SlotName.rSkyB]: [300, 600],
                        [SlotName.rSkyB2]: [300, 600],
                        [SlotName.integratedListing]: [300, 250],
                    },
                    [ScreenSize.XL]: {
                        [SlotName.integratedMpu]: [300, 250],
                        [SlotName.rSkyT]: [160, 600],
                        [SlotName.tBanner]: [970, 250],
                        [SlotName.rSkyB]: [160, 600],
                        [SlotName.integratedListing]: [300, 250],
                    },
                    [ScreenSize.L]: {
                        [SlotName.integratedMpu]: [300, 250],
                        [SlotName.tBanner]: [970, 250],
                        [SlotName.integratedListing]: [300, 250],
                    },
                },
                [DeviceType.tablet]: {
                    [ScreenSize.L]: {
                        [SlotName.integratedMpu]: [300, 250],
                        [SlotName.tBanner]: [970, 250],
                        [SlotName.integratedListing]: [300, 250],
                    },
                    [ScreenSize.M]: {
                        [SlotName.integratedMpu]: [300, 250],
                        [SlotName.tBanner]: [728, 90],
                        [SlotName.integratedListing]: [300, 250],
                    },
                },
                [DeviceType.mobile]: {
                    [ScreenSize.S]: {
                        [SlotName.integratedListing]: [300, 250],
                        [SlotName.floatingFooter]: [320, 50],
                        [SlotName.integratedMpu]: [300, 250],
                    },
                    [ScreenSize.XS]: {
                        [SlotName.integratedListing]: [300, 250],
                        [SlotName.floatingFooter]: [320, 50],
                        [SlotName.integratedMpu]: [300, 250],
                    },
                },
            },
            [PageType.VIP]: {
                [DeviceType.desktop]: {
                    [ScreenSize.XXL]: {
                        [SlotName.vipBanner]: [970, 250],
                        [SlotName.mpu]: [300, 600],
                        [SlotName.vipMiddleDesktop]: [300, 250],
                    },
                    [ScreenSize.XL]: {
                        [SlotName.vipBanner]: [970, 250],
                        [SlotName.mpu]: [300, 600],
                        [SlotName.vipMiddleDesktop]: [300, 250],
                    },
                    [ScreenSize.L]: {
                        [SlotName.vipBanner]: [970, 250],
                        [SlotName.mpu]: [300, 600],
                    },
                },
                [DeviceType.tablet]: {
                    [ScreenSize.L]: {
                        [SlotName.vipBanner]: [970, 250],
                        [SlotName.mpu]: [300, 600],
                        [SlotName.fBanner]: [728, 90],
                    },
                    [ScreenSize.M]: {
                        [SlotName.mpu]: [300, 600],
                        [SlotName.fBanner]: [728, 90],
                    },
                },
                [DeviceType.mobile]: {
                    [ScreenSize.S]: {
                        [SlotName.floatingFooter]: [320, 50],
                        [SlotName.vipMiddleMobile]: [300, 250],
                    },
                    [ScreenSize.XS]: {
                        [SlotName.floatingFooter]: [320, 50],
                        [SlotName.vipMiddleMobile]: [300, 250],
                    },
                },
            },
            [PageType.Homepage]: {
                [DeviceType.desktop]: {
                    [ScreenSize.XXL]: {
                        [SlotName.homeSideAd]: [300, 600],
                    },
                    [ScreenSize.XL]: {
                        [SlotName.homeSideAd]: [300, 600],
                    },
                    [ScreenSize.L]: {
                        [SlotName.homeSideAd]: [300, 600],
                    },
                },
                [DeviceType.tablet]: {
                    [ScreenSize.M]: {
                        [SlotName.homeSideAd]: [160, 600],
                    },
                },
                [DeviceType.mobile]: {
                    [ScreenSize.S]: {
                        [SlotName.homeBanner]: [320, 50],
                    },
                    [ScreenSize.XS]: {
                        [SlotName.homeBanner]: [320, 50],
                    },
                },
            },
        };

        if (
            sizeMapping[this.pageType] &&
            sizeMapping[this.pageType][this.deviceType] &&
            sizeMapping[this.pageType][this.deviceType][this.screenSize]
        ) {
            return sizeMapping[this.pageType][this.deviceType][this.screenSize][this.slotName];
        }

        return undefined;
    }

    toObject() {
        if (Nullable.isNone(this.getPlacement()) || Nullable.isNone(this.findSize())) {
            return undefined;
        }

        return {
            bidder: this.bidder,
            params: {
                siteId: this.getPlacement(),
                size: this.findSize(),
            },
        };
    }
}
