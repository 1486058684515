import React from 'react';
import { createRoot } from 'react-dom/client';
import AttributionComponent from './component/attribution';

export default (gumtreeSlotsConfig, rawBingSlotsConfig) => {
    const attributions = gumtreeSlotsConfig
        .map((slot) => window.document.querySelector(`#${slot.divId}-attribution`))
        .filter((container) => container !== null);

    attributions.forEach((attributionEl) => {
        if (rawBingSlotsConfig.length) {
            const root = createRoot(attributionEl);
            root.render(<AttributionComponent />);
        }
    });
};
