import { useMedia } from 'react-media';
import { useSelector } from 'react-redux';
import { DeviceTypes } from '@gumtree/shared/src/types/client-data';
import { breakpoints } from '../base/theme';

const isBetween = (min: breakpoints, max: breakpoints) => {
    const maxWidth = Number.parseInt(max.split('px')[0], 10) - 1;
    return `(min-width: ${min}) and (max-width: ${maxWidth}px)`;
}

const GLOBAL_MEDIA_QUERIES = {
    xxsmall: `(max-width: ${breakpoints.xsmall})`,
    xsmall: isBetween(breakpoints.xsmall, breakpoints.small),
    small: isBetween(breakpoints.small, breakpoints.medium),
    medium: isBetween(breakpoints.medium, breakpoints.large),
    large: isBetween(breakpoints.large, breakpoints.xlarge),
    xlarge: isBetween(breakpoints.xlarge, breakpoints.xxlarge),
    xxlarge: `(min-width: ${breakpoints.xxlarge})`
};

const useMediaQuery = () => {
    const { baseConfig } = useSelector((state: any) => state);
    const { type: deviceType } = baseConfig.device;
    const matches = useMedia({
        queries: GLOBAL_MEDIA_QUERIES, defaultMatches: {
            xxsmall: deviceType === DeviceTypes.MOBILE,
            xsmall: deviceType === DeviceTypes.MOBILE,
            small: deviceType === DeviceTypes.MOBILE,
            medium: deviceType === DeviceTypes.TABLET,
            large: deviceType === DeviceTypes.DESKTOP,
            xlarge: deviceType === DeviceTypes.DESKTOP,
            xxlarge: deviceType === DeviceTypes.DESKTOP,
        }
    });

    const isSmallMobile = matches.xxsmall;
    const isMobile = matches.small || matches.xsmall;
    const isTablet = matches.medium;
    const isDesktop = matches.large || matches.xlarge || matches.xxlarge;

    return { isSmallMobile, isMobile, matches, isTablet, isDesktop };
}
export default useMediaQuery;
