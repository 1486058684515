import React from 'react';
import { createRoot } from 'react-dom/client';
import Listing from './listing';
import reportTileClick from '../reporting/report-tile-click';

export default (adConfigs = [], adSlotElement, deviceSize, abTests) => {
    const onAdClick = ({ index }) => {
        reportTileClick({ slotId: adSlotElement.id, deviceSize, index, window });
    };
    const root = createRoot(adSlotElement);

    root.render(<Listing adConfigs={adConfigs} onAdClick={onAdClick} abTests={abTests} />);
};
