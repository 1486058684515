import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../common/icon';

const Star = ({ noFill }) => {
    const className = noFill ? 'no-fill' : '';
    return <Icon name="star-fill" className={className} />;
};

Star.defaultProps = {
    noFill: false,
};

Star.propTypes = {
    noFill: PropTypes.bool,
};

export default Star;
