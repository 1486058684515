import { css } from "@emotion/react";
import { colors, fontSizes, gutterSizes, zIndexes } from "@gumtree/ui-library/src/base/theme";

export const appBannerTakeoverCss = css`
    .banner-open {
        height: 100vh;
        overflow: hidden !important;
        position: relative;
    }

    .banner-takeover {
        background-color: ${colors.white};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: ${zIndexes.high};
        box-sizing: border-box;
        padding: 64px ${gutterSizes.xlarge} 0 ${gutterSizes.xlarge};

        &-close {
            position: absolute;
            right: 0;
            top: 0;
        }

        &-cancel {
            margin: ${gutterSizes.xlarge} 0 ${gutterSizes.medium};
            color: ${colors.blue};
            font-size: ${fontSizes.medium};
        }

        &-content {
            text-align: center;
        }

        &-text {
            padding: 0 ${gutterSizes.medium};
        }

        &-phone {
            background-position: center ${gutterSizes.large};
            background-repeat: no-repeat;
            background-size: contain;
            height: 65vh;
            margin: 0 auto;
            width: 250px;

            &.ios {
                background-image: url("./post-banner-ios.jpg");
            }
        }

        &-buttons {
            margin: ${gutterSizes.xxlarge} auto 0;
            max-width: 300px;
        }
    }
`;
