import { getAppViewTheme } from './appview-theme';
import { GumtreeTheme } from './gumtree-theme.d';
import { modernTheme } from './modern-theme';

export const PageTheme = {
    WEBVIEW: 'web-view',
    APPVIEW: 'app-view',
} as const;

export const createTheme = ({
    page: { pageTheme },
}: {
    page: { pageTheme: string };
}): GumtreeTheme => {
    if (pageTheme === PageTheme.APPVIEW) {
        return getAppViewTheme();
    }
    return modernTheme;
};
