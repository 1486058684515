// TODO clean
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Slide } from '@gumtree/ui-library/src/transitions';

import './side-nav-box.scss';

const getClassName = (right) => classnames('side-nav-box', { 'side-nav-box--right': right });

const notClose = (event) => event.stopPropagation();

const SideNavBox = ({ children, isOpen, right }) => (
    <Slide in={isOpen} direction={right ? 'left' : 'right'}>
        <div
            data-testid="side-nav-box"
            className={getClassName(right)}
            onClick={notClose}
            role="button"
            onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                    notClose();
                }
            }}
            tabIndex={-1}
        >
            {children}
        </div>
    </Slide>
);

SideNavBox.defaultProps = {
    children: null,
    isOpen: false,
    right: false,
};

SideNavBox.propTypes = {
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    right: PropTypes.bool,
};

export default SideNavBox;
