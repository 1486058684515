import React, { Component, cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './tabs.scss';
import './tabs-with-buttons.scss';

class Tabs extends Component {
    state = {
        activeTabIndex: 0,
    };

    getClassName(withNeutralBackground, withButtonsNavigation, minimalistStyle) {
        const { className } = this.props;
        return classnames(
            {
                tabs: true,
                neutral: withNeutralBackground,
                newStyle: minimalistStyle,
                'tabs-with-buttons': withButtonsNavigation,
            },
            className
        );
    }

    handleClick = (index) => (event) => {
        this.setState({ activeTabIndex: index });
        event.stopPropagation();
    };

    render() {
        const {
            withNeutralBackground,
            withButtonNavigation,
            minimalistStyle,
            children,
            overrideActiveTabIndex,
        } = this.props;
        const { activeTabIndex } = this.state;

        return (
            <div
                className={this.getClassName(
                    withNeutralBackground,
                    withButtonNavigation,
                    minimalistStyle,
                    overrideActiveTabIndex
                )}
                role="tablist"
                data-q="tabs"
            >
                {Children.toArray(children).map((child, index) =>
                    cloneElement(child, {
                        key: index,
                        ...(!overrideActiveTabIndex && { isActive: activeTabIndex === index }),
                        ...(!overrideActiveTabIndex && {
                            onClick: this.handleClick(index).bind(this),
                        }),
                    })
                )}
            </div>
        );
    }
}

Tabs.defaultProps = {
    withButtonNavigation: false,
    overrideActiveTabIndex: false,
    withNeutralBackground: false,
    minimalistStyle: false,
    className: '',
};

Tabs.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    minimalistStyle: PropTypes.bool,
    overrideActiveTabIndex: PropTypes.bool,
    withButtonNavigation: PropTypes.bool,
    withNeutralBackground: PropTypes.bool,
};

export default Tabs;
