import styled from '@emotion/styled';
import {
    mediaQuery,
    breakpoints,
    fontSizes,
    fontWeights,
} from '@gumtree/ui-library/src/base/theme';
import { Button, Icon } from '@gumtree/ui-library';

export const LoginToolTipWrapper = styled.div`
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgb(0 0 0 / 40%);

    ${mediaQuery.from(breakpoints.medium)} {
        position: initial;
        width: 0;
        height: 0;
    }

    .button + .button.button.button--full-width {
        margin-left: 0;
        margin-top: 0;
    }
`;

export const LoginToolTip = styled.div`
    background-color: white;
    box-shadow: 0 4px 4px 0 rgb(0 0 0 / 40%);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    width: 290px;
    z-index: 1;

    ${mediaQuery.from(breakpoints.medium)} {
        position: absolute;
        right: 0;
        margin: 7px 15px 0 0;
        top: 47px;
        left: initial;
        transform: initial;
        border-radius: 0;
        width: 340px;
    }
`;

export const ContentWrapper = styled.div`
    padding: 18px 16px 24px 30px;

    ${mediaQuery.from(breakpoints.medium)} {
        padding-right: 35px;
        padding-left: 45px;
    }
`;

export const Title = styled.div`
    font-size: ${fontSizes.base};
    font-weight: ${fontWeights.bold};
    margin-bottom: 5px;
`;

export const Description = styled.div`
    margin-bottom: 20px;
`;

export const CloseButton = styled(Button)`
    && {
        position: absolute;
        top: 8px;
        right: 10px;
    }
`;

export const ButtonWrapper = styled.div`
    padding: 0 18px;

    ${mediaQuery.from(breakpoints.medium)} {
        display: flex;
        justify-content: space-between;
        padding: 0 32px;
    }
`;

export const CTAButton = styled(Button)`
    && {
        border-radius: 50px;
        margin-bottom: 12px;

        &:last-of-type {
            margin-bottom: 18px;
        }

        ${mediaQuery.from(breakpoints.medium)} {
            &.button,
            &.button--full-width {
                margin-top: 0;
                display: inline-block;
                width: 126px;
                margin-bottom: 31px;
            }

            &:last-of-type {
                margin-bottom: 31px;
            }
        }
    }
`;

export const StyledList = styled.ul`
    li {
        display: flex;

        .icon,
        svg {
            position: absolute;
        }
    }
`;

export const StyledListItemDescription = styled.div`
    margin: 0 0 10px 35px;
`;

export const StyledIcon = styled(Icon)`
    font-size: 23px;
`;
