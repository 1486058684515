export const SET_SHOW_FULL_SCREEN_OVERLAY = 'SET_SHOW_FULL_SCREEN_OVERLAY';

export const SET_SHOW_POPUP_PROMPT = 'SET_SHOW_POPUP_PROMPT';
export const SET_IS_FIRST_TIME_VISIT = 'SET_IS_FIRST_TIME_VISIT';

export const setShowFullScreenOverlay = (value: boolean) => ({
    type: SET_SHOW_FULL_SCREEN_OVERLAY,
    payload: value,
});

export const setShowPopUpPrompt = (value: boolean) => ({
    type: SET_SHOW_POPUP_PROMPT,
    payload: value,
});

export const setIsFirstTimeVisit = (value: boolean) => ({
    type: SET_IS_FIRST_TIME_VISIT,
    payload: value,
});

export interface State {
    showFullScreenOverlay: boolean;
    showPopUpPrompt: boolean;
    isFirstTimeVisit: boolean;
}

export const initialState: State = {
    showFullScreenOverlay: false,
    showPopUpPrompt: false,
    isFirstTimeVisit: false,
};

export default (state = initialState, action): State => {
    switch (action.type) {
        case SET_SHOW_FULL_SCREEN_OVERLAY:
            return {
                ...state,
                showFullScreenOverlay: action.payload,
            };
        case SET_SHOW_POPUP_PROMPT:
            return {
                ...state,
                showPopUpPrompt: action.payload,
            };
        case SET_IS_FIRST_TIME_VISIT:
            return {
                ...state,
                isFirstTimeVisit: action.payload,
            };

        default:
            return state;
    }
};
