const numberFormatter = new Intl.NumberFormat('en-GB', {});

/**
 * Return number of words in `str`, or `null` if none.
 */
const getWordCount = (str: string) => {
    if (typeof str === 'string') {
        const matches = str.match(/\w+/g);
        if (matches) {
            return matches.length;
        }
    }
    return null;
};

const formatNumber = (value) => {
    return numberFormatter.format(value);
};

const formatCurrency = (amountInPence) => {
    const pounds = amountInPence / 100;
    const hasFractionDigits = amountInPence % 100 !== 0;

    const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: hasFractionDigits ? 2 : 0,
        maximumFractionDigits: 2,
    });

    return formatter.format(pounds);
};

const sanitizeString = (unsafe_str) =>
    unsafe_str
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;');

const splitByNewLine = (text: string) => text.split('\n');

const decodeQuoteCharacter = (text: string | undefined = '') => text.replace(/&quot;/g,'"');

const toTitleCase = (text: string | number) =>
    text.toString().replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());

export {
    splitByNewLine,
    formatCurrency,
    formatNumber,
    sanitizeString,
    getWordCount,
    toTitleCase,
    decodeQuoteCharacter,
};
