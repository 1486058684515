import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FullScreenPrompt from './full-screen';
import PopUpPrompt from './pop-up';
import { ShellState } from '../reducers/common';
import { setShowFullScreenOverlay, setShowPopUpPrompt } from '../reducers/common/location-prompt';
import {
    fullScreenPrompt,
    hasBeenShownLocationPrompt,
    popUpPrompt,
    setHasBeenShownLocationPrompt,
} from './common/persistence-util';

export const LocationPrompt: React.FC = () => {
    const dispatch = useDispatch();

    const {
        showFullScreenOverlay,
        showPopUpPrompt,
        isFirstTimeVisit,
        pageType,
        isUsingDefaultLocation,
        experiments,
    } = useSelector((state: ShellState) => {
        return {
            showFullScreenOverlay: state.locationPrompt.showFullScreenOverlay,
            showPopUpPrompt: state.locationPrompt.showPopUpPrompt,
            isFirstTimeVisit: state.locationPrompt.isFirstTimeVisit,
            pageType: state.baseConfig.pageType,
            isUsingDefaultLocation: state.searchBar.location.isUsingDefaultLocation,
            experiments: state.experiments,
        };
    });

    useEffect(() => {
        if (
            isUsingDefaultLocation &&
            isFirstTimeVisit &&
            (pageType === 'ResultsBrowse' ||
                pageType === 'ResultsSearch' ||
                pageType === 'Homepage') &&
            experiments['GTF-1533'] === 'B' &&
            !hasBeenShownLocationPrompt(fullScreenPrompt)
        ) {
            setHasBeenShownLocationPrompt(fullScreenPrompt);
            dispatch(setShowFullScreenOverlay(true));
        }

        if (
            isUsingDefaultLocation &&
            (pageType === 'ResultsBrowse' || pageType === 'ResultsSearch') &&
            experiments['GTF-1533'] === 'C' &&
            !hasBeenShownLocationPrompt(popUpPrompt)
        ) {
            setHasBeenShownLocationPrompt(popUpPrompt);
            dispatch(setShowPopUpPrompt(true));
        }
    }, []); // setting the state in JavaScript so that it doesn't affect crawlers

    return (
        <>
            {showFullScreenOverlay && <FullScreenPrompt />}
            {showPopUpPrompt && <PopUpPrompt />}
        </>
    );
};

export default LocationPrompt;
