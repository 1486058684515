import React, { Component, ChangeEvent } from 'react';
import { Icon, TypeAhead } from '@gumtree/ui-library'; // Assuming these imports are correct
import { trackV2 } from '@gumtree/shared/src/util/track-ga-event';

import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import { locationFieldAriaLabel, locationFieldPlaceholderText } from '../constants';

interface LocationFieldProps {
    setLocationAndUpdateSuggestions(value: string): void;
    loadRecentLocationHistory(): void;
    query: string;
    selectSuggestion(option: { name: string }, useRecentHistory: boolean): void;
    suggestions: any[];
    useRecentHistory: boolean;
    removeSingleFromHistory: (text: string) => void;
    clearAllFromHistory: () => void;
}

interface LocationFieldState {
    hasTrackedLocationHistoryShown: boolean;
}

class LocationField extends Component<LocationFieldProps, LocationFieldState> {
    static defaultProps = {
        suggestions: [],
    };

    constructor(props: LocationFieldProps) {
        super(props);
        this.state = {
            hasTrackedLocationHistoryShown: false,
        };
    }

    handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const { setLocationAndUpdateSuggestions } = this.props;
        setLocationAndUpdateSuggestions(value);
    };

    componentDidMount() {
        const { loadRecentLocationHistory } = this.props;
        loadRecentLocationHistory();
    }

    render() {
        const {
            query,
            selectSuggestion,
            suggestions,
            removeSingleFromHistory,
            clearAllFromHistory,
            useRecentHistory,
        } = this.props;

        return (
            <TypeAhead
                inputProps={{
                    'aria-label': locationFieldAriaLabel,
                    onInput: this.handleInput,
                    placeholder: locationFieldPlaceholderText,
                    ...qaAttribute('search-location-field'),
                }}
                icon={<Icon type="beacon" size="medium" />}
                id="header-search-location"
                limit={10}
                name="search_location"
                onOpen={() => {
                    if (useRecentHistory && !this.state.hasTrackedLocationHistoryShown) {
                        trackV2('LocationHistoryShown');
                        this.setState({ hasTrackedLocationHistoryShown: true });
                    }
                }}
                onSelectOption={(option) => {
                    selectSuggestion(option, useRecentHistory);
                }}
                options={suggestions}
                removeSingleFromHistory={removeSingleFromHistory}
                clearAllFromHistory={clearAllFromHistory}
                useRecentHistory={useRecentHistory}
                defaultValue={query}
                typeAheadIcon={
                    useRecentHistory ? (
                        <Icon type="clock" size="medium" />
                    ) : (
                        <Icon type="beacon" size="medium" />
                    )
                }
                titleOfRecentHistory={`Recent Location${suggestions.length === 1 ? '' : 's'}`}
                showClearAllButton={false}
            />
        );
    }
}

export default LocationField;
