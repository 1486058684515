import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FormElement from '../form-element';
import { qaAttribute } from '../../utils/qa-service';

import '../form-element.scss';

class TypeAheadContainer extends PureComponent {
    handleKeyDown = (event) => {
        const { focusedOption, onFocusOption, onSelectOption, options, moveCursor, limit } =
            this.props;
        let nextOption = focusedOption;
        let endOption;

        if (options.length > limit) {
            endOption = limit;
        } else {
            endOption = options.length;
        }

        switch (event.key) {
            case 'ArrowDown':
                if (focusedOption === endOption) {
                    nextOption = 0;
                }

                if (focusedOption < options.length) {
                    nextOption++;
                } else {
                    nextOption = 1;
                }

                onFocusOption(nextOption);

                break;
            case 'ArrowUp':
                if (focusedOption > 1) {
                    nextOption--;
                } else {
                    nextOption = endOption;
                }

                onFocusOption(nextOption);
                moveCursor();

                break;
            case 'Enter':
                if (nextOption) {
                    event.preventDefault();
                    onSelectOption(nextOption);
                }

                break;
        }
    };

    render() {
        const { children, inline, id, onBlur, isFocused } = this.props;

        return (
            <FormElement
                type="input"
                className={classNames('type-ahead', 'input-field', { 'is-focused': isFocused })}
                inline={inline}
                onBlur={onBlur}
                onKeyDown={this.handleKeyDown}
                {...qaAttribute(`type-ahead-${id}`)}
            >
                {children}
            </FormElement>
        );
    }
}

TypeAheadContainer.propTypes = {
    children: PropTypes.node,
    focusedOption: PropTypes.number,
    id: PropTypes.string.isRequired,
    inline: PropTypes.bool,
    isFocused: PropTypes.bool.isRequired,
    limit: PropTypes.number,
    moveCursor: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onFocusOption: PropTypes.func,
    onSelectOption: PropTypes.func,
    options: PropTypes.array,
};

TypeAheadContainer.defaultProps = {
    children: null,
    focusedOption: 0,
    inline: false,
    limit: 10,
    onBlur: () => {},
    onFocusOption: () => {},
    onSelectOption: () => {},
    options: [],
};

export default TypeAheadContainer;
