import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import TopSearchesTabSection from './top-searches-tab-section';

const TopSearches = ({ items }) => (
    <Fragment>
        {items.map((search, index) => (
            <div key={index}>
                <TopSearchesTabSection isOpen={index === 0} {...search} />
            </div>
        ))}
    </Fragment>
);

TopSearches.propTypes = {
    items: PropTypes.array.isRequired,
};

export default TopSearches;
