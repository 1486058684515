import { PageType } from '@gumtree/shared/src/types/client-data';

const getInskinKeyValue = (
    screenWidth: number,
    pageType: PageType
): { key: 'inskin'; value: '1' }[] => {
    const isScreenSizeAllowed = screenWidth >= 1400;
    const isPageTypeAllowed = pageType === 'VIP';
    return isScreenSizeAllowed && isPageTypeAllowed
        ? [
              {
                  key: 'inskin',
                  value: '1',
              },
          ]
        : [];
};

export default getInskinKeyValue;
