import React from 'react';
import PropTypes from 'prop-types';

const TextAdWrapper = ({ children, num }) => {
    const id = `bing-text-ad-${num}`;

    return (
        <article
            id={id}
            style={{
                borderBottom: '1px solid #f1f0f1',
                padding: '8px 0 8px 16px',
                width: '100%',
                overflow: 'hidden',
            }}
        >
            {children}
        </article>
    );
};

TextAdWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    num: PropTypes.number.isRequired,
};

export default TextAdWrapper;
