/* eslint-disable react/jsx-no-target-blank */
/*
	Common component to render external links

	- is secure using `rel="noopener noreferrer"`, see https://mathiasbynens.github.io/rel-noopener/
	- opens the external link in a new tab / window
	- can take a custom CSS class in `className` prop

	Usage:

	import ExternalLink from `frontend/common/component/external-link`;

	export default () => (
		<ExternalLink url="http://example.com" className="custom-class">link to example.com</ExternalLink>
	)
*/
import React from 'react';
import PropTypes from 'prop-types';

const ExternalLink = ({ url, children, style, onClick, noReferrer }) => (
    <a
        href={url}
        rel={`noopener ${noReferrer ? 'noreferrer' : ''}`}
        target="_blank"
        style={style}
        onClick={onClick}
        data-testid="external-link"
    >
        {children}
    </a>
);

ExternalLink.defaultProps = {
    noReferrer: true,
    style: {},
    onClick: () => {},
};

ExternalLink.propTypes = {
    children: PropTypes.any.isRequired,
    noReferrer: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object,
    url: PropTypes.string.isRequired,
};

export default ExternalLink;
