import React from 'react';
import classnames from 'classnames';

const ToggleContent: React.FC<Props> = ({ children, className, ...props }) => (
    <div className={classnames('content', className)} {...props}>
        {children}
    </div>
);

interface Props extends React.PropsWithChildren {
    className?: string;
};

export default ToggleContent;
