import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import VisibilityObserver from '@gumtree/shared/src/util/element-visibility/visibility-observer';

import Tile from './tile';
import { AdModel } from '../../../../common/transform-data-into-ad-models';

// eslint-disable-next-line react/display-name
const Carousel = forwardRef(
    (
        {
            adConfigs,
            onTileClick,
            onVisible,
        }: {
            adConfigs: AdModel[];
            onTileClick: any;
            onVisible: ({ impressionToken }: { impressionToken: string }) => void;
        },
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        return (
            <div
                ref={ref}
                style={{
                    display: 'flex',
                    overflowX: 'scroll',
                    paddingTop: '6px',
                    paddingLeft: '16px',
                }}
            >
                {adConfigs.map((props, i) => (
                    <VisibilityObserver
                        key={`ad-${i + 1}`}
                        style={{
                            width: '120px',
                            padding: '8px',
                            border: 'solid 1px #d8d6d9',
                            marginRight: '9px',
                        }}
                        onVisible={() => {
                            onVisible({ impressionToken: props.impressionToken });
                        }}
                        threshold={0.1}
                    >
                        <Tile {...props} onClick={() => onTileClick({ index: i + 1 })} />
                    </VisibilityObserver>
                ))}
            </div>
        );
    }
);

Carousel.defaultProps = {
    onTileClick: () => {},
};

Carousel.propTypes = {
    adConfigs: PropTypes.array.isRequired,
    onTileClick: PropTypes.func,
};

export default Carousel;
