/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector as useSelectorHook } from 'react-redux';

import { Container, Grid } from '@gumtree/ui-library';
import { DeviceTypes } from '@gumtree/shared/src/types/client-data';

import { ShellState } from '../reducers/common';

import NavItem, { onClickFunction, onMouseEnterFunction } from './category-nav-ribbon-item';
import {
    ItemListContainer,
    ItemListWrapper,
    ItemList,
    Fade,
} from './category-nav-ribbon-item-list.style';

export default function NavItemList({
    items,
    activeItemIndex,
    onItemClick,
    onItemMouseEnter,
    useSelector = useSelectorHook,
    ...params
}: Props) {
    const [isFadeShown, setIsFadeShown] = useState(true);
    const [initListLeft, setInitListLeft] = useState<number | null>(null);
    const [iniCursorX, setIniCursorX] = useState<number | null>(null);
    const [isDragging, _setIsDragging] = useState(false);
    const isDraggingRef = useRef(isDragging);
    const setIsDragging = (arg) => {
        isDraggingRef.current = arg;
        _setIsDragging(arg);
    };

    const { device } = useSelector(({ baseConfig }: ShellState) => baseConfig);
    const itemListContainerRef = useRef<HTMLDivElement>(null!);
    const itemListRef = useRef<HTMLUListElement>(null!);
    const lastItemRef = useRef<HTMLLIElement>(null!);

    const itemMouseEnterHandler = (e, { seoName, labelRef, index }) => {
        onItemMouseEnter && onItemMouseEnter(e, { seoName, labelRef, index });
    };

    const itemClickHandler = (e, { seoName, labelRef, path, name }) => {
        if (isDraggingRef.current) {
            e.preventDefault();
            return;
        }

        onItemClick && onItemClick(e, { seoName, labelRef, path, name });
    };

    const itemListMouseDownHandler = (e) => {
        if (['desktop'].includes(device?.type as DeviceTypes)) {
            setInitListLeft(itemListRef.current.scrollLeft);
            setIniCursorX(e.clientX);
        }
    };

    useEffect(() => {
        if (['desktop'].includes(device?.type as DeviceTypes)) {
            if ([initListLeft, iniCursorX].includes(null)) return;

            const mouseMoveHandler = (e) => {
                // How far the mouse has been moved
                const dx = e.clientX - (iniCursorX as number);
                // Scroll the element
                itemListRef.current.scrollLeft = (initListLeft as number) - dx;
                // updateUnderlinePostion(hoveringItemRef.current);
                if (Math.abs(dx) > 10) {
                    setIsDragging(true);
                }
            };

            const mouseUpHandler = (e) => {
                document.removeEventListener('mousemove', mouseMoveHandler);
                document.removeEventListener('mouseup', mouseUpHandler);
                setTimeout(() => {
                    setIsDragging(false);
                }, 150);
                e.preventDefault();
            };

            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);

            return () => {
                document.removeEventListener('mousemove', mouseMoveHandler);
                document.removeEventListener('mouseup', mouseUpHandler);
            };
        }

        return;
    }, [initListLeft, iniCursorX]);

    const initFadeToggle = (): IntersectionObserver | undefined => {
        let intersectionObserver;
        if ('IntersectionObserver' in window) {
            intersectionObserver = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.intersectionRatio >= 0.8) {
                            setIsFadeShown(false);
                        } else {
                            setIsFadeShown(true);
                        }
                    });
                },
                { root: itemListRef.current, threshold: [0, 0.2, 0.4, 0.6, 0.8, 1] }
            );
            intersectionObserver.observe(lastItemRef.current);
        }
        return intersectionObserver;
    };

    useEffect(() => {
        const fadeObserver = initFadeToggle();
        return () => {
            fadeObserver?.disconnect();
        };
    }, []);

    return (
        <ItemListContainer ref={itemListContainerRef} {...params}>
            <Container>
                <Grid container>
                    <Grid item col={12}>
                        <ItemListWrapper>
                            <ItemList ref={itemListRef} onMouseDown={itemListMouseDownHandler}>
                                {items.map((item, i) => (
                                    <NavItem
                                        key={`nav-ribbon-item-${item.seoName}`}
                                        name={item.name}
                                        seoName={item.seoName}
                                        path={item.path}
                                        qaAttr={`nav-${item.seoName}`}
                                        onClick={itemClickHandler}
                                        onMouseEnter={itemMouseEnterHandler}
                                        ref={i === items.length - 1 ? lastItemRef : null}
                                        index={i}
                                        isActive={i === activeItemIndex}
                                    />
                                ))}
                            </ItemList>
                        </ItemListWrapper>
                    </Grid>
                </Grid>
            </Container>
            <Fade isShown={isFadeShown} />
        </ItemListContainer>
    );
}

interface Props {
    items: Array<any>;
    showHoverUnderline: boolean;
    activeItemIndex: number | null;
    onItemClick: onClickFunction;
    onItemMouseEnter: onMouseEnterFunction;
    useSelector?: typeof useSelectorHook;
}
