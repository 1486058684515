import React from 'react';
import PropTypes from 'prop-types';

const Content = ({ children }) => {
    const styles = {
        overflow: 'hidden',
        minHeight: window?.innerWidth >= 768 ? '92px' : '75px',
    };

    return <div style={styles}>{children}</div>;
};

Content.propTypes = {
    children: PropTypes.any.isRequired,
};

export default Content;
