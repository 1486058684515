import { L1Category, L2Category, Device, PageType } from '@gumtree/shared/src/types/client-data';
import getBingAdConfig from './ad-config';

import sendImpressionFeedback from './send-impression-feedback';
import renderAttributions from './components/attribution/renderer';
import renderSlots from './renderer';
import { validatePageConfig } from './ad-config/ad-config-validator';
import findSlotsOnDom from './slots-on-dom-locator';
import fetchBingAds from './fetch-bing-ads';
import transformDataIntoAdModels from '../common/transform-data-into-ad-models';
import buildUrl from '../common/bing-server-url-constructor';
import suppressTextAdSpace from '../../suppress-text-ad-space';

export type DataLayer = {
    push: (attributes: {
        event: string;
        eventCategory: string;
        eventAction?: string;
        eventLabel?: string;
    }) => undefined;
};

const loader = async (
    device: Device,
    keywords: string,
    clientIp: string,
    l1Category: L1Category,
    l2Category: L2Category,
    resultSize: number,
    abTests: string[],
    googlePpcVisit: boolean,
    pageType: PageType,
    _dataLayer: DataLayer,
    window: Window
) => {
    const pageConfig = getBingAdConfig({
        device,
        l1Category,
        l2Category,
        googlePpcVisit,
        abTests,
        pageType,
        resultSize,
    });

    if (!validatePageConfig(pageConfig)) {
        return Promise.resolve('No valid Bing config found on page');
    }

    const bingUrl = buildUrl({
        device,
        l1Category,
        keywords,
        pageType,
        clientIp,
        isGooglePpcVisit: googlePpcVisit,
        mainlineCount: pageConfig.number,
        abTests,
        resultSize,
    });

    const slotsOnDom = findSlotsOnDom(pageConfig.slots, window);

    if (slotsOnDom.length === 0) {
        return Promise.resolve('No Bing slot found on DOM');
    }

    try {
        const { bingAds, impressionFeedbackUrl, visibilityFeedbackUrl } = await fetchBingAds(
            bingUrl
        );

        bingAds.length > 0
            ? renderAttributions(pageConfig.slots, bingAds)
            : suppressTextAdSpace(pageType);

        const adModels = transformDataIntoAdModels(bingAds);

        const renderedModels = renderSlots({
            slotsOnDom,
            device,
            adModels,
            serverConfig: pageConfig,
            pageType,
            visibilityFeedbackBaseUrl: visibilityFeedbackUrl,
            abTests,
        });

        sendImpressionFeedback({
            renderedAds: renderedModels,
            impressionFeedbackUrl,
        });
    } catch (_ex) {
        suppressTextAdSpace(pageType);
        return Promise.resolve('Bing connection error');
    }

    return Promise.resolve();
};

export default loader;
