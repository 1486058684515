import { WindowWithClientData } from '@gumtree/shared/src/types/window';

export default (googletag, window: WindowWithClientData, pageType) => {
    if (!['ResultsSearch', 'ResultsBrowse'].includes(pageType) || !window.clientData) {
        return;
    }

    const naturalResultSize = window.clientData.resultsPage?.searchAds.length;
    const nearbyResultSize = window.clientData.resultsPage?.nearbyAds?.ads.length;

    if (!naturalResultSize || !nearbyResultSize) {
        return;
    }

    googletag.cmd.push(() => {
        googletag.pubads().setTargeting('res_ntrl', `${naturalResultSize}`);
        googletag.pubads().setTargeting('res_nrby', `${nearbyResultSize}`);
        googletag.pubads().setTargeting('res_cmb', `${naturalResultSize}-${nearbyResultSize}`);
    });
};
