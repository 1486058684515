import React, { PureComponent } from 'react';

import { Icon } from '@gumtree/ui-library';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import slug from '@gumtree/ui-library/src/utils/slug';
import { trackGA4PreNav } from '@gumtree/shared/src/util/ga4-shared';
import { getDomainFromUrl } from '@gumtree/shared/src/util/url-service';

import './side-menu-item.scss';
import { MenuItemLabel } from './side-menu-data';

export default class SideMenuItem extends PureComponent<Props & typeof SideMenuItem['defaultProps']> {

    static defaultProps = {
        betaLabel: false,
        className: '',
        eventAction: '',
        eventLabel: '',
        features: {},
        icon: '',
        id: '',
        isBannerTakeoverShowing: false,
        label: '',
        openDialog: () => {},
        inExperimentGTC2229: false,
        launchBanner: () => {},
        link: '',
        onClick: () => {},
        qaAttr: undefined,
    }

    handleClick = async (event: React.MouseEvent<HTMLAnchorElement>, isBannerTakeoverShowing, hasFeatureToggle) => {
        const {
            eventAction,
            eventLabel,
            link,
            onClick,
            trackAndNavigate,
            launchBanner,
            label,
        } = this.props;
        
        onClick(event);

        trackGA4PreNav<GA4.ClickPrimaryNavigation>({
            event: 'click_primary_navigation',
            linkDomain: getDomainFromUrl(link),
            linkText: label,
            linkURL: link,
            navigationCategory: 'menu',
            navigationHeader: undefined,
            navigationHierarchy: 'sub header',
        });

        if (eventAction) {
            event.preventDefault();
            trackAndNavigate(eventAction, link, eventLabel);
        }

        if (isBannerTakeoverShowing && hasFeatureToggle) {
            event.preventDefault();
            launchBanner(event.currentTarget.href);
        }
    };

    render() {
        const {
            icon,
            label,
            link,
            className,
            isBannerTakeoverShowing,
            features,
            betaLabel,
            inExperimentGTC2229,
            openDialog
        } = this.props;
        const hasFeatureToggle =
            features[0] && features[0].length && features[0].includes('POST_AD_APP_SIGN_POST');

        return (
            <a
                className={`side-menu-link ${className}`}
                href={link}
                onClick={(event) => {
                    this.handleClick(event, isBannerTakeoverShowing, hasFeatureToggle);
                    (inExperimentGTC2229 && label === MenuItemLabel.Login) && openDialog(event);
                }}
                {...qaAttribute(slug(`side-menu-link-${label}`))}
            >
                {icon && <Icon type={icon} />}
                {label}
                {betaLabel && <span className="has-beta-label">Beta</span>}
            </a>
        );
    }
}

interface Props {
    betaLabel?: boolean;
    className?: string;
    eventAction?: string;
    eventLabel?: string;
    features: any;
    icon?: string;
    id?: string;
    inExperimentGTC2229: boolean;
    isBannerTakeoverShowing?: boolean;
    label?: string;
    launchBanner?(href: string): void;
    link?: string;
    openDialog?: (event: React.MouseEvent) => void;
    onClick?(event: React.MouseEvent): void;
    qaAttr?: string;
    trackAndNavigate(...args: any[]): void,
};
