import React from 'react';
import PropTypes from 'prop-types';

const Seller = ({ name }) => <span className="listing-seller">{name}</span>;

Seller.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Seller;
