import { Experiments } from '../types/client-data';
import { ExperimentNames, ExperimentVariant } from '../model/experiment';

export const isInExperiment = (
    experiments: Experiments,
    experimentName: ExperimentNames,
    variant: ExperimentVariant
) => {
    return experiments?.[experimentName] === variant;
};
