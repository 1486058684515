import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { qaAttribute } from '../utils/qa-service';
import Icon from '../icon/icon';

const getClassName = ({ base, isActive, withMinHeight }) => {
    return classnames({
        [base]: true,
        'is-active': isActive,
        'min-height': withMinHeight,
    });
};

const Tab = ({ children, isActive, forceRender, onClick, title, withMinHeight, icon }) => {
    const formattedTitle = title.toLowerCase().replace(/\s/g, '-');
    const accessibilityTab = `${formattedTitle}-tab`;
    const accessibilityTabPanel = `${formattedTitle}-tabpanel`;

    const shouldRenderChildren = isActive || forceRender;

    return (
        <Fragment>
            <button
                type="button"
                role="tab"
                id={accessibilityTab}
                aria-selected={isActive}
                aria-controls={accessibilityTabPanel}
                tabIndex="-1"
                onClick={onClick}
                className={getClassName({ base: 'tabs-title', isActive })}
                {...qaAttribute(`${accessibilityTab}-toggle`)}
            >
                {icon && <Icon type={icon} />}
                {title}
            </button>
            <div
                id={accessibilityTabPanel}
                role="tabpanel"
                aria-labelledby={accessibilityTab}
                className={getClassName({
                    base: 'tabs-content',
                    isActive,
                    withMinHeight,
                })}
                {...qaAttribute(`${accessibilityTab}-content`)}
            >
                {shouldRenderChildren && children}
            </div>
        </Fragment>
    );
};

Tab.defaultProps = {
    forceRender: false,
    icon: undefined,
    isActive: false,
    onClick: undefined,
    withMinHeight: false,
};

Tab.propTypes = {
    children: PropTypes.node.isRequired,
    forceRender: PropTypes.bool,
    icon: PropTypes.string,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    title: PropTypes.string.isRequired,
    withMinHeight: PropTypes.bool,
};

export default Tab;
