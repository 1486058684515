const appendHighValueBids = (googletag) => {
    const slots = googletag.pubads().getSlots();
    slots.forEach((slot) => {
        const priceRaw = slot.getTargeting('hb_pb');
        if (priceRaw && priceRaw.length === 1) {
            const floorPrice = Math.floor(parseFloat(priceRaw[0]));
            if (floorPrice >= 12) {
                slot.setTargeting('hbid', 'high');
            }
            if (floorPrice >= 6 && floorPrice < 12) {
                slot.setTargeting('hbid', String(floorPrice));
            }
        }
    });
};

export default appendHighValueBids;
