import styled from "@emotion/styled";
import { colorVariables, fontSizes, fontWeights, gutterSizes } from "@gumtree/ui-library/src/base/theme";

export const SuccessModalContainer = styled.div`
    svg {
        width: 128px;
        height: 128px;
        margin-bottom: ${gutterSizes.xlarge};
    }
`;

export const SuccessText = styled.p`
    color: ${colorVariables.textNeutral};
    font-size: ${fontSizes.base};
    margin: ${gutterSizes.base} 0 ${gutterSizes.xlarge} 0;
`;

export const SuccessTitle = styled.h2`
    font-size: ${fontSizes.xlarge};
    color: ${colorVariables.textPrimary};
    margin-bottom: 0;
`;

export const SuccessButtonContainer = styled.div`
    display: flex;
    justify-content: center;

    .button {
        width: 50%;
        display: block;
        text-align: center;
        font-weight: ${fontWeights.bold};
    }
`;
