import { Nullable } from 'typescript-nullable';

enum DeviceType {
    desktop,
    tablet,
    mobile,
}

export function getDeviceType(type: string): Nullable<DeviceType> {
    const mapping: { [index: string]: DeviceType } = {
        desktop: DeviceType.desktop,
        tablet: DeviceType.tablet,
        mobile: DeviceType.mobile,
    };

    return mapping[type];
}

export default DeviceType;
