import prebidConfigGenerator from '../ad-config/src';

export const transformCommandFactoryParams = (
    pbjs,
    timeout,
    googletag,
    pageType,
    deviceType,
    screenSize,
    categoryL1,
    categoryL2,
    categoryL3,
    keywords,
    experiments,
    vipAdId
) => ({
    pbjs,
    timeout,
    googletag,
    pageType,
    deviceType,
    screenSize,
    categoryL1,
    categoryL2,
    categoryL3,
    keywords,
    experiments,
    vipAdId,
});

export const commandFactory = (commandFactoryParams, bidsBackHandler) => {
    return function command() {
        const {
            pbjs,
            timeout,
            pageType,
            deviceType,
            screenSize,
            categoryL1,
            categoryL2,
            categoryL3,
            keywords,
            experiments,
            vipAdId,
        } = commandFactoryParams;

        const config = {
            gptPreAuction: {
                enabled: true,
                useDefaultPreAuction: true,
            },
            ortb2: {
                site: {
                    ext: {
                        data: {
                            ...(categoryL1 && { l1Category: categoryL1 }),
                            ...(categoryL2 && { l2Category: categoryL2 }),
                            ...(categoryL3 && { l3Category: categoryL3 }),
                        },
                    },
                },
            },
            priceGranularity: {
                buckets: [
                    {
                        max: 10,
                        increment: 0.01,
                    },
                    {
                        max: 30,
                        increment: 0.1,
                    },
                ],
            },
            userSync: {
                filterSettings: {
                    iframe: {
                        bidders: '*', // '*' represents all bidders
                        filter: 'include',
                    },
                },
                userIds: [
                    {
                        name: 'identityLink',
                        params: {
                            pid: '13291',
                            notUse3P: false,
                        },
                        storage: {
                            type: 'html5',
                            name: 'idl_env',
                            expires: 7,
                            refreshInSeconds: 1800,
                        },
                    },
                    {
                        name: 'pubCommonId',
                        storage: {
                            type: 'cookie',
                            name: '_pubcid',
                            expires: 365,
                        },
                    },
                    {
                        name: 'pairId',
                        params: {
                            liveramp: {
                                storageKey: '_lr_pairId',
                            },
                        },
                    },
                ],
                syncDelay: 3000,
            },
            consentManagement: {
                gdpr: {
                    cmpApi: 'iab',
                    defaultGdprScope: true,
                    timeout: 5000,
                    rules: [
                        {
                            purpose: 'storage',
                            enforcePurpose: true,
                            enforceVendor: true,
                            vendorExceptions: ['permutive'],
                        },
                    ],
                },
            },
            bidderTimeout: timeout,
            alwaysIncludeDeals: true,
            enableSendAllBids: false,
            realTimeData: {
                dataProviders: [
                    {
                        name: 'permutive',
                        params: {
                            acBidders: ['appnexus', 'rubicon', 'ozone', 'grid', 'pubmatic', 'ix'],
                        },
                    },
                ],
            },
        };

        const adUnitsFromPrebidConfigGenerator = prebidConfigGenerator(
            pageType,
            deviceType,
            screenSize,
            categoryL1,
            categoryL2,
            categoryL3,
            keywords,
            experiments,
            vipAdId
        );

        pbjs.removeAdUnit();

        pbjs.addAdUnits(adUnitsFromPrebidConfigGenerator);

        pbjs.setConfig(config);

        pbjs.enableAnalytics([
            {
                provider: 'atsAnalytics',
                options: {
                    pid: '13291',
                },
            },
        ]);

        pbjs.requestBids({
            timeout,
            bidsBackHandler,
        });
    };
};
