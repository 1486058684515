import { saveByKey, loadByKey, removeSingleByKey, clearAllByKey } from './recent-service';

export const RECENT_SEARCHES_KEY = 'recentSearches';

export type RecentSearchesItem = {
    name: string;
    category?: string;
    categoryDisplayName?: string;
    searchOptionsExactMatch?: boolean;
};

export const save = (searches: RecentSearchesItem) => saveByKey(RECENT_SEARCHES_KEY, searches);

export const load = (limit = 5) => loadByKey(RECENT_SEARCHES_KEY, limit);

export const removeSingleFromHistory = (text: string) => {
    removeSingleByKey(RECENT_SEARCHES_KEY, text);
};

export const clearAllFromHistory = () => {
    clearAllByKey(RECENT_SEARCHES_KEY);
};
