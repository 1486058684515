import React from 'react';
import SuccessSVG from '@gumtree/ui-library/src/assets/Success.svg';
import { Button } from '@gumtree/ui-library';
import { SuccessModalContainer, SuccessButtonContainer, SuccessText, SuccessTitle } from './success-modal.style';
import { LoginModalProps } from './app';

const SuccessModal = ({ contactName = '', openSearchBar }: Partial<LoginModalProps>) => {
    return (
        <SuccessModalContainer>
            <SuccessSVG />
            <SuccessTitle>Welcome to Gumtree, {contactName}</SuccessTitle>
            <SuccessText>
                You've just joined millions of others to buy, sell, share, trade and save locally.
                Get started today!
            </SuccessText>
            <SuccessButtonContainer>
                <Button display="primary" label="Search" onClick={openSearchBar} />
                <Button display="primary" label="Post ad" href="/postad/category" />
            </SuccessButtonContainer>
        </SuccessModalContainer>
    );
};

export default SuccessModal;
