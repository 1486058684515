import React, { Fragment } from 'react';
import ErrorBoundary from '../error-boundary';
import NavBar from './nav-bar/nav-bar';
import CategoryNavRibbon from '../category-nav-ribbon/category-nav-ribbon';

const Header = () => {
    return (
        <Fragment>
            <ErrorBoundary>
                <header className="header">
                    <NavBar />
                    <CategoryNavRibbon />
                </header>
            </ErrorBoundary>
        </Fragment>
    );
};

export default Header;
