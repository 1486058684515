import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';

class Transition extends React.Component {
    constructor() {
        super();
        this.state = {
            style: {}
        };

        this.handleEnter = this.handleEnter.bind(this);
        this.handleEntering = this.handleEntering.bind(this);
        this.handleExiting = this.handleExiting.bind(this);
        this.handleRef = this.handleRef.bind(this);
    }

    componentDidMount() {
        this.handleEnter(this.elem);
    }

    handleEnter(elem) {
        const { getStartStyle } = this.props;
        this.setState({
            style: {
                ...getStartStyle(elem),
            }
        });
    }

    handleEntering(elem) {
        const { getEndStyle, duration } = this.props;
        this.setState({
            style: {
                ...getEndStyle(elem),
                transitionDuration: `${duration}ms`,
            }
        });
    }

    handleExiting(elem) {
        const { getStartStyle, duration } = this.props;
        this.setState({
            style: {
                ...getStartStyle(elem),
                transitionDuration: `${duration}ms`,
            }
        });
    }

    handleRef(ref) {
        this.elem = ref;
    }

    render() {
        const { children, duration, id, in: inProp, startMounted } = this.props;
        const { style } = this.state;

        const getClasses = (existing) => classnames(existing, id);
        const getStyles = (existing) => ({ ...existing, ...style });

        return (
            <CSSTransition
                classNames={id}
                in={inProp}
                onEnter={this.handleEnter}
                onEntering={this.handleEntering}
                onExiting={this.handleExiting}
                appear={!startMounted}
                mountOnEnter={!startMounted}
                unmountOnExit={!startMounted}
                timeout={duration}
            >
                {children && React.cloneElement(children, {
                    ...children.props,
                    ref: this.handleRef,
                    className: getClasses(children.props.className),
                    style: getStyles(children.props.style),
                })}
            </CSSTransition>
        );
    }
}

Transition.defaultProps = {
    children: null,
    duration: 300,
    getEndStyle: () => ({}),
    getStartStyle: () => ({}),
    id: 'transition',
    in: false,
    startMounted: false,
};

Transition.propTypes = {
    children: PropTypes.node,
    duration: PropTypes.number,
    getEndStyle: PropTypes.func,
    getStartStyle: PropTypes.func,
    id: PropTypes.string,
    in: PropTypes.bool,
    startMounted: PropTypes.bool,
};

export default Transition;
