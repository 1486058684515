const filterByProbability = (...groups) => {
    const rand = Math.random() * 100;
    let accumulator = 0;

    for (const group of groups) {
        accumulator += group.probability || 0;

        if (accumulator > rand) {
            return group;
        }
    }

    return;
};

export default filterByProbability;
