import { detect } from 'detect-browser';

export default (googletag) => {
    const detectedBrowser = detect();
    if (!detectedBrowser) {
        return;
    }

    let browserLabel = '';
    const isIos = detectedBrowser.os === 'iOS';
    const isAndroid = detectedBrowser.os === 'Android OS';
    if (isIos) {
        if (detectedBrowser.name === 'crios') {
            browserLabel = 'ios-chrome';
        } else if (detectedBrowser.name === 'ios') {
            browserLabel = 'ios-safari';
        } else {
            browserLabel = 'ios-other';
        }
    } else if (isAndroid) {
        browserLabel = detectedBrowser.name === 'chrome' ? 'android-chrome' : 'android-other';
    } else {
        const isOther = !['chrome', 'safari'].includes(detectedBrowser.name);
        browserLabel = isOther ? 'other' : detectedBrowser.name;
    }
    googletag.cmd.push(() => {
        googletag.pubads().setTargeting('browser', browserLabel);
    });
};
