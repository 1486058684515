import { trackV2 } from '@gumtree/shared/src/util/track-ga-event';
import { ConsentStatus, getConsentStatus, WindowWithCmp } from './util';

const trackConsentState = async (window: WindowWithCmp) => {
    const consentStatus = await getConsentStatus(window);
    const eventName = 'GdprState';
    let eventLabel: string;
    if (consentStatus.consentStatus === ConsentStatus.CONSENTED) {
        eventLabel = `${consentStatus.cmpProvider}-Accepted`;
    } else if (consentStatus.consentStatus === ConsentStatus.DECLINED) {
        eventLabel = `${consentStatus.cmpProvider}-Declined`;
    } else if (consentStatus.consentStatus === ConsentStatus.IMPLICITLY_CONSENTED) {
        eventLabel = `${consentStatus.cmpProvider}-ImplicitlyAccepted`;
    } else {
        eventLabel = `${consentStatus.cmpProvider}-NoInteractionYet`;
    }
    trackV2(eventName, eventLabel, { uaNonInt: true });
};

export default trackConsentState;
