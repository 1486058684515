const safeGetData = (path: string, root?: any): any => {
    if (!root) {
        return {};
    }
    const pathArray = path.split('.');
    const property = root[pathArray[0]];

    if (pathArray.length > 1 && property) {
        const newRoot = root[pathArray.shift()!];
        const newPath = pathArray.join('.');

        return safeGetData(newPath, newRoot);
    }

    return property;
};

const getClientData = (path: string) => safeGetData(path, (window as any).clientData);

const getLegacyGumData = (path: string) => safeGetData(path, (window as any).Gum);

export { getClientData, safeGetData, getLegacyGumData };
