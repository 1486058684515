import React from 'react';
import { createRoot } from 'react-dom/client';
import { Device, PageType } from '@gumtree/shared/src/types/client-data';

import Carousel from '../component/carousel';
import WithScroll from '../component/with-scroll';
import {
    reportCarouselScrollLeftClick,
    reportCarouselScrollRightClick,
} from '../../reporting/carousel-scroll-click';
import reportTileClick from '../../reporting/report-tile-click';
import { DataLayer } from '../../../loader';
import { AdModel } from '../../../../common/transform-data-into-ad-models';
import { emitPing } from '../../common/pixel-ping';

export default (
    adConfigs: AdModel[],
    adSlotElement: HTMLElement,
    hasScroll: boolean,
    deviceSize: Device,
    pageType: PageType,
    visibilityFeedbackBaseUrl: string
) => {
    const onAdClick = ({ index }) => {
        const dataLayer = window.dataLayer as unknown as DataLayer;
        const slotId = adSlotElement.id;
        reportTileClick({ slotId, deviceSize, index, pageType, dataLayer });
    };

    const onVisible = ({ impressionToken }: { impressionToken: string }) => {
        const url = `${visibilityFeedbackBaseUrl}&tids=${impressionToken}`;
        emitPing(url, window);
    };

    if (adConfigs.length === 0) {
        return;
    }

    const root = createRoot(adSlotElement);

    if (hasScroll) {
        root.render(
            <WithScroll
                onScrollLeftClick={() => reportCarouselScrollLeftClick(pageType, window)}
                onScrollRightClick={() => reportCarouselScrollRightClick(pageType, window)}
            >
                <Carousel adConfigs={adConfigs} onTileClick={onAdClick} onVisible={onVisible} />
            </WithScroll>
        );
    } else {
        root.render(
            <Carousel adConfigs={adConfigs} onTileClick={onAdClick} onVisible={onVisible} />
        );
    }
};
