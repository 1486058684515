import { PageType, Device, L1Category, L2Category, L3Category } from '@gumtree/shared/src/types/client-data';
import { DfpServerConfig, GoogleTag } from '@gumtree/shared/src/types/advertising/dfp';
import { runOnBreakpointChange, ScreenSize } from '@gumtree/shared/src/util/breakpoints-service';
import { transformCommandFactoryParams } from './common/load-ads';
import { CommandFactoryParams } from './types';
import preDfpRequestActions from './common/pre-dfp-request-actions';
import { getWhitelistedSlotIds } from '../dfp/refresh-whitelist';

export interface WindowWithPbjs extends Window {
    pbjs?: { que?: { push: object }; bidderSettings?: object };
    googletag?: { cmd?: { push: object } };
}

const refreshAds = (commandFactoryParams: CommandFactoryParams) => {
    const adsReloadDelay = 20;
    const maxRefreshes = 3;
    let refreshCount = 0;

    const { deviceType, categoryL1, pageType, screenSize } = commandFactoryParams;

    const whiteListedSlotIds = getWhitelistedSlotIds(deviceType, categoryL1, pageType, screenSize);

    if (whiteListedSlotIds && whiteListedSlotIds.length > 0) {
        const refreshId = setInterval(() => {
            preDfpRequestActions(commandFactoryParams, true).then(() => {
                window.document.dispatchEvent(new Event('GUM.ADS.DISPLAY.REFRESH'));
            });

            if (++refreshCount >= maxRefreshes) {
                clearInterval(refreshId);
            }
        }, adsReloadDelay * 1000);
    }
};

const initPrebid = (window: WindowWithPbjs, abTests: string[]) => {
    window.pbjs = window.pbjs || {};
    window.pbjs.que = window.pbjs.que || [];

    if (abTests.includes('GTALL-28061-B') || abTests.includes('GTALL-28061-C')) {
        window.pbjs.bidderSettings = {
            ...(abTests.includes('GTALL-28061-C') && {
                standard: {
                    adserverTargeting: [
                        {
                            key: 'hb_bidder',
                            val: (bid) => bid.bidderCode,
                        },
                        {
                            key: 'hb_size',
                            val: (bid) => bid.size,
                        },
                        {
                            key: 'hb_adid',
                            val: (bid) => bid.adId,
                        },
                        {
                            key: 'hb_deal',
                            val: (bid) => bid.dealId,
                        },
                        {
                            key: 'hb_bid_time',
                            val: (bid) => bid.timeToRespond,
                        },
                        {
                            key: 'hb_source',
                            val: (bid) => bid.source,
                        },
                        {
                            key: 'hb_format',
                            val: (bid) => bid.mediaType,
                        },
                    ],
                },
            }),
            appnexus: {
                adserverTargeting: [
                    {
                        key: 'hb_deal_appnexus',
                        val: (bidResponse) =>
                            bidResponse.appnexus ? bidResponse.appnexus.dealCode : undefined,
                    },
                ],
            },
        };
    }
};

const startPrebid = async (
    googletag: GoogleTag,
    pageType: PageType,
    deviceType: Device,
    screenSize: ScreenSize | undefined,
    categoryL1: L1Category | undefined,
    categoryL2: L2Category | undefined,
    categoryL3: L3Category | undefined,
    keywords: string,
    abTests: string[],
    serverConfig: DfpServerConfig | undefined,
    window: WindowWithPbjs
) => {
    initPrebid(window, abTests);

    const isPrebidValid =
        typeof serverConfig?.config?.prebid === 'object' &&
        typeof window.pbjs === 'object' &&
        typeof window.pbjs.que === 'object' &&
        typeof window.pbjs.que.push === 'function';

    const isDisplayValid =
        typeof serverConfig === 'object' &&
        typeof serverConfig.config === 'object' &&
        typeof window.googletag === 'object' &&
        typeof window.googletag.cmd === 'object' &&
        typeof window.googletag.cmd.push === 'function';

    if (!isPrebidValid && !isDisplayValid) {
        return;
    }
    if (!isPrebidValid && isDisplayValid) {
        // ensure event listeners are defined previously
        window.document.dispatchEvent(new Event('GUM.ADS.DISPLAY.LOAD'));
        return;
    }

    const prebidClientTimeout = 1500;

    const prebidTimeout = serverConfig?.config?.prebid?.timeout || prebidClientTimeout;
    const vipAdId = serverConfig?.config?.targeting?.g_adid;

    const commandFactoryParams = transformCommandFactoryParams(
        window.pbjs,
        prebidTimeout,
        googletag,
        pageType,
        deviceType,
        screenSize,
        categoryL1,
        categoryL2,
        categoryL3,
        keywords,
        abTests,
        vipAdId
    );

    runOnBreakpointChange(() => {
        preDfpRequestActions(commandFactoryParams).then(() => {
            window.document.dispatchEvent(new Event('GUM.ADS.DISPLAY.LOAD'));
        });
    });

    await preDfpRequestActions(commandFactoryParams);
    window.document.dispatchEvent(new Event('GUM.ADS.DISPLAY.LOAD'));

    refreshAds(commandFactoryParams);
};

export default startPrebid;
