import { css } from "@emotion/react";
import { boxSizes, colors, gutterSizes } from "@gumtree/ui-library/src/base/theme";

export const sideMenuCss = (theme) => css`
    .side-menu {
        color: ${colors.bark80};
        width: 270px;

        .app-download-button {
            background: ${theme.palette.primary.main};
            margin: ${gutterSizes.medium} ${gutterSizes.xlarge};
            border-radius: ${boxSizes.borderRadius};
            color: ${theme.palette.primary.mainContrastText};
            display: flex;
            justify-content: center;

            &:active,
            &:hover {
                color: ${theme.palette.primary.dark};
                background-color: ${theme.palette.primary.darkContrastText};
            }
        }
    }
`;
