import React from 'react';

import { Link } from '@gumtree/ui-library';
import slug from '@gumtree/ui-library/src/utils/slug';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import { trackGA4AndNavigate } from '@gumtree/shared/src/util/ga4-shared';
import { getLinkHostname } from '@gumtree/shared/src/util/ga4-bff';

const TopSearchesSection = ({ title, items }: Props) => (
    <section className="section" {...qaAttribute(`section-${slug(title)}`)}>
        <h4 {...qaAttribute('top-searches-content-title')}>{title}</h4>
        <ul>
            {items.map(({ link, label }, index) => (
                <li key={index}>
                    <Link
                        href={link}
                        text={label}
                        dataQ="top-searches-link"
                        onClick={(e) =>
                            trackGA4AndNavigate<GA4.ClickFooterTop>({
                                href: link,
                                mouseEvent: e,
                                payload: {
                                    event: 'click_footer_top_searches',
                                    linkDomain: getLinkHostname(link),
                                    linkHeader: title,
                                    linkText: label,
                                    linkType: 'list link',
                                    linkURL: link,
                                }
                            })
                        }
                    />
                </li>
            ))}
        </ul>
    </section>
);

interface Props {
    items: { link: string; label: string }[];
    title: string;
}

export default TopSearchesSection;
