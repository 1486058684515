 
import React, { useEffect, useState as useStateHook } from 'react';
import { useSelector as useSelectorHook } from 'react-redux';
import styled from '@emotion/styled';
import { mediaQuery, breakpoints } from '@gumtree/ui-library/src/base/theme';
import { Button } from '@gumtree/ui-library';
import { Props as ButtonProps } from '@gumtree/ui-library/src/button/button';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import { ShellState } from '../../../reducers/common';

import NavLoginToolTip from './nav-bar-login-tool-tip';
import { NavBarButtonOverride } from '../common.style';

export const Wrapper = styled.div`
    position: relative;
`;

const StyledHeaderLoginButton = styled(Button, {
    shouldForwardProp: (p: string) => !['isLogInToolTipShown', 'isInGTC2229'].includes(p),
})<{ isLogInToolTipShown: boolean; isInGTC2229: boolean }>`
    && {
        ${NavBarButtonOverride}
        display: none;

        .icon--person {
            width: 100%;
        }
        ${mediaQuery.from(breakpoints.medium)} {
            display: inline-flex;
        }

        ${({isInGTC2229}) => isInGTC2229 &&`
            ${mediaQuery.from(breakpoints.xsmall)} {
                display: inline-flex;
            }
        `}

        ${({ isLogInToolTipShown }) =>
            isLogInToolTipShown &&
            `&:after {
                content: '';
                display: block;
                border-color: transparent transparent #fff;
                border-width: 0 16px 20px;
                border-style: solid;
                height: 0;
                width: 0;
                position: absolute;
                top: 35px;
                margin-left: -5px;
                left: 42%;
                transform: translateX(-3px);
                z-index: 1;
            }`}
    }
`;

interface Props extends ButtonProps {
    useState?: typeof useStateHook;
    useSelector?: typeof useSelectorHook;
    showTooltip?: boolean;
    isInGTC2229: boolean;
    qaAttr?: string;
}

const LOGIN_TOOLTIP_DISPLAY_TIMEOUT = 15000;

const NavLoginButton = ({
    dark,
    icon,
    label,
    href,
    onClick,
    useState = useStateHook,
    isInGTC2229,
    qaAttr,
    showTooltip,
    useSelector = useSelectorHook,
}: Props) => {
    const [isLogInToolTipShown, setIsLogInToolTipShown] = useState(false);
    const { pageType } = useSelector(({ baseConfig }: ShellState) => baseConfig);

    const closeToolTipHandler = () => {
        setIsLogInToolTipShown(false);
    };

    useEffect(() => {
        if (showTooltip && pageType === 'Homepage') {
            const timeoutId = setTimeout(() => {
                setIsLogInToolTipShown(true);
            }, LOGIN_TOOLTIP_DISPLAY_TIMEOUT);

            return () => {
                clearTimeout(timeoutId);
            };
        }

        return;
    }, []);

    return (
        <Wrapper>
            <StyledHeaderLoginButton
                {...qaAttribute(qaAttr ? qaAttr : 'hm-login')}
                dark={dark}
                icon={icon}
                label={label}
                href={href}
                onClick={onClick}
                isInGTC2229={isInGTC2229}
                isLogInToolTipShown={isLogInToolTipShown}
                bottomLabel
            />
            {isLogInToolTipShown ? <NavLoginToolTip onClose={closeToolTipHandler} /> : <></>}
        </Wrapper>
    );
};

export default NavLoginButton;
