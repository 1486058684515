import React from 'react';
import PropTypes from 'prop-types';

import Transition from './transition';
import { getOutsidePosition } from './slide-service';
import './slide.scss';

const getInitial = (elem, direction) => {
    switch (direction) {
        case 'up':
            return `0,${getOutsidePosition(elem, 'bottom', window)}px,0`;
        case 'down':
            return `0,-${getOutsidePosition(elem, 'top', window)}px,0`;
        case 'left':
            return `${getOutsidePosition(elem, 'right', window)}px,0,0`;
        case 'right':
            return `-${getOutsidePosition(elem, 'left', window)}px,0,0`;
    }
};

const Slide = ({ children, direction, in: inProp }) => (
    <Transition
        getEndStyle={() => ({ transform: 'translate3d(0,0,0)' })}
        getStartStyle={(elem) => ({ transform: `translate3d(${getInitial(elem, direction)})` })}
        id="slide"
        in={inProp}
    >
        {children}
    </Transition>
);

Slide.defaultProps = {
    children: null,
    direction: 'up',
    in: false,
};

Slide.propTypes = {
    children: PropTypes.node,
    direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
    in: PropTypes.bool,
};

export default Slide;
