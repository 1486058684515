import { connect } from 'react-redux';
import get from 'lodash/get';
import Notifications from './notifications';

const mapStateToProps = (state) => ({
    message: get(state, 'notifications.message'),
});

export default connect(mapStateToProps)(Notifications);

export { mapStateToProps };
