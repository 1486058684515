import { PageType } from '@gumtree/shared/src/types/client-data';

export default (params: { pageType: PageType; isOnBff: boolean; isGridView: boolean }): string => {
    const { pageType, isOnBff, isGridView } = params;
    const isResultsPage = pageType === 'ResultsSearch' || pageType === 'ResultsBrowse';
    return [
        isOnBff ? 'y' : 'n',
        ...(isOnBff && isResultsPage ? [isGridView ? 'grid' : 'list'] : []),
        pageType,
    ].join('_');
};
