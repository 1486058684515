import React, { forwardRef, useRef, ForwardedRef } from 'react';
import styled from '@emotion/styled';

import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import { colors, fontWeights, mediaQuery, breakpoints } from '@gumtree/ui-library/src/base/theme';

export const LINK_PADDING = '14px';

export const Li = styled.li`
    display: block;
    position: relative;
    height: 100%;

    color: ${colors.bark};
    white-space: nowrap;

    &:hover {
        cursor: pointer;
    }

    &:hover::before,
    &:hover::after {
        content: '';
        position: absolute;
        z-index: 1000;
        bottom: 0;
        height: 35%;
        width: 100%;
    }

    &:hover {
        &::before {
            left: -98%;
            clip-path: polygon(100% 0, 0% 100%, 100% 100%);
        }

        &::after {
            right: -98%;
            clip-path: polygon(0 0, 0% 100%, 100% 100%);
        }
    }
    ${mediaQuery.from(breakpoints.medium)} {
        width: 100%;
        text-align: center;

        & + & {
            border-left: 1px solid ${colors.bark20};
        }
    }
`;

const Wrapper = styled.div<{ isActive: boolean }>`
    height: 100%;
    color: ${colors.bark};
    ${(p) => p.isActive && `background-color: ${colors.white};`}
`;

const Link = styled.a`
    display: inline-block;
    font-weight: ${fontWeights.bold};
    height: 100%;
    width: 100%;
    padding-top: 11px;
    padding-left: ${LINK_PADDING};
    padding-right: ${LINK_PADDING};
    box-sizing: border-box;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
`;

export type onMouseEnterFunction = (e: React.MouseEvent, { seoName, labelRef, index }) => void;
export type onClickFunction = (e: React.MouseEvent, { seoName, labelRef, path, name }) => void;

interface Props {
    name: string;
    seoName: string;
    path: string;
    qaAttr: string;
    index: number;
    isActive: boolean;
    onMouseEnter: onMouseEnterFunction;
    onClick: onClickFunction;
}

// eslint-disable-next-line react/display-name
const NavItem = forwardRef(
    (
        { name, seoName, path, qaAttr, isActive, index, onMouseEnter, onClick, ...params }: Props,
        ref: ForwardedRef<HTMLLIElement>
    ) => {
        const labelRef = useRef(null!);

        const handleClick = (e: React.MouseEvent) => {
            onClick && onClick(e, { seoName, labelRef, path, name });
        };

        const mouseEnterHandler = (e) => {
            onMouseEnter && onMouseEnter(e, { seoName, labelRef, index });
        };

        return (
            <Li {...params} ref={ref}>
                <Wrapper onMouseEnter={mouseEnterHandler} isActive={isActive}>
                    <Link
                        href={path}
                        onClick={handleClick}
                        {...qaAttribute(qaAttr)}
                        draggable="false"
                    >
                        <span ref={labelRef}>{name}</span>
                    </Link>
                </Wrapper>
            </Li>
        );
    }
);

export default NavItem;
