// TODO clean
 
 
import React from 'react';
import classnames from 'classnames';
import Icon, { IconColor } from '../icon/icon';

const Toggler: React.FC<Props> = ({
    children,
    className,
    iconType = { title: 'dropdown', color: undefined },
    onClick,
    iconText,
    testId,
}) => (
    <button
        className={classnames(className, 'toggler')}
        onClick={onClick}
        onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
                onClick && onClick();
            }
        }}
        data-testid={testId}
        tabIndex={0}
        type="button"
    >
        {children}
        <>
            {iconText && <span className="icon-text" />}
            {children && <Icon type={iconType.title} color={iconType.color} size="smallest" />}
        </>
    </button>
);

interface Props extends React.PropsWithChildren {
    className?: string;
    iconText?: boolean;
    iconType?: {
        title: 'dropdown' | 'chevron-d';
        color?: IconColor;
    };
    onClick?: () => void;
    testId?: string;
}

export default Toggler;
