import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import TopLocationItem from './top-location-item';

const TopLocations = ({ items, showTitle }) => (
    <Fragment>
        {items.map((location, index) => (
            <TopLocationItem key={index} {...location} showTitle={showTitle} />
        ))}
    </Fragment>
);

TopLocations.propTypes = {
    items: PropTypes.array.isRequired,
    showTitle: PropTypes.bool.isRequired,
};

export default TopLocations;
