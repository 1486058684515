/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */

import { css } from '@emotion/react';
import {
    fontSizes,
    gutterSizes,
    colors,
    zIndexes,
    fontWeights,
    lineHeights,
    boxSizes,
} from '@gumtree/ui-library/src/base/theme';
import { Dialog, Icon } from '@gumtree/ui-library';
import React from 'react';

export const StyledTopSection = ({ children, ...props }: React.PropsWithChildren) => {
    const styles = css`
        background-color: ${colors.blade};
        padding: ${gutterSizes.base} ${gutterSizes.large} ${gutterSizes.medium};
    `;

    return (
        <div css={styles} {...props}>
            {children}
        </div>
    );
};

export const StyledTitle = ({ children, ...props }: React.PropsWithChildren) => {
    const styles = css`
        display: flex;
        justify-content: center;
        margin-top: ${gutterSizes.base};
        margin-bottom: ${gutterSizes.large};

        font-size: ${fontSizes.base};
        color: ${colors.white};
        line-height: 24px;
    `;

    return (
        <h2 css={styles} {...props}>
            {children}
        </h2>
    );
};

export const StyledLocationContainer = ({ children, ...props }: React.PropsWithChildren) => {
    const styles = css`
        margin-top: ${gutterSizes.base};
    `;

    return (
        <div css={styles} {...props}>
            {children}
        </div>
    );
};

export const StyledIcon = ({ type }) => {
    const divStyles = css`
        position: relative;
        width: 48px;
    `;
    const iconStyles = css`
        color: ${colors.bark80};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    `;

    return (
        <div css={divStyles}>
            <Icon css={iconStyles} type={type} size="medium" />
        </div>
    );
};

export const StyledField = ({ children, ...props }: React.PropsWithChildren) => {
    const styles = css`
        display: flex;
        align-items: center;
        background: ${colors.white};
        border-radius: ${boxSizes.borderRadius8};
    `;

    return (
        <div css={styles} {...props}>
            {children}
        </div>
    );
};

export const StyledDialog = ({
    isOpen,
    onClose,
    children,
    ...props
}: React.PropsWithChildren & { isOpen: boolean; onClose: () => void }) => {
    const styles = css`
        .dialog {
            height: 100vh;
            width: 100vw;
            margin: 0;
            padding: 0;
            border-radius: 0;
            border: none;
            max-width: 100vw;

            .dialog-close {
                width: initial;
                height: initial;
                top: 21px;
                right: ${gutterSizes.large};

                .icon {
                    color: ${colors.white};
                    font-size: ${fontSizes.small};
                }
            }

            .type-ahead-options {
                position: relative;
                border: 0;
                box-shadow: none;
                margin: 0;
                top: 0;
            }
        }
    `;

    return (
        <Dialog isOpen={isOpen} onClose={onClose} css={styles} {...props}>
            {children}
        </Dialog>
    );
};

const ErrorContent = ({ children, ...props }: React.PropsWithChildren) => {
    const styles = css`
        @keyframes moveUp {
            from {
                bottom: -100px;
            }
            to {
                bottom: 0;
            }
        }

        animation-name: moveUp;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        position: fixed;
        width: calc(100vw - ${gutterSizes.large} - ${gutterSizes.large});
        bottom: 0;
        margin: ${gutterSizes.large};
        border-radius: ${boxSizes.borderRadius8};
        padding: ${gutterSizes.medium};
        box-sizing: border-box;
        background: ${colors.messageTextColor};
        border: none;
        color: ${colors.white};
        opacity: 100%;
        display: flex;
        font-size: ${fontSizes.small};
        font-weight: ${fontWeights.light};
        line-height: ${lineHeights.small};
        align-items: start;
        justify-content: space-between;
    `;

    return (
        <div css={styles} {...props}>
            {children}
        </div>
    );
};

const ErrorOverlay = ({ onCloseClick }: { onCloseClick: (e: React.MouseEvent) => void }) => {
    const styles = css`
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background: ${colors.messageTextColor};
        border: none;
        opacity: 40%;
    `;

    return (
        <button
            onClick={onCloseClick}
            css={styles}
            type="button"
            tabIndex={0}
        />
    );
};

const ErrorMessage = ({ children, ...props }: React.PropsWithChildren) => {
    return <div {...props}>{children}</div>;
};

const ErrorCloseButton = ({ onCloseClick }: { onCloseClick: (e: React.MouseEvent) => void }) => {
    const styles = css`
        border: none;
        background: none;
        text-decoration: underline;
        color: ${colors.white};
        font-size: ${fontSizes.small};
        font-weight: ${fontWeights.bold};
        line-height: ${lineHeights.small};
    `;
    return (
        <button css={styles} type="button" onClick={onCloseClick}>
            Dismiss
        </button>
    );
};

export const ErrorWindow = ({
    message,
    onCloseClick,
    ...props
}: React.PropsWithChildren & {
    message: string;
    onCloseClick: (e: React.MouseEvent) => void;
}) => {
    const styles = css`
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: ${zIndexes.high + 5};
    `;

    return (
        <div css={styles} {...props}>
            <ErrorOverlay onCloseClick={onCloseClick} />
            <ErrorContent>
                <ErrorMessage>{message}</ErrorMessage>
                <ErrorCloseButton onCloseClick={onCloseClick} />
            </ErrorContent>
        </div>
    );
};
