import { LoginDisplayAppName, LoginErrorType } from '../types';

export const DEFAULT_ERROR_MESSAGE =
    'Something went wrong, please try again or login using your email address.';

export const getErrorMsg = ({
    errorType,
    appDisplayName,
}: {
    errorType?: LoginErrorType;
    appDisplayName: LoginDisplayAppName;
}): string => {
    switch (errorType) {
        case LoginErrorType.ShareEmailError:
            return `To login with ${appDisplayName} you need to share your email address with Gumtree.`;

        case LoginErrorType.NoEmailRegistered:
            return `We couldn't log you in because you don't have an email address registered with ${appDisplayName} . Please use a different account or login using your email address.`;

        default:
            return DEFAULT_ERROR_MESSAGE;
    }
};
