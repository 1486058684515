 
 
import React, { MouseEventHandler } from 'react';

import { css } from '@emotion/react';

import { fontSizes, colorVariables } from '@gumtree/ui-library/src/base/theme';

export default function SubNavPanelSectionItem({ href, label, onClick, sectionTitle, ...props }: Props) {
    const onClickHandler: MouseEventHandler = (e) => {
        onClick?.(e, { href, itemLabel: label }, sectionTitle);
    };

    return (
        <li {...props} css={liStyle}>
            <a onClick={onClickHandler} href={href} css={linkStyle}>
                {label}
            </a>
        </li>
    );
}

export type onClickFunction = (
    e: React.MouseEvent<Element, MouseEvent>,
    { href, itemLabel }: { href: string; itemLabel: string },
    sectionTitle: NavRibbonSectionTitle,
) => void;

interface Props {
    href: string;
    label: string;
    onClick?: onClickFunction;
    sectionTitle: NavRibbonSectionTitle;
}

export type NavRibbonSectionTitle = 'Browse by' | 'Discover more in our guides';

const liStyle = css`
    list-style-type: none;
    margin-bottom: 10px;
`;

const linkStyle = css`
    display: block;
    font-size: ${fontSizes.base};
    line-height: 22px;
    color: ${colorVariables.textNeutral};

    &:hover,
    &:focus {
        text-decoration: underline;
    }
`;
