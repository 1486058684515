import { getCookies } from '@gumtree/shared/src/util/cookie-service';
import {
    CustomPurposeConsentNames,
    getHasUserMadeChoice,
    isCustomPurposeConsented,
} from '../gdpr/util';


export default async function init(window, userEmail) {
    if (!userEmail) {
        return;
    }
    await getHasUserMadeChoice(window);

    const cookie = getCookies(decodeURIComponent(window.document.cookie));
    const isGdprConsented = isCustomPurposeConsented(
        cookie.OptanonConsent,
        CustomPurposeConsentNames.Liveramp
    );

    if (!isGdprConsented) {
        return;
    }

    const script = window.document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = "https://ats-wrapper.privacymanager.io/ats-modules/d858fdeb-e532-4cbd-a144-b3fbc9eb1a6e/ats.js";
    window.document.body.appendChild(script);
    script.onload = () => {
        window.addEventListener("envelopeModuleReady", () => {
            window.atsenvelopemodule.setAdditionalData({
                'type': 'email',
                'id': userEmail
            });
        });
    };
}

