export const getHostFromUrl = (url) => {
    return url.replace(/http(s)?:\/\/(www\.)?/, '');
};

export const getBaseUrl = (url) => {
    const urlObject = new URL(url);
    return `${urlObject.protocol}//${urlObject.hostname}${
        urlObject.port ? `:${urlObject.port}` : ''
    }`;
};

export const getQueryParamsFromUrl = (url) => {
    const urlObject = new URL(url);
    return urlObject.search.substring(1);
};

export const getDomainFromUrl = (url) => {
    try {
        return new URL(url).hostname;
    } catch (_error) {
        return '';
    }
};
