import { Device, PageType, L1Category } from '@gumtree/shared/src/types/client-data';
import { getCurrentBreakpoint, ScreenSize } from '@gumtree/shared/src/util/breakpoints-service';
import { SlotName } from '../../dfp/ad-config/build-slot-config';
import { getWhitelistedSlotIds } from '../../dfp/refresh-whitelist';

type SlotConfig = {
    slotID: SlotName;
    slotName: `/5144/${Device}/${'home' | 'srp' | 'vip'}/*`;
    sizes: number[][];
};

const buildSlotConfig = (
    device: Device,
    pageType: PageType,
    categoryL1: L1Category,
    isAdRefresh?: boolean
): SlotConfig[] => {
    const resultsPages = ['ResultsSearch', 'ResultsBrowse'];
    const vipPages = ['VIP', 'eVIP', 'pVIP'];
    const currentTShirtSize = getCurrentBreakpoint(window)?.tshirt;
    const refreshableSlotId =
        currentTShirtSize && getWhitelistedSlotIds(device, categoryL1, pageType, currentTShirtSize);
    let slotConfig: SlotConfig[] = [];

    const getPageType = (pageType): 'home' | 'srp' | 'vip' | undefined => {
        if (pageType === 'Homepage') {
            return 'home';
        } else if (resultsPages.includes(pageType)) {
            return 'srp';
        } else if (vipPages.includes(pageType)) {
            return 'vip';
        }
        return undefined;
    };

    const getAdSizesByTShirtSize = (
        slotId: SlotName,
        currentTShirtSize?: ScreenSize
    ): number[][] => {
        if (!currentTShirtSize) return [];

        if (slotId === 'homeSideAd') {
            if (currentTShirtSize === 'm') {
                return [[160, 600]];
            } else if (['l', 'xl', 'xxl'].includes(currentTShirtSize)) {
                return [
                    [300, 250],
                    [300, 600],
                ];
            }
        }

        if (['rSkyT', 'rSkyB'].includes(slotId)) {
            if (currentTShirtSize === 'xl') {
                return [[160, 600]];
            } else if (currentTShirtSize === 'xxl') {
                return [
                    [300, 250],
                    [300, 600],
                ];
            }
        }

        if (['rSkyT2', 'rSkyB2'].includes(slotId)) {
            if (currentTShirtSize === 'xxl') {
                return [
                    [300, 250],
                    [300, 600],
                ];
            }
        }

        if (slotId === 'fBanner') {
            if (currentTShirtSize === 'm') {
                return [[728, 90]];
            } else if (['l', 'xl', 'xxl'].includes(currentTShirtSize)) {
                return [[970, 250]];
            }
        }

        return [];
    };

    const convertedPageType = getPageType(pageType);

    if (!convertedPageType) {
        return [];
    }

    const homeSideAd: SlotConfig = {
        slotID: 'homeSideAd',
        slotName: `/5144/${device}/${convertedPageType}/*`,
        sizes: getAdSizesByTShirtSize('homeSideAd', currentTShirtSize),
    };

    const homeBanner: SlotConfig = {
        slotID: 'homeBanner',
        slotName: `/5144/${device}/${convertedPageType}/*`,
        sizes: [[320, 50]],
    };

    const rSkyT: SlotConfig = {
        slotID: 'rSkyT',
        slotName: `/5144/${device}/${convertedPageType}/*`,
        sizes: getAdSizesByTShirtSize('rSkyT', currentTShirtSize),
    };

    const rSkyB: SlotConfig = {
        slotID: 'rSkyB',
        slotName: `/5144/${device}/${convertedPageType}/*`,
        sizes: getAdSizesByTShirtSize('rSkyB', currentTShirtSize),
    };

    const rSkyT2: SlotConfig = {
        slotID: 'rSkyT2',
        slotName: `/5144/${device}/${convertedPageType}/*`,
        sizes: getAdSizesByTShirtSize('rSkyT2', currentTShirtSize),
    };

    const rSkyB2: SlotConfig = {
        slotID: 'rSkyB2',
        slotName: `/5144/${device}/${convertedPageType}/*`,
        sizes: getAdSizesByTShirtSize('rSkyB2', currentTShirtSize),
    };

    const integratedMpu: SlotConfig = {
        slotID: 'integratedMpu',
        slotName: `/5144/${device}/${convertedPageType}/*`,
        sizes: ['desktop', 'tablet'].includes(device)
            ? [
                  [300, 250],
                  [728, 90],
              ]
            : [[300, 250]],
    };

    const integratedListing: SlotConfig = {
        slotID: 'integratedListing',
        slotName: `/5144/${device}/${convertedPageType}/*`,
        sizes: [
            [300, 250],
            [728, 90],
        ],
    };

    const tBanner: SlotConfig = {
        slotID: 'tBanner',
        slotName: `/5144/${device}/${convertedPageType}/*`,
        sizes: [[728, 90]],
    };

    const baseMpu: SlotConfig = {
        slotID: 'baseMpu',
        slotName: `/5144/${device}/${convertedPageType}/*`,
        sizes: [
            [300, 600],
            [300, 250],
        ],
    };

    const textLinkBase: SlotConfig = {
        slotID: 'textLinkBase',
        slotName: `/5144/${device}/${convertedPageType}/*`,
        sizes: [[300, 250]],
    };

    const floatingFooter: SlotConfig = {
        slotID: 'floatingFooter',
        slotName: `/5144/${device}/${convertedPageType}/*`,
        sizes: [[320, 50]],
    };

    const mpu: SlotConfig = {
        slotID: 'mpu',
        slotName: `/5144/${device}/${convertedPageType}/*`,
        sizes: [
            [300, 600],
            [300, 250],
        ],
    };

    const vipBanner: SlotConfig = {
        slotID: 'vipBanner',
        slotName: `/5144/${device}/${convertedPageType}/*`,
        sizes: [[728, 90]],
    };

    const fBanner: SlotConfig = {
        slotID: 'fBanner',
        slotName: `/5144/${device}/${convertedPageType}/*`,
        sizes: getAdSizesByTShirtSize('fBanner', currentTShirtSize),
    };

    const vipMiddleDesktop: SlotConfig = {
        slotID: 'vipMiddleDesktop',
        slotName: `/5144/${device}/${convertedPageType}/*`,
        sizes: [[300, 250]],
    };

    const vipMiddleMobile: SlotConfig = {
        slotID: 'vipMiddleMobile',
        slotName: `/5144/${device}/${convertedPageType}/*`,
        sizes: [[300, 250]],
    };

    if (pageType === 'Homepage') {
        if (device === 'desktop') {
            slotConfig = [homeSideAd];
        }

        if (device === 'tablet') {
            slotConfig = [homeSideAd];
        }

        if (device === 'mobile') {
            slotConfig = [homeBanner];
        }
    }

    if (resultsPages.includes(pageType)) {
        if (device === 'desktop') {
            slotConfig = [
                ...(currentTShirtSize === 'xxl'
                    ? [rSkyT, rSkyB, rSkyT2, rSkyB2]
                    : currentTShirtSize === 'xl'
                    ? [rSkyT, rSkyB]
                    : []),
                tBanner,
                integratedMpu,
                integratedListing,
            ];
        }

        if (device === 'tablet') {
            slotConfig = [tBanner, integratedMpu, integratedListing, baseMpu];
        }

        if (device === 'mobile') {
            slotConfig = [integratedMpu, textLinkBase, floatingFooter];
        }
    }

    if (vipPages.includes(pageType)) {
        if (device === 'desktop') {
            slotConfig = [mpu, vipBanner, vipMiddleDesktop];
        }

        if (device === 'tablet') {
            slotConfig = [mpu, vipBanner, fBanner];
        }

        if (device === 'mobile') {
            slotConfig = [vipMiddleMobile, floatingFooter];
        }
    }

    if (isAdRefresh) {
        slotConfig = slotConfig.filter((slot) => refreshableSlotId?.includes(slot.slotID));
    }

    return slotConfig;
};

export default buildSlotConfig;
