import { getCookies } from '@gumtree/shared/src/util/cookie-service';
import {
    CustomPurposeConsentNames,
    getHasUserMadeChoice,
    isCustomPurposeConsented,
} from '../gdpr/util';

export default async function initMicrosoftClarity(window: Window) {
    await getHasUserMadeChoice(window);

    const cookie = getCookies(decodeURIComponent(window.document.cookie));
    const isGdprConsented = isCustomPurposeConsented(
        cookie.OptanonConsent,
        CustomPurposeConsentNames.NonIABVendors
    );

    if (isGdprConsented) {
        const createdScriptEl = document.createElement('script');
        createdScriptEl.innerHTML = `
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "cs1da35hhu");
        `.trimRight();
        document.body.appendChild(createdScriptEl);
    }

}

