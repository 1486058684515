import { getHasUserMadeChoice } from './util';
import { oneTrustLocalStorageKeyName, oneTrustLocalStorageValue } from './constants';

const buttonClickEventName = 'ONETRUST.BUTTON.CLICKED';

const listenForImplicitConsentEvents = async (window) => {
    if (!window.__tcfapi) {
        return;
    }

    if (await getHasUserMadeChoice(window)) {
        return;
    }

    window.document.body.addEventListener('click', async (event) => {
        if (await getHasUserMadeChoice(window)) {
            return;
        }

        const oneTrustUI = window.document.querySelector('#onetrust-consent-sdk');
        const isClickInsideOneTrustUI = oneTrustUI && oneTrustUI.contains(event?.target);
        const isPreferencesCentreTrigger =
            event?.target?.classList &&
            Array.from(event.target.classList).includes('ot-sdk-show-settings');
        const tagName = event?.target?.tagName;
        const isInteractiveTag =
            tagName === 'BUTTON' ||
            tagName === 'A' ||
            event?.target?.closest('a') ||
            event?.target?.closest('button');

        if (!isClickInsideOneTrustUI && isInteractiveTag && !isPreferencesCentreTrigger) {
            window.OneTrust.AllowAll();
            window.ga &&
                window.ga('send', {
                    hitType: 'event',
                    eventCategory: 'OneTrust Cookie Consent',
                    eventAction: 'Click Implicit Consent',
                    eventLabel: tagName === 'BUTTON' ? 'Button Click' : 'Link Click',
                });

            window.localStorage &&
                window.localStorage.setItem(oneTrustLocalStorageKeyName, oneTrustLocalStorageValue);
        }
    });
};

const initialiseExplicitConsentEventEmitters = async (window) => {
    await getHasUserMadeChoice(window); // wait for banner load
    const oneTrustButtons = document.querySelectorAll('#onetrust-consent-sdk button');
    oneTrustButtons?.forEach((button) => {
        button.addEventListener('click', () => {
            window.document.dispatchEvent(new Event(buttonClickEventName));
        });
    });
};

const listenForExplicitConsentEvents = (window) => {
    window.document.addEventListener(buttonClickEventName, () => {
        window.localStorage && window.localStorage.removeItem(oneTrustLocalStorageKeyName);
    });
};

export default (window) => {
    listenForExplicitConsentEvents(window);
    listenForImplicitConsentEvents(window);
    initialiseExplicitConsentEventEmitters(window);
};
