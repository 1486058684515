import { connect } from 'react-redux';

import AppBannerTakeover from './app-banner-takeover';
import { closeBanner } from './app-banner-takeover-reducer';

export const mapState = ({
    baseConfig: { device, sellerUrl, domain },
    bannerTakeover: { isOpenBanner, target },
}) => ({
    device,
    isOpenBanner,
    sellerUrl,
    domain,
    target,
});

export const mapDispatch = {
    closeBanner,
};

export default connect(
    mapState,
    mapDispatch
)(AppBannerTakeover);
