import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './link.scss';

/** @param {PropTypes.InferProps<Link['propTypes']>} _ */
const Link = ({
    href,
    onClick,
    openInNewTab,
    text,
    className,
    noFollow,
    children,
    dataQ,
    title,
}) => (
    <a
        className={classnames('link', {
            [className]: className,
        })}
        href={href}
        onClick={onClick}
        rel={`noopener noreferrer${noFollow ? ' nofollow' : ''}`}
        role={href ? null : 'button'}
        target={openInNewTab ? `_blank` : undefined}
        data-q={dataQ}
        title={title}
    >
        {text || children}
    </a>
);
Link.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    dataQ: PropTypes.string,
    href: PropTypes.string,
    noFollow: PropTypes.bool,
    /** @type {PropTypes.Validator<React.MouseEventHandler<HTMLAnchorElement>>} */
    onClick: PropTypes.func,
    openInNewTab: PropTypes.bool,
    text: PropTypes.string,
    title: PropTypes.string,
};

Link.defaultProps = {
    children: null,
    className: null,
    dataQ: null,
    href: null,
    onClick: () => {},
    openInNewTab: false,
    noFollow: false,
    text: null,
    title: '',
};

export default Link;
