import { decodeFromBase64, encodeToBase64 } from './base64';
import { setCookie } from './browser-service';
import { getHostFromUrl } from './url-service';

const addCookie = ({ name, value, maxAge, domain, path, shouldNeverExpire }) => {
    let cookie = `${name}=${value}`;

    if (path) {
        cookie += `;path=${path}`;
    }

    if (shouldNeverExpire) {
        cookie += ';expires=Fri, 31 Dec 9999 23:59:59 GMT';
    } else if (maxAge) {
        cookie += `;Max-Age=${maxAge}`;
    }

    if (domain) {
        cookie += `;domain=.${getHostFromUrl(domain)}`;
    }

    setCookie(cookie);
};

const deleteCookie = (name) => {
    setCookie(`${name}=;Max-Age=0`);
};

const getCookies = (cookieString) =>
    cookieString
        .split(';')
        .map((keyValuePair) => {
            const [first, ...rest] = keyValuePair.split('=');

            return [first, rest.join('=')];
        })
        .map(([key, value]) => ({ [key.trim()]: value }))
        .reduce((acc, cookie) => Object.assign(acc, cookie), {});

const encodeCookieValue = (cookieValue) =>
    Object.entries(cookieValue)
        .reduce(
            (accumulator, [key, val]) => `${accumulator}${key}:${encodeToBase64(val.toString())}|`,
            ''
        )
        .slice(0, -1);

const decodeCookieValue = (cookieValue = '') =>
    cookieValue
        .split('|')
        .filter((val) => val.includes(':'))
        .map((val) => val.split(':'))
        .reduce(
            (accumulator, [key, val]) => ({ ...accumulator, [key]: decodeFromBase64(val) }),
            {}
        );

export { addCookie, deleteCookie, getCookies, setCookie, encodeCookieValue, decodeCookieValue };
