import {
    hasCreativeAnnotation,
    getCreativeAnnotation,
    getCreativeAnnotationOverrides,
} from './extension/creative-annotation';

import { hasSiteLinkExtension, getSiteLinkExtension, getSiteLinks } from './extension/site-links';

import {
    hasMerchantRatingExtension,
    getMerchantRatingExtension,
    getMerchantRatingInfo,
} from './extension/merchant-rating';

import { hasCalloutExtension, getCalloutExtension, getCallout } from './extension/callout';

import {
    hasClickToCallExtension,
    getClickToCallExtensions,
    getClickToCallNumbers,
} from './extension/click-to-call';
import { AdParamsFromBingApi } from '../../transform-data-into-ad-models';

const createTransformer = (adParams: AdParamsFromBingApi) => {
    const extensions = adParams.extensions || [];
    const adConfig = {
         
        _type: adParams._type,
        url: adParams.url,
        title: adParams.title,
        description: adParams.description,
        displayUrl: adParams.displayUrl,
        position: adParams.position,
        impressionToken: adParams.impressionToken,
    };

    if (hasCreativeAnnotation(extensions)) {
        const extension = getCreativeAnnotation(extensions);
        const overrides = getCreativeAnnotationOverrides(extension);
        Object.assign(adConfig, overrides);
    }
    if (hasSiteLinkExtension(extensions)) {
        const extension = getSiteLinkExtension(extensions);
        const siteLinks = getSiteLinks(extension);
        Object.assign(adConfig, { siteLinks });
    }
    if (hasMerchantRatingExtension(extensions)) {
        const extension = getMerchantRatingExtension(extensions);
        const merchantRating = getMerchantRatingInfo(extension);
        Object.assign(adConfig, { merchantRating });
    }
    if (hasCalloutExtension(extensions)) {
        const extension = getCalloutExtension(extensions);
        const callout = getCallout(extension);
        Object.assign(adConfig, { callout });
    }
    if (hasClickToCallExtension(extensions)) {
        const extension = getClickToCallExtensions(extensions);
        const clickToCallNumbers = getClickToCallNumbers(extension);
        Object.assign(adConfig, { clickToCallNumbers });
    }
    return adConfig;
};

export default createTransformer;
