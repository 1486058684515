import React, { Component } from 'react';

import Input from '../input';
import TypeAheadContainer from './type-ahead-container';
import TypeAheadOptions from './type-ahead-options';

interface Option {
    name: string;
    category?: string;
    categoryDisplayName?: string;
}

interface TypeAheadProps {
    clearAllFromHistory?: () => void;
    defaultValue?: string;
    icon?: JSX.Element;
    id: string;
    inline?: boolean;
    inputProps: any; // Consider specifying a more detailed type
    inputRef?: (element: HTMLInputElement) => void;
    useRecentHistory?: boolean;
    isTypeAheadOptionsIconsVisible?: boolean;
    limit?: number;
    name?: string;
    onChange: (value: string) => void;
    onSelectOption: (value: Option) => void;
    options: Option[];
    removeSingleFromHistory?: (text: string) => void;
    typeAheadIcon: JSX.Element;
    titleOfRecentHistory: string;
    showClearAllButton: boolean;
    onOpen?: () => void;
    outsideFocus?: boolean;
}

interface TypeAheadState {
    isOpen: boolean;
    isFocused: boolean;
    focusedOption: number;
    charLimit: number;
    value: string;
}

export const setOpen =
    ({
        props,
        setState,
    }: {
        props: TypeAheadProps;
        setState: ({ isOpen }: { isOpen: boolean }) => void;
    }) =>
    (isFocused: boolean) => {
        const { options } = props;
        const hasOptions = options.length > 0;
        const nextIsOpen = hasOptions && isFocused;
        setState({ isOpen: nextIsOpen });
        nextIsOpen && props.onOpen?.();
    };

export const setInputValue =
    ({
        input,
        setState,
    }: {
        input: HTMLInputElement | undefined | null;
        setState: ({
            isOpen,
            focusedOption,
            value,
        }: {
            isOpen: boolean;
            focusedOption: number;
            value: string;
        }) => void;
    }) =>
    (value: string) => {
        if (input) {
            input.value = value;
        }
        setState({ isOpen: false, focusedOption: 0, value });
    };

export const moveCursor = (input: HTMLInputElement | undefined | null) => {
    if (input) {
        /* Stop cursor moving on up arrow press */
        /* Hide the cursor colour to stop blinking effect */
        /* timeout needed as keypress fired too quickly */
        const inputValueLength = input.value.length;
        input.style.caretColor = 'transparent';
        setTimeout(() => {
            if (input) {
                input.setSelectionRange(inputValueLength, inputValueLength);
                input.style.caretColor = 'black';
            }
        }, 0);
    }
};

export const setSelectedOption =
    (state: TypeAheadState, props: TypeAheadProps, setInputValue: (value: string) => void) =>
    (nextOption: number) => {
        const { isOpen } = state;
        if (!isOpen) {
            return;
        }

        const { options, onChange, onSelectOption } = props;
        const option = options[nextOption - 1];
        const value = option.name;
        setInputValue(option.name);
        onChange(value);
        onSelectOption(option);
    };

export const onFocusOption =
    (
        setState: ({ focusedOption, value }: { focusedOption: number; value: string }) => void,
        props: TypeAheadProps,
        input: HTMLInputElement | undefined | null
    ) =>
    (nextOption: number) => {
        const { options } = props;
        if (options && options.length > 0) {
            const value = options[nextOption - 1].name;
            if (input) {
                input.value = value;
            }
            setState({ focusedOption: nextOption, value });
        }
    };

export const handleChange = (
    props: TypeAheadProps,
    setState: ({ isOpen, value }: { isOpen: boolean; value: string }, callback: () => void) => void,
    event: React.ChangeEvent<HTMLInputElement>
) => {
    const { onChange } = props;
    const { value } = event.target;
    setState({ isOpen: !!props.options.length, value }, () => {
        onChange(value);
    });
};

export const onOptionClick = (
    props: TypeAheadProps,
    setInputValue: (value: string) => void,
    value: Option
) => {
    setInputValue(value.name);
    const { onChange, onSelectOption } = props;
    onChange(value.name);
    onSelectOption(value);
};

class TypeAhead extends Component<TypeAheadProps, TypeAheadState> {
    input: HTMLInputElement | null = null;

    static defaultProps = {
        clearAllFromHistory: undefined,
        defaultValue: '',
        outsideFocus: false,
        inline: false,
        inputRef: () => {},
        limit: undefined,
        name: undefined,
        onChange: (_: string) => {},
        onSelectOption: () => {},
        options: [],
        removeSingleFromHistory: undefined,
        useRecentHistory: false,
        isTypeAheadOptionsIconsVisible: false,
        onOpen: () => {},
    };

    constructor(props: TypeAheadProps) {
        super(props);
        this.state = {
            isOpen: false,
            isFocused: false,
            focusedOption: 0,
            charLimit: 50,
            value: props.defaultValue || '',
        };
    }

    componentDidMount() {
        const isFocused = this.input === document.activeElement;
        if (isFocused) {
            this.handleFocus();
        }
    }

    componentDidUpdate(prevProps: TypeAheadProps) {
        const { options } = this.props;
        const { isFocused } = this.state;

        if (options !== prevProps.options) {
            // this is required to ensure that the typeahead is opened when the options change, as the options refresh are async
            setOpen({ props: this.props, setState: this.setState.bind(this) })(isFocused);
        }

        if (this.props.outsideFocus && this.props.outsideFocus !== prevProps.outsideFocus) {
            this.handleFocus();
        }
    }

    handleBlur = () => this.setState({ isOpen: false, isFocused: false, focusedOption: 0 });

    handleFocus = () => {
        this.setState({ isFocused: true }, () => {
            setOpen({ props: this.props, setState: this.setState.bind(this) })(true);
        });
    };

    handleClear = () => {
        this.setState({ value: '' });
    };

    handleInputRef = (element: HTMLInputElement) => {
        this.input = element;
        if (this.props.inputRef) {
            this.props.inputRef(element);
        }
    };

    render() {
        const {
            icon,
            id,
            inline,
            limit,
            options,
            inputProps,
            removeSingleFromHistory,
            clearAllFromHistory,
            useRecentHistory,
            typeAheadIcon,
            titleOfRecentHistory,
            showClearAllButton,
        } = this.props;
        const { focusedOption, isOpen, value, charLimit, isFocused } = this.state;

        return (
            <TypeAheadContainer
                focusedOption={focusedOption}
                id={id}
                isFocused={isFocused}
                limit={limit}
                inline={inline}
                onBlur={this.handleBlur}
                onFocusOption={(nextOption: number) => {
                    onFocusOption(this.setState.bind(this), this.props, this.input)(nextOption);
                }}
                onSelectOption={() => {
                    setSelectedOption(
                        this.state,
                        this.props,
                        setInputValue({ input: this.input, setState: this.setState.bind(this) })
                    )(focusedOption);
                }}
                options={options}
                moveCursor={() => {
                    moveCursor(this.input);
                }}
            >
                {icon && <div className="icon-container">{icon}</div>}
                <Input
                    {...inputProps}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(this.props, this.setState.bind(this), e);
                    }}
                    onBlur={this.handleBlur}
                    autoComplete="off"
                    className="input"
                    id={id}
                    inline={inline}
                    name={id} // adjusted to use `id` for `name` if not provided
                    onFocus={this.handleFocus}
                    inputRef={this.handleInputRef}
                    onClear={this.handleClear}
                    value={value}
                />
                <TypeAheadOptions
                    focusedOption={focusedOption}
                    highlight={value}
                    isLimitReached={value.length >= charLimit}
                    isOpen={isOpen}
                    limit={limit}
                    onOptionClick={(value: Option) => {
                        onOptionClick(
                            this.props,
                            setInputValue({
                                input: this.input,
                                setState: this.setState.bind(this),
                            }),
                            value
                        );
                    }}
                    options={options}
                    useRecentHistory={useRecentHistory}
                    removeSingleFromHistory={(text) => {
                        if (removeSingleFromHistory) {
                            removeSingleFromHistory(text);
                            setTimeout(() => {
                                if (this.input) {
                                    this.input.focus();
                                }
                            }, 0);
                        }
                    }}
                    clearAllFromHistory={clearAllFromHistory!}
                    icon={typeAheadIcon}
                    titleOfRecentHistory={titleOfRecentHistory}
                    showClearAllButton={showClearAllButton}
                />
            </TypeAheadContainer>
        );
    }
}

export default TypeAhead;
