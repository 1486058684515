import React from 'react';
import PropTypes from 'prop-types';

import { typeAheadClearHistoryHeaderCss } from './type-ahead-clear-history.style';

const TypeAheadClearHistoryHeader = ({ clearAllFromHistory, title, showClearAllButton }) => {
    const clearAllHistory = (event) => {
        event.stopPropagation();
        clearAllFromHistory();
    };

    return (
        <div
            className="type-ahead-clear-history-header"
            css={typeAheadClearHistoryHeaderCss}
            role="listitem"
        >
            <span>{title}</span>
            {showClearAllButton && (
                <button type="button" onMouseDown={clearAllHistory}>
                    Clear All
                </button>
            )}
        </div>
    );
};

TypeAheadClearHistoryHeader.propTypes = {
    clearAllFromHistory: PropTypes.func,
    showClearAllButton: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
};

TypeAheadClearHistoryHeader.defaultProps = {
    clearAllFromHistory: () => {},
};

export default TypeAheadClearHistoryHeader;
