import { css } from "@emotion/react";
import { colors, fontSizes, fontWeights, gutterSizes } from "../../base/theme";

export const typeAheadClearHistoryHeaderCss = css`
    &.type-ahead-clear-history-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px ${gutterSizes.base} 5px 17px;
        font-size: ${fontSizes.base};
        line-height: 24px;

        & > span {
            color: ${colors.bark};
            font-weight: ${fontWeights.bold};
        }

        & > button {
            background: transparent;
            border: none;
            color: ${colors.blue};
            cursor: pointer;
        }
    }
`;
