import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
    gutterSizes,
    fontSizes,
    colorVariables,
    breakpoints,
    mediaQuery,
    boxSizes,
} from '@gumtree/ui-library/src/base/theme';

const Item = styled.a`
    background-color: ${colorVariables.borderDark};
    border-radius: ${boxSizes.borderRadius};
    color: ${colorVariables.fontLight};
    font-size: ${fontSizes.small};
    margin: 0 ${gutterSizes.small} ${gutterSizes.base};
    padding: ${gutterSizes.small} ${gutterSizes.small};

    span {
        color: ${colorVariables.fontLight};
    }

    ${mediaQuery.from(breakpoints.small)} {
        padding: ${gutterSizes.small} ${gutterSizes.base};
    }

    &:last-of-type {
        margin-right: 0;
    }
`;

class GlobalSitesItem extends PureComponent {
    render() {
        const { label, link } = this.props;

        return (
            <Item href={link}>
                <span>{label}</span>
            </Item>
        );
    }
}

GlobalSitesItem.propTypes = {
    label: PropTypes.string,
    link: PropTypes.string,
};

GlobalSitesItem.defaultProps = {
    label: '',
    link: '',
};

export default GlobalSitesItem;
