import React from 'react';
import PropTypes from 'prop-types';

import TextAd from './text-ad/component/text-ad';
import ProductAd from './product-ad/component/product-ad';
import { isTextAd, isProductAd } from '../../../common/domain/ad-qualifier';

const Listing = ({ adConfigs, onAdClick, abTests }) => (
    <ul className="clearfix">
        {adConfigs.map((props, i) => (
            <li key={`ad-${i + 1}`}>
                {isTextAd(props) && (
                    <TextAd
                        onClick={() => onAdClick({ index: i + 1 })}
                        {...props}
                        abTests={abTests}
                    />
                )}
                {isProductAd(props) && (
                    <ProductAd onClick={() => onAdClick({ index: i + 1 })} {...props} />
                )}
            </li>
        ))}
    </ul>
);

Listing.defaultProps = {
    abTests: [],
    onAdClick: () => {},
};

Listing.propTypes = {
    abTests: PropTypes.arrayOf(PropTypes.string),
    adConfigs: PropTypes.array.isRequired,
    onAdClick: PropTypes.func,
};

export default Listing;
