import { Nullable } from 'typescript-nullable';
import createSlotConfig from './slot-config';
import { getScreenSize } from './common/screen-size';
import { getDeviceType } from './common/device-type';
import { getSlotNamesBy } from './common/slot-name';
import { getPageType } from './common/page-type';

function prebidConfig(
    pageTypeAsString: Nullable<'ResultsSearch' | 'ResultsBrowse' | 'VIP' | 'Homepage'>,
    deviceTypeAsString: Nullable<string>,
    screenSizeAsString: Nullable<string>,
    categoryL1: Nullable<string>,
    categoryL2: Nullable<string>,
    categoryL3: Nullable<string>,
    keywords: Nullable<string>,
    experiments: Array<string>,
    vipAdId?: Nullable<number>
) {
    if (Nullable.isNone(pageTypeAsString)) {
        return [];
    }

    const pageType = getPageType(pageTypeAsString);
    if (Nullable.isNone(pageType)) {
        return [];
    }

    if (!deviceTypeAsString) {
        return [];
    }

    const deviceType = getDeviceType(deviceTypeAsString);
    if (Nullable.isNone(deviceType)) {
        return [];
    }

    if (!screenSizeAsString) {
        return [];
    }
    const screenSize = getScreenSize(screenSizeAsString);
    if (Nullable.isNone(screenSize)) {
        return [];
    }

    const slotNames = getSlotNamesBy(pageType, deviceType, screenSize) || [];

    return slotNames
        .map((slotName) =>
            createSlotConfig(
                pageType,
                deviceType,
                screenSize,
                slotName,
                categoryL1,
                categoryL2,
                categoryL3,
                keywords,
                experiments,
                vipAdId
            )
        )
        .filter(Nullable.isSome);
}

export default prebidConfig;
