/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';

import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import { Container, Grid } from '@gumtree/ui-library';

import { FooterCopyrightContainer, FooterCopyrightText } from './footer-copyright-style';

const FooterCopyright = ({ links }) => {
    const yearNow = new Date().getFullYear();

    return (
        <Container>
            <Grid container>
                <Grid item col={12} colXl={6} offsetXl={3}>
                    <FooterCopyrightContainer {...qaAttribute('footer-copyright')}>
                        <FooterCopyrightText data-testid="footer-copyright-text">
                            {`© Copyright 2000-${yearNow} Gumtree.com Limited. All rights reserved.`}
                            <br />
                            <br />
                            Gumtree.com Limited (FRN 560524) is an Appointed Representative of
                            Consumer Credit Compliance Limited who are authorised and regulated by
                            the Financial Conduct Authority (FRN 631736).
                            <br />
                            <br />
                            The permissions of Consumer Credit Compliance Limited as a Principal
                            firm allow Gumtree.com Limited to undertake insurance and credit
                            broking. Gumtree.com Limited acts as a credit broker, not a lender. We
                            will refer you to CarMoney Limited (FRN 674094) for credit, we receive a
                            fixed fee commission up to an agreed number of leads, and additional
                            commission for those above the agreed level. We will refer you to
                            Inspop.com Ltd T/A Confused.com (FRN 310635) for Insurance and we
                            receive a fixed fee commission. You will not pay more as a result of our
                            commission arrangements.
                            <br />
                            <br />
                            Gumtree.com Limited, registered in England and Wales with number
                            03934849, 27 Old Gloucester Street, London, WC1N 3AX, United Kingdom.
                            VAT No. 476 0835 68.
                        </FooterCopyrightText>
                        <a
                            href={links[0].link}
                            className="footer-copyright-link"
                            {...qaAttribute(`footer-copyright-link-${links[0].name}`)}
                        >
                            {links[0].label}
                        </a>
                        {', '}
                        <a
                            href={links[1].link}
                            className="footer-copyright-link"
                            {...qaAttribute(`footer-copyright-link-${links[1].name}`)}
                        >
                            {links[1].label}
                        </a>
                        {', '}
                        {/* This copy is overriden/controlled on OneTrust */}
                        <a
                            name="privacy-settings"
                            href="#privacy-settings"
                            className="ot-sdk-show-settings footer-copyright-link"
                        >
                            Privacy Settings
                        </a>
                        <span>, </span>
                        {' & '}
                        <a
                            href={links[2].link}
                            className="footer-copyright-link"
                            {...qaAttribute(`footer-copyright-link-${links[2].name}`)}
                        >
                            {links[2].label}
                        </a>
                    </FooterCopyrightContainer>
                </Grid>
            </Grid>
        </Container>
    );
};

FooterCopyright.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.string,
            name: PropTypes.string,
        })
    ),
};

FooterCopyright.defaultProps = {
    links: [{}, {}, {}, {}],
};

export default FooterCopyright;
