import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Overlay } from '@gumtree/ui-library';
import SideNavBox from './side-nav-box';
import SideNavButton from './side-nav-button';

/** provides a button which will open a sliding menu drawer */

const SideNavContainer = styled.div`
    display: inline-block;
`;
class SideNav extends Component {
    state = {
        isOpen: false,
    };

    open = () => this.setState({ isOpen: true });
    close = () => this.setState({ isOpen: false });

    render() {
        const { children, dark, right, inExperimentGTC2229 } = this.props;
        const { isOpen } = this.state;
        const { close, open } = this;

        return (
            <SideNavContainer>
                <SideNavButton
                    dark={dark}
                    onClick={this.open}
                    inExperimentGTC2229={inExperimentGTC2229}
                />

                <Overlay isOpen={isOpen} onClick={close} />

                <SideNavBox isOpen={isOpen} right={right}>
                    {children({ close, open })}
                </SideNavBox>
            </SideNavContainer>
        );
    }
}

SideNav.defaultProps = {
    children: () => {},
    dark: false,
    inExperimentGTC2229: false,
    right: false,
};

SideNav.propTypes = {
    children: PropTypes.func,
    dark: PropTypes.bool,
    inExperimentGTC2229: PropTypes.bool,
    right: PropTypes.bool,
};

export default SideNav;
