import React from 'react';
import PropTypes from 'prop-types';

import Side from '../../../common/side';
import Content from '../../../common/content';
import Title from '../../../common/title';
import Description from '../../../common/description';
import ExternalLink from '../../../common/external-link';
import Thumbnail from './thumbnail';
import Seller from './seller';
import OriginalPrice from '../../../common/price/original-price';
import SalePrice from '../../../common/price/sale-price';

const ProductAd = ({ num, url, description, item, seller, price, onClick }) => {
    const id = `bing-product-ad-${num}`;
    const { image } = item;
    const externalLinkStyle = {
        display: 'block',
        borderRadius: '4px',
        height: '100%',
    };
    const salePriceProps = {
        value: price.sale,
        currency: price.currency,
    };
    const originalPriceProps = {
        value: price.original,
        currency: price.currency,
        overridden: Boolean(price.sale),
        isInline: true,
    };
    const offerDescription = description && <Description>{description}</Description>;
    const itemDescription = item.description && <Description>{item.description}</Description>;
    const salePrice = price.sale && <SalePrice {...salePriceProps} />;
    const originalPrice = <OriginalPrice {...originalPriceProps} />;
    const thumbnail = <Thumbnail imageSrc={image.url} description={item.description} />;
    return (
        <article
            id={id}
            style={{
                borderBottom: '1px solid #f1f0f1',
                padding: '8px 0',
                width: '100%',
                overflow: 'hidden',
            }}
        >
            <Side
                style={{
                    float: 'left',
                    width: window?.innerWidth >= 768 ? '120px' : '100px',
                    marginRight: '16px',
                }}
            >
                <ExternalLink onClick={onClick} url={url} noReferrer={false}>
                    {thumbnail}
                </ExternalLink>
            </Side>
            <Content>
                <ExternalLink
                    onClick={onClick}
                    url={url}
                    noReferrer={false}
                    style={externalLinkStyle}
                >
                    <Title text={item.name} />
                    {offerDescription}
                    <Seller name={seller} />
                    {itemDescription}
                    {salePrice}
                    {originalPrice}
                </ExternalLink>
            </Content>
        </article>
    );
};

ProductAd.defaultProps = {
    onClick: () => {},
};

ProductAd.propTypes = {
    description: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    num: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    price: PropTypes.object.isRequired,
    seller: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default ProductAd;
