import { L1Category } from '@gumtree/shared/src/types/client-data';
import { WindowWithGoogleTag } from '@gumtree/shared/src/types/window';

export const removeHideFully = (slotId: string, window: Window) => {
    if (['premiumNative1', 'premiumNative2'].includes(slotId)) {
        const slotElement = window.document.querySelector(`#${slotId}`);
        slotElement?.classList.remove('hide-fully');
    }
};

export const addWrapperClass = (slotId: string, window: Window) => {
    if (['premiumNative1', 'premiumNative2'].includes(slotId)) {
        const slotElement = window.document.querySelector(`#${slotId}`);
        slotElement?.parentElement?.classList.add('premium-native-slots-wrap');
    }
};

const adjustPremiumSlots = (l1Category: L1Category | undefined, window: WindowWithGoogleTag) => {
    window.googletag.cmd.push(() => {
        window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
            const slotId = event.slot.getSlotElementId();
            if (l1Category === 'jobs') {
                removeHideFully(slotId, window);
            } else {
                if (!event.isEmpty) {
                    addWrapperClass(slotId, window);
                }
            }
        });
    });
};
export default adjustPremiumSlots;
