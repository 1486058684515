import React from 'react';
import PropTypes from 'prop-types';

import { Toggle, Toggler, ToggleContent, Grid } from '@gumtree/ui-library';
import FooterLink from './footer-link';

import { FooterLinksSection, FooterLinksTitle } from './footer-links-style';

/**
 * @param {{
 *   sections: import('./footer-data.json')['sections'];
 *   device: import('../reducers/common/base-config').DeviceMeta;
 *   trackAndNavigate: (event: string, url: string, eventLabel?: string) => void;
 * }} param0
 */
const FooterLinks = ({ sections, device, trackAndNavigate }) => (
    <Grid container>
        {sections.map((section, sectionIndex) => (
            <Grid
                item
                key={sectionIndex}
                col={10}
                colM={5}
                colL={3}
                colXl={2}
                offset={1}
                offsetL={0}
                offsetXl={[0, sections.length - 1].includes(sectionIndex) ? 1 : 0}
                className="footer-links-grid-section"
            >
                <FooterLinksSection key={sectionIndex}>
                    <>
                        {device.type === 'mobile' ? (
                            <Toggle transitionToggle>
                                <Toggler iconType={{ title: 'chevron-d', color: 'white' }}>
                                    <FooterLinksTitle>{section.title}</FooterLinksTitle>
                                </Toggler>
                                <ToggleContent className="transition">
                                    {section.items &&
                                        section.items
                                            .filter((item) => item.name !== 'popular-searches')
                                            .map((item, itemIndex) => (
                                                <FooterLink
                                                    key={itemIndex}
                                                    eventAction={item.eventAction}
                                                    eventLabel={item.eventLabel}
                                                    trackAndNavigate={trackAndNavigate}
                                                    image={item.image}
                                                    label={item.label}
                                                    link={item.link}
                                                    name={item.name}
                                                    tagAttributes={item.tagAttributes}
                                                />
                                            ))}
                                </ToggleContent>
                            </Toggle>
                        ) : (
                            <>
                                <FooterLinksTitle>{section.title}</FooterLinksTitle>
                                {section.items &&
                                    section.items
                                        .filter((item) => item.name !== 'popular-searches')
                                        .map((item, itemIndex) => (
                                            <FooterLink
                                                key={itemIndex}
                                                eventAction={item.eventAction}
                                                eventLabel={item.eventLabel}
                                                trackAndNavigate={trackAndNavigate}
                                                image={item.image}
                                                label={item.label}
                                                link={item.link}
                                                name={item.name}
                                                tagAttributes={item.tagAttributes}
                                            />
                                        ))}
                            </>
                        )}
                    </>
                </FooterLinksSection>
            </Grid>
        ))}
    </Grid>
);

FooterLinks.propTypes = {
    device: PropTypes.object.isRequired,
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    eventAction: PropTypes.string,
                    eventLabel: PropTypes.string,
                    image: PropTypes.string,
                    label: PropTypes.string,
                    link: PropTypes.string,
                    name: PropTypes.string,
                    tagAttributes: PropTypes.object,
                })
            ),
            title: PropTypes.string,
        })
    ),
    trackAndNavigate: PropTypes.func.isRequired,
};

FooterLinks.defaultProps = {
    sections: [],
};

export default FooterLinks;
