import styled from '@emotion/styled';
import {
    gutterSizes,
    fontSizes,
    colorVariables,
    breakpoints,
    mediaQuery,
} from '@gumtree/ui-library/src/base/theme';

export const FooterGlobalSitesContainer = styled.div`
    display: grid;

    ${mediaQuery.from(breakpoints.medium)} {
        justify-items: end;
    }
`;

export const FooterGlobalSitesWrapper = styled.div`
    margin-bottom: ${gutterSizes.large};
    text-align: center;
`;

export const FooterGlobalSitesTitle = styled.h4`
    color: ${colorVariables.fontQuaternary};
    font-size: ${fontSizes.large};
    margin: 0 0 ${gutterSizes.base};
`;
