import Cookies from 'js-cookie';

const appBannerCookieStorage = (domain) => {
    const hasCookie = Cookies.get('searchCount');
    const hasDismissed = Cookies.get('AppBannerSearchDismissed');
    if (hasDismissed && hasDismissed === 'true') {
        return null;
    }
    if (hasCookie) {
        let count = parseInt(hasCookie, 10);
        if (count % 4 === 0) {
            count += 1;
            Cookies.set('searchCount', count, { domain });
            Cookies.set('showBanner', 'true', { domain });
        } else {
            count += 1;
            Cookies.set('searchCount', count, { domain });
        }
    } else {
        Cookies.set('searchCount', 2, { domain });
    }
};

export default appBannerCookieStorage;
