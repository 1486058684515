import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Button, Icon } from '@gumtree/ui-library';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import { trackV2 } from '@gumtree/shared/src/util/track-ga-event';
import { fontSizes } from '@gumtree/ui-library/src/base/theme';
import { NavBarButtonOverride } from '../nav-bar/common.style';

export const StyledButton = styled(Button)`
    && {
        ${NavBarButtonOverride}
    }

    &.inGTC2229 {
        .icon {
            font-size: ${fontSizes.pgLarge};
        }
    }
`;

const SideNavButton = ({ dark, onClick, inExperimentGTC2229 }) => (
    <StyledButton
        bottomLabel
        className={inExperimentGTC2229 ? 'inGTC2229' : ''}
        dark={dark}
        icon={<Icon type="burger" />}
        label="Menu"
        onClick={() => {
            onClick();
            trackV2('HeaderMenuSelect');
        }}
        {...qaAttribute('side-nav-button-mobile')}
    />
);

SideNavButton.defaultProps = {
    dark: false,
    inExperimentGTC2229: false,
    onClick: () => {},
};

SideNavButton.propTypes = {
    dark: PropTypes.bool,
    inExperimentGTC2229: PropTypes.bool,
    onClick: PropTypes.func,
};

export default SideNavButton;
