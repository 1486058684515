// import getPageType from 'frontend/common/extract-global/page-type';

const STORAGE_KEY_MAP = {
    SPOTLIGHT: 'GUM.SYI.FEATURES.SPOTLIGHT--UNCHECK',
    URGENT: 'GUM.SYI.FEATURES.URGENT--UNCHECK',
    FEATURED: 'GUM.SYI.FEATURES.FEATURED--UNCHECK',
};

export const EVENTS = {
    SPOTLIGHT_UNCHECKED: 'GUM.SYI.FEATURES.SPOTLIGHT--UNCHECK',
    URGENT_UNCHECKED: 'GUM.SYI.FEATURES.URGENT--UNCHECK',
    FEATURED_UNCHECKED: 'GUM.SYI.FEATURES.FEATURED--UNCHECK',
    SELECT_ALL_UNCHECKED: 'GUM.SYI.FEATURES.SELECT_ALL--UNCHECK',
    SPOTLIGHT_CHECKED: 'GUM.SYI.FEATURES.SPOTLIGHT--CHECK',
    URGENT_CHECKED: 'GUM.SYI.FEATURES.URGENT--CHECK',
    FEATURED_CHECKED: 'GUM.SYI.FEATURES.FEATURED--CHECK',
    SELECT_ALL_CHECKED: 'GUM.SYI.FEATURES.SELECT_ALL--CHECK',
};

const initEventListeners = (window) => {
    window.document.addEventListener(EVENTS.SELECT_ALL_UNCHECKED, () => {
        window.sessionStorage.setItem(STORAGE_KEY_MAP.SPOTLIGHT, 'true');
        window.sessionStorage.setItem(STORAGE_KEY_MAP.URGENT, 'true');
        window.sessionStorage.setItem(STORAGE_KEY_MAP.FEATURED, 'true');
    });

    window.document.addEventListener(EVENTS.SELECT_ALL_CHECKED, () => {
        window.sessionStorage.removeItem(STORAGE_KEY_MAP.SPOTLIGHT);
        window.sessionStorage.removeItem(STORAGE_KEY_MAP.URGENT);
        window.sessionStorage.removeItem(STORAGE_KEY_MAP.FEATURED);
    });

    window.document.addEventListener(EVENTS.SPOTLIGHT_UNCHECKED, () => {
        window.sessionStorage.setItem(STORAGE_KEY_MAP.SPOTLIGHT, 'true');
    });

    window.document.addEventListener(EVENTS.SPOTLIGHT_CHECKED, () => {
        window.sessionStorage.removeItem(STORAGE_KEY_MAP.SPOTLIGHT);
    });

    window.document.addEventListener(EVENTS.URGENT_UNCHECKED, () => {
        window.sessionStorage.setItem(STORAGE_KEY_MAP.URGENT, 'true');
    });

    window.document.addEventListener(EVENTS.URGENT_CHECKED, () => {
        window.sessionStorage.removeItem(STORAGE_KEY_MAP.URGENT);
    });

    window.document.addEventListener(EVENTS.FEATURED_UNCHECKED, () => {
        window.sessionStorage.setItem(STORAGE_KEY_MAP.FEATURED, 'true');
    });

    window.document.addEventListener(EVENTS.FEATURED_CHECKED, () => {
        window.sessionStorage.removeItem(STORAGE_KEY_MAP.FEATURED);
    });
};

const resetFlags = (window) => {
    window.sessionStorage.removeItem(STORAGE_KEY_MAP.SPOTLIGHT);
    window.sessionStorage.removeItem(STORAGE_KEY_MAP.URGENT);
    window.sessionStorage.removeItem(STORAGE_KEY_MAP.FEATURED);
};

export const getSyiFeaturesRemovedFromBasket = () => [
    ...(window.sessionStorage.getItem(STORAGE_KEY_MAP.SPOTLIGHT) === 'true' ? ['SPOTLIGHT'] : []),
    ...(window.sessionStorage.getItem(STORAGE_KEY_MAP.URGENT) === 'true' ? ['URGENT'] : []),
    ...(window.sessionStorage.getItem(STORAGE_KEY_MAP.FEATURED) === 'true' ? ['FEATURED'] : []),
];

const init = (window) => {
    if (window.clientData.page.type === 'syi') {
        resetFlags(window);
        initEventListeners(window);
    }
};

export default init;
