import { L1Category } from '@gumtree/shared/src/types/client-data';

export enum AdsType {
    Text,
    ProductWithBackfill,
}

export const getAdsTypeToShow = (categoryL1: L1Category): AdsType => {
    if (categoryL1 === 'toplevel' || categoryL1 === 'for-sale') {
        return AdsType.ProductWithBackfill;
    }

    return AdsType.Text;
};
