import { useEffect, useState } from 'react';
import debounce from './debounce';

interface WindowSize {
    width: number;
}

function useWindowWidth(delay = 500): WindowSize {
    const [width, setWidth] = useState(0);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        const debouncedHandleResize = debounce(handleResize, delay);

        handleResize();

        window.addEventListener('resize', debouncedHandleResize);
        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, [delay]);

    return { width };
}

export default useWindowWidth;
