 
import {
    getConsentData,
    getHasUserMadeChoice,
} from '@gumtree/third-party-ads/src/framework/gdpr/util';
import { getClientData as get } from '@gumtree/ui-library/src/utils/data-service';
import injectScript from '@gumtree/ui-library/src/utils/inject-script';

export const getComscoreConsent = async (window) => {
    const { vendorConsents, purposeConsents } = await getConsentData(window);

    const isAllPurposesConsented =
        purposeConsents && Object.values(purposeConsents).every((_) => _ === true);
    const isComScoreConsented = vendorConsents && vendorConsents[77] === true;

    return isAllPurposesConsented && isComScoreConsented ? 1 : 0;
};

type CsUcfr = 1 | 0 | undefined;
type Result = {
    options: { url_append: string };
    c1: string;
    cs_ucfr: undefined | 1 | 0;
    c2: string;
    c5?: string;
    c6?: string;
};

export default async (window) => {
    window._comscore = window._comscore || [];

    const vertical = get('comScore.vertical');
    const categoryL1 = get('comScore.categoryL1');
    const categoryL2 = get('comScore.categoryL2');

    const hasMadeChoice = await getHasUserMadeChoice(window);

    let csUcfr: CsUcfr = undefined;
    if (hasMadeChoice) {
        csUcfr = await getComscoreConsent(window);
    }

    const result: Result = {
        c1: '2',
        c2: '7849854',
        cs_ucfr: csUcfr,
        options: {
            url_append: `comscorekw=${vertical}`,
        },
    };

    if (categoryL1) {
        result.c5 = categoryL1;
    }

    if (categoryL2) {
        result.c6 = categoryL2;
    }

    window._comscore.push(result);

    const url = `https://sb.scorecardresearch.com/cs/${result.c2}/beacon.js`;

    injectScript(url, { async: true });
};
