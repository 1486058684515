import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ src, description, style }) => (
    <img style={style} className="hide-fully-no-js" src={src} alt={description} />
);

Image.propTypes = {
    description: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    style: PropTypes.object,
};

Image.defaultProps = {
    style: {},
};

export default Image;
