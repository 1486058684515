const PROD_ORIGIN = 'https://www.gumtree.com';
const GATEWAY_ORIGIN = 'https://gateway.gumtree.com';

export function validateOrigin(window: Window, origin: string) {
    const { isQA } = window.clientData?.baseConfig ?? {};

    return [PROD_ORIGIN, GATEWAY_ORIGIN].includes(origin) || isQA;
}

export function getTargetOrigin(window: Window) {
    const { domain } = window.clientData?.baseConfig ?? {};
    if (domain) {
        return `https://www.${domain}`;
    }
    return PROD_ORIGIN;
}
