import React, { useEffect, useState } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import SocialForm from '@gumtree/register/src/components/social-form/social-form';
import { useDispatch, useSelector } from 'react-redux';
import { trackGA4Event } from '@gumtree/shared/src/util/ga4-shared';
import { ModalLogin, ModalTitle, EmailButton, ButtonWrapper } from './app.style';
import RegisterModal from './register-modal';
import SuccessModal from './success-modal';

import { ShellState } from '../reducers/common';
import { Type, setIsLogin } from '../reducers/common/login-modal';
import LoginFormModal from './login-form-modal';

export interface LoginModalProps {
    contactName?: string;
    registrationSuccess?: boolean;
    openSearchBar?: () => void;
}

const LoginModal = ({ registrationSuccess, openSearchBar }: LoginModalProps) => {
    const dispatch = useDispatch();
    const [showForm, setShowForm] = useState(false);
    const googleAppId = useSelector(({ baseConfig }: ShellState) => baseConfig.google_app_id);
    const { type, isLogin, showForgotPassword } = useSelector(
        ({ loginModal }: ShellState) => loginModal
    );
    const { userData } = useSelector((state: ShellState) => state);

    let titleType;

    if (type === Type.REGISTER) {
        titleType = 'Sign up';
    } else if (type === Type.REVEAL_PHONE) {
        titleType = 'Log in to reveal the number';
    } else if (type === Type.LOGIN) {
        titleType = 'Log in';
    } else if (type === Type.SELL) {
        titleType = 'Log in to sell an item';
    } else if (type === Type.EMAIL) {
        titleType = 'Log in to send a message';
    } else if (type === Type.OFFER) {
        titleType = 'Log in to make an offer';
    }

    const handleToggle = (showLogin) => {
        dispatch(setIsLogin(showLogin));
        setShowForm(true);
    };

    useEffect(() => {
        if (showForgotPassword) {
            setShowForm(true);
            dispatch(setIsLogin(true));
        }

        if (isLogin) {
            trackGA4Event<GA4.OpenLoginModal>({
                event: 'open_login_modal',
            });
        } else {
            trackGA4Event<GA4.OpenUserRegistrationModal>({
                event: 'open_user_registration_modal',
            });
        }
    }, []);

    return (
        <>
            {registrationSuccess ? (
                <SuccessModal contactName={userData?.firstName} openSearchBar={openSearchBar} />
            ) : (
                <>
                    {showForm ? (
                        isLogin ? (
                            <LoginFormModal type={type} />
                        ) : (
                            <RegisterModal setShowForm={setShowForm} />
                        )
                    ) : (
                        <GoogleOAuthProvider clientId={googleAppId}>
                            <ModalTitle>{titleType}</ModalTitle>
                            {isLogin ? (
                                <ModalLogin>
                                    Don't have an account?{' '}
                                    <button onClick={() => {
                                        handleToggle(false);
                                        trackGA4Event<GA4.OpenUserRegistrationModal>({
                                            event: 'open_user_registration_modal',
                                        });
                                        }} type="button">
                                        Sign up
                                    </button>
                                </ModalLogin>
                            ) : (
                                <ModalLogin>
                                    Already got an account?{' '}
                                    <button
                                        data-q="login-button"
                                        onClick={() => {
                                            handleToggle(true);
                                            trackGA4Event<GA4.OpenLoginModal>({
                                                event: 'open_login_modal',
                                            });
                                        }}
                                        type="button"
                                    >
                                        Log in
                                    </button>
                                </ModalLogin>
                            )}
                            <ButtonWrapper>
                                <SocialForm type={isLogin ? "login" : "register"} fromModal />
                                <EmailButton
                                    data-q="email-login"
                                    type="button"
                                    onClick={() => setShowForm(true)}
                                >
                                    Continue with email
                                </EmailButton>
                            </ButtonWrapper>
                        </GoogleOAuthProvider>
                    )}
                </>
            )}
        </>
    );
};

export default LoginModal;
