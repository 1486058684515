import { AdParamsFromBingApi } from '../../transform-data-into-ad-models';
import transformOffer from './transform-offer';

const transform = (adParams: AdParamsFromBingApi) => {
    const { _type, offer, position, impressionToken } = adParams;

    return {
        _type,
        ...(offer ? transformOffer(offer) : {}),
        position,
        impressionToken,
    };
};

export default transform;
