import styled from '@emotion/styled';
import { Button } from '@gumtree/ui-library';
import { colors } from '@gumtree/ui-library/src/base/theme';
import { NavBarButtonOverride } from '../common.style';

export const StyledWrapper = styled.div`
    position: relative;
`;

export const MessagesButton = styled(Button)`
    && {
        ${NavBarButtonOverride};
    }

    .icon--envelope {
        width: 100%;
    }
`;

export const StyledCountBullet = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-75%, -40%);
    width: 16px;
    height: 16px;
    background-color: #5cf100;
    border-radius: 50%;
    color: ${colors.black};
    font-size: 11px;
    font-weight: normal;
    line-height: 16px;
    vertical-align: middle;
    text-align: center;
`;
