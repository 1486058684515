// Utility function to determine if a child element is visible within its scrollable parent
export function isChildVisible(parent: HTMLElement, child: HTMLElement): boolean {
    const parentRect = parent.getBoundingClientRect();
    const childRect = child.getBoundingClientRect();

    // Calculate the child's top and bottom relative to the parent's viewport,
    // adjusted for vertical scrolling.
    const childTopRelativeToParent = childRect.top - parentRect.top + parent.scrollTop;
    const childBottomRelativeToParent = childTopRelativeToParent + childRect.height;

    // Calculate the child's left and right relative to the parent's viewport,
    // adjusted for horizontal scrolling.
    const childLeftRelativeToParent = childRect.left - parentRect.left + parent.scrollLeft;
    const childRightRelativeToParent = childLeftRelativeToParent + childRect.width;

    // Check if the child is within the visible vertical area of the parent.
    const isVisibleVertically =
        childTopRelativeToParent >= 0 && childBottomRelativeToParent <= parent.clientHeight;

    // Check if the child is within the visible horizontal area of the parent.
    const isVisibleHorizontally =
        childLeftRelativeToParent >= 0 && childRightRelativeToParent <= parent.clientWidth;

    // The child is considered visible if it is both vertically and horizontally within the parent's visible area.
    return isVisibleVertically && isVisibleHorizontally;
}
