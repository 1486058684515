import React from 'react';

import CheckEmailSVG from '@gumtree/ui-library/src/assets/check-email.svg';
import useMediaQuery from '@gumtree/ui-library/src/common-hooks/use-media-query';
import { resendLink } from '@gumtree/login/src/post-data';
import {
    CheckEmailTitle,
    CheckEmailContent,
    CheckEmailLink,
    OpenEmailButton,
} from './register-modal.style';

interface CheckEmailProps {
    resendPath: string;
}

const CheckEmailModal = ({ resendPath }: CheckEmailProps) => {
    const handleClick = () => {
        resendLink(resendPath);
    }

    const { isMobile } = useMediaQuery();
    return (
        <>
            <CheckEmailSVG />
            <CheckEmailTitle>Check your email</CheckEmailTitle>
            <CheckEmailContent>
                Please click the activation link in the email to continue the sign up process. Be
                sure to check your spam folder.
            </CheckEmailContent>
            {isMobile && (
                <OpenEmailButton
                    shouldOpenNewTab
                    display="primary"
                    fullWidth
                    type="button"
                    label="Open email"
                    href="https://mail.google.com/mail"
                />
            )}
            <CheckEmailLink onClick={handleClick}>Resend email</CheckEmailLink>
        </>
    );
};

export default CheckEmailModal;
