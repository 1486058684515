import { Nullable } from 'typescript-nullable';
import Partner from './partner';
import SlotName from '../common/slot-name';
import ScreenSize from '../common/screen-size';
import DeviceType from '../common/device-type';
import PageType from '../common/page-type';

export default class Rubicon extends Partner {
    readonly bidder = 'rubicon';
    readonly siteId: Nullable<number>;
    readonly accoundId: number = 13948;

    constructor(
        pageType: PageType,
        deviceType: DeviceType,
        slotName: SlotName,
        screenSize: ScreenSize,
        keywords: Nullable<string>,
        categoryL1: Nullable<string>,
        categoryL2: Nullable<string>,
        categoryL3: Nullable<string>,
        experiments: Array<string>
    ) {
        super(
            pageType,
            deviceType,
            slotName,
            screenSize,
            keywords,
            categoryL1,
            categoryL2,
            categoryL3,
            experiments
        );
        if (deviceType === DeviceType.desktop) {
            this.siteId = 98816;
        }
        if (deviceType === DeviceType.tablet) {
            this.siteId = 98818;
        }
        if (deviceType === DeviceType.mobile) {
            this.siteId = 98820;
        }
    }

    mapping = {
        [PageType.ResultsPage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.integratedMpu]: 463748,
                    [SlotName.rSkyT]: 463738,
                    [SlotName.rSkyT2]: 1811778,
                    [SlotName.tBanner]: 463730,
                    [SlotName.rSkyB]: 463770,
                    [SlotName.rSkyB2]: 1811776,
                    [SlotName.integratedListing]: 463756,
                },
                [ScreenSize.XL]: {
                    [SlotName.integratedMpu]: 463748,
                    [SlotName.rSkyT]: 463738,
                    [SlotName.rSkyT2]: 1811778,
                    [SlotName.tBanner]: 463730,
                    [SlotName.rSkyB]: 463770,
                    [SlotName.rSkyB2]: 1811776,
                    [SlotName.integratedListing]: 463756,
                },
                [ScreenSize.L]: {
                    [SlotName.integratedMpu]: 463748,
                    [SlotName.tBanner]: 463730,
                    [SlotName.integratedListing]: 463756,
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.integratedMpu]: 463854,
                    [SlotName.tBanner]: 463844,
                    [SlotName.integratedListing]: 463858,
                },
                [ScreenSize.M]: {
                    [SlotName.integratedMpu]: 463854,
                    [SlotName.tBanner]: 463844,
                    [SlotName.integratedListing]: 463858,
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.integratedMpu]: 463898,
                    [SlotName.integratedListing]: 1286298,
                    [SlotName.floatingFooter]: 463896,
                },
                [ScreenSize.XS]: {
                    [SlotName.integratedMpu]: 463898,
                    [SlotName.integratedListing]: 1286298,
                    [SlotName.floatingFooter]: 463896,
                },
            },
        },
        [PageType.VIP]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.vipBanner]: 463806,
                    [SlotName.mpu]: 463796,
                    [SlotName.vipMiddleDesktop]: 984298,
                },
                [ScreenSize.XL]: {
                    [SlotName.vipBanner]: 463806,
                    [SlotName.mpu]: 463796,
                    [SlotName.vipMiddleDesktop]: 984298,
                },
                [ScreenSize.L]: {
                    [SlotName.vipBanner]: 463806,
                    [SlotName.mpu]: 463796,
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.vipBanner]: 463878,
                    [SlotName.mpu]: 463886,
                    [SlotName.fBanner]: 463890,
                },
                [ScreenSize.M]: {
                    [SlotName.vipBanner]: 463878,
                    [SlotName.mpu]: 463886,
                    [SlotName.fBanner]: 463890,
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.floatingFooter]: 463904,
                    [SlotName.vipMiddleMobile]: 984300,
                },
                [ScreenSize.XS]: {
                    [SlotName.floatingFooter]: 463904,
                    [SlotName.vipMiddleMobile]: 984300,
                },
            },
        },
        [PageType.Homepage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.homeSideAd]: 463728,
                },
                [ScreenSize.XL]: {
                    [SlotName.homeSideAd]: 463728,
                },
                [ScreenSize.L]: {
                    [SlotName.homeSideAd]: 463728,
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.homeSideAd]: 463840,
                },
                [ScreenSize.M]: {
                    [SlotName.homeSideAd]: 463840,
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.homeBanner]: 463894,
                },
                [ScreenSize.XS]: {
                    [SlotName.homeBanner]: 463894,
                },
            },
        },
    };

    toObject() {
        if (Nullable.isNone(this.getPlacement())) {
            return undefined;
        }

        return {
            bidder: this.bidder,
            params: {
                zoneId: this.getPlacement(),
                accountId: this.accoundId,
                siteId: this.siteId,
                ...(Nullable.isSome(this.keywords) && {
                    visitor: {
                        search: this.keywords,
                    },
                }),
                inventory: {
                    experiments: this.experiments,
                },
                ...(Nullable.isSome(this.keywords) && { keywords: this.keywords }),
            },
        };
    }
}
