import { Device, L1Category, L2Category, PageType } from '@gumtree/shared/src/types/client-data';

type SlotId = 'slot12' | 'slot15';

type AdSlotSelectorParameters = {
    googlePpcVisit: boolean;
    device: Device;
    l1Category: L1Category;
    l2Category?: L2Category;
    abTests: string[];
    pageType: PageType;
    resultSize?: number;
};

const getSlots = ({
    isSlot12Active,
    isIntegratedListingActive,
    isSlot15Active,
}: {
    isSlot12Active: boolean;
    isIntegratedListingActive: boolean;
    isSlot15Active: boolean;
}): SlotId[] => {
    return [
        ...(isSlot12Active ? ['slot12' as SlotId] : []),
        ...(isIntegratedListingActive ? ['integratedListing' as SlotId] : []),
        ...(isSlot15Active ? ['slot15' as SlotId] : []),
    ];
};

export const determineSlots = (params: AdSlotSelectorParameters): SlotId[] => {
    const { googlePpcVisit, pageType } = params;

    if (['ResultsSearch', 'ResultsBrowse'].includes(pageType)) {
        return getSlots({
            isSlot12Active: !googlePpcVisit,
            isIntegratedListingActive: false,
            isSlot15Active: true,
        });
    }

    return [];
};
