import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

class Form extends Component {
    state = {
        hasSubmitted: false,
    };

    handleSubmit = (event) => {
        event.preventDefault();

        if (event.target.checkValidity()) {
            const { onSubmit } = this.props;
            onSubmit(event);
        }

        this.setState({ hasSubmitted: true });
    };

    render() {
        const { children, ...props } = this.props;
        const { hasSubmitted } = this.state;

        return (
            <form
                {...props}
                noValidate
                data-testid={`submitted-${hasSubmitted}`}
                className={classnames({ 'has-submitted': hasSubmitted })}
                onSubmit={this.handleSubmit}
            >
                {children}
            </form>
        );
    }
}

Form.defaultProps = {
    children: null,
    onSubmit: () => {},
};

Form.propTypes = {
    children: PropTypes.node,
    onSubmit: PropTypes.func,
};

export default Form;
