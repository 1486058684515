import { getArray, setValue } from '@gumtree/ui-library/src/utils/local-storage-service';

const storageKey = 'locationPromptShown';

export const popUpPrompt = 'POP_UP_PROMPT';
export const fullScreenPrompt = 'FULL_SCREEN_PROMPT';
type PromptType = typeof popUpPrompt | typeof fullScreenPrompt;

export const hasBeenShownLocationPrompt = (promptType: PromptType) =>
    getArray(storageKey).includes(promptType);

export const setHasBeenShownLocationPrompt = (promptType: PromptType) =>
    setValue(storageKey, Array.from(new Set([...getArray(storageKey), promptType])));
