import React, { PureComponent } from 'react';
import classnames from 'classnames';

import TypeAheadOption from './type-ahead-option';
import TypeAheadClearHistoryHeader from './type-ahead-clear-history-header';
import Icon from '../../icon/icon';

import './type-ahead-options.scss';

export interface Option {
    name: string;
    highlight?: string;
    category?: string;
    categoryDisplayName?: string;
}

interface TypeAheadOptionsProps {
    clearAllFromHistory?: () => void;
    focusedOption?: number;
    highlight?: string;
    icon: JSX.Element;
    isLimitReached?: boolean;
    isOpen?: boolean;
    useRecentHistory?: boolean;
    limit?: number;
    onOptionClick: (value: Option) => void;
    options: Option[];
    removeSingleFromHistory: (text: string) => void;
    titleOfRecentHistory: string;
    showClearAllButton: boolean;
}

class TypeAheadOptions extends PureComponent<TypeAheadOptionsProps> {
    static defaultProps = {
        clearAllFromHistory: () => {},
        focusedOption: 0,
        useRecentHistory: false,
        highlight: '',
        isLimitReached: false,
        isOpen: false,
        limit: undefined,
        onOptionClick: () => {},
        options: [],
        removeSingleFromHistory: () => {},
    };

    getClassName = (isOpen: boolean) =>
        classnames({
            'type-ahead-options': true,
            'type-ahead-options--open': isOpen,
        });

    render() {
        const {
            options,
            isLimitReached,
            focusedOption,
            highlight,
            isOpen,
            limit,
            useRecentHistory,
            removeSingleFromHistory,
            clearAllFromHistory,
            titleOfRecentHistory,
            icon,
            showClearAllButton,
            onOptionClick,
        } = this.props;

        const limitedOptions = options.slice(0, limit);
        const hasNonZeroHistory = options.length !== 0;

        return (
            <div className={this.getClassName(isOpen || false)} role="listbox">
                {useRecentHistory && hasNonZeroHistory && (
                    <TypeAheadClearHistoryHeader
                        clearAllFromHistory={clearAllFromHistory}
                        title={titleOfRecentHistory}
                        showClearAllButton={showClearAllButton}
                    />
                )}
                {limitedOptions.map((option, index) => (
                    <TypeAheadOption
                        key={`${option.name}-${index}`}
                        focused={focusedOption === index + 1}
                        highlight={option.highlight || highlight}
                        onMouseDown={() => {
                            if (option.name) {
                                onOptionClick(option);
                            }
                        }}
                        text={option.name}
                        secondaryText={option.categoryDisplayName}
                        removeSingleFromHistory={removeSingleFromHistory}
                        useRecentHistory={useRecentHistory}
                        icon={icon}
                    />
                ))}
                {isLimitReached && (
                    <li className="type-ahead-option type-ahead-limit">
                        <Icon type="warning" />
                        Your search exceeded the maximum number of characters, please reduce it and
                        try again
                    </li>
                )}
            </div>
        );
    }
}

export default TypeAheadOptions;
