import React, { useRef } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { Icon } from '@gumtree/ui-library';
import useWindowWidth from '@gumtree/ui-library/src/utils/use-window-size';
import useFacebookSignIn from '../../hooks/useFacebookSignIn';
import useGoogleSignIn from '../../hooks/useGoogleSignIn';
import {
    StyledFacebookButton,
    StyledForm,
    StyledGoogleButtonWrapper,
    StyledFacebookLogoSvg,
    StyledFacebookLogoPng,
    StyledSocialFormWrapper,
    StyledErrorBox,
    StyledErrorTitle,
    StyledErrorDescription,
} from './style';
import { SocialLoginPayload } from '../../types';

const SocialForm = ({ type, fromModal }: { type: 'login' | 'register'; fromModal: boolean }) => {
    const socialFormRef = useRef<HTMLFormElement>(null);

    const handleSubmitLogin = ({ username, password, loginPlatform }: SocialLoginPayload) => {
        if (!socialFormRef.current) {
            return;
        }

        socialFormRef.current.username.value = username;
        socialFormRef.current.password.value = password;
        socialFormRef.current.loginPlatform.value = loginPlatform;

        socialFormRef.current.submit();
    };

    const { onLaunch, errorMessage: fbErrorMessage } = useFacebookSignIn(handleSubmitLogin, type);
    const {
        onSuccess,
        onError,
        errorMessage: googleErrorMessage,
    } = useGoogleSignIn(handleSubmitLogin, type);

    const errorMessage = fbErrorMessage || googleErrorMessage;

    const { width } = useWindowWidth();

    let buttonWidth;

    if (width > 280 && width < 767) {
        buttonWidth = 310;
    } else {
        buttonWidth = 400;
    }

    return (
        <StyledSocialFormWrapper data-testid="social-form-wrapper">
            {errorMessage && (
                <StyledErrorBox>
                    <StyledErrorTitle>
                        <Icon type="circ-error" />
                        Login Error
                    </StyledErrorTitle>
                    <StyledErrorDescription data-testid="social-error-message">
                        {errorMessage}
                    </StyledErrorDescription>
                </StyledErrorBox>
            )}
            <StyledGoogleButtonWrapper isLoginPage={!fromModal}>
                <GoogleLogin
                    onSuccess={onSuccess}
                    onError={onError}
                    text="continue_with"
                    logo_alignment="center"
                    ux_mode="popup"
                    width={fromModal ? buttonWidth : undefined}
                />
            </StyledGoogleButtonWrapper>
            <StyledFacebookButton
                isLoginPage={!fromModal}
                type="button"
                data-testid="facebook-sign-in-btn"
                onClick={onLaunch}
            >
                {fromModal ? <StyledFacebookLogoPng /> : <StyledFacebookLogoSvg />}
                Continue with Facebook
            </StyledFacebookButton>
            <StyledForm
                ref={socialFormRef}
                method="POST"
                action="/bff-api/register/via-social"
                noValidate
            >
                <input type="hidden" name="username" />
                <input type="hidden" name="password" />
                <input type="hidden" name="loginPlatform" />
            </StyledForm>
        </StyledSocialFormWrapper>
    );
};

export default SocialForm;
