import React from 'react';
import PropTypes from 'prop-types';

import GlobalSitesItems from './global-sites-items';
import {
    FooterGlobalSitesContainer,
    FooterGlobalSitesWrapper,
    FooterGlobalSitesTitle,
} from './global-sites-style';

const GlobalSites = ({ items, title }) => (
    <FooterGlobalSitesContainer>
        <FooterGlobalSitesWrapper>
            <FooterGlobalSitesTitle>{title}</FooterGlobalSitesTitle>
            <GlobalSitesItems items={items} />
        </FooterGlobalSitesWrapper>
    </FooterGlobalSitesContainer>
);

GlobalSites.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            link: PropTypes.string,
        })
    ),
    title: PropTypes.string,
};

GlobalSites.defaultProps = {
    items: [],
    title: '',
};

export default GlobalSites;
