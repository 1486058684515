const isMerchantRating = (ext) => ext._type === 'Ads/MerchantRatingExtension';

export const hasMerchantRatingExtension = (extensions = []) => extensions.some(isMerchantRating);

export const getMerchantRatingExtension = (extensions = []) => extensions.find(isMerchantRating);

export const getMerchantRatingInfo = ({ rating, domain, numberOfRatings }) => ({
    rating,
    domain,
    numberOfRatings,
});
