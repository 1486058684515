/*
	Common component to render "Gumicons"

	- `alignRight` prop positions the icon absolute right
	- `name` prop defines the icon
	- can take a custom CSS class in `className` prop
*/
import React from 'react';
import PropTypes from 'prop-types';

const getClassName = (alignRight, classNameProp, name) => {
    let className = `icn-${name}`;

    if (alignRight) {
        className += ' icn-align-right';
    }

    if (classNameProp) {
        className += ` ${classNameProp}`;
    }

    return className;
};

const Icon = ({ alignRight, className, name }) => (
    <span className={getClassName(alignRight, className, name)} aria-hidden="true" />
);

export default Icon;

Icon.defaultProps = {
    alignRight: false,
    className: '',
};

Icon.propTypes = {
    alignRight: PropTypes.bool,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
};
