import { Nullable } from 'typescript-nullable';
import SlotName from '../common/slot-name';
import ScreenSize from '../common/screen-size';
import DeviceType from '../common/device-type';
import PageType from '../common/page-type';

export function getSizesBySlotName(
    pageType: PageType,
    deviceType: DeviceType,
    slotName: SlotName,
    screenSize: ScreenSize
): Nullable<[number, number][]> {
    const mapping: {
        [key: string]: { [key: string]: { [key: string]: { [key: string]: [number, number][] } } };
    } = {
        [PageType.ResultsPage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.rSkyT2]: [[300, 600]],
                    [SlotName.rSkyB2]: [[300, 600]],
                    [SlotName.rSkyT]: [
                        [300, 250],
                        [300, 600],
                    ],
                    [SlotName.rSkyB]: [
                        [300, 250],
                        [300, 600],
                    ],
                    [SlotName.integratedMpu]: [
                        [300, 250],
                        [728, 90],
                    ],
                    [SlotName.tBanner]: [
                        [728, 90],
                        [970, 250],
                    ],
                    [SlotName.integratedListing]: [
                        [300, 250],
                        [728, 90],
                    ],
                },
                [ScreenSize.XL]: {
                    [SlotName.lSkyB]: [[160, 600]],
                    [SlotName.lSkyT]: [[160, 600]],
                    [SlotName.integratedMpu]: [
                        [300, 250],
                        [728, 90],
                    ],
                    [SlotName.rSkyT]: [[160, 600]],
                    [SlotName.tBanner]: [
                        [728, 90],
                        [970, 250],
                    ],
                    [SlotName.rSkyB]: [[160, 600]],
                    [SlotName.integratedListing]: [
                        [300, 250],
                        [728, 90],
                    ],
                    [SlotName.baseMpu]: [
                        [300, 250],
                        [300, 600],
                    ],
                },
                [ScreenSize.L]: {
                    [SlotName.lSkyB]: [[160, 600]],
                    [SlotName.lSkyT]: [[160, 600]],
                    [SlotName.integratedMpu]: [
                        [300, 250],
                        [728, 90],
                    ],
                    [SlotName.tBanner]: [
                        [728, 90],
                        [970, 250],
                    ],
                    [SlotName.baseMpu]: [
                        [300, 250],
                        [300, 600],
                    ],
                    [SlotName.integratedListing]: [
                        [300, 250],
                        [728, 90],
                    ],
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.lSkyB]: [[160, 600]],
                    [SlotName.lSkyT]: [[160, 600]],
                    [SlotName.integratedMpu]: [
                        [300, 250],
                        [728, 90],
                    ],
                    [SlotName.tBanner]: [
                        [728, 90],
                        [970, 250],
                    ],
                    [SlotName.baseMpu]: [
                        [300, 250],
                        [300, 600],
                    ],
                    [SlotName.integratedListing]: [
                        [300, 250],
                        [728, 90],
                    ],
                },
                [ScreenSize.M]: {
                    [SlotName.integratedMpu]: [
                        [300, 250],
                        [728, 90],
                    ],
                    [SlotName.tBanner]: [[728, 90]],
                    [SlotName.baseMpu]: [
                        [300, 250],
                        [300, 600],
                    ],
                    [SlotName.integratedListing]: [
                        [300, 250],
                        [728, 90],
                    ],
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.integratedListing]: [
                        [300, 250],
                        [320, 50],
                    ],
                    [SlotName.floatingFooter]: [[320, 50]],
                    [SlotName.integratedMpu]: [
                        [300, 250],
                        [320, 50],
                    ],
                },
                [ScreenSize.XS]: {
                    [SlotName.integratedListing]: [
                        [300, 250],
                        [320, 50],
                    ],
                    [SlotName.floatingFooter]: [[320, 50]],
                    [SlotName.integratedMpu]: [
                        [300, 250],
                        [320, 50],
                    ],
                },
            },
        },
        [PageType.VIP]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.vipBanner]: [
                        [728, 90],
                        [970, 250],
                    ],
                    [SlotName.mpu]: [
                        [300, 250],
                        [300, 600],
                    ],
                    [SlotName.vipMiddleDesktop]: [[300, 250]],
                },
                [ScreenSize.XL]: {
                    [SlotName.vipBanner]: [
                        [728, 90],
                        [970, 250],
                    ],
                    [SlotName.mpu]: [
                        [300, 250],
                        [300, 600],
                    ],
                    [SlotName.vipMiddleDesktop]: [[300, 250]],
                },
                [ScreenSize.L]: {
                    [SlotName.vipBanner]: [
                        [728, 90],
                        [970, 250],
                    ],
                    [SlotName.mpu]: [
                        [300, 250],
                        [300, 600],
                    ],
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.vipBanner]: [
                        [728, 90],
                        [970, 250],
                    ],
                    [SlotName.mpu]: [
                        [300, 250],
                        [300, 600],
                    ],
                    [SlotName.fBanner]: [[728, 90]],
                },
                [ScreenSize.M]: {
                    [SlotName.vipBanner]: [[728, 90]],
                    [SlotName.mpu]: [
                        [300, 250],
                        [300, 600],
                    ],
                    [SlotName.fBanner]: [[728, 90]],
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.integratedListing]: [[300, 250]],
                    [SlotName.floatingFooter]: [[320, 50]],
                    [SlotName.vipMiddleMobile]: [[300, 250]],
                },
                [ScreenSize.XS]: {
                    [SlotName.integratedListing]: [[300, 250]],
                    [SlotName.floatingFooter]: [[320, 50]],
                    [SlotName.vipMiddleMobile]: [[300, 250]],
                },
            },
        },
        [PageType.Homepage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.homeSideAd]: [
                        [300, 250],
                        [300, 600],
                    ],
                },
                [ScreenSize.XL]: {
                    [SlotName.homeSideAd]: [
                        [300, 250],
                        [300, 600],
                    ],
                },
                [ScreenSize.L]: {
                    [SlotName.homeSideAd]: [
                        [300, 250],
                        [300, 600],
                    ],
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.homeSideAd]: [
                        [300, 250],
                        [300, 600],
                    ],
                },
                [ScreenSize.M]: {
                    [SlotName.homeSideAd]: [[160, 600]],
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.homeBanner]: [[320, 50]],
                },
                [ScreenSize.XS]: {
                    [SlotName.homeBanner]: [[320, 50]],
                },
            },
        },
    };

    if (
        mapping[pageType] &&
        mapping[pageType][deviceType] &&
        mapping[pageType][deviceType][screenSize]
    ) {
        return mapping[pageType][deviceType][screenSize][slotName];
    }

    return undefined;
}
