import { Nullable } from 'typescript-nullable';

enum ScreenSize {
    XXL,
    XL,
    L,
    M,
    S,
    XS,
}

export function getScreenSize(size: string): Nullable<ScreenSize> {
    const mapping: { [index: string]: ScreenSize } = {
        xxl: ScreenSize.XXL,
        xl: ScreenSize.XL,
        l: ScreenSize.L,
        m: ScreenSize.M,
        s: ScreenSize.S,
        xs: ScreenSize.XS,
    };

    return mapping[size];
}

export default ScreenSize;
