import { GrowthBook } from '@growthbook/growthbook-react';
import { FeatureApiResponse } from '@growthbook/growthbook';
import { ExperimentOverride } from '@gumtree/web-bff/src/render/util/experiment-override';
import { trackGA4Event } from '@gumtree/shared/src/util/ga4-shared';
import logger from '../logger';

const initGrowthbook = (
    growthbookExperimentData: FeatureApiResponse,
    appViewDevice?: 'iOS' | 'Android' | null,
    gbUserPseudoId?: string,
    includeTrackingCallback?: boolean
) => {
    let growthbook;

    if (includeTrackingCallback) {
        growthbook = new GrowthBook({
            trackingCallback: (experiment, result) => {
                trackGA4Event({
                    event: 'experiment_viewed',
                    experimentId: experiment.key,
                    variationId: result.key,
                });
            },
        });
    } else {
        growthbook = new GrowthBook();
    }

    growthbook.init({
        payload: growthbookExperimentData,
    });

    growthbook.setAttributes({
        isAppView: !!appViewDevice,
        ...(gbUserPseudoId && { gbUserPseudoId }),
    });

    return growthbook;
};

const initForcedFeatures = (
    growthbook: GrowthBook,
    experimentOverrides: Array<ExperimentOverride>
) => {
    if (experimentOverrides && experimentOverrides.length > 0) {
        const overriddenFeatures = new Map();

        experimentOverrides.forEach((extractedExperiment) => {
            if (extractedExperiment) {
                overriddenFeatures.set(extractedExperiment.key, extractedExperiment.value);
            }
        });

        growthbook.setForcedFeatures(overriddenFeatures);
    }
};

const getGrowthbookServerSide = (request: Express.Request) => {
    if (!request.growthbook) {
        logger.debug('Growthbook was requested on the serverside, but did not exist');
    }

    return request.growthbook;
};

const getGrowthbookClientSide = () => {
    if (!window.growthbook) {
        logger.debug('Growthbook was requested on the clientside, but did not exist');
    }

    return window.growthbook;
};

export { initGrowthbook, initForcedFeatures, getGrowthbookServerSide, getGrowthbookClientSide };
