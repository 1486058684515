const addMarginToTextLinkSlot = (window) => {
    window.googletag.cmd.push(() => {
        window.googletag.pubads().addEventListener('slotRequestedEvent', (event) => {
            const slotId = event.slot.getSlotElementId();
            const slotElement = window.document.querySelector(`#${slotId}`);
            slotElement.classList.add('space-mbm');
            slotElement.style.padding = '5px 0';
        });
    });
};

const textLinkSlotAdjust = addMarginToTextLinkSlot;

export default textLinkSlotAdjust;
