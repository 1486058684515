import React from 'react';
import PropTypes from 'prop-types';

import Transition from './transition';

const Fade = ({ children, in: inProp }) => (
    <Transition
        getEndStyle={() => ({ opacity: 1 })}
        getStartStyle={() => ({ opacity: 0 })}
        id="fade"
        in={inProp}
    >
        {children}
    </Transition>
);

Fade.defaultProps = {
    children: null,
    in: false,
};

Fade.propTypes = {
    children: PropTypes.node,
    in: PropTypes.bool,
};

export default Fade;
