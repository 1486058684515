import React from 'react';

import { css } from '@emotion/react';
import {
    colors,
    fontSizes,
    gutterSizes,
    lineHeights,
    mediaQuery,
} from '@gumtree/ui-library/src/base/theme';

import backgroundImage from '../common/armchair.jpg';
import curveVerticalImage from '../common/curve-vertical.png';
import Sticker from '../common/good-find-sticker';
import {
    armchairStyles,
    commonCurveVerticalStyles,
    commonTitleStyles,
    layoutBoundary,
} from '../common/common.style';

export { StyledLocationField, AlternativeText } from '../common/common.style';

const borderRadius = '18px';
export const Root = ({ children, ...props }: React.PropsWithChildren) => {
    const styles = css`
        & .filter-dialog--location-prompt-popup {
            border: none;
            padding: 0;
            max-width: initial;
            background-color: transparent;
            overflow: initial;

            ${mediaQuery.until(layoutBoundary)} {
                & .dialog-close {
                    right: 5px;
                    top: 5px;
                }

                & .icon--clear {
                    color: ${colors.white};
                    font-size: 14px;
                    font-weight: normal;
                }
            }
            ${mediaQuery.from(layoutBoundary)} {
                & .dialog-close {
                    background: ${colors.white};
                    opacity: 80%;
                    border-radius: 50%;
                    right: 10px;
                    top: 10px;
                }

                & .icon--clear {
                    color: ${colors.black};
                    font-size: 14px;
                    font-weight: normal;
                }
            }
        }
    `;

    return (
        <div css={styles} {...props}>
            {children}
        </div>
    );
};

export const ContentWrapper = ({ children, ...props }: React.PropsWithChildren) => {
    const styles = css`
        display: flex;
        flex-direction: column;
        background: ${colors.blade};
        border-radius: ${borderRadius};

        ${mediaQuery.from(layoutBoundary)} {
            width: 660px;
        }
    `;

    return (
        <div css={styles} {...props}>
            {children}
        </div>
    );
};

export const Content = ({ children, ...props }: React.PropsWithChildren) => {
    const styles = css`
        display: flex;
        justify-content: center;
        align-items: center;

        ${mediaQuery.from(layoutBoundary)} {
            width: 50%;
        }

        box-sizing: border-box;

        ${mediaQuery.from(layoutBoundary)} {
            padding-top: 90px;
            padding-left: ${gutterSizes.xxlarge};
            padding-bottom: 60px;
        }

        ${mediaQuery.until(layoutBoundary)} {
            padding: ${gutterSizes.xxlarge};
        }
    `;

    return (
        <div css={styles} {...props}>
            {children}
        </div>
    );
};

export const BackgroundImage = () => {
    const containerStyles = css`
        overflow: hidden;
        position: absolute;
        left: 50%;
        top: 0;

        width: 50%;
        height: 100%;

        border-bottom-right-radius: ${borderRadius};
        border-top-right-radius: ${borderRadius};

        ${mediaQuery.until(layoutBoundary)} {
            display: none;
        }
    `;

    const curveVerticalStyles = css`
        width: 100px;
    `;

    const stickerStyles = css`
        position: absolute;
        top: 27%;
        right: 20%;
        width: 90px;
    `;

    return (
        <div css={containerStyles}>
            <img css={armchairStyles} src={backgroundImage} alt="background" />
            <img
                css={css(commonCurveVerticalStyles, curveVerticalStyles)}
                src={curveVerticalImage}
                alt="vertical curve"
            />
            <Sticker css={stickerStyles} />
        </div>
    );
};

export const Title = ({ children, ...props }: React.PropsWithChildren) => {
    const styles = css`
        font-size: ${fontSizes.xlarge};
        line-height: ${lineHeights.large};

        ${mediaQuery.until(layoutBoundary)} {
            width: calc(100% - 20px);
        }
    `;

    return (
        <h1 css={css(commonTitleStyles, styles)} {...props}>
            {children}
        </h1>
    );
};
