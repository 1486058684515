import PropTypes from 'prop-types';

/**
 * A component that conditionally adds a containing component for example an anchor only when a url is available
 */
const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

ConditionalWrapper.propTypes = {
    /** the components to be wrapped */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    /** the condition on which the children are wrapped */
    condition: PropTypes.any,
    /** the component(s) which wrap the children when the condition is met */
    wrapper: PropTypes.func,
};

ConditionalWrapper.defaultProps = {
    children: null,
    condition: null,
    wrapper: null,
};

export default ConditionalWrapper;
