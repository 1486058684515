import { css } from "@emotion/react";
import {
    boxSizes,
    breakpoints,
    colors,
    fontSizes,
    gutterSizes,
    mediaQuery,
} from "@gumtree/ui-library/src/base/theme";

export const searchBarContainerCss = css`
    &.search-bar-container {
        position: relative;
        width: 100%;
        background-color: ${colors.blade};

        .popular-search-wrapper {
            position: relative;
            display: none;

            label {
                position: absolute;
                color: white;
                top: 0;
                right: 0;
                text-align: center;
                height: 32px;
                width: 48px;
                box-sizing: border-box;
                padding-top: 4px;
            }

            .popular-search-container {
                padding-top: ${gutterSizes.base};
                overflow: hidden;
                height: auto;
                margin-right: 40px;
                max-height: 20px;
                font-size: ${fontSizes.smaller};
                line-height: 1;

                ${mediaQuery.from(breakpoints.large)} {
                    padding-left: 16px;
                }

                &:before {
                    content: "";
                    display: table;
                }

                .popular-search-container-title {
                    padding-top: 3px;
                    color: ${colors.bark40};
                }

                .icon--dropdown {
                    float: right;
                }

                li {
                    float: left;
                    margin: 0 ${gutterSizes.medium} ${gutterSizes.base} 0;
                }

                a {
                    display: block;
                    border: 1px solid ${colors.bark40};
                    border-radius: 25px;
                    color: ${colors.bark40};
                    padding: 2px 7px;
                }

                .toggler {
                    text-align: right;
                    padding-right: ${gutterSizes.xlarge};
                    padding-top: ${gutterSizes.base};
                }

                ${mediaQuery.until(breakpoints.small)} {
                    .content {
                        clear: both;
                    }
                }
            }

            .expand-label {
                color: ${colors.bark40};
                cursor: pointer;
            }

            #popular-search-container-input {
                &:checked {
                    & ~ .popular-search-container {
                        max-height: none;
                    }

                    & ~ .expand-label {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .search-bar {
        ${mediaQuery.from(breakpoints.large)} {
            padding-top: ${gutterSizes.base};
        }

        .search-button {
            border: 1px transparent solid;

            svg {
                margin: 0;
                width: 24px;
                height: 24px;
            }

            ${mediaQuery.until(breakpoints.medium)} {
                width: 44px;
                border-radius: ${boxSizes.borderRadius};
                margin-left: ${gutterSizes.base};
                border-color: ${colors.berry};
            }

            ${mediaQuery.from(breakpoints.medium)} {
                border-radius: 0 ${boxSizes.borderRadius} ${boxSizes.borderRadius} 0;
                border-left-width: 0;
            }

            .icon {
                ${mediaQuery.from(breakpoints.medium)} {
                    left: 2px;
                }
            }

            &.input-clear {
                margin-right: 5px;
            }
        }

        .input-field {
            display: flex;
            align-items: center;
            background: ${colors.white};
            border-radius: ${boxSizes.borderRadius};

            .icon-container {
                position: relative;
                width: 20px;
                padding-left: ${gutterSizes.large};
                padding-right: ${gutterSizes.large};

                .icon {
                    color: ${colors.bark80};
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .button.input-clear .icon {
                top: 0;
            }
        }

        .location-container {
            margin-top: ${gutterSizes.base};
        }

        .inputs-container--fake-search {
            padding-bottom: ${gutterSizes.base};
        }

        .inputs-container--real-search {
            display: flex;
            flex-flow: column wrap;
            align-items: stretch;
            padding-bottom: ${gutterSizes.base};
            margin: 0 auto;

            ${mediaQuery.from(breakpoints.large)} {
                max-width: 820px;
            }

            ${mediaQuery.from(breakpoints.medium)} {
                flex-flow: row nowrap;
                align-items: center;
            }

            .input-field {
                ${mediaQuery.from(breakpoints.medium)} {
                    box-sizing: border-box;
                }

                &.is-focused {
                    ${mediaQuery.from(breakpoints.medium)} {
                        outline: 2px solid #1b73e8;
                    }
                }
            }

            .keyword-search-container {
                flex-grow: 1;

                .input-field {
                    ${mediaQuery.from(breakpoints.medium)} {
                        border-radius: ${boxSizes.borderRadius} 0 0 ${boxSizes.borderRadius};
                    }
                }
            }

            .dividing-line {
                ${mediaQuery.until(breakpoints.medium)} {
                    display: none;
                }
                ${mediaQuery.from(breakpoints.medium)} {
                    height: 44px;
                    border-right: 1px ${colors.blade} solid;
                }
            }

            .location-container {
                display: flex;
                flex-grow: 1;
                margin-left: 0;

                ${mediaQuery.from(breakpoints.medium)} {
                    margin-top: 0;
                    max-width: 340px;
                }

                ${mediaQuery.between(breakpoints.large, breakpoints.xlarge)} {
                    max-width: 270px;
                }

                .input-field {
                    ${mediaQuery.from(breakpoints.medium)} {
                        border-radius: 0;
                    }
                }
            }
        }

        .input {
            border: none;

            &:focus {
                border: none;
            }

            &:focus:focus {
                border: none;
            }
            border-radius: initial;
            background: transparent;
            padding-left: 0;
        }
    }
`;
