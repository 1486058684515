import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ErrorBoundary from './error-boundary';
import './view.scss';

const View = ({ App }) => (
    <div className="view-container">
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </div>
);

View.propTypes = {
    App: PropTypes.any.isRequired,
};

const mapStateToProps = ({ page: { App } }) => ({
    App,
});

export default connect(mapStateToProps)(View);
