import React from 'react';
import { trackGA4Event } from '@gumtree/shared/src/util/ga4-shared';
import { getLinkHostname } from '@gumtree/shared/src/util/ga4-bff';
import FacebookIcon from './social-icons/facebook-logo.svg';
import InstagramIcon from './social-icons/instagram-logo.svg';
import TiktokIcon from './social-icons/tiktok-logo.svg';
import TwitterIcon from './social-icons/twitter-logo.svg';
import YoutubeIcon from './social-icons/youtube-logo.svg';
import PinterestIcon from './social-icons/pinterest-logo.svg';

import { FooterSocialContainer, IconLink, H4 } from './footer-social-style';

export default function FooterSocial() {
    return (
        <FooterSocialContainer>
            <div>
                <H4>Join Gumtree Community</H4>
            </div>
            <div>
                <IconLink
                    href="https://www.facebook.com/gumtree"
                    rel="noopener noreferrer"
                    target="_blank"
                    onClick={trackSocialFooterClick}
                >
                    <FacebookIcon />
                    <span hidden>Facebook</span>
                </IconLink>
                <IconLink
                    href="https://www.instagram.com/gumtreeuk/"
                    rel="noopener noreferrer"
                    target="_blank"
                    onClick={trackSocialFooterClick}
                >
                    <InstagramIcon />
                    <span hidden>Instagram</span>
                </IconLink>
                <IconLink
                    href="https://www.tiktok.com/@gumtreeuk"
                    rel="noopener noreferrer"
                    target="_blank"
                    onClick={trackSocialFooterClick}
                >
                    <TiktokIcon />
                    <span hidden>Tiktok</span>
                </IconLink>
                <IconLink
                    href="https://twitter.com/Gumtree"
                    rel="noopener noreferrer"
                    target="_blank"
                    onClick={trackSocialFooterClick}
                >
                    <TwitterIcon />
                    <span hidden>Twitter</span>
                </IconLink>
                <IconLink
                    href="https://www.youtube.com/user/GumtreeClassifieds"
                    rel="noopener noreferrer"
                    target="_blank"
                    onClick={trackSocialFooterClick}
                >
                    <YoutubeIcon />
                    <span hidden>Youtube</span>
                </IconLink>
                <IconLink
                    href="https://www.pinterest.co.uk/gumtreeuk/"
                    rel="noopener noreferrer"
                    target="_blank"
                    onClick={trackSocialFooterClick}
                >
                    <PinterestIcon />
                    <span hidden>Pinterest</span>
                </IconLink>
            </div>
        </FooterSocialContainer>
    );
}

function trackSocialFooterClick(e: React.MouseEvent) {
    const link = (e.currentTarget as HTMLAnchorElement).href;
    trackGA4Event<GA4.ClickFooter>({
        event: 'click_footer',
        linkDomain: getLinkHostname(link),
        linkHeader: "footer",
        linkText: undefined,
        linkType: 'social icon',
        linkURL: link,
    });
}
