import { DeviceMeta } from '../../reducers/common/base-config';

export enum MenuItemLabel {
    Home = 'Home',
    PostAd = 'Post an Ad',
    ManageAds = 'Manage my Ads',
    Messages = 'Messages',
    Favourites = 'Favourites',
    MyAlerts = 'My Alerts',
    MyDetails = 'My Details',
    ManageJobAds = 'Manage my Job Ads',
    HelpAndContact = 'Help & Contact',
    Login = 'Login',
    Logout = 'Logout',
    Download = 'Download the app',
}

const appMenuItems = {
    ios: {
        mobile: {
            id: 'ios-download-mobile',
            label: MenuItemLabel.Download,
            link: 'https://app.adjust.com/ez1o5rv?campaign=Navigation%20Link&adgroup=Global%20Mobile%20Menu&creative=V1',
            icon: 'download',
            className: 'app-download-button',
            eventAction: 'AppDownloadBegin',
            eventLabel: 'mobile_menu',
        },
        tablet: {
            id: 'ios-download-tablet',
            label: MenuItemLabel.Download,
            link: 'https://itunes.apple.com/gb/app/gumtree-for-ipad/id906578543?mt=8',
            icon: 'download',
            eventAction: 'AppDownloadBegin',
            eventLabel: 'mobile_menu',
        },
    },
    android: {
        id: 'android-download',
        label: MenuItemLabel.Download,
        link: 'https://app.adjust.com/ez1o5rv?campaign=Navigation%20Link&adgroup=Global%20Mobile%20Menu&creative=V1',
        icon: 'download',
        className: 'app-download-button',
        eventAction: 'AppDownloadBegin',
        eventLabel: 'mobile_menu',
    },
};

export interface MenuItem {
    id: string;
    label: MenuItemLabel;
    link?: string;
    icon: string;
    eventAction?: string | undefined;
    eventLabel?: string;
    isBannerTakeoverShowing?: boolean;
    loggedIn?: boolean;
    loggedOut?: boolean;
    className?: string;
    betaLabel?: boolean;
}

interface MainItem {
    id: string;
    items: MenuItem[];
    title?: string;
}

interface MyGumtreeItem {
    id: string;
    title: string;
    items: MenuItem[];
}

interface SideMenuDataProps {
    buyerUrl: string;
    device: DeviceMeta;
    sellerUrl: string;
}

const sideMenuData = (
    { buyerUrl, device, sellerUrl }: SideMenuDataProps,
    isLoggedIn: boolean,
    inExperimentGTC1815: boolean,
    inExperimentGTC2229: boolean,
    inExperimentGTP2827: boolean
): [MainItem, MyGumtreeItem] => {
    const main: MainItem = {
        id: 'main',
        items: [],
    };

    if (device.type === 'mobile' || device.type === 'tablet') {
        if (device.os === 'ANDROID') {
            main.items.push(appMenuItems.android);
        } else if (device.os === 'IOS' && device.type === 'mobile') {
            main.items.push(appMenuItems.ios.mobile);
        } else if (device.os === 'IOS' && device.type === 'tablet') {
            main.items.push(appMenuItems.ios.tablet);
        }
    }

    if (!isLoggedIn) {
        main.items.push(
            {
                id: 'home',
                label: MenuItemLabel.Home,
                link: `${buyerUrl}/`,
                icon: 'house',
            },
            {
                id: 'postad',
                label: MenuItemLabel.PostAd,
                link: `${sellerUrl}/postad`,
                icon: 'pin',
                isBannerTakeoverShowing: true,
            }
        );
    }

    const myGumtree: MyGumtreeItem = {
        id: 'my-gumtree',
        title: 'My Gumtree',
        items: [
            {
                id: 'ads',
                label: MenuItemLabel.ManageAds,
                link: `${inExperimentGTP2827 ? buyerUrl : sellerUrl}/manage/ads`,
                icon: 'folder',
                eventAction: 'HeaderDropdownSelect',
                eventLabel: 'ManageAds',
            },
            {
                id: 'messages',
                label: MenuItemLabel.Messages,
                link: `${buyerUrl}/manage/messages`,
                icon: 'envelope',
                eventAction: 'HeaderDropdownSelect',
                eventLabel: 'MyMessages',
            },
            {
                id: 'favourites',
                label: MenuItemLabel.Favourites,
                link: `${buyerUrl}/my-account/favourites`,
                icon: 'full-heart',
                eventAction: 'HeaderDropdownSelect',
                eventLabel: 'Favourites',
            },
            {
                id: 'my-alerts',
                label: MenuItemLabel.MyAlerts,
                link: `${buyerUrl}/my-account/saved-searches`,
                icon: 'bell',
                eventAction: 'HeaderDropdownSelect',
                eventLabel: 'SavedSearches',
            },
            {
                id: 'my-details',
                label: MenuItemLabel.MyDetails,
                link: `${buyerUrl}/manage-account`,
                icon: 'person',
                eventAction: 'HeaderDropdownSelect',
                eventLabel: 'MyAccount',
            },
            {
                id: 'manage-job-ads',
                label: MenuItemLabel.ManageJobAds,
                link: 'https://recruiters.gumtree.com/job-listing/',
                icon: 'tie',
                eventAction: 'HeaderDropdownSelect',
                eventLabel: 'ManageMyJobAds',
            },
            {
                id: 'help-and-contact',
                label: MenuItemLabel.HelpAndContact,
                link: 'https://help.gumtree.com/',
                icon: 'circ-question-mark',
                eventAction: 'HeaderDropdownSelect',
                eventLabel: 'Help',
            },
            {
                id: 'login',
                label: MenuItemLabel.Login,
                ...(!inExperimentGTC2229
                    ? inExperimentGTC1815
                        ? { link: `${buyerUrl}/login` }
                        : { link: `${sellerUrl}/login` }
                    : null),
                icon: 'inbox',
                loggedOut: true,
            },
            {
                id: 'logout',
                label: MenuItemLabel.Logout,
                link: `${sellerUrl}/logout`,
                icon: 'exit',
                loggedIn: true,
                eventAction: 'HeaderDropdownSelect',
                eventLabel: 'Logout',
            },
        ],
    };

    return [main, myGumtree];
};

export default sideMenuData;
