import { addEventListener } from './browser-service';

const breakpoints: Breakpoint[] = [
    {
        tshirt: 'xs',
        min: 0,
        max: 479,
    },
    {
        tshirt: 's',
        min: 480,
        max: 767,
    },
    {
        tshirt: 'm',
        min: 768,
        max: 1023,
    },
    {
        tshirt: 'l',
        min: 1024,
        max: 1279,
    },
    {
        tshirt: 'xl',
        min: 1280,
        max: 1348,
    },
    {
        tshirt: 'xxl',
        min: 1349,
        max: Infinity,
    },
];

export type ScreenSize = 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs';
export type Breakpoint = {
    tshirt: ScreenSize;
    min: number;
    max: number;
};

const getBreakpointByTshirtSize = (tshirt: ScreenSize) =>
    breakpoints.find((breakpoint) => breakpoint.tshirt === tshirt);

const getCurrentBreakpoint = (w: Window = window): Breakpoint | undefined => {
    const windowWidth =
        w.innerWidth || w?.document?.documentElement?.clientWidth || w?.document?.body?.clientWidth;

    return breakpoints.find((b) => b.min <= windowWidth && windowWidth <= b.max);
};

const getCurrentWindowWidth = (w: Window = window): number =>
    w.innerWidth || w?.document?.documentElement?.clientWidth || w?.document?.body?.clientWidth;

const getCurrentWindowHeight = (w: Window = window): number =>
    w.innerHeight || w?.document?.documentElement?.clientHeight || w?.document?.body?.clientHeight;

const runOnBreakpointChange = (callback: () => void, w = window) => {
    let tshirtSize = getCurrentBreakpoint(w)?.tshirt;

    addEventListener(w, 'resize', () => {
        const newTshirtSize = getCurrentBreakpoint(w)?.tshirt;

        if (tshirtSize !== newTshirtSize) {
            tshirtSize = newTshirtSize;
            callback();
        }
    });
};

const getBreakpointMinByTshirtSize = (tshirtSize: ScreenSize) => {
    const breakpoint = breakpoints.find((b) => b.tshirt === tshirtSize);
    return breakpoint ? breakpoint.min : undefined;
};

const isMobile = (w = window) => ['xs', 's'].includes(getCurrentBreakpoint(w)?.tshirt as string);
const isUpToTablet = (w = window) => (getCurrentWindowWidth(w) < 768) as boolean;

export {
    getBreakpointMinByTshirtSize,
    getBreakpointByTshirtSize,
    getCurrentBreakpoint,
    isMobile,
    isUpToTablet,
    getCurrentWindowWidth,
    getCurrentWindowHeight,
    runOnBreakpointChange,
    breakpoints,
};
