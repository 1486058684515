const getFragments = (text, highlight) => {
    const highlightIndex = text.toLowerCase().indexOf(highlight.toLowerCase());

    const prefixPart = highlightIndex > 0 ? text.slice(0, highlightIndex) : '';
    const highlightPart =
        highlightIndex !== -1
            ? text.slice(prefixPart.length, prefixPart.length + highlight.length)
            : '';
    const suffixPart = text.slice(prefixPart.length + highlightPart.length);

    return { prefixPart, highlightPart, suffixPart };
};

export default getFragments;
