import React from 'react';
import PropTypes from 'prop-types';

const RatingCount = ({ value }) => <span className="listing-rating-count">({value} reviews)</span>;

RatingCount.propTypes = {
    value: PropTypes.number.isRequired,
};

export default RatingCount;
