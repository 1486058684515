export default (googletag) => {
    if (!window.innerWidth) {
        return;
    }

    const isSmallMobile: boolean = window.innerWidth < 360;

    googletag.cmd.push(() => {
        googletag.pubads().setTargeting('below_360px', isSmallMobile.toString());
    });
};
