import React from 'react';
import { StyledSpinner, Circle } from './spinner.style';
import { colors } from '../base/theme';

const AppViewSpinnerImage = ({color}) => (<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1251_31709)">
        <path d="M16 2.66683C15.4352 2.66683 14.9778 3.12594 14.9778 3.69071V8.84532V8.84867V8.91862H14.9811C15.0185 9.44918 15.4598 9.86754 16 9.86754C16.5404 9.86754 16.9822 9.44917 17.019 8.91862H17.0224L17.0222 3.69019C17.022 3.12544 16.5648 2.6665 16 2.6665L16 2.66683Z" fill={color} />
        <path d="M16 22.1336C15.4596 22.1336 15.0186 22.5536 14.9811 23.0842H14.9778V23.1541V23.1575V28.3106V28.3123C14.9778 28.8771 15.4352 29.3343 16 29.3343C16.5648 29.3343 17.0222 28.8771 17.0222 28.3123L17.022 23.0839H17.0187C16.9822 22.5533 16.5402 22.1333 16 22.1333L16 22.1336Z" fill={color}/>
        <path d="M8.96049 5.85233L11.5354 10.3138C11.5362 10.3138 11.5371 10.3154 11.5371 10.3154C11.5371 10.3154 11.5371 10.3171 11.5386 10.3171L11.5728 10.3789C11.5745 10.3789 11.5745 10.3772 11.5762 10.3772C11.8732 10.8183 12.4657 10.9598 12.9336 10.6897C13.4007 10.4194 13.575 9.83513 13.3413 9.35668H13.3438L10.7297 4.82848C10.4474 4.3385 9.8223 4.17087 9.33327 4.4542C8.84495 4.73584 8.67737 5.36088 8.9588 5.85067C8.9588 5.85067 8.95956 5.85065 8.96049 5.85233Z" fill={color}/>
        <path d="M23.0415 26.1523L20.4275 21.6239C20.4258 21.6239 20.4258 21.6239 20.4241 21.6255C20.127 21.1845 19.5345 21.0427 19.0665 21.313C18.5994 21.5833 18.426 22.1659 18.6587 22.6446C18.658 22.6446 18.6573 22.6446 18.6563 22.6461L18.6922 22.7064V22.7097C18.6937 22.7097 18.6937 22.7097 18.6937 22.7112L21.2703 27.1726V27.1741C21.5527 27.6622 22.1777 27.83 22.6668 27.5486C23.1555 27.2654 23.3231 26.6404 23.0414 26.1523L23.0415 26.1523Z" fill={color}/>
        <path d="M4.8274 10.7301L4.82815 10.7318L9.28958 13.3067C9.29125 13.3084 9.29125 13.3084 9.29293 13.3084C9.29293 13.3084 9.29367 13.31 9.29442 13.31L9.35543 13.3459C9.35636 13.3443 9.35636 13.3426 9.35636 13.3426C9.83482 13.5753 10.4191 13.4029 10.6887 12.9341C10.959 12.467 10.8165 11.8745 10.3754 11.5767C10.3771 11.5767 10.3771 11.575 10.3778 11.575L5.84964 8.96096C5.36056 8.67783 4.73552 8.84544 4.45317 9.33375C4.1706 9.82354 4.33839 10.4486 4.82745 10.73L4.8274 10.7301Z" fill={color}/>
        <path d="M27.1736 21.2706L22.6454 18.6564C22.6447 18.6581 22.6439 18.6581 22.6439 18.6598C22.1653 18.4269 21.5817 18.5995 21.3116 19.0666C21.0413 19.5354 21.1838 20.1279 21.6249 20.4242C21.6232 20.4257 21.6232 20.4257 21.6225 20.4276L21.6835 20.4618C21.6844 20.4635 21.6851 20.4635 21.6859 20.4635C21.6868 20.4635 21.6868 20.4649 21.6883 20.4649L26.1495 23.0415H26.1505C26.6397 23.323 27.2646 23.1571 27.5471 22.6671C27.8295 22.1788 27.6619 21.5537 27.1736 21.2706Z" fill={color}/>
        <path d="M8.84532 17.0226H8.91787C8.91787 17.0226 8.91694 17.0209 8.91694 17.0193C9.44769 16.9834 9.86752 16.5406 9.86752 16.0003C9.86752 15.4616 9.44748 15.0188 8.91694 14.9814C8.91694 14.9814 8.91787 14.9797 8.91787 14.978H3.68896C3.12421 14.978 2.66675 15.4369 2.66675 16.0002C2.66675 16.565 3.12418 17.0224 3.68896 17.0224H3.69063H8.8424C8.84388 17.0226 8.84365 17.0226 8.84532 17.0226Z" fill={color}/>
        <path d="M28.3111 14.9785H23.0822C23.0822 14.9802 23.0829 14.9819 23.0829 14.9819C22.5524 15.0193 22.1333 15.462 22.1333 16.0008C22.1333 16.5412 22.5524 16.9839 23.0829 17.0198C23.0829 17.0213 23.0822 17.0231 23.0822 17.0231H23.1546H23.1579H28.3111C28.8758 17.0231 29.3333 16.5657 29.3333 16.0009C29.3333 15.4376 28.8761 14.9785 28.3111 14.9785Z" fill={color}/>
        <path d="M10.315 20.4637C10.315 20.4637 10.3159 20.4637 10.3166 20.4621L10.3777 20.428L10.3762 20.4247C10.8172 20.1283 10.9588 19.5359 10.6894 19.0671C10.4191 18.6 9.83504 18.4275 9.35639 18.66C9.35639 18.6584 9.35639 18.6584 9.35546 18.6567L4.82727 21.2707C4.33819 21.554 4.17041 22.1791 4.4528 22.6674C4.73518 23.1574 5.36024 23.3233 5.84927 23.0419H5.85001L10.3122 20.4653C10.3125 20.4654 10.3135 20.4637 10.315 20.4637Z" fill={color}/>
        <path d="M21.6229 11.5751C21.6236 11.5751 21.6236 11.5768 21.6253 11.5768C21.1842 11.8746 21.0425 12.4671 21.312 12.9342C21.5823 13.403 22.1657 13.5754 22.6443 13.3427L22.6458 13.3461L22.7061 13.3102C22.707 13.3102 22.7078 13.3085 22.7085 13.3085C22.7094 13.3085 22.7094 13.3085 22.7109 13.3068L27.1722 10.7319C27.1722 10.7319 27.1729 10.7319 27.1738 10.7302C27.6622 10.4486 27.8297 9.82353 27.5483 9.33374C27.265 8.84542 26.6402 8.67784 26.1509 8.96095L21.6229 11.5751Z" fill={color}/>
        <path d="M12.9338 21.313C12.4659 21.0427 11.8734 21.1845 11.5764 21.6256C11.5747 21.6239 11.5747 21.6239 11.573 21.6239L8.95918 26.1521C8.67754 26.6404 8.84515 27.2652 9.33365 27.5487C9.82272 27.8304 10.4485 27.6628 10.7301 27.1743V27.1726L13.3066 22.7112C13.3066 22.7097 13.3073 22.7097 13.3082 22.7097C13.3082 22.708 13.3082 22.708 13.309 22.7063L13.344 22.6461C13.344 22.6446 13.3423 22.6446 13.3415 22.6446C13.5752 22.1659 13.4018 21.5833 12.9338 21.313Z" fill={color}/>
        <path d="M19.0665 10.6897C19.5346 10.9598 20.1269 10.8182 20.4242 10.3772C20.4258 10.3772 20.4258 10.3788 20.4275 10.3788L20.4625 10.3171L20.4634 10.3154C20.4642 10.3154 20.4649 10.3137 20.4649 10.3137L23.0406 5.85062H23.0415C23.3232 5.36064 23.1555 4.73579 22.667 4.45415C22.178 4.17084 21.5529 4.33863 21.2706 4.82843L18.6564 9.35663C18.6564 9.35663 18.658 9.35663 18.6588 9.3583C18.4261 9.83509 18.5993 10.4194 19.0665 10.6897Z" fill={color}/>
    </g>
    <defs>
        <clipPath id="clip0_1251_31709">
            <rect width="32" height="32" fill="white" />
        </clipPath>
    </defs>
</svg>
);

const Spinner: React.FC<SpinnerProps> = ({ color = colors.white, isAppView = false, includeLeftGutter = true }) => {
    const viewBox = isAppView ? "0 0 32 32" : "0 0 50 50";
    const image = isAppView ?
        <AppViewSpinnerImage color={color} />
        : <Circle cx="25" cy="25" r="20" fill="none" strokeWidth="5" stroke={color} />;

    return (
        <StyledSpinner viewBox={viewBox} data-testid="loading-spinner" includeLeftGutter={includeLeftGutter}>
            {image}
        </StyledSpinner>
    );
};

interface SpinnerProps {
    color?: string;
    isAppView?: boolean;
    includeLeftGutter?: boolean;
}

export default Spinner;
