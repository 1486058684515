import { Nullable } from 'typescript-nullable';

enum PageType {
    ResultsPage = 'ResultsPage',
    VIP = 'VIP',
    Homepage = 'Homepage',
}

export function getPageType(
    pageTypeAsString: 'ResultsSearch' | 'ResultsBrowse' | 'VIP' | 'Homepage'
): Nullable<PageType> {
    const mapping: { [index: string]: PageType } = {
        ResultsSearch: PageType.ResultsPage,
        ResultsBrowse: PageType.ResultsPage,
        VIP: PageType.VIP,
        Homepage: PageType.Homepage,
    };

    return mapping[pageTypeAsString];
}

export default PageType;
