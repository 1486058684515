import React from 'react';
import PropTypes from 'prop-types';

const Callout = ({ text }) => (
    <div style={{ fontSize: '13px', letterSpacing: '0.3px' }}>{text}</div>
);

Callout.propTypes = {
    text: PropTypes.string.isRequired,
};

export default Callout;
