import React from 'react';
import { string } from 'prop-types';
import FlipMove from 'react-flip-move';
import ErrorBoundary from '../error-boundary';

import './notifications.scss';

const Notifications = ({ message }) => (
    <ErrorBoundary>
        <div className="notifications">
            <FlipMove>
                {message !== null && <p className="notifications-message">{message}</p>}
            </FlipMove>
        </div>
    </ErrorBoundary>
);

Notifications.defaultProps = {
    message: null,
};

Notifications.propTypes = {
    message: string,
};

export default Notifications;
