const appendRefreshLabel = (googletag, isRefresh) => {
    if (isRefresh) {
        const slots = googletag.pubads().getSlots();
        slots.forEach((slot) => {
            slot.setTargeting('houseRefresh', 'true');
        });
    }
};

export default appendRefreshLabel;
