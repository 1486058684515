import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@gumtree/ui-library';
import FooterLink from './footer-link';
import { FooterLinkTitle, FooterAppLinksWrapper } from './style';

export const iOSNames = /** @type {const} */ (['ios-app', 'download-mobile-apps']);

export const androidNames = /** @type {const} */ (['android-app', 'download-mobile-apps']);

/**
 * @param {{
 *   sections: import('./footer-data.json')['apps'];
 *   device: import('../reducers/common/base-config').DeviceMeta;
 *   trackAndNavigate: (event: string, url: string, eventLabel?: string) => void;
 * }} param0
 */
const FooterAppLinks = ({ sections, device: { os }, trackAndNavigate }) => (
    <Grid container>
        <Grid item col={10} offset={1}>
            <FooterAppLinksWrapper>
                {sections.map((section, sectionIndex) => (
                    <section key={sectionIndex}>
                        <FooterLinkTitle>{section.title}</FooterLinkTitle>
                        {section.items &&
                            section.items.map((item, itemIndex) => (
                                <Fragment key={itemIndex}>
                                    {os === 'IOS' && iOSNames.includes(item.name) && (
                                        <FooterLink
                                            key={itemIndex}
                                            eventAction={item.eventAction}
                                            eventLabel={item.eventLabel}
                                            trackAndNavigate={trackAndNavigate}
                                            image={item.image}
                                            label={item.label}
                                            link={item.link}
                                            name={item.name}
                                            tagAttributes={item.tagAttributes}
                                        />
                                    )}
                                    {os === 'ANDROID' && androidNames.includes(item.name) && (
                                        <FooterLink
                                            key={itemIndex}
                                            eventAction={item.eventAction}
                                            eventLabel={item.eventLabel}
                                            trackAndNavigate={trackAndNavigate}
                                            image={item.image}
                                            label={item.label}
                                            link={item.link}
                                            name={item.name}
                                            tagAttributes={item.tagAttributes}
                                        />
                                    )}
                                </Fragment>
                            ))}
                    </section>
                ))}
            </FooterAppLinksWrapper>
        </Grid>
    </Grid>
);

FooterAppLinks.propTypes = {
    device: PropTypes.object.isRequired,
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    image: PropTypes.string,
                    label: PropTypes.string,
                    link: PropTypes.string,
                    name: PropTypes.string,
                    tagAttributes: PropTypes.object,
                })
            ),
            title: PropTypes.string,
        })
    ),
    trackAndNavigate: PropTypes.func.isRequired,
};

FooterAppLinks.defaultProps = {
    sections: [],
};

export default FooterAppLinks;
