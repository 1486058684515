// eslint-disable import/no-unresolved
import * as Sentry from '@sentry/react';
import logger from '../logger';

const initSentry = (isDev, environment, sentryDSN, experiments, appViewDevice) => {
    if (!experiments['GTP-2718'] || experiments['GTP-2718'] !== 'B') {
        logger.debug(`Not initialising Sentry as you're currently running within the experiment`);
        return;
    }

    if (!appViewDevice) {
        // Only enabling Sentry for AppViews just now.
        logger.debug(`Not initialising Sentry as you're not running within an AppView`);
        return;
    }

    if (isDev) {
        logger.debug(
            `Not initialising Sentry as you're currently running within a DEV environment.`
        );
        return;
    }

    logger.debug(`Initialising Sentry`);

    Sentry.init({
        dsn: sentryDSN,
        environment: `${environment}`,
    });

    window.addEventListener('error', (event) => {
        Sentry.captureException(event);
    });
};

export default initSentry;
