import { clearAllByKey, loadByKey, removeSingleByKey, saveByKey } from './recent-service';

export const RECENT_LOCATIONS_KEY = 'recentLocations';

export type RecentLocationsItem = {
    name: string;
};

export const save = (locations: RecentLocationsItem) =>
    saveByKey(RECENT_LOCATIONS_KEY, locations, 5);

export const load = (limit = 5) => loadByKey(RECENT_LOCATIONS_KEY, limit);

export const removeSingleFromHistory = (text: string) => {
    removeSingleByKey(RECENT_LOCATIONS_KEY, text);
};

export const clearAllFromHistory = () => {
    clearAllByKey(RECENT_LOCATIONS_KEY);
};
