import { WindowWithAdSense, WindowWithClientData } from '@gumtree/shared/src/types/window';

export const initAdSenseAds = (window: WindowWithAdSense & WindowWithClientData) => {
    const pageOptions = {
        pubId: 'partner-pub-8646360968412482',
        query: (window as WindowWithClientData).clientData?.adsenseAds?.query,
        styleId: '8681725653',
        adtest: 'on',
    };

    const slot12Id = 'slot12';
    const slot12Config = {
        container: slot12Id,
        maxTop: 1,
    };
    const hasSlot12 = Boolean(window.document.getElementById(slot12Id));

    const slot15Id = 'slot15';
    const slot15Config = {
        container: slot15Id,
        number: 1,
    };
    const hasSlot15 = Boolean(window.document.getElementById(slot15Id));

    const slotConfigs = [
        ...(hasSlot12 ? [slot12Config] : []),
        ...(hasSlot15 ? [slot15Config] : []),
    ];

     
    window._googCsa('ads', pageOptions, ...slotConfigs);
};
