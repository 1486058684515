import { getCategorySeoNameByLevel } from './get-category';
import { getLocationSeoNameByLevel } from './get-location';

const init = (window) => {
    window.gumtreeApi = window.gumtreeApi || {};

    const gumtreeApi = {
        getCategorySeoNameByLevel,
        getLocationSeoNameByLevel,
    };

    window.gumtreeApi = gumtreeApi;

    return gumtreeApi;
};

export default init;
