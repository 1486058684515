import filterByProbability from './filter-by-probability';

const maxElapsed = 2 * 60 * 1000; // 2 minutes max

const attachPerformanceTracker = (googletag, ga, performance) => {
    googletag.cmd.push(() => {
        googletag.pubads().addEventListener('slotRenderEnded', (event) => {
            const timingValue = Math.round(performance.now());
            const timingLabel = event.slot.getSlotElementId();

            if (timingValue > maxElapsed) {
                return;
            }

            ga('send', {
                hitType: 'timing',
                timingCategory: 'ADs',
                timingVar: 'slotRenderEnded',
                timingValue,
                timingLabel,
            });
        });
    });
};

const trackAdsPerformance = ({ googletag, ga, performance }) => {
    /*
        this code requires ga which is at the time of writing loads before
        this function is invoked; in case that changes we need to migrate
        GA from GTM to our code as an inline script so that is always available
    */
    if (!performance || !googletag || !ga) {
        return;
    }

    // google analytics samples 1% of calls - we want 0.1%,
    // so we're only gathering 10% of requests
    const shouldRunTracker = filterByProbability({
        probability: 10,
    });

    if (shouldRunTracker) {
        attachPerformanceTracker(googletag, ga, performance);
    }
};

export default trackAdsPerformance;
