import React from 'react';
import PropTypes from 'prop-types';
import ExternalLink from '../../../common/external-link';

const SiteLink = ({ url, children }) => {
    const styles = {
        fontSize: '13px',
        letterSpacing: '0.3px',
        color: '#007fb0',
        textDecoration: 'none',
        cursor: 'pointer',
    };

    return (
        <ExternalLink url={url} style={styles}>
            {children}
        </ExternalLink>
    );
};

SiteLink.propTypes = {
    children: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default SiteLink;
