import { css } from "@emotion/react";
import { boxSizes, breakpoints, colors, gutterSizes, mediaQuery } from "../base/theme";

export const postAdButtonCss = css`
    .button--post-ad {
        color: ${colors.bark};
        background-color: transparent;
        border-color: transparent;
        overflow: hidden;
        min-width: ${boxSizes.boxHeight};
        width: ${boxSizes.boxHeight};

        .button + & {
            margin-left: ${gutterSizes.small};
        }

        &.button--dark {
            color: ${colors.white};
        }

        .icon {
            color: ${colors.white};
            line-height: 18px;
            transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            margin: 0 ${gutterSizes.medium} 0 ${gutterSizes.small};
        }

        ${mediaQuery.from(breakpoints.small)} {
            border-color: ${colors.berry};
            width: auto;

            .icon {
                color: ${colors.berry};
            }

            .button + & {
                margin-left: 0;
            }
        }

        &:active,
        &:hover {
            color: ${colors.white};
            background-color: ${colors.berry};

            .icon {
                ${mediaQuery.from(breakpoints.xsmall)} {
                    &:before {
                        color: ${colors.white};
                        transform: rotate(30deg);
                    }
                }

                ${mediaQuery.from(breakpoints.small)} {
                    color: ${colors.white};
                    transform: rotate(30deg);
                }
            }
        }

        &:disabled {
            color: ${colors.bark40};
            background-color: ${colors.branch10};
            border-color: ${colors.branch20};

            .icon {
                color: ${colors.bark40};
            }

            &:hover .icon {
                transform: none;
            }
        }
    }
`;
