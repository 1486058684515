import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../../common/image';

const Thumbnail = ({ imageSrc, description }) => (
    <div
        style={{
            width: '100%',
            float: 'left',
            marginBottom: '8px',
            overflow: 'hidden',
            position: 'relative',
            border: '1px solid #d8d6d9',
            height: window?.innerWidth >= 768 ? '92px' : '77px',
        }}
    >
        <Image
            src={imageSrc}
            description={description}
            style={{
                width: 'auto',
                maxWidth: 'inherit',
                position: 'absolute',
                height: '100%',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%,-50%)',
                display: 'block',
            }}
        />
    </div>
);

Thumbnail.propTypes = {
    description: PropTypes.string.isRequired,
    imageSrc: PropTypes.string.isRequired,
};

export default Thumbnail;
