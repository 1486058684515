export const getUrlSearchParamsObjectFromAnyObject = (input: object) =>
    new URLSearchParams(input as Record<string, string>);

const removeTrailingSlash = (url: string) => url.replace(/\/\?/, '?');

export const updateUrlWithKeyValue = (
    originalUrl: string | undefined,
    key: string | undefined,
    value: string | undefined
): typeof originalUrl => {
    if (!originalUrl || !key || value === undefined) {
        return originalUrl;
    }

    // Check if URL has query parameters
    const questionMarkIndex = originalUrl.indexOf('?');
    const hasQuery = questionMarkIndex !== -1;

    // Prepare the base URL and existing query parameters
    const baseUrl = hasQuery ? originalUrl.substring(0, questionMarkIndex) : originalUrl;
    const existingParams = hasQuery ? originalUrl.substring(questionMarkIndex + 1) : '';
    const searchParams = new URLSearchParams(existingParams);

    // Update or add the query parameter
    searchParams.set(key, value);

    // Construct the updated URL
    const updatedQuery = searchParams.toString();
    const updatedUrl = baseUrl + (updatedQuery ? `?${updatedQuery}` : '');

    return removeTrailingSlash(updatedUrl.toString());
};

export const removeFalsyProperties = (obj: Record<string, any>): Record<string, any> => {
    // Create a new object to store the filtered properties
    const filteredObject: Record<string, any> = {};

    // Use Object.entries to iterate over the key-value pairs
    Object.entries(obj).forEach(([key, value]) => {
        // If the value is truthy, add it to the filtered object
        if (value) {
            filteredObject[key] = value;
        }
    });

    return filteredObject;
};

export const getNonFalsyUrlSearchParams = (input: Record<string, any>) =>
    getUrlSearchParamsObjectFromAnyObject(removeFalsyProperties(input));
