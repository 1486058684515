import React from 'react';
import PropTypes from 'prop-types';

import Side from '../../../common/side';
import Content from '../../../common/content';
import NoImageThumbnail from '../../../common/no-image-thumbnail';
import ExternalLink from '../../../common/external-link';

import Title from '../../../common/title';
import Description from '../../../common/description';
import DisplayUrl from './display-url';
import Rating from './rating';
import SiteLinkList from './site-link-list';
import Callout from './callout';
import ClickToCallNumbers from './click-to-call-numbers';
import TextAdWrapper from './text-ad-wrapper';

const TextAd = ({
    num,
    url,
    title,
    displayUrl,
    description,
    merchantRating,
    siteLinks,
    callout,
    clickToCallNumbers,
    onClick,
}) => {
    const isDesktop = window?.innerWidth >= 768;

    const sideStyle = {
        float: 'left',
        width: isDesktop ? '120px' : '100px',
        marginRight: '16px',
    };

    const externalLinkStyle = {
        display: 'block',
        borderRadius: '4px',
        height: '100%',
    };

    const hasMerchantRating = merchantRating.numberOfRatings > 0;
    const hasSiteLinks = siteLinks.length > 0;
    const hasCallout = !!callout;
    const hasClickToCallNumbers = clickToCallNumbers.length > 0;

    if (!isDesktop) {
        return (
            <TextAdWrapper num={num}>
                <Content>
                    <ExternalLink url={url} onClick={onClick} style={externalLinkStyle}>
                        <Title text={title} />
                        <Description isExperimentView>{description}</Description>
                        <DisplayUrl url={displayUrl} />
                    </ExternalLink>
                    {hasSiteLinks && <SiteLinkList siteLinks={[siteLinks[0]]} />}
                </Content>
            </TextAdWrapper>
        );
    }

    return (
        <TextAdWrapper num={num}>
            <Side style={sideStyle}>
                <ExternalLink url={url} onClick={onClick}>
                    <NoImageThumbnail />
                </ExternalLink>
            </Side>
            <Content>
                <ExternalLink url={url} onClick={onClick} style={externalLinkStyle}>
                    <Title text={title} />
                    <Description>{description}</Description>
                    <DisplayUrl url={displayUrl} />
                </ExternalLink>
                {hasMerchantRating && <Rating {...merchantRating} />}
                {hasSiteLinks && <SiteLinkList siteLinks={siteLinks} />}
                {hasCallout && <Callout text={callout} />}
                {hasClickToCallNumbers && <ClickToCallNumbers numbers={clickToCallNumbers} />}
            </Content>
        </TextAdWrapper>
    );
};

TextAd.defaultProps = {
    abTests: [],
    callout: '',
    clickToCallNumbers: [],
    merchantRating: { domain: '', numberOfRatings: 0, rating: 0 },
    onClick: () => {},
    siteLinks: [],
};

TextAd.propTypes = {
    abTests: PropTypes.arrayOf(PropTypes.string),
    callout: PropTypes.string,
    clickToCallNumbers: PropTypes.array,
    description: PropTypes.string.isRequired,
    displayUrl: PropTypes.string.isRequired,
    merchantRating: PropTypes.object,
    num: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    siteLinks: PropTypes.array,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default TextAd;
