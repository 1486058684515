import React from 'react';
import PropTypes from 'prop-types';
import SiteLink from './site-link';

const SiteLinkList = ({ siteLinks }) => (
    <div style={{ marginBottom: '6px' }}>
        {siteLinks.map((siteLink, i) => (
            <span key={siteLink.text}>
                <SiteLink url={siteLink.link}>{siteLink.text}</SiteLink>
                {i < siteLinks.length - 1 && <span> - </span>}
            </span>
        ))}
    </div>
);

SiteLinkList.propTypes = {
    siteLinks: PropTypes.array.isRequired,
};

export default SiteLinkList;
