import { addClass, removeClass } from '@gumtree/ui-library/src/utils/browser-service';
import Cookies from 'js-cookie';

const bannerTakeoverOpen = () => {
    addClass(document.body, 'banner-open');
    addClass(document.querySelector('html'), 'banner-open');
};

const bannerTakeoverClose = () => {
    removeClass(document.body, 'banner-open');
    removeClass(document.querySelector('html'), 'banner-open');
};

const setAppBannerCookie = (domain, inExperiment) => {
    if (
        inExperiment &&
        (Cookies.get('AppBannerTakeover') !== 'notShowing' ||
            Cookies.get('AppBannerTakeover') === undefined)
    ) {
        Cookies.set('AppBannerTakeover', 'showing', { domain });
    }
};

export { bannerTakeoverClose, bannerTakeoverOpen, setAppBannerCookie };
