import { DfpServerConfig } from './advertising/dfp';
import { AdsenseConfig } from './advertising/adsense';
import { BingConfig } from './advertising/bing';
import { PartnershipsClientDataConfig } from './advertising/partnership';

export enum DeviceTypes {
    DESKTOP = 'desktop',
    MOBILE = 'mobile',
    TABLET = 'tablet',
}

export type Device = 'mobile' | 'tablet' | 'desktop';
export type PageType = GA4.PageType;

export type ExperimentVariants = 'A' | 'B' | 'C' | 'D' | 'E';
export type ExperimentName = `${'GTALL' | 'GTP' | 'GTCO' | 'GTF'}-${string}`;
export type Experiments = { [key: ExperimentName]: ExperimentVariants };

export type L1Category =
    | 'for-sale'
    | 'toplevel'
    | 'community'
    | 'flats-houses'
    | 'business-services'
    | 'cars-vans-motorbikes'
    | 'jobs'
    | 'pets';

export type L2Category =
    | 'commercial'
    | 'trucks'
    | 'plant-tractors'
    | 'cars'
    | 'vans'
    | 'business-office-services'
    | 'telecoms-computer-services'
    | 'tax-money-visa-services'
    | 'pet-services-supplies'
    | 'building-home-removal-services'
    | 'property-to-rent'
    | 'property-to-share'
    | 'holiday-rentals'
    | 'pets-for-sale'
    | 'pet-equipment-accessories'
    | 'motors-parts'
    | 'motors-accessories'
    | 'motorbikes-scooters'
    | 'caravans'
    | 'campervans-motorhomes'
    | 'other-vehicles'
    | 'property-for-sale'
    | 'international-property-for-sale'
    | 'pets-missing-lost-found'
    | 'cars-wanted'
    | 'property-wanted'
    | null
    | undefined;

export type L3Category =
    | 'commercial-property-to-rent'
    | 'commercial-property-for-sale'
    | 'garage-parking-to-rent'
    | 'garage-parking-for-sale'
    | undefined;

export type Breadcrumbs = {
    links: {
        name: string;
        url: string;
        location: boolean;
    }[];
};

export type ClientData = {
    adsenseAds?: AdsenseConfig;
    bingAds?: BingConfig;
    displayAds?: DfpServerConfig;
    experiments: Experiments;
    baseConfig: {
        pageType: PageType;
        device: {
            type: Device;
        };
        canonicalUrl: string;
    };
    resultsPage?: {
        nearbyAds?: { ads: object[]; totalNearbyAdsNumFound: number };
        searchAds: object[];
    };
    partnerships?: PartnershipsClientDataConfig | undefined;
    userData?: {
        email: string;
        userLoggedIn: boolean;
    };
    breadcrumbs?: Breadcrumbs;
};

export type Categories = {
    l1Category: L1Category | undefined;
    l2Category: L2Category | undefined;
    l3Category: L3Category | undefined;
};
