/*
	Simple Promise-based fetch using the standard window.XMLHttpRequest API

	- sends a POST AJAX request to given URL
	- assumes that the response payload is in JSON format
	- rejects Promise on any error, when status code is not 200 or it timed out

	Usage:

	import factory from `frontend/common/post-json`;
	const fetch = factory(window.XMLHttpRequest);
	const timeout = 5000;
	fetch('http://example.com/api', timeout)
		.then(response => console.log(JSON.stringify(response)))
		.catch(err => console.err(err));
*/

export default (Request) => (url, timeout, params) => {
    return new Promise((resolve, reject) => {
        const request = new Request();
        request.onload = function onload() {
            if (this.status >= 200 && this.status < 300) {
                resolve(JSON.parse(this.responseText || '{}'));
            } else {
                if (this.responseText) {
                    resolve(JSON.parse(this.responseText || '{}'));
                } else {
                    reject(`${this.status} ${this.statusText}`);
                }
            }
        };
        request.onerror = function onerror() {
            if (this.status === 409) {
                reject('conflict error');
            } else {
                reject('request error');
            }
        };
        request.ontimeout = function ontimeout() {
            reject('timeout error');
        };
        request.open('POST', url);
        request.setRequestHeader('Content-type', 'application/json');
        request.timeout = timeout;
        request.send(JSON.stringify(params));
    });
};
