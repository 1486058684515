import React from 'react';
import PropTypes from 'prop-types';

const OriginalPrice = ({ overridden, currency, value, isInline }) => {
    const style = {
        base: {
            color: '#3c3241',
        },
        strikeThrough: {
            textDecoration: 'line-through',
            fontSize: '14px',
            color: '#8a848d',
        },
    };

    const normal = <strong style={style.base}>{`${currency}${value}`}</strong>;
    const strikeThrough = isInline ? (
        <span style={{ ...style.strikeThrough, paddingLeft: '5px' }}>{`${currency}${value}`}</span>
    ) : (
        <div style={style.strikeThrough}>{`${currency}${value}`}</div>
    );

    return overridden ? strikeThrough : normal;
};

OriginalPrice.defaultProps = {
    overridden: false,
};

OriginalPrice.propTypes = {
    currency: PropTypes.string.isRequired,
    isInline: PropTypes.bool,
    overridden: PropTypes.bool,
    value: PropTypes.number.isRequired,
};

export default OriginalPrice;
