import styled from '@emotion/styled';
import {
    colorVariables,
    fontSizes,
    mediaQuery,
    breakpoints,
    gutterSizes,
    colors,
} from '@gumtree/ui-library/src/base/theme';

export const FooterCopyrightContainer = styled.p`
    color: ${colorVariables.fontQuaternary};
    font-size: ${fontSizes.small};
    text-align: center;
    letter-spacing: 0.01em;
    line-height: 20px;

    a {
        color: ${colors.blue40};
    }
`;

export const FooterCopyrightText = styled.span`
    display: block;
    margin: ${gutterSizes.xxlarge} 0 ${gutterSizes.xlarge};

    ${mediaQuery.until(breakpoints.medium)} {
        margin: ${gutterSizes.xlarge} 0 ${gutterSizes.large};
    }
`;

export const FooterCopyrightLink = styled.a`
    color: ${colorVariables.fontQuaternary};
    text-decoration: underline;
`;
