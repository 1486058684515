 
import { oneTrustLocalStorageKeyName, oneTrustLocalStorageValue } from './constants';

const googleVendorId = 755;

export interface WindowWithCmp extends Window {
    __cmp?: any;
    __tcfapi?: any;
    OnetrustActiveGroups?: string;
}

const checkTcfapiExists = (window: WindowWithCmp) => {
    if (!('__tcfapi' in window)) {
        throw new Error('__tcfapi is not defined');
    }
};

export type CoarseConsent = '0' | '1' | '2' | '3'; // DECLINED: '0', ACCEPTED: '1', IMPLICITLY_ACCEPTED: '2', NOT_YET_CONSENTED: '3'

type ConsentData = {
    cmpId: number;
    cmpVersion: number;
    consentLanguage: string;
    consentScreen: number;
    gdprApplies: boolean;
    hasGlobalScope: boolean;
    metadata: object;
    purposeConsents: { [key: number]: boolean };
    vendorConsents: { [key: number]: boolean };
    vendorListVersion: number;
    hasAcceptedAll: boolean;
    facebookConsent: boolean;
    customVendorConsents: { [key: number]: boolean };
    coarseConsent: CoarseConsent;
};

type TCData = {
    tcString: 'base64url-encoded TC string with segments';
    tcfPolicyVersion: 2;
    cmpId: 1000;
    cmpVersion: 1000;
    gdprApplies: boolean | undefined;
    eventStatus: string;
    cmpStatus: 'string';
    listenerId: number | undefined;
    isServiceSpecific: boolean;
    useNonStandardStacks: boolean;
    publisherCC: 'Two-letter ISO 3166-1 alpha-2 code';
    purposeOneTreatment: boolean;
    outOfBand: {
        allowedVendors: {
            [vendorId: number]: boolean;
        };
        discloseVendors: {
            [vendorId: number]: boolean;
        };
    };
    purpose: {
        consents: {
            [purposeId: number]: boolean;
        };
        legitimateInterests: {
            [purposeId: number]: boolean;
        };
    };
    vendor: {
        consents: {
            [vendorId: number]: boolean;
        };
        legitimateInterests: {
            [vendorId: number]: boolean;
        };
    };
    specialFeatureOptins: {
        [specialFeatureId: number]: boolean;
    };
    publisher: {
        consents: {
            [purposeId: number]: boolean;
        };
        legitimateInterests: {
            [purposeId: number]: boolean;
        };
        customPurpose: {
            consents: {
                [purposeId: number]: boolean;
            };
            legitimateInterests: {
                [purposeId: number]: boolean;
            };
        };
        restrictions: {
            [purposeId: number]: {
                [vendorId: number]: 1;
            };
        };
    };
};

export const hasAcceptedToAll = (
    vendorConsents?: {
        [vendorId: number]: boolean;
    },
    purposeConsents?: {
        [purposeId: number]: boolean;
    }
) => {
    if (!vendorConsents || !purposeConsents || Object.keys(vendorConsents).length === 0) {
        return false;
    }

    const allPurposeConsented = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].every((_) => purposeConsents[_]);
    const isGoogleConsented = vendorConsents[googleVendorId];
    return isGoogleConsented && allPurposeConsented;
};

const isUserAlreadyImplicitlyConsented = (window: Window) =>
    window.localStorage?.getItem(oneTrustLocalStorageKeyName) === oneTrustLocalStorageValue;

export const getConsentData: (window: WindowWithCmp) => Promise<ConsentData | TCData | any> = (
    window
) =>
    new Promise((resolve: any, reject: any) => {
        if (window.__tcfapi) {
            checkTcfapiExists(window);
            (
                window as {
                    __tcfapi: (
                        command: 'addEventListener',
                        version: number | null,
                        callback: (data: TCData, success: boolean) => void
                    ) => void;
                }
            ).__tcfapi('addEventListener', 2, (tcData, success) => {
                if (success) {
                    const { vendor, purpose, eventStatus } = tcData;
                    let coarseConsent: CoarseConsent = '0';
                    if (hasAcceptedToAll(vendor.consents, purpose.consents)) {
                        if (isUserAlreadyImplicitlyConsented(window)) {
                            coarseConsent = '2';
                        } else {
                            coarseConsent = '1';
                        }
                    }

                    resolve({
                        eventStatus,
                        purposeConsents: purpose.consents,
                        vendorConsents: vendor.consents,
                        coarseConsent,
                    });
                }
            });
        } else {
            reject();
        }
    });

export const getHasUserMadeChoice = async (window: WindowWithCmp) => {
    if (window.__tcfapi) {
        const { eventStatus } = await getConsentData(window);
        return eventStatus !== 'cmpuishown';
    } else {
        throw new Error('window.__tcfapi not found. Error id: 18b');
    }
};

export enum ConsentStatus {
    CONSENTED = 'CONSENTED',
    DECLINED = 'DECLINED',
    NOT_INTERACTED_YET = 'NOT_INTERACTED_YET',
    IMPLICITLY_CONSENTED = 'IMPLICITLY_CONSENTED',
}

export type CmpProvider = 'Gumtree' | 'OneTrust';

export const getConsentStatus = async (
    window: WindowWithCmp
): Promise<{ consentStatus: ConsentStatus; cmpProvider: CmpProvider }> => {
    const hasUserMadeChoice = await getHasUserMadeChoice(window);
    let cmpProvider: CmpProvider;
    if (window.__tcfapi) {
        cmpProvider = 'OneTrust';
    } else {
        throw new Error('Neither window.__cmp nor window.__tcfapi found. Error id: 19b');
    }

    if (hasUserMadeChoice) {
        const { coarseConsent } = await getConsentData(window);
        if (parseInt(coarseConsent) === 1) {
            return {
                consentStatus: ConsentStatus.CONSENTED,
                cmpProvider,
            };
        } else if (parseInt(coarseConsent) === 2) {
            return {
                consentStatus: ConsentStatus.IMPLICITLY_CONSENTED,
                cmpProvider,
            };
        } else {
            return {
                consentStatus: ConsentStatus.DECLINED,
                cmpProvider,
            };
        }
    } else {
        return {
            consentStatus: ConsentStatus.NOT_INTERACTED_YET,
            cmpProvider,
        };
    }
};

export enum CustomPurposeConsentNames {
    Liveramp = 'LIVER',
    NonIABVendors = 'C0023',
}

export const isCustomPurposeConsented = (
    cookieValue: string | undefined,
    vendorName: CustomPurposeConsentNames
): boolean => {
    if (!cookieValue) {
        return false;
    }

    const groupsKeyValue = cookieValue
        .split('&')
        .map((_) => _.split('='))
        .find(([key, _]) => key === 'groups');

    if (groupsKeyValue && groupsKeyValue.length === 2) {
        const customGroupsAsString = groupsKeyValue[1];
        const foundVendor = customGroupsAsString
            .split(',')
            .map((_) => _.split(':'))
            .find(([key, _]) => key === vendorName);

        if (foundVendor && foundVendor.length === 2) {
            return foundVendor[1] === '1';
        }
    }

    return false;
};
