import { useEffect, useState } from 'react';
import { trackGA4Event, setLoginBeginCookie, setSocialRegistrationBeginCookie } from '@gumtree/shared/src/util/ga4-shared';
import { useSelector } from 'react-redux';
import { ShellState } from '@gumtree/shell/src/reducers/common';
import { LOGIN_APP_DISPLAY_NAME, LOGIN_PLATFORM } from '../constants';
import { getErrorMsg } from '../utils/social-errors';
import { LoginErrorType, SocialLoginPayload, SubmitSocialLoginFunction } from '../types';

declare global {
    interface Window {
        fbAsyncInit: () => void;
    }
}

declare const FB: any;

const FACEBOOK_SCRIPT_ID = 'facebook-jssdk';

const useFacebookSignIn = (submitLogin: SubmitSocialLoginFunction, type: 'login' | 'register') => {
    const facebookAppId = useSelector(({ baseConfig }: ShellState) => baseConfig.facebook_app_id);

    const facebookAppIdDefault = facebookAppId ?? '';

    const [errorMessage, setErrorMessage] = useState('');

    const setFbAsyncInit = () => {
        window.fbAsyncInit = function () {
            FB.init({
                appId: facebookAppIdDefault,
                cookie: true, // Enable cookies to allow the server to access the session.
                xfbml: true, // Parse social plugins on this webpage.
                version: 'v8.0',
            });
        };
    };

    const handleErrorsMessage = (error) => {
        trackGA4Event(
            type === 'login'
                ? {
                      event: 'user_login',
                      formName: 'user login',
                      formStep: 'user login',
                      formValidation: 'failure',
                      formError: error,
                      userAccountType: 'facebook',
                  }
                : {
                      event: 'user_registration',
                      formName: 'user registration',
                      formStep: 'user registration',
                      formValidation: 'failure',
                      formError: error,
                      userAccountType: 'facebook',
                  }
        );

        setErrorMessage(error);
    };

    const loadFacebookSdk = () => {
        if (document.getElementById(FACEBOOK_SCRIPT_ID)) {
            return;
        }

        const firstScript = document.getElementsByTagName('script')[0];
        const facebookSdk = document.createElement('script');

        facebookSdk.id = FACEBOOK_SCRIPT_ID;
        facebookSdk.src = `https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v18.0&appId=${facebookAppId}`;

        firstScript?.parentNode!.insertBefore(facebookSdk, firstScript);
    };

    const handleMissingAccessRights = () => {
        FB.api('/me/permissions', (permissions) => {
            const hasMissingAccessRights = permissions.data.some(
                (permission) => permission.status === 'declined'
            );

            const errorType = hasMissingAccessRights
                ? LoginErrorType.ShareEmailError
                : LoginErrorType.NoEmailRegistered;

            const error = getErrorMsg({
                errorType,
                appDisplayName: LOGIN_APP_DISPLAY_NAME.FACEBOOK,
            });

            return handleErrorsMessage(error);
        });
    };

    const login = (loginResponse) => {
        FB.api('/me?fields=id,email,name', async (response) => {
            if (!response.email) {
                handleMissingAccessRights();

                return;
            }

            const loginPayload: SocialLoginPayload = {
                username: response.email,
                password: loginResponse.authResponse.accessToken,
                loginPlatform: LOGIN_PLATFORM.FACEBOOK,
            };

            // user_{login,registration} events will be sent on manage/ads
            if (type === 'login') {
                setLoginBeginCookie('facebook');
            }
            if (type === 'register') {
                setSocialRegistrationBeginCookie('facebook');
            }

            submitLogin(loginPayload);
        });
    };

    const handleLoginResponse = (loginResponse) => {
        FB.getLoginStatus((response) => {
            if (response.status === 'connected') {
                return login(loginResponse);
            }

            const error = getErrorMsg({
                errorType: LoginErrorType.ShareEmailError,
                appDisplayName: LOGIN_APP_DISPLAY_NAME.FACEBOOK,
            });

            return handleErrorsMessage(error);
        });
    };

    const onLaunch = () => {
        FB.login((loginResponse) => handleLoginResponse(loginResponse), {
            scope: 'public_profile,email',
            auth_type: 'rerequest',
        });
    };

    useEffect(() => {
        if (typeof document !== 'undefined') {
            setFbAsyncInit();
            loadFacebookSdk();
        }
    }, []);

    return { onLaunch, errorMessage };
};

export default useFacebookSignIn;
