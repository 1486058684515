import { Nullable } from 'typescript-nullable';
import Partner from './partner';
import SlotName from '../common/slot-name';
import ScreenSize from '../common/screen-size';
import DeviceType from '../common/device-type';
import PageType from '../common/page-type';

export default class MediaGrid extends Partner {
    readonly bidder = 'grid';
    mapping = {
        [PageType.ResultsPage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.integratedMpu]: 48461,
                    [SlotName.rSkyT]: 48465,
                    [SlotName.rSkyT2]: 48466,
                    [SlotName.tBanner]: 48462,
                    [SlotName.rSkyB]: 48463,
                    [SlotName.rSkyB2]: 48464,
                    [SlotName.integratedListing]: 48468,
                },
                [ScreenSize.XL]: {
                    [SlotName.integratedMpu]: 48461,
                    [SlotName.rSkyT]: 48465,
                    [SlotName.rSkyT2]: 48466,
                    [SlotName.tBanner]: 48462,
                    [SlotName.rSkyB]: 48463,
                    [SlotName.rSkyB2]: 48464,
                    [SlotName.integratedListing]: 48468,
                },
                [ScreenSize.L]: {
                    [SlotName.integratedMpu]: 48461,
                    [SlotName.tBanner]: 48462,
                    [SlotName.integratedListing]: 48468,
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.integratedMpu]: 48459,
                    [SlotName.tBanner]: 48469,
                    [SlotName.integratedListing]: 48470,
                },
                [ScreenSize.M]: {
                    [SlotName.integratedMpu]: 48459,
                    [SlotName.tBanner]: 48469,
                    [SlotName.integratedListing]: 48470,
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.integratedMpu]: 48472,
                    [SlotName.integratedListing]: 48473,
                    [SlotName.floatingFooter]: 48474,
                },
                [ScreenSize.XS]: {
                    [SlotName.integratedMpu]: 48472,
                    [SlotName.integratedListing]: 48473,
                    [SlotName.floatingFooter]: 48474,
                },
            },
        },
        [PageType.VIP]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.vipBanner]: 48478,
                    [SlotName.mpu]: 48475,
                    [SlotName.vipMiddleDesktop]: 48476,
                },
                [ScreenSize.XL]: {
                    [SlotName.vipBanner]: 48478,
                    [SlotName.mpu]: 48475,
                    [SlotName.vipMiddleDesktop]: 48476,
                },
                [ScreenSize.L]: {
                    [SlotName.vipBanner]: 48478,
                    [SlotName.mpu]: 48475,
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.vipBanner]: 48479,
                    [SlotName.mpu]: 48480,
                    [SlotName.fBanner]: 48477,
                },
                [ScreenSize.M]: {
                    [SlotName.vipBanner]: 48479,
                    [SlotName.mpu]: 48480,
                    [SlotName.fBanner]: 48477,
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.floatingFooter]: 48481,
                    [SlotName.vipMiddleMobile]: 48482,
                },
                [ScreenSize.XS]: {
                    [SlotName.floatingFooter]: 48481,
                    [SlotName.vipMiddleMobile]: 48482,
                },
            },
        },
        [PageType.Homepage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.homeSideAd]: 48458,
                },
                [ScreenSize.XL]: {
                    [SlotName.homeSideAd]: 48458,
                },
                [ScreenSize.L]: {
                    [SlotName.homeSideAd]: 48458,
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.homeSideAd]: 48467,
                },
                [ScreenSize.M]: {
                    [SlotName.homeSideAd]: 48467,
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.homeBanner]: 48460,
                },
                [ScreenSize.XS]: {
                    [SlotName.homeBanner]: 48460,
                },
            },
        },
    };

    toObject() {
        if (Nullable.isNone(this.getPlacement())) {
            return undefined;
        }

        return {
            bidder: this.bidder,
            params: {
                uid: this.getPlacement(),
            },
        };
    }
}
