import { Device, L1Category, PageType } from '@gumtree/shared/src/types/client-data';

type ScreenSize = 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs';

const ALL_CATEGORIES = [
    'for-sale',
    'jobs',
    'business-services',
    'cars-vans-motorbikes',
    'flats-houses',
    'community',
    'pets',
];

const ALL_SCREEN_SIZES: ScreenSize[] = ['xxl', 'xl', 'l', 'm', 's', 'xs'];

const whitelist = (): {
    slotId: string;
    devices: string[];
    screenSizes: string[];
    categories: string[];
    pageTypes: (PageType | 'MyAds' | 'home')[];
}[] => [
    {
        slotId: 'floatingFooter',
        devices: ['mobile'],
        screenSizes: ALL_SCREEN_SIZES,
        categories: ALL_CATEGORIES,
        pageTypes: ['ResultsBrowse', 'ResultsSearch', 'VIP', 'eVIP', 'pVIP'],
    },
];

export const getWhitelistedSlotIds = (
    device: Device,
    l1Category: L1Category,
    pageType: PageType,
    screenSize: ScreenSize
) => {
    if (!l1Category) {
        return;
    }
    return whitelist()
        .filter(
            (item) =>
                item.devices.includes(device) &&
                item.categories.includes(l1Category) &&
                item.pageTypes.includes(pageType) &&
                item.screenSizes.includes(screenSize)
        )
        .map((item) => item.slotId);
};
