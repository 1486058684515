import { Nullable } from 'typescript-nullable';
import Partner from './partner';
import SlotName from '../common/slot-name';
import ScreenSize from '../common/screen-size';
import DeviceType from '../common/device-type';
import PageType from '../common/page-type';

export default class Pubmatic extends Partner {
    readonly bidder = 'pubmatic';
    readonly publisherId = '156407';
    mapping = {
        [PageType.ResultsPage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.integratedMpu]: 'srp_middle1',
                    [SlotName.rSkyT]: 'desktop_srp_topright',
                    [SlotName.rSkyT2]: 'desktop_srp_topright2',
                    [SlotName.tBanner]: 'desktop_srp_top_new',
                    [SlotName.rSkyB]: 'srp_bottomright',
                    [SlotName.rSkyB2]: 'desktop_srp_bottomright2',
                    [SlotName.integratedListing]: 'srp_middle2',
                },
                [ScreenSize.XL]: {
                    [SlotName.integratedMpu]: 'srp_middle1',
                    [SlotName.rSkyT]: 'desktop_srp_topright',
                    [SlotName.rSkyT2]: 'desktop_srp_topright2',
                    [SlotName.tBanner]: 'desktop_srp_top_new',
                    [SlotName.rSkyB]: 'srp_bottomright',
                    [SlotName.rSkyB2]: 'desktop_srp_bottomright2',
                    [SlotName.integratedListing]: 'srp_middle2',
                },
                [ScreenSize.L]: {
                    [SlotName.integratedMpu]: 'srp_middle1',
                    [SlotName.tBanner]: 'desktop_srp_top_new',
                    [SlotName.integratedListing]: 'srp_middle2',
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.integratedMpu]: 'srp_middle1_Tablet',
                    [SlotName.tBanner]: 'srp_top_Tablet',
                    [SlotName.integratedListing]: 'srp_middle2_Tablet',
                },
                [ScreenSize.M]: {
                    [SlotName.integratedMpu]: 'srp_middle1_Tablet',
                    [SlotName.tBanner]: 'srp_top_Tablet',
                    [SlotName.integratedListing]: 'srp_middle2_Tablet',
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.integratedMpu]: 'srp_middle1_MobileWeb',
                    [SlotName.integratedListing]: 'srp_middle2_Mobileweb',
                    [SlotName.floatingFooter]: 'srp_floating_MobileWeb',
                },
                [ScreenSize.XS]: {
                    [SlotName.integratedMpu]: 'srp_middle1_MobileWeb',
                    [SlotName.integratedListing]: 'srp_middle2_Mobileweb',
                    [SlotName.floatingFooter]: 'srp_floating_MobileWeb',
                },
            },
        },
        [PageType.VIP]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.vipBanner]: 'desktop_vip_top_new',
                    [SlotName.mpu]: 'desktop_vip_topright',
                    [SlotName.vipMiddleDesktop]: 'srp_topright',
                },
                [ScreenSize.XL]: {
                    [SlotName.vipBanner]: 'desktop_vip_top_new',
                    [SlotName.mpu]: 'desktop_vip_topright',
                    [SlotName.vipMiddleDesktop]: 'srp_topright',
                },
                [ScreenSize.L]: {
                    [SlotName.vipBanner]: 'desktop_vip_top_new',
                    [SlotName.mpu]: 'desktop_vip_topright',
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.vipBanner]: 'tablet_vip_top_new',
                    [SlotName.mpu]: 'vip_topright',
                    [SlotName.fBanner]: 'vip_bottom',
                },
                [ScreenSize.M]: {
                    [SlotName.mpu]: 'vip_topright',
                    [SlotName.fBanner]: 'vip_bottom',
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.floatingFooter]: 'vip_floating_MobileWeb',
                    [SlotName.vipMiddleMobile]: 'vip_bottom_MobileWeb',
                },
                [ScreenSize.XS]: {
                    [SlotName.floatingFooter]: 'vip_floating_MobileWeb',
                    [SlotName.vipMiddleMobile]: 'vip_bottom_MobileWeb',
                },
            },
        },
        [PageType.Homepage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.homeSideAd]: 'hp',
                },
                [ScreenSize.XL]: {
                    [SlotName.homeSideAd]: 'hp',
                },
                [ScreenSize.L]: {
                    [SlotName.homeSideAd]: 'hp',
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.M]: {
                    [SlotName.homeSideAd]: 'hp_Tablet',
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.homeBanner]: 'hp_MobileWeb',
                },
                [ScreenSize.XS]: {
                    [SlotName.homeBanner]: 'hp_MobileWeb',
                },
            },
        },
    };

    toObject() {
        if (Nullable.isNone(this.getPlacement())) {
            return undefined;
        }

        return {
            bidder: this.bidder,
            params: {
                adSlot: this.getPlacement(),
                publisherId: this.publisherId,
            },
        };
    }
}
