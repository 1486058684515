import React from 'react';

import Link from '../link/link';

import './link-list.scss';

export default function LinkList({ items, onClickItem }: Props) {
    return (
        <ul className="link-list">
            {items.map((item, index) => (
                <li key={index} className="item">
                    <Link {...item} onClick={(e) => onClickItem?.(e, item)} title={item?.text} />
                </li>
            ))}
        </ul>
    );
}

interface Props {
    items: { href: string; text: string }[];
    onClickItem?(e: React.MouseEvent, item: Props['items'][0]): void;
}
