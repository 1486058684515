import React, { useEffect, useState, useRef, MutableRefObject } from 'react';
import styled from '@emotion/styled';
import classNames from 'classnames';

import { Button, Icon } from '@gumtree/ui-library';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import { breakpoints, colorVariables, mediaQuery } from '@gumtree/ui-library/src/base/theme';
import { trackV2 } from '@gumtree/shared/src/util/track-ga-event';
import { trackGA4Event } from '@gumtree/shared/src/util/ga4-shared';
import { isMobile } from '@gumtree/shared/src/util/breakpoints-service';

import { NavBarButtonOverride } from '../nav-bar/common.style';
import UserSubMenu from './user-sub-menu';

export default function UserMenu(props: Props) {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef() as MutableRefObject<HTMLInputElement>;

    const useOnClickOutside = (ref, handler) => {
        useEffect(() => {
            const listener = (event) => {
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }

                handler(event);
            };

            document && document.addEventListener('click', listener);

            return () => {
                document.removeEventListener('click', listener);
            };
        }, [ref, handler]);
    };

    useOnClickOutside(ref, () => setIsOpen(false));

    const toggleSubMenu = () => {
        const next = !isOpen;
        setIsOpen(next);
        trackGA4Event<GA4.ClickBurgerMenu>({
            event: 'click_burger_menu',
            clickMenuAction: next ? 'open' : 'close',
            breakpointSize: isMobile() ? 'mobile' : 'desktop',
        });
    };

    return (
        <StyledWrapper
            ref={ref}
            className={classNames('user-menu', {
                opened: isOpen,
            })}
        >
            <StyledButton
                bottomLabel
                className={classNames({
                    'user-logged-in': props.isLoggedIn,
                })}
                dark
                onClick={() => {
                    toggleSubMenu();
                    trackV2('HeaderMenuSelect');
                }}
                icon={<Icon type="burger" />}
                label="Menu"
                {...qaAttribute('user-menu-button')}
            />
            <UserSubMenu
                isOpen={isOpen}
                buyerUrl={props.buyerUrl}
                sellerUrl={props.sellerUrl}
                experiments={props.experiments}
            />
        </StyledWrapper>
    );
}

interface Props {
    inExperimentGTCC921CoreChatRead?: boolean;
    isLoggedIn: true;
    buyerUrl: string;
    sellerUrl: string;
    experiments: { [key: string]: string };
}

const StyledWrapper = styled.div`
    position: relative;
    display: none;

    &.opened {
        background: ${colorVariables.textNeutral};
    }

    ${mediaQuery.from(breakpoints.medium)} {
        display: inline-block;
    }
`;

export const StyledButton = styled(Button)`
    && {
        ${NavBarButtonOverride}
    }

    .opened & {
        &:after {
            content: '';
            display: block;
            border: 13px solid ${colorVariables.textNeutral};
            border-style: solid;
            height: 100%;
            width: 100%;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
        }
    }
`;
