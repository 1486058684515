import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import { redirectToUrl } from '@gumtree/shared/src/util/browser-service';
import OnlyRenderOn from '@gumtree/shared/src/only-render-on/only-render-on';
import { createTheme } from '@gumtree/ui-library/src/base/themes/theme-factory';

import Header from './header/header-container';
import Footer from './footer/footer';
import Content from './content/content-container';
import View from './view';
import BrowseLinks from './browse-links/container';
import NotificationsContainer from './notifications/notifications-container';
import AppBannerTakeover from './app-banner-takeover/app-banner-takeover-container';
import IEUpgradeBanner from './ie-upgrade-banner';
import ErrorBoundary from './error-boundary';
import logger from './logger';
import LocationPrompt from './location-prompt';

const handleCSRError = (e) => {
    logger.error(e);
    setTimeout(() => {
        redirectToUrl('/error/500');
    }, 3000);
};

// TODO: remove the Connect wrapper component as that only serves the purpose of the mediator
// for redirects, grabbing `url` from the store and making the redirect in the browser
const createApp = (store) => (
    <ThemeProvider theme={createTheme(store.getState())}>
        <ErrorBoundary handleError={handleCSRError}>
            <Provider store={store}>
                <Content>
                    <OnlyRenderOn web>
                        <LocationPrompt />
                        <IEUpgradeBanner />
                        <Header />
                    </OnlyRenderOn>

                    <View />

                    <OnlyRenderOn web>
                        <BrowseLinks />
                        <Footer />
                        <NotificationsContainer />
                        <AppBannerTakeover />
                    </OnlyRenderOn>
                </Content>
            </Provider>
        </ErrorBoundary>
    </ThemeProvider>
);

export default createApp;
