import React from 'react';
import PropTypes from 'prop-types';

const TopSearchCondensed = ({ topSearchCondensed }) => (
    <ul className="link-list">
        {topSearchCondensed[0].items.map((el, index) => (
            <li className="item" key={el.name + index}>
                <a className="link" href={el.url}>
                    {el.name}
                </a>
            </li>
        ))}
    </ul>
);

TopSearchCondensed.propTypes = {
    topSearchCondensed: PropTypes.array.isRequired,
};

export default TopSearchCondensed;
