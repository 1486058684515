import { trackGA4Event } from './ga4-shared';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

export function trackV2(eventName: string, eventLabel = '', others = {}) {
    if (!eventLabel && Object.keys(others).length) {
        eventLabel = Object.entries(others)
            .map(([k, v]) => `${k}=${v}`)
            .join('; ')
            .trim();
    }

    let extra: string | undefined = undefined;
    try {
        extra = JSON.stringify(others);
    } catch (_e) {
        // NOOP
    }

    trackGA4Event<GA4.GA3LegacyEvent>({
        event: 'ga3_legacy_event',
        legacyEvent: {
            name: eventName,
            label: eventLabel,
            extra,
        },
    });
}
