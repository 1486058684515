import { connect } from 'react-redux';

import { ShellState } from '../../reducers/common';
import SearchBar from './search-bar';
import { trackAndNavigate } from '../../common/common-actions';

const mapState = ({
    baseConfig: { isQA, algoliaKey, device },
    popularSearches,
    searchBar,
}: ShellState) => ({
    device: device.type,
    popularSearches,
    isQA,
    algoliaKey,
    keyword: searchBar.keyword,
    selectedKeyword: searchBar.keyword.selectedSuggestion,
    selectedLocation: searchBar.location.selectedSuggestion,
    location: searchBar.location,
    showSearchModal: searchBar.showSearchModal,
    showSearchBarOnMobile: searchBar.showSearchBarOnMobile,
});

const mapDispatchToProps = {
    trackAndNavigate,
};

export default connect(mapState, mapDispatchToProps)(SearchBar);
