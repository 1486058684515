import React from 'react';

const Logo = () => {
    return (
        <svg
            width="138"
            height="28"
            viewBox="0 0 138 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.1635 7.9316C17.9575 7.78629 17.8271 7.55884 17.8041 7.30847C17.4739 3.63789 14.3762 0.751953 10.6157 0.751953C6.85531 0.751953 3.75763 3.63789 3.42682 7.30791C3.40443 7.55884 3.27344 7.78629 3.06746 7.9316C1.21355 9.23709 0 11.3915 0 13.8242C0 17.1527 2.2726 19.9616 5.35013 20.7853V20.7859C5.87742 20.9273 6.42542 20.9921 6.61854 20.8809C7.00477 20.6579 7.4884 18.8561 7.26506 18.4705C7.15366 18.2777 6.64709 18.0603 6.11924 17.9195L6.1198 17.9189C4.30955 17.4344 2.9723 15.7824 2.9723 13.8242C2.9723 12.7339 3.38652 11.7391 4.06606 10.9869C4.37504 10.6454 4.73888 10.3537 5.14359 10.1262C5.91661 9.69256 6.37337 8.86209 6.36945 7.97686C6.36945 7.97072 6.36945 7.96513 6.36945 7.95898C6.36945 7.50072 6.44278 7.05922 6.57824 6.64566C7.13295 4.94897 8.73329 3.71949 10.6157 3.71949C12.4982 3.71949 14.098 4.94897 14.6527 6.64566C14.7882 7.05922 14.8615 7.50072 14.8615 7.95898C14.8615 7.96513 14.8615 7.97072 14.8615 7.97686C14.8581 8.86209 15.3149 9.69256 16.0879 10.1268C16.4921 10.3537 16.8559 10.6454 17.1649 10.9869C17.8444 11.7391 18.2586 12.7339 18.2586 13.8242C18.2586 16.1658 16.3471 18.0698 14.0006 18.0631C11.3026 18.0559 9.1296 20.279 9.1296 22.9721V25.7642C9.1296 26.3091 9.20852 26.8545 9.36581 27.0115C9.68152 27.3267 11.5494 27.3267 11.8651 27.0115C12.023 26.8545 12.1019 26.3091 12.1019 25.7642V22.9246C12.1019 21.8734 12.9634 21.0312 14.0163 21.0307C17.995 21.029 21.2309 17.7965 21.2309 13.8242C21.2309 11.3915 20.0174 9.23709 18.1635 7.9316Z"
                fill="#72EF36"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M63.8002 22.4256H60.5222V21.3101C59.5292 22.3015 58.2295 22.7972 56.6241 22.7972C55.134 22.7972 53.9339 22.3138 53.0238 21.347C52.113 20.3801 51.658 19.1283 51.658 17.5914V10.0547H54.9353V16.8973C54.9353 17.7731 55.1631 18.4756 55.6182 19.0042C56.0739 19.5335 56.6818 19.7978 57.4436 19.7978C59.4962 19.7978 60.5222 18.3761 60.5222 15.5337V10.0547H63.8002V22.4256Z"
                fill="#F6F5F6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M85.2719 11.0821C86.2157 12.016 86.687 13.2181 86.687 14.689V22.4242H83.4342V15.3585C83.4342 14.5487 83.2025 13.8999 82.739 13.4126C82.2755 12.9247 81.6962 12.681 81.001 12.681C80.1071 12.681 79.4034 12.999 78.8902 13.6356C78.3774 14.2721 78.1205 15.2182 78.1205 16.474V22.4242H74.8426V15.3585C74.8426 14.5487 74.6114 13.8999 74.1479 13.4126C73.6839 12.9247 73.1045 12.681 72.4093 12.681C71.5154 12.681 70.8202 12.9951 70.3237 13.6233C69.8272 14.2514 69.5786 15.2015 69.5786 16.474V22.4242H66.3013V10.0533H69.5786V11.0698C70.5056 10.1444 71.7393 9.68164 73.2786 9.68164C75.0665 9.68164 76.4239 10.359 77.3508 11.7142C78.4435 10.359 79.9498 9.68164 81.8697 9.68164C83.1941 9.68164 84.3282 10.1483 85.2719 11.0821Z"
                fill="#F6F5F6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M99.1431 21.8792C98.0007 22.4906 96.9248 22.7963 95.915 22.7963C94.3919 22.7963 93.2002 22.3872 92.3393 21.5696C91.4784 20.7514 91.048 19.5158 91.048 17.8632V12.7313H88.3914V10.0538H91.048V6.31055H94.3259V10.0538H98.3735V12.7313H94.3259V17.6151C94.3259 19.0363 94.9299 19.7472 96.1384 19.7472C96.7675 19.7472 97.4543 19.5488 98.1994 19.152L99.1431 21.8792Z"
                fill="#F6F5F6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M109.972 9.97978L109.798 13.1284H109.078C106.131 13.1284 104.658 14.8966 104.658 18.4336V22.425H101.38V10.0541H104.658V12.3102C105.784 10.6906 107.265 9.88086 109.102 9.88086C109.483 9.88086 109.773 9.91383 109.972 9.97978Z"
                fill="#F6F5F6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M115.32 13.1276C114.732 13.5243 114.347 14.086 114.165 14.8136H120.15C119.951 14.053 119.587 13.483 119.057 13.103C118.528 12.7224 117.915 12.5324 117.22 12.5324C116.541 12.5324 115.908 12.7308 115.32 13.1276ZM123.502 17.1189H114.066C114.231 17.9946 114.6 18.6932 115.171 19.2141C115.742 19.7344 116.408 19.9948 117.17 19.9948C118.577 19.9948 119.603 19.4494 120.249 18.3585L123.179 18.9782C122.65 20.2345 121.863 21.1846 120.82 21.829C119.778 22.4739 118.561 22.7963 117.17 22.7963C115.382 22.7963 113.876 22.1844 112.651 20.9616C111.426 19.7383 110.814 18.1685 110.814 16.251C110.814 14.3341 111.43 12.7598 112.663 11.5287C113.897 10.2975 115.415 9.68164 117.22 9.68164C118.908 9.68164 120.361 10.2724 121.578 11.4543C122.794 12.6358 123.436 14.1519 123.502 16.0034V17.1189Z"
                fill="#F6F5F6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M129.818 13.1276C129.23 13.5243 128.846 14.086 128.663 14.8136H134.648C134.449 14.053 134.085 13.483 133.555 13.103C133.026 12.7224 132.413 12.5324 131.718 12.5324C131.039 12.5324 130.406 12.7308 129.818 13.1276ZM138 17.1189H128.564C128.73 17.9946 129.098 18.6932 129.669 19.2141C130.24 19.7344 130.906 19.9948 131.668 19.9948C133.075 19.9948 134.101 19.4494 134.747 18.3585L137.677 18.9782C137.148 20.2345 136.361 21.1846 135.318 21.829C134.275 22.4739 133.059 22.7963 131.668 22.7963C129.88 22.7963 128.374 22.1844 127.149 20.9616C125.924 19.7383 125.312 18.1685 125.312 16.251C125.312 14.3341 125.928 12.7598 127.162 11.5287C128.395 10.2975 129.913 9.68164 131.718 9.68164C133.406 9.68164 134.859 10.2724 136.076 11.4543C137.292 12.6358 137.933 14.1519 138 16.0034V17.1189Z"
                fill="#F6F5F6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.2401 12.732V15.8063H45.7766C45.4951 16.9961 44.9073 17.9338 44.0134 18.6201C43.1195 19.3058 42.0436 19.6484 40.7853 19.6484C39.2292 19.6484 37.9216 19.1197 36.862 18.0618C35.8029 17.0044 35.2728 15.6906 35.2728 14.1202C35.2728 12.5666 35.7861 11.2611 36.8127 10.2031C37.8387 9.14579 39.0971 8.61655 40.5866 8.61655C42.7131 8.61655 44.286 9.44646 45.3092 11.0979L48.0442 9.27377C47.4464 8.21753 46.6084 7.3703 45.5281 6.73264C44.0716 5.87312 42.4242 5.44336 40.5866 5.44336C38.1371 5.44336 36.0677 6.2822 34.3789 7.95989C32.6907 9.63703 31.8466 11.6908 31.8466 14.1202C31.8466 16.5663 32.6991 18.624 34.4041 20.2933C36.1091 21.9626 38.2031 22.797 40.6862 22.797C43.1362 22.797 45.1558 21.9788 46.745 20.3431C48.3341 18.7067 49.1284 16.649 49.1284 14.1699C49.1284 13.7899 49.1038 13.3104 49.054 12.732H40.2401Z"
                fill="#F6F5F6"
            />
        </svg>
    );
};

export default Logo;
