import React from 'react';

const Attribution = () => {
    const container = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    };

    const linkStyle = {
        marginRight: '16px',
        textDecoration: 'underline',
    };

    const sponsorLinks = {
        fontSize: '11px',
        fontWeight: '400',
        color: '#635b67',
        marginBottom: '0',
    };

    const txtTertiary = {
        fontSize: '11px',
        color: '#8a848d',
        letterSpacing: '0.3px',
    };

    return (
        <div style={container}>
            <h4 style={sponsorLinks}>Sponsored Links</h4>
            <div style={txtTertiary}>
                Results by Microsoft&nbsp;
                <a
                    href="https://go.microsoft.com/fwlink/?LinkId=248681"
                    style={{ ...linkStyle, ...txtTertiary }}
                >
                    (Privacy)
                </a>
            </div>
        </div>
    );
};

export default Attribution;
