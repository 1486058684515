import { AdParamsFromBingApi } from '../common/transform-data-into-ad-models';
import createFetchInstance from './util/fetch-json';

type ApiResponse = {
    impressionFeedbackUrl: string;
    instrumentation: {
        _type: 'ResponseInstrumentation';
        pingUrlBase: string;
        pageLoadPingUrl: string;
    };
    queryContext: {
        originalQuery: string;
    };
    productAdsLayouts: {
        _type: 'ProductAdsLayout';
        layoutType: string;
        productAds: {
            _type: 'ProductAd';
            id: string;
            rank: number;
            position: string;
            impressionToken: string;
            offer: {
                url: string;
                urlPingSuffix: string;
                seller: {
                    name: string;
                };
                price: number;
                priceCurrency: string;
                itemOffered: {
                    name: string;
                    image: {
                        contentUrl: string;
                        width: number;
                        height: number;
                    };
                    description: string;
                };
                priceCurrencySymbol: string;
                lowPrice?: number;
            };
            primaryImageInfo?: {
                colorInfo: {
                    backgroundColor: string;
                    layoutColor: string;
                    fontColor: string;
                };
            };
            shippingInfo?: {
                description: string;
                price: number;
            };
            entityInfos?: {
                entityIdType: string;
                entityIds: string[];
            }[];
        }[];
    }[];
    readLink: string;
    value: Array<AdParamsFromBingApi>;
    visibilityFeedbackUrl: string;
    _type: 'Ads';
};

const fetchBingAds = async (bingUrl: string) => {
    const TIMEOUT_MS = 20000;

    const fetchData = createFetchInstance(window.XMLHttpRequest);

    const {
        value: bingAds = [],
        impressionFeedbackUrl,
        visibilityFeedbackUrl,
    }: ApiResponse = await fetchData(bingUrl, TIMEOUT_MS);

    return { bingAds, impressionFeedbackUrl, visibilityFeedbackUrl };
};

export default fetchBingAds;
