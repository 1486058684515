/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React from 'react';
import classnames from 'classnames';

import getFragments from './fragment';
import { qaAttribute } from '../../utils/qa-service';

import './type-ahead-option.scss';

const TypeAheadOption = ({
    focused = false,
    highlight = '',
    onMouseDown,
    secondaryText = '',
    text = '',
    removeSingleFromHistory,
    useRecentHistory = false,
    icon,
}: {
    focused?: boolean;
    highlight?: string;
    onMouseDown: () => void;
    secondaryText?: string;
    text?: string;
    removeSingleFromHistory: (text: string) => void;
    useRecentHistory?: boolean;
    icon: JSX.Element;
}) => {
    const clearSingleHistory = (event) => {
        event.stopPropagation();
        removeSingleFromHistory(text);
    };
    const { prefixPart, highlightPart, suffixPart } = getFragments(text, highlight);

    return (
        <div
            role="listitem"
            onMouseDown={() => {
                onMouseDown();
            }}
            data-testid="type-ahead-option"
            className={classnames('type-ahead-option', {
                'type-ahead-option--focused': focused,
            })}
        >
            {icon}
            <div className="text-part-container">
                {useRecentHistory && (
                    <div className="keyword-highlight-text-wrapper type-ahead-option-primary-text">
                        <span className="type-ahead-option-no-highlight">{text}</span>
                    </div>
                )}
                {!useRecentHistory &&
                    (highlight ? (
                        <div className="keyword-highlight-text-wrapper type-ahead-option-primary-text">
                            <span className="type-ahead-option-no-highlight">{prefixPart}</span>
                            <span className="type-ahead-option-highlight">{highlightPart}</span>
                            <span className="type-ahead-option-no-highlight">{suffixPart}</span>
                        </div>
                    ) : (
                        <span className="type-ahead-option-no-highlight type-ahead-option-primary-text">
                            {text}
                        </span>
                    ))}

                {secondaryText && (
                    <div
                        className="type-ahead-option-secondary-text"
                        {...qaAttribute('secondary-text')}
                    >
                        {secondaryText}
                    </div>
                )}
            </div>
            {useRecentHistory && (
                <button
                    className="button button--only-icon"
                    type="button"
                    aria-label="Clear history"
                    data-q="clear-history-button"
                    onMouseDown={clearSingleHistory}
                >
                    <span className="icon icon--smallest icon--clear" aria-hidden="true" />
                </button>
            )}
        </div>
    );
};

export default TypeAheadOption;
