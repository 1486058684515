import React from 'react';
import thumbnail from '@gumtree/ui-library/src/assets/ads/noimage_thumbnail.png';

const NoImageThumbnail = () => {
    const listingThumbnailStyle = {
        width: '100%',
        float: 'left',
        marginBottom: '8px',
        overflow: 'hidden',
        positive: 'relative',
        border: '1px solid #d8d6d9',
    };
    return (
        <div style={listingThumbnailStyle}>
            <div
                style={{
                    backgroundImage: `url(${thumbnail})`,
                    backgroundSize: '100%',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: window?.innerWidth >= 768 ? '90px' : '77px',
                }}
            />
        </div>
    );
};

export default NoImageThumbnail;
