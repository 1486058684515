import appendHighValueBids from './append-high-value-bids';
import appendRefreshLabel from './append-refresh-label';
import { transformPrebidVersion } from './util';

const onBidsReturnedCallback = (commandFactoryParams, isRefresh) => {
    return new Promise((resolve) => {
        const { googletag, pbjs, useRubiconDemandManager } = commandFactoryParams;
        googletag.cmd.push(() => {
            const transformedPrebidVersion = transformPrebidVersion(pbjs.version);
            googletag.pubads().setTargeting('g_prebid_version', transformedPrebidVersion);

            pbjs.que.push(() => {
                if (!useRubiconDemandManager) {
                    pbjs.setTargetingForGPTAsync();
                }

                appendRefreshLabel(googletag, isRefresh);
                appendHighValueBids(googletag);
                resolve();
            });
        });
    });
};

export default onBidsReturnedCallback;
