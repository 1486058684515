import React from 'react';
import PropTypes from 'prop-types';

const SalePrice = ({ currency, value }) => {
    const style = {
        color: '#3c3241',
    };

    return <strong style={style}>{`${currency}${value}`}</strong>;
};

SalePrice.propTypes = {
    currency: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
};

export default SalePrice;
