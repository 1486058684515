import React from 'react';

const Logo = () => {
    return (
        <svg
            width="28"
            height="36"
            viewBox="0 0 28 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.955 9.984C23.683 9.792 23.511 9.492 23.481 9.161C23.045 4.313 18.96 0.5 14 0.5C9.041 0.5 4.956 4.312 4.52 9.16C4.49 9.491 4.318 9.792 4.046 9.984C1.601 11.709 0 14.555 0 17.768C0 22.165 2.997 25.875 7.056 26.963V26.964C7.752 27.151 8.474 27.236 8.729 27.089C9.238 26.795 9.876 24.415 9.582 23.905C9.435 23.651 8.766 23.364 8.071 23.177C5.683 22.537 3.92 20.354 3.92 17.768C3.92 16.328 4.467 15.013 5.363 14.02C5.77 13.569 6.25 13.183 6.784 12.883C7.803 12.31 8.405 11.213 8.401 10.043C8.401 10.036 8.4 10.028 8.4 10.02C8.4 9.415 8.497 8.832 8.676 8.285C9.408 6.044 11.518 4.42 14 4.42C16.483 4.42 18.593 6.044 19.325 8.286C19.504 8.832 19.6 9.415 19.6 10.02C19.6 10.028 19.6 10.036 19.6 10.043C19.596 11.213 20.198 12.31 21.217 12.883C21.751 13.184 22.231 13.569 22.638 14.02C23.534 15.013 24.08 16.328 24.08 17.768C24.08 20.861 21.56 23.376 18.464 23.368C14.907 23.358 12.04 26.294 12.04 29.852V33.54C12.041 34.26 12.145 34.98 12.353 35.188C12.769 35.604 15.232 35.604 15.648 35.188C15.856 34.98 15.961 34.26 15.961 33.54H15.96V29.789C15.96 28.4 17.097 27.288 18.485 27.288C23.732 27.285 28 23.015 28 17.768C28 14.555 26.4 11.709 23.955 9.984Z"
                fill="#72EF36"
            />
        </svg>
    );
};

export default Logo;
