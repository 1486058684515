import styled from '@emotion/styled';

import { css } from '@emotion/react';
import {
    breakpoints,
    colors,
    colorVariables,
    gutterSizes,
    mediaQuery,
} from '@gumtree/ui-library/src/base/theme';
import { Button, Grid, Logo, PostAdButton } from '@gumtree/ui-library';
import { Wrapper as NavLoginButtonWrapper } from './nav-bar-login-button';
import { NavBarButtonOverride } from './common.style';

const unforwarded = {
    mobileCondition: true,
    searchOpen: true,
    isLogInToolTipShown: true,
    isInGTC2229: true,
};
function shouldForwardProp(p: string) {
    return !unforwarded[p];
}

export const NavBarContainer = styled('nav')`
    background-color: ${colors.blade};
    padding: ${gutterSizes.base} 0;
    position: relative;
    padding: 0;

    .side-menu-container {
        &.opened {
            background: ${colorVariables.textNeutral};
        }

        ${mediaQuery.from(breakpoints.medium)} {
            display: none;
        }
    }

    .login-button {
        display: none;

        ${mediaQuery.from(breakpoints.medium)} {
            display: inline-block;
        }
    }

    ${mediaQuery.between(breakpoints.xsmall, breakpoints.small)} {
        .login-button {
            display: none;
        }
    }
`;

export const NavLogo = styled(Logo, { shouldForwardProp })`
    display: flex;
    align-items: center;

    .logo-text {
        display: none;

        ${mediaQuery.from(breakpoints.small)} {
            display: flex;
        }
    }
`;

export const StyledSearchButton = styled(Button, { shouldForwardProp })`
    && {
        box-sizing: border-box;
        ${NavBarButtonOverride}
    }
`;

type NavPostAdButtonStyledProps = {
    mobileCondition: boolean;
    isInGTC2229?: boolean;
    experiments: object;
};

export const NavPostAdButton = styled(PostAdButton, {
    shouldForwardProp,
})<NavPostAdButtonStyledProps>`
    && {
        ${NavBarButtonOverride}

        ${({ isInGTC2229 }) =>
            isInGTC2229 &&
            `
            width: 47px;
            text-align: center;
        `}

        &:hover {
            color: ${colors.bark40};

            .icon--pin {
                transform: rotate(30deg);
                color: ${colors.white};
            }

            .icon--tag-solid {
                &:after {
                    background: ${colors.bark40};
                }
            }
        }

        > .icon--pin {
            width: 100%;

            color: ${colorVariables.mainPrimary};
            transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    }
`;

export const sellMyCarButtonCss = (theme) => css`
    && {
        ${NavBarButtonOverride}

        &:hover {
            color: ${colors.white};

            .icon--car {
                transform: rotate(30deg);
                color: ${colors.white};
            }
        }

        > .icon--car {
            width: 100%;
            color: ${theme.palette.iconBtn.ctaIcon};
            transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    }
`;

export const StyledGrid = styled(Grid)`
    flex-wrap: wrap;

    ${mediaQuery.from(breakpoints.large)} {
        flex-wrap: nowrap;
    }
`;

const WrapperHeight = '60px';
const NavBarButtonsGutter = gutterSizes.large;
export const NavLogoWrapper = styled.div`
    display: flex;
    align-items: center;
    height: ${WrapperHeight};
`;

export const ButtonsWrapper = styled('div', {
    shouldForwardProp,
})<{
    isInGTC2229: boolean;
}>`
    display: flex;
    justtify-content: flex-end;
    align-items: center;
    margin-left: -${NavBarButtonsGutter};
    height: ${WrapperHeight};

    ${({ isInGTC2229 }) =>
        isInGTC2229 &&
        `
        gap: ${gutterSizes.medium};
    `}

    ${mediaQuery.from(breakpoints.large)} {
        order: 2;
    }

    ${NavLoginButtonWrapper} {
        margin-left: 0;

        ${({ isInGTC2229 }) =>
            isInGTC2229 &&
            `
            text-align: center;
            width: 47px;
        `}

        ${mediaQuery.from(breakpoints.medium)} {
            ${({ isInGTC2229 }) =>
                isInGTC2229
                    ? `
            margin-left: 0;
        `
                    : `margin-left: ${NavBarButtonsGutter};
        `}
        }
    }

    > * {
        margin-left: ${NavBarButtonsGutter};

        ${({ isInGTC2229 }) =>
            isInGTC2229 &&
            `
            margin-left: 0;
        `}
    }
`;

export const SearchBarWrapper = styled.div`
    flex: 100%;
    display: flex;
    align-items: center;

    ${mediaQuery.from(breakpoints.large)} {
        order: 1;
        flex: auto;
    }

    ${mediaQuery.between(breakpoints.large, breakpoints.xlarge)} {
        padding: 0 ${gutterSizes.xxlarge};
    }

    ${mediaQuery.from(breakpoints.xlarge)} {
        padding: 0 ${gutterSizes.large};
    }
`;
