export const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL';
export const LOGIN_MODAL_TYPE = 'LOGIN_MODAL_TYPE';
export const IS_LOGIN = 'IS_LOGIN';
export const SHOW_FORGOT_PASSWORD = 'SHOW_FORGOT_PASSWORD';

export enum Type {
    LOGIN = 'login',
    REGISTER = 'register',
    REVEAL_PHONE = 'reveal-number',
    SELL = 'sell',
    EMAIL = 'email',
    OFFER = 'offer',
}

export const setIsOpen = (value: boolean) => ({
    type: OPEN_LOGIN_MODAL,
    payload: value,
});

export const showForgotPasswordModal = (payload: { value: boolean; isReset?: boolean, showResetText?: boolean }) => ({
    type: SHOW_FORGOT_PASSWORD,
    payload,
});

export const setType = (value: Type) => ({
    type: LOGIN_MODAL_TYPE,
    payload: value,
});

export const setIsLogin = (value: boolean) => ({
    type: IS_LOGIN,
    payload: value,
});

export interface State {
    isOpen: boolean;
    isLogin: boolean;
    type: Type;
    showForgotPassword: boolean;
    isReset: boolean;
    showResetText: boolean;
}

export const initialState: State = {
    isOpen: false,
    type: Type.LOGIN,
    isLogin: false,
    showForgotPassword: false,
    isReset: false,
    showResetText: false
};

export default (state = initialState, action): State => {
    switch (action.type) {
        case OPEN_LOGIN_MODAL:
            return {
                ...state,
                isOpen: action.payload,
            };
        case IS_LOGIN:
            return {
                ...state,
                isLogin: action.payload,
            };
        case LOGIN_MODAL_TYPE:
            return {
                ...state,
                type: action.payload,
            };
        case SHOW_FORGOT_PASSWORD:
            return {
                ...state,
                showForgotPassword: action.payload.value,
                isReset: action.payload.isReset ?? null,
                showResetText: action.payload.showResetText ?? null,
            };
        default:
            return state;
    }
};
