import React, { ErrorInfo } from 'react';

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    state: ErrorBoundaryState = {
        hasError: false,
    };

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        const { handleError } = this.props;

        handleError?.(error, errorInfo);
        this.setState({
            hasError: true,
            error,
            errorInfo,
        });
    }

    render() {
        return this.state.hasError ? (
            <div style={{ padding: '16px 8px' }}>
                <div style={{ marginBottom: 32, fontSize: 20 }}>
                    Oops. Please reload or try again later.
                </div>
                {this.state.error && this.state.errorInfo && (
                    <div style={{ padding: 16, border: '1px solid #ddd', fontSize: 12 }}>
                        <div style={{ fontSize: 18, marginBottom: 24 }}>Technical details</div>
                        <pre>
                            Component Error: {this.state.error.message}
                            {this.state.errorInfo.componentStack}
                            {'\n'}
                            {'\n'}
                            {this.state.error.stack}
                        </pre>
                    </div>
                )}
            </div>
        ) : (
            this.props.children
        );
    }
}

interface ErrorBoundaryProps extends React.PropsWithChildren {
    handleError?(error: Error, errorInfo: ErrorInfo): void;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error?: Error;
    errorInfo?: ErrorInfo;
}
