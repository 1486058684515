import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { emitPing } from './pixel-ping';

class TrackedPhoneNumber extends PureComponent {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        const { trackingUrl } = this.props;
        emitPing(trackingUrl, window);
    }

    render() {
        const { number, children, trackingUrl } = this.props;

        return (
            <a href={`tel:${number}`} onClick={trackingUrl ? this.onClick : null}>
                {children}
            </a>
        );
    }
}

TrackedPhoneNumber.defaultProps = {
    trackingUrl: '',
};

TrackedPhoneNumber.propTypes = {
    children: PropTypes.any.isRequired,
    number: PropTypes.string.isRequired,
    trackingUrl: PropTypes.string,
};

export default TrackedPhoneNumber;
