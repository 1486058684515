import styled from "@emotion/styled";
import { breakpoints, colors, mediaQuery } from "@gumtree/ui-library/src/base/theme";
import { LINK_PADDING } from "./category-nav-ribbon-item";

export const ItemListContainer = styled.div`
    position: relative;
`;

export const ItemListWrapper = styled.div`
    height: 100%;
    position: relative;
`;

export const ItemList = styled.ul`
    height: 45px;
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        /*Chrome, Safari and Opera */
        display: none;
    }

    margin-left: -${LINK_PADDING};

    ${mediaQuery.from(breakpoints.medium)} {
        margin-left: 0;
    }
`;

type FadeProps = {
    isShown: boolean;
};

export const Fade = styled.div`
    display: ${(p: FadeProps) => (p.isShown ? "block" : "none")};
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 62px;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, #fff0 0%, #f1f1f1 100%);
`;

type UnderHoverlineProps = {
    width: number;
    x: number;
    animate: boolean;
};
export const HoverUnderline = styled.hr`
    height: 4px;
    width: 1px;
    margin: 0;
    background: ${colors.white};
    border: none;
    position: absolute;
    bottom: 0;
    ${(e: UnderHoverlineProps) => e.animate && `transition: 0.1s ease-in-out;`}
    transform-origin: left;
    transform: translateX(${(e: UnderHoverlineProps) => e.x}px)
        scaleX(${(e: UnderHoverlineProps) => e.width});
`;
