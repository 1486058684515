import React from 'react';
import debounce from 'lodash/debounce';

import { updateUrlWithKeyValue } from '@gumtree/shared/src/util/url-params';
import { trackGtmEvent } from '@gumtree/shared/src/util/gtm-tracker';

import { getLocationSuggestions, LocationList } from '../fetch-from-locations';

export const debouncedUpdateSearchSuggestion = debounce(
    (newLocation: string, setLocationSuggestions) => {
        getLocationSuggestions(newLocation).then((locations) => {
            setLocationSuggestions(locations);
        });
    },
    200
);

export const showLocationSuggestions = (location: string) => location.length >= 2;

export const submitSearchHandler = async (
    location: string,
    setIsSearchSubmitted: React.Dispatch<React.SetStateAction<boolean>>,
    trackingEvent: string
) => {
    setIsSearchSubmitted(true);

    await trackGtmEvent({
        action: trackingEvent,
        label: location,
    });

    const urlParams = window.location.search;
    const newUrl = `/search${urlParams}`;
    const updatedUrl = updateUrlWithKeyValue(newUrl, 'search_location', location);
    if (updatedUrl) {
        window.location.assign(updatedUrl);
    }
};

export const onInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    location: string,
    setLocationSuggestions: React.Dispatch<React.SetStateAction<LocationList>>
) => {
    const { value } = e.target;
    if (showLocationSuggestions(location)) {
        debouncedUpdateSearchSuggestion(value, setLocationSuggestions);
    }
};
