import React, { PureComponent } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import GlobalSitesItem from '../global-sites-item';

const Items = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

class GlobalSitesItems extends PureComponent {
    render() {
        const { items } = this.props;

        return (
            <Items>
                {items.map((item) => (
                    <GlobalSitesItem key={item.id} label={item.label} link={item.link} />
                ))}
            </Items>
        );
    }
}

GlobalSitesItems.propTypes = {
    items: PropTypes.array,
};

GlobalSitesItems.defaultProps = {
    items: [],
};

export default GlobalSitesItems;
