import { commandFactory } from './load-ads';
import onBidsReturned from './on-bids-returned-callback';
import fetchBidsFromAmazonTam from '../../amazon-tam/ad-config';
import { CommandFactoryParams } from '../types';

export default async (commandFactoryParams: CommandFactoryParams, isAdRefresh = false) => {
    const { pbjs, deviceType, pageType, categoryL1 } = commandFactoryParams;

    const biddingPromise = new Promise((resolve) => {
        pbjs.que.push(commandFactory(commandFactoryParams, resolve));
    }).then(() => onBidsReturned(commandFactoryParams, isAdRefresh));

    const amazonTamPromise = fetchBidsFromAmazonTam(
        deviceType,
        pageType,
        categoryL1,
        isAdRefresh,
        window
    );

    await Promise.all([biddingPromise, amazonTamPromise]);
};
