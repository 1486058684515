import React from 'react';
import PropTypes from 'prop-types';

import './sub-menu.scss';

const SubMenu = ({ children }) => <ul className="sub-menu">{children}</ul>;

SubMenu.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
};

export default SubMenu;
