import fetch from 'isomorphic-fetch';
import { encode } from 'html-entities';

import { redirectToUrl } from '@gumtree/ui-library/src/utils/browser-service';
import {
    getIndex,
    getNewInstance,
    KeywordSuggestionsResponse,
} from '@gumtree/ui-library/src/utils/suggestions-service';
import { safeGetData } from '@gumtree/ui-library/src/utils/data-service';
import { RecentSearchesItem, save as saveSearches } from './recent-service/recent-searches-service';
import { save as saveLocations } from './recent-service/recent-locations-service';

export type Suggestion = {
    name: string;
    highlight: string;
    category: string;
    categoryDisplayName: string;
};

let internalSuggestionsService;
let isSubmitSearchCalled = false; // Browser seems to have a bug that calls submitSearch() a second time after a click on search item. This ensures submitSearch() is only ever called once

export const setSuggestionsService = (service) => {
    internalSuggestionsService = service;
};

export const getSuggestionsService = () => internalSuggestionsService;

export const setIsSubmitSearchCalled = (val) => (isSubmitSearchCalled = val);

const setupSuggestionsService = (isQa = false, algoliaKey) => {
    const algoliaIndex = getIndex(isQa);

    setSuggestionsService(getNewInstance(algoliaIndex, algoliaKey));
};

const fetchLocationSuggestions = (value: string): Promise<{ name: string; type: string }[]> =>
    fetch(`/ajax/location/prefix?input=${value}`)
        .then((response) => response.json())
        .then((response) => response.locationList as { name: string; type: string }[]);

const getHighlight = (completion = '') => {
    const match = completion.match(/<em>(.*?)<\/em>/);

    return Array.isArray(match) && match.length > 0 ? match[1] : undefined;
};

const addCategoriesToSuggestion = (
    {
        categories = [],
        completion = '',
    }: {
        categories?: {
            id: string;
            canonicalName: string;
            localizedNames: {
                en_GB: string;
            };
            depth: string;
            pathToRoot: {
                localizedNames: {
                    en_GB: string;
                };
                canonicalName: string;
                id: string;
            }[];
        }[];
        completion?: string;
    } = {},
    highlight: string
): Suggestion[] =>
    categories.map(({ canonicalName, localizedNames: { en_GB: categoryName } }) => ({
        name: completion,
        highlight,
        category: canonicalName,
        categoryDisplayName: categoryName,
    }));

const transformSuggestions = (suggestions, suggestion, index) => {
    let highlight;
    const { categories, completion } = suggestion;
    const completionMarkup = safeGetData('_highlightResult.completion.value', suggestion);

    if (completionMarkup) {
        highlight = getHighlight(completionMarkup);
    }

    let items = [{ name: completion, highlight }];

    if (index === 0 && categories && categories.length) {
        items = items.concat(addCategoriesToSuggestion(suggestion, highlight));
    }

    return suggestions.concat(items);
};

const fetchKeywordSuggestions = (value) => {
    const suggestionsService = getSuggestionsService();

    return suggestionsService.search(value).then((results: KeywordSuggestionsResponse = []) => {
        return results.reduce(transformSuggestions, []);
    });
};

const submitSearch = ({
    category = 'all',
    categoryDisplayName,
    keyword,
    location,
    searchOptionsExactMatch,
    additionalParams = {},
    requireKeywordCorrection,
}: {
    category: string | undefined;
    categoryDisplayName: string | undefined;
    keyword: string;
    location: string;
    searchOptionsExactMatch: boolean;
    additionalParams?: Record<string, string | boolean | number>;
    requireKeywordCorrection: boolean;
}) => {
    if (isSubmitSearchCalled) {
        return;
    }

    isSubmitSearchCalled = true;

    const queryParams: string[] = [];

    if (category) {
        queryParams.push(`search_category=${category}`);
    }

    if (keyword) {
        queryParams.push(`q=${keyword}`);

        if (requireKeywordCorrection) {
            queryParams.push(`keyword_correction=auto`);
        }

        const recentSearch: RecentSearchesItem = {
            name: keyword,
        };

        if (category) {
            recentSearch.category = category;
        }

        if (categoryDisplayName) {
            recentSearch.categoryDisplayName = categoryDisplayName;
        }

        if (searchOptionsExactMatch) {
            queryParams.push(`searchOptionsExactMatch=true`);
            recentSearch.searchOptionsExactMatch = true;
        }

        saveSearches(recentSearch);
    }

    if (location) {
        queryParams.push(`search_location=${encode(location)}`);

        saveLocations({ name: location });
    }

    const paramsKeys = Object.keys(additionalParams);

    if (paramsKeys.length) {
        paramsKeys.forEach((param) => {
            queryParams.push(`${param}=${additionalParams[param]}`);
        });
    }

    const searchUrl = `/search?${queryParams.join('&')}`;

    redirectToUrl(searchUrl);
};

export const SEARCH_FORMATS = {
    BY_TERM: 'search_term',
    BY_LOCATION: 'search_location',
    EMPTY: 'search_empty',
} as const;

export const getSearchFormat = ({ term, location }: { term?: string; location?: string }) => {
    if (term && location) {
        return `${SEARCH_FORMATS.BY_TERM}, ${SEARCH_FORMATS.BY_LOCATION}`;
    }

    if (location) {
        return SEARCH_FORMATS.BY_LOCATION;
    }

    if (term) {
        return SEARCH_FORMATS.BY_TERM;
    }

    return SEARCH_FORMATS.EMPTY;
};

export { fetchLocationSuggestions, fetchKeywordSuggestions, setupSuggestionsService, submitSearch };
