import { connect } from 'react-redux';

import { trackAndNavigate, launchBanner } from '../../common/common-actions';
import SideMenuItem from './side-menu-item';

const mapState = ({ features }) => ({
    features,
});

export const mapDispatch = (dispatch) => ({
    trackAndNavigate: (event, url, eventLabel) => {
        dispatch(trackAndNavigate(event, url, eventLabel));
    },
    launchBanner: (target) => {
        dispatch(launchBanner(target));
    },
});

export default connect(mapState, mapDispatch)(SideMenuItem);
