import Cookies from 'js-cookie';
import React, { Fragment, Component } from 'react';
import classNames from 'classnames';
import { bool, object, func, string } from 'prop-types';

import { Button, Icon } from '@gumtree/ui-library';
import { bannerTakeoverClose, bannerTakeoverOpen } from './app-banner-takeover-logic';
import { trackV2 } from '../common/common-actions';
import ErrorBoundary from '../error-boundary';
import { appBannerTakeoverCss } from './app-banner-takeover.style';

class AppBannerTakeover extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.closeOverlay = this.closeOverlay.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isOpenBanner !== state.isOpen) {
            return {
                isOpen: props.isOpenBanner,
            };
        }
        return null;
    }

    closeOverlay() {
        const { closeBanner, target } = this.props;

        closeBanner();
        bannerTakeoverClose();
        typeof window !== 'undefined' && window.location.assign(target);
    }

    clearCookie() {
        const { closeBanner } = this.props;
        const { domain } = this.props;
        Cookies.set('AppBannerTakeover', 'notShowing', { domain });
        closeBanner();
        bannerTakeoverClose();
    }

    trackButtonClicks(eventName, eventLabel) {
        trackV2(eventName, eventLabel);
    }

    navigate() {
        const { isOpen } = this.state;
        const { target } = this.props;
        if (isOpen) {
            typeof window !== 'undefined' && window.location.assign(target);
        }
    }

    isShowing() {
        const { device, domain, target } = this.props;
        let isShowing = false;

        if (
            device.type === 'mobile' &&
            device.os === 'IOS' &&
            target.includes('recruiters') === false
        ) {
            if (Cookies.get('AppBannerTakeover')) {
                bannerTakeoverOpen();
                isShowing = true;
            } else {
                bannerTakeoverOpen();
                Cookies.set('AppBannerTakeover', 'showing', { domain });
                isShowing = true;
            }
        } else {
            this.navigate();
        }

        return isShowing;
    }

    render() {
        const { isOpen } = this.state;
        const {
            device: { os },
        } = this.props;
        const isIOS = os === 'IOS';
        const adjustUrl =
            'https://app.adjust.com/ez1o5rv?campaign=Wall%20Sign%20Post&adgroup=Global&creative=V1'; //eslint-disable-line
        const deviceClass = classNames('banner-takeover-phone', {
            ios: os === 'IOS',
        });

        return (
            <ErrorBoundary>
                <Fragment>
                    {isIOS && Cookies.get('AppBannerTakeover') !== 'notShowing' ? (
                        <Fragment css={appBannerTakeoverCss}>
                            {isOpen && this.isShowing() && (
                                <div
                                    className="banner-takeover banner-takeover-IOS"
                                    data-testid="banner-takeover"
                                >
                                    <div className="grid-container">
                                        <div className="banner-takeover-content">
                                            <h2 className="banner-takeover-title">
                                                After a simple sale?
                                            </h2>
                                            <p className="banner-takeover-text">
                                                Get selling in seconds. Post an ad, straight from
                                                your phone
                                            </p>
                                            <div className="banner-takeover-buttons">
                                                <a
                                                    data-testid="app-banner-accept"
                                                    onClick={() => {
                                                        this.trackButtonClicks(
                                                            'AppDownloadBegin',
                                                            'SignPostWall'
                                                        );
                                                        this.clearCookie();
                                                        this.setState({ isOpen: false });
                                                    }}
                                                    className="banner-takeover-accept"
                                                    href={adjustUrl}
                                                >
                                                    <Button
                                                        display="primary"
                                                        className="button--full-width"
                                                        label="Download the app"
                                                        icon={<Icon type="download" />}
                                                        iconPosition="left"
                                                    />
                                                </a>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        this.trackButtonClicks(
                                                            'AppDownloadBegin',
                                                            'DismissWall'
                                                        );
                                                        this.closeOverlay();
                                                    }}
                                                    className="button banner-takeover-cancel"
                                                >
                                                    Continue posting on the site
                                                </button>
                                            </div>
                                            <div className={deviceClass} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Fragment>
                    ) : (
                        this.navigate()
                    )}
                </Fragment>
            </ErrorBoundary>
        );
    }
}

AppBannerTakeover.propTypes = {
    closeBanner: func.isRequired,
    device: object.isRequired,
    domain: string.isRequired,
    isOpenBanner: bool.isRequired,
    target: string.isRequired,
};

export default AppBannerTakeover;
