import { Nullable } from 'typescript-nullable';
import Partner from './partner';
import SlotName from '../common/slot-name';
import ScreenSize from '../common/screen-size';
import DeviceType from '../common/device-type';
import PageType from '../common/page-type';

export default class Criteo extends Partner {
    readonly bidder = 'criteo';
    readonly networkId = 1472;
    mapping = {
        [PageType.ResultsPage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.integratedMpu]: 'desktop_srp_middle1',
                    [SlotName.rSkyT]: 'desktop_srp_topRight',
                    [SlotName.rSkyT2]: 'desktop_srp_topright2',
                    [SlotName.tBanner]: 'desktop_srp_top',
                    [SlotName.rSkyB]: 'desktop_srp_bottomRight',
                    [SlotName.rSkyB2]: 'desktop_srp_bottomright2',
                    [SlotName.integratedListing]: 'desktop_srp_middle2',
                },
                [ScreenSize.XL]: {
                    [SlotName.rSkyT2]: 'desktop_srp_topright2',
                    [SlotName.integratedMpu]: 'desktop_srp_middle1',
                    [SlotName.rSkyT]: 'desktop_srp_topRight',
                    [SlotName.tBanner]: 'desktop_srp_top',
                    [SlotName.rSkyB]: 'desktop_srp_bottomRight',
                    [SlotName.rSkyB2]: 'desktop_srp_bottomright2',
                    [SlotName.integratedListing]: 'desktop_srp_middle2',
                },
                [ScreenSize.L]: {
                    [SlotName.integratedMpu]: 'desktop_srp_middle1',
                    [SlotName.tBanner]: 'desktop_srp_top',
                    [SlotName.integratedListing]: 'desktop_srp_middle2',
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.integratedMpu]: 'tablet_srp_middle1',
                    [SlotName.tBanner]: 'tablet_srp_top',
                    [SlotName.integratedListing]: 'tablet_srp_middle2',
                },
                [ScreenSize.M]: {
                    [SlotName.integratedMpu]: 'tablet_srp_middle1',
                    [SlotName.tBanner]: 'tablet_srp_top',
                    [SlotName.integratedListing]: 'tablet_srp_middle2',
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.integratedMpu]: 'mobile_srp_middle1',
                    [SlotName.integratedListing]: 'mobile_srp_middle2',
                    [SlotName.floatingFooter]: 'mobile_srp_floating',
                },
                [ScreenSize.XS]: {
                    [SlotName.integratedMpu]: 'mobile_srp_middle1',
                    [SlotName.integratedListing]: 'mobile_srp_middle2',
                    [SlotName.floatingFooter]: 'mobile_srp_floating',
                },
            },
        },
        [PageType.VIP]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.vipBanner]: 'desktop_vip_top',
                    [SlotName.mpu]: 'desktop_vip_topright',
                    [SlotName.vipMiddleDesktop]: 'desktop_vip_bottomRight',
                },
                [ScreenSize.XL]: {
                    [SlotName.vipBanner]: 'desktop_vip_top',
                    [SlotName.mpu]: 'desktop_vip_topright',
                    [SlotName.vipMiddleDesktop]: 'desktop_vip_bottomRight',
                },
                [ScreenSize.L]: {
                    [SlotName.vipBanner]: 'desktop_vip_top',
                    [SlotName.mpu]: 'desktop_vip_topright',
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.L]: {
                    [SlotName.vipBanner]: 'tablet_vip_top',
                    [SlotName.mpu]: 'tablet_vip_topright',
                    [SlotName.fBanner]: 'tablet_vip_bottom',
                },
                [ScreenSize.M]: {
                    [SlotName.mpu]: 'tablet_vip_topright',
                    [SlotName.fBanner]: 'tablet_vip_bottom',
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.floatingFooter]: 'mobile_vip_floating',
                    [SlotName.vipMiddleMobile]: 'mobile_vip_middle1',
                },
                [ScreenSize.XS]: {
                    [SlotName.floatingFooter]: 'mobile_vip_floating',
                    [SlotName.vipMiddleMobile]: 'mobile_vip_middle1',
                },
            },
        },
        [PageType.Homepage]: {
            [DeviceType.desktop]: {
                [ScreenSize.XXL]: {
                    [SlotName.homeSideAd]: 'desktop_hp_topRight',
                },
                [ScreenSize.XL]: {
                    [SlotName.homeSideAd]: 'desktop_hp_topRight',
                },
                [ScreenSize.L]: {
                    [SlotName.homeSideAd]: 'desktop_hp_topRight',
                },
            },
            [DeviceType.tablet]: {
                [ScreenSize.M]: {
                    [SlotName.homeSideAd]: 'tablet_hp_topright',
                },
            },
            [DeviceType.mobile]: {
                [ScreenSize.S]: {
                    [SlotName.homeBanner]: 'mobile_hp_middle1',
                },
                [ScreenSize.XS]: {
                    [SlotName.homeBanner]: 'mobile_hp_middle1',
                },
            },
        },
    };

    toObject() {
        if (Nullable.isNone(this.getPlacement())) {
            return undefined;
        }

        return {
            bidder: this.bidder,
            params: {
                publisherSubId: this.getPlacement(),
                networkId: this.networkId,
            },
        };
    }
}
