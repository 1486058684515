export default (googletag) => {
    if (!window.innerHeight) {
        return;
    }

    const isTallMobile: boolean = window.innerHeight > 600;

    googletag.cmd.push(() => {
        googletag.pubads().setTargeting('tall_mobile', isTallMobile.toString());
    });
};
