import { Device, PageType } from '@gumtree/shared/src/types/client-data';
import { PageConfig } from './ad-config/build-page-config';
import { isProductAd } from '../common/domain/ad-qualifier';
import renderDefaultSlot from './components/native/listing-renderer';
import renderCarousel from './components/carousel/renderer';
import { isDesktop } from '../common/domain/device';
import { AdModel } from '../common/transform-data-into-ad-models';

const hasProductAd = (ads) => ads.some((ad) => isProductAd(ad));

export type RenderedAd = {
    position: 'Mainline';
    impressionToken: string;
    requireVisibilityFeedback: boolean;
};

const renderer = ({
    slotsOnDom,
    device,
    adModels,
    serverConfig,
    pageType,
    visibilityFeedbackBaseUrl,
    abTests = [],
}: {
    slotsOnDom: HTMLElement[];
    device: Device;
    adModels: AdModel[];
    serverConfig: PageConfig;
    pageType: PageType;
    visibilityFeedbackBaseUrl: string;
    abTests: string[];
}): RenderedAd[] => {
    const renderedModels = [] as RenderedAd[];
    slotsOnDom.forEach((slot) => {
        const slotConfig = serverConfig.slots.find(
            ({ divId }) => divId === (slot as HTMLElement).id
        );

        if (!slotConfig) {
            return;
        }

        const showCarousel = hasProductAd(adModels) && slotConfig.isCarousel;

        let modelsForThisSlot: AdModel[] = [];
        if (showCarousel) {
            modelsForThisSlot = adModels.filter(isProductAd);

            const showChevrons = isDesktop(device) && modelsForThisSlot.length > 5;
            renderCarousel(
                modelsForThisSlot,
                slot,
                showChevrons,
                device,
                pageType,
                visibilityFeedbackBaseUrl
            );
        } else {
            modelsForThisSlot = adModels.slice(
                0,
                slotConfig.divId === 'slot12' ? serverConfig.maxTop : serverConfig.number
            );

            renderDefaultSlot(modelsForThisSlot, slot, device, abTests);
        }

        renderedModels.push(
            ...modelsForThisSlot.map(({ position, impressionToken }) => ({
                position,
                impressionToken,
                requireVisibilityFeedback: showCarousel,
            }))
        );
    });

    return renderedModels;
};

export default renderer;
