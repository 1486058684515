import React from 'react';
import PropTypes from 'prop-types';
import OriginalPrice from '../../common/price/original-price';
import SalePrice from '../../common/price/sale-price';
import Image from '../../common/image';
import ExternalLink from '../../common/external-link';

const truncate = (str, len) => (str && str.length > len ? `${str.substring(0, len)}...` : str);

const Tile = ({ seller, item, price, url, onClick }) => {
    const { image } = item;
    const originalImageSrc = image.url;
    const resizedImageSrc = originalImageSrc.includes('?')
        ? `${originalImageSrc}&h=200`
        : `${originalImageSrc}?h=200`;

    const style = {
        imageContainer: {
            width: '100px',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: '5px',
        },
        image: {
            maxWidth: '100%',
            maxHeight: '100%',
        },
        itemName: {
            fontSize: '15px',
            fontWeight: '600',
            textAlign: 'left',
            color: '#007FB0',
            lineHeight: '1.2',
            minHeight: '36px',
            wordWrap: 'break-word',
        },
        seller: {
            whiteSpace: 'nowrap',
            color: '#635b67',
            fontSize: '14px',
        },
    };

    const salePriceProps = {
        value: price.sale,
        currency: price.currency,
    };
    const originalPriceProps = {
        value: price.original,
        currency: price.currency,
        overridden: Boolean(price.sale),
        isInline: false,
    };

    const thumbnail = (
        <Image src={resizedImageSrc} style={style.image} description={item.description} />
    );

    const itemName = (
        <div style={style.itemName}>
            <ExternalLink url={url} onClick={onClick} noReferrer={false}>
                {truncate(item.name, 20)}
            </ExternalLink>
        </div>
    );

    return (
        <article data-testid="tile">
            <ExternalLink
                onClick={onClick}
                noReferrer={false}
                style={style.imageContainer}
                url={url}
            >
                {thumbnail}
            </ExternalLink>
            {itemName}
            {price.sale && <SalePrice {...salePriceProps} />}
            <OriginalPrice {...originalPriceProps} />
            <div style={style.seller}>{truncate(seller, 15)}</div>
        </article>
    );
};

Tile.defaultProps = {
    onClick: () => {},
};

Tile.propTypes = {
    item: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    price: PropTypes.object.isRequired,
    seller: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default Tile;
