import React from "react";
import { useSelector } from "react-redux";

const OnlyRenderOn = ({ children, app, web }: React.PropsWithChildren<OnlyRenderOnProps>) => {
    if(app && web) {
        throw new Error('You cannot use this component with both app and web properties specified as true.  If this was intentional, you can just remove the use of this component.');
    }

    if(!app && !web) {
        throw new Error('You must specify either the app or web property when using this component.');
    }

    const isAppView = useSelector(selectState);

    if(app && isAppView) {
        return <>{children}</>
    }

    if(web && !isAppView) {
        return <>{children}</>
    }

    return null;
};

function selectState(state: AppViewState) {
    return !!state?.page?.appViewDevice;
}

interface OnlyRenderOnProps {
    app?: boolean;
    web?: boolean;
}

type AppViewState = {
    page: {
        appViewDevice: string | null
    }
};

export default OnlyRenderOn;
