import { colors } from '../theme';
import { GumtreeTheme } from './gumtree-theme.d';

const modernColors = {
    green: '#5CE00B',
    darkGreen: '#54CC0A',

    navy: '#0D495C',
    lightBlue: '#E7EDEF',

    grey: '#ECEDEF',
    darkGrey: '#636E77',

    transparent: 'transparent',
};

export const modernTheme: GumtreeTheme = {
    name: 'modernTheme',
    palette: {
        primary: {
            main: modernColors.green,
            mainContrastText: modernColors.navy,
            dark: modernColors.darkGreen,
            darkContrastText: modernColors.navy,
            darker: modernColors.darkGreen,
            darkerContrastText: modernColors.navy,
        },
        secondary: {
            main: colors.white,
            mainContrastText: modernColors.navy,
            dark: modernColors.lightBlue,
            darkContrastText: modernColors.navy,
            darker: modernColors.lightBlue,
            darkerContrastText: modernColors.navy,
        },
        iconBtn: {
            icon: modernColors.navy,
            main: colors.white,
            border: modernColors.transparent,
            borderDark: colors.bark20,
            iconHover: modernColors.navy,
            mainHover: modernColors.lightBlue,
            mainHoverDark: modernColors.lightBlue,
            borderHover: modernColors.navy,
            ctaIcon: colors.white,
        },
        danger: {
            // These is currently using the primary stylings until we've got a new design
            main: modernColors.green,
            mainContrastText: modernColors.navy,
            dark: modernColors.darkGreen,
            darkContrastText: modernColors.navy,
            darker: modernColors.darkGreen,
            darkerContrastText: modernColors.navy,
        },
        disabled: {
            main: modernColors.grey,
            mainContrastText: modernColors.darkGrey,
            border: modernColors.transparent,
        },
        error: {
            text: colors.red,
        },
        action: {
            active: colors.blue60,
            secondary: {
                selected: {
                    default: modernColors.navy,
                    mainContrastText: colors.white
                },
                unselected: {
                    default: colors.white,
                    mainContrastText: modernColors.navy
                }
            }
        },
        input: {
            icon: colors.darkGrey,
        },
    },
    spacing: {
        xxs: "2px",
        xs: "4px",
        s: "8px",
        sm: "12px",
        m: "16px",
        ml: "20px",
        l: "24px",
        xl: "32px",
        xxl: "40px",
        xxxl: "48px",
        xxxxl: "64px",
    }
};
