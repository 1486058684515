import styled from '@emotion/styled';
import { colorVariables, mediaQuery, breakpoints } from '../base/theme';

export const Figure = styled.figure<{ noSrc: boolean }>`
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;

    &.profile-card-picture {
        width: 150px;
        height: 100%;

        .profile-card-name {
            ${mediaQuery.until(breakpoints.small)} {
                width: 150px;
            }
        }
    }

    ${(props) => props.noSrc &&`
        background: ${colorVariables.backgroundMedium};
        min-height: 100px;
        min-width: 100px;
        width: 100%;

        &.listing-job-image {
            width: 150px;

            img {
                @supports (object-fit: contain) {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            ${mediaQuery.until(breakpoints.medium)} {
                width: 100%;
            }
        }
    `}

    &.listing-job-image {
        width: 150px;

        img {
            @supports (object-fit: contain) {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        ${mediaQuery.until(breakpoints.medium)} {
            width: 100%;
        }
    }

    img {
        @supports (object-fit: cover) {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    svg {
        fill: ${colorVariables.fontLight};
        height: 50%;
        width: 50%;
    }
`;
