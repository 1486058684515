import React from 'react';
import { object, string } from 'prop-types';

import { Tab, Tabs } from '@gumtree/ui-library';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import TopLocations from './top-locations';
import TopSearches from './top-searches';
import TopSearchCondensed from './top-search-condensed';
import ErrorBoundary from '../error-boundary';

import { BrowseLinksContainer } from './style';

const BrowseLinks = ({ pageType, browseLinks }) => {
    if (!browseLinks) {
        return null;
    }
    const { topLocations, topCities, topSearches, topSearchCondensed } = browseLinks;

    const locationItems = pageType === 'Homepage' ? topLocations : topCities;

    const showRenderTopLocation =
        Array.isArray(locationItems) && locationItems.some((location) => location.items.length);

    return (
        <ErrorBoundary>
            <BrowseLinksContainer isSection {...qaAttribute('browse-links-container')}>
                <Tabs>
                    {topSearches ? (
                        <Tab title="Top searches" forceRender>
                            <TopSearches items={topSearches} />
                        </Tab>
                    ) : topSearchCondensed ? (
                        <Tab title={topSearchCondensed[0].title} forceRender>
                            <TopSearchCondensed topSearchCondensed={topSearchCondensed} />
                        </Tab>
                    ) : null}
                    {showRenderTopLocation && (
                        <Tab
                            title="Top Locations"
                            {...qaAttribute('top-locations-toggler')}
                            forceRender
                        >
                            <TopLocations
                                items={locationItems}
                                showTitle={pageType === 'Homepage'}
                                {...qaAttribute('top-locations-content')}
                            />
                        </Tab>
                    )}
                </Tabs>
            </BrowseLinksContainer>
        </ErrorBoundary>
    );
};

BrowseLinks.defaultProps = {
    browseLinks: null,
    pageType: 'Homepage',
};

BrowseLinks.propTypes = {
    browseLinks: object,
    pageType: string,
};

export default BrowseLinks;
